import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CheckIcon from "@mui/icons-material/Check";
// Material Dashboard 2 PRO React example components
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UserContext from "context/UserContext";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "assets/css/onboarding.css";

// redux
import { connect } from "react-redux";
import { change_allbanks } from "../../../../context/actions/allActions";
import WizardNew from "layouts/applications/wizard2.0";
import ListSubheader from "@mui/material/ListSubheader";
import { FormGroup } from "@mui/material";
import { act } from "react-dom/test-utils";
import ErrorPopup from "scientia/New Design/ErrorPopup/ErrorPopup";

const SettingsBar = ({ resetToDefault }) => {
  const navigate = useNavigate();

  const userContext = useContext(UserContext);
  const [dashboards, setDashboards] = useState([]);
  const [selectedOriginID, setSelectedOriginID] = useState(
    "62456e67813ff77e9dddc20b"
  );
  const [searchValue, setSearchValue] = useState("");
  const [activeDashboard, setActiveDashboard] = useState({
    _id: "",
    Title: "",
  });

  const [unique_users, setUniqueUsers] = useState([]);
  // dropdown
  const [action, setAction] = useState(false);
  const handleChange = () => setAction(!action);

  // modal
  const [open_modal, setOpenModal] = useState(false);
  const handleOpen = () => {
    if (dashboard == null) {
      alert(
        "You can't delete the active dashboard. Please select another one first."
      );
      return;
    }
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  async function getActiveDashboard() {
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/workspace/get";
    const metadataResponse = await fetch(apiURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
    var data = resp[0];
    if (data.dashboard !== undefined) {
      setActiveDashboard(data.dashboard[0]);
    }
  }

  async function removeDashBoard() {
    var apiURL = process.env.REACT_APP_SERVER_URL;
    var data = {};
    data["dashboardId"] = selectedOriginID;
    apiURL += "/api/dashboard/remove";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      body: JSON.stringify(data),
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
    window.location.href = window.location.href;
  }

  // wizard drawer
  const [drawer, setDrawer] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  function editDashboard() {
    let link = activeDashboard._id;
    if (dashboard !== null && dashboard !== undefined) {
      link = dashboard;
    }
    dashboards.forEach((item, i) => {
      if (item._id == link) {
        if (userContext.userData.email !== item.us.email) {
          setIsCopy(true);
        }
      }
    });
    setDrawer(true);
  }

  async function getDashboards() {
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/getall";
    const metadataResponse = await fetch(apiURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();

    setDashboards(resp);

    //  Get unique users
    var array = [];

    resp
      .filter((v) => {
        return v._id !== activeDashboard._id;
      })
      .map((item) => {
        if (item.us !== undefined) {
          var obj = {
            id: item.us._id,
            firstName: item.us.firstName,
            lastName: item.us.lastName,
            email: item.us.email,
          };
          array.push(obj);
        }
      });
    let unique = [...new Map(array.map((item) => [item["id"], item])).values()];

    setUniqueUsers(unique);
  }

  const MenuProps = {
    PaperProps: {
      style: {
        height: 550,
        width: 500,
        marginTop: 4,
        marginLeft: 110,
        paddingTop: 0,
      },
    },
  };

  useEffect(() => {
    getDashboards();
    getActiveDashboard();
    if (activeDashboard._id !== "") {
    }
  }, []); // <-- empty dependency array

  const [dashboard, setDashboard] = useState(null);

  const handleDashboard = (dashboardId) => {
    const selectedDashboard = dashboards.find(
      (dashboard) => dashboard._id === dashboardId
    );

    setDashboard(selectedDashboard);
  };

  useEffect(() => {
    selectDashboard();
  }, [dashboard]);

  async function selectDashboard() {
    let data = {};
    data["dashboardId"] = dashboard;
    data["originId"] = selectedOriginID;
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/setActive";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    const resp = await metadataResponse.json();
    if (resetToDefault) resetToDefault();

    window.location.reload();
  }

  return (
    <MDBox className="SettingBarComponent">
      <WizardNew
        drawer={drawer}
        setDrawer={setDrawer}
        dashBoardID={activeDashboard._id}
        isCopy={isCopy}
      />
      <ErrorPopup
        errorModal={open_modal}
        setErrorModal={setOpenModal}
        errorCB={true}
        cb={removeDashBoard}
        title="Delete this Market"
        text="Are you really sure you want to delete this Market?"
      />
      <MDBox className="settingAddition">
        {/* <Modal open={open_modal} onClose={handleClose}>
          <MDBox
            sx={{
              position: "absolute",
              top: "49%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <MDTypography variant="h6" component="h2">
              Are you sure you want to delete this dashboard?
            </MDTypography>
            <MDTypography sx={{ mt: 2, display: "flex" }}>
              <MDButton
                variant="outlined"
                color="info"
                onClick={
                  // delete the dashboard
                  removeDashBoard
                }
              >
                Yes
              </MDButton>
              <MDButton
                variant="outlined"
                color="info"
                sx={{ ml: 3 }}
                onClick={(e) => setOpenModal(false)}
              >
                No
              </MDButton>
            </MDTypography>
          </MDBox>
        </Modal> */}

        {dashboards !== null ? (
          <>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Markets</InputLabel>
              <Select
                sx={{ p: 1, width: 300 }}
                MenuProps={MenuProps}
                label="Markets"
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                value={
                  dashboard !== null && dashboard !== undefined
                    ? dashboard._id
                    : activeDashboard._id
                }
                renderValue={(selected) =>
                  // if selected is not null, return the selected market name
                  selected
                    ? dashboards
                        .filter((d) => d._id === selected)
                        .map((item) => {
                          return item.Title;
                        })
                    : activeDashboard.Title
                }
                displayEmpty
                // when the page loads, the value is set to the active dashboard
                onChange={(e) => {
                  handleDashboard(e.target.value);
                }}
              >
                <ListSubheader
                  sx={{
                    paddingTop: 1,
                    zIndex: 1,
                    lineHeight: "41px",
                    height: "41px",
                  }}
                >
                  <TextField
                    size="small"
                    // Autofocus on textfield
                    autoFocus
                    placeholder="Type to search..."
                    fullWidth
                    sx={{ textAlign: "left" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ color: "black" }} />
                        </InputAdornment>
                      ),
                      style: { height: "29px" },
                    }}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                <MenuItem value={activeDashboard._id}>
                  <div
                    style={{
                      flexGrow: "1",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {activeDashboard.Title}
                  </div>
                </MenuItem>
                <ListSubheader
                  sx={{
                    fontSize: "15px",
                    paddingBottom: "0px",
                    height: "40px",
                    zIndex: 1,
                  }}
                >
                  By You
                </ListSubheader>
                {dashboards
                  .filter((v) => {
                    return v._id !== activeDashboard._id;
                  })
                  .filter((item) => {
                    return item.Title.toLowerCase().includes(
                      searchValue.toLowerCase()
                    );
                  })
                  .map((item, i) => {
                    if (item.us !== undefined) {
                      if (item.us.email === userContext.userData.email) {
                        return (
                          <MenuItem key={item._id} value={item._id}>
                            <div
                              style={{
                                flexGrow: "1",
                                paddingTop: "1px",
                                paddingBottom: "1px",
                                marginLeft: "10px",
                              }}
                            >
                              {item.Title}
                            </div>
                            <div>
                              <DeleteIcon
                                sx={{ color: "black" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedOriginID(item._id);
                                  setOpenModal(true);
                                }}
                              />
                            </div>
                          </MenuItem>
                        );
                      }
                    }
                  })}
                {unique_users
                  .filter((v) => {
                    return v.email !== userContext.userData.email;
                  })
                  .map((user) => {
                    return [
                      <ListSubheader
                        sx={{
                          fontSize: "15px",
                          paddingBottom: "0px",
                          height: "40px",
                          zIndex: 1,
                        }}
                      >
                        By {user.firstName} {user.lastName}
                      </ListSubheader>,
                      dashboards
                        .filter((v) => {
                          return v._id !== activeDashboard._id;
                        })
                        .filter((item) => {
                          return item.Title.toLowerCase().includes(
                            searchValue.toLowerCase()
                          );
                        })
                        .map((item, i) => {
                          if (item.us !== undefined) {
                            if (item.us.email === user.email) {
                              return (
                                <MenuItem key={item._id} value={item._id}>
                                  <div
                                    style={{
                                      flexGrow: "1",
                                      paddingTop: "1px",
                                      paddingBottom: "1px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {item.Title}
                                  </div>
                                </MenuItem>
                              );
                            }
                          }
                        }),
                    ];
                  })}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <MDButton
                className="SettingsBarBtn"
                variant="gradient"
                color="info"
                title="Create New"
                onClick={() => navigate("/wizard")}
              >
                <AddIcon />
              </MDButton>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <MDButton
                className="SettingsBarBtn"
                title="Edit"
                variant="gradient"
                //  variant="outlined"
                color="info"
                onClick={(e) => editDashboard()}
              >
                <EditIcon />
              </MDButton>
            </FormControl>
          </>
        ) : (
          <></>
        )}
      </MDBox>
    </MDBox>
  );
};

// injecting state to props
const mapStateToProps = (state) => {
  return state;
};

// export default Bank;
export default connect(mapStateToProps, { change_allbanks })(SettingsBar);
