// ref: component to track
// cb: callback to be executed if the hook triggers
// cancel: a state that you pass so that it canclels the trigger (per se: error modal in view)

import { useEffect } from "react";

export default function useOutsideAlerter(ref, cb, cancel) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cancel) return null;
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, cancel]);
}
