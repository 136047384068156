import IconButton from "@mui/material/IconButton";
import HighchartsExporting from "highcharts/modules/exporting";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Popup from "layouts/dashboards/popup";
import downloadweb from "assets/images/downloadwebp.webp";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";

const ITEM_HEIGHT = 48;

export default function LongMenu({ chartRef, title }) {
  useEffect(() => {
    const cb = () => {
      if (!document.fullscreenElement) {
        chartRef?.current?.chart?.setTitle({ text: "" });
      }
    };
    document.addEventListener("fullscreenchange", cb);
    return () => {
      document.removeEventListener("fullscreenchange", cb);
    };
  }, []);

  
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    height: "80vh",
    border: "1px solid #e4e4e4",
    overflow: "scroll",
    display: "block",
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadXLS = () => {
    setOpenModal(true);

    handleClose();
  };

  const downloadPDF = () => {
    setOpenModal(true);
    handleClose();
  };

  const downloadPNG = () => {
    setOpenModal(true);
    handleClose();
  };

  const fullscreen = () => {
    chartRef?.current?.chart?.fullscreen?.toggle();

    // timeout to compensate for the time it takes to get in fullscreen, so the title does not flash
    setTimeout(() => {
      chartRef?.current?.chart?.setTitle({ text: title });
    }, 150);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon color="dark" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        disableScrollLock={true}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "19ch",
          },
        }}
      >
        <MenuItem onClick={fullscreen}>Fullscreen</MenuItem>
        <MenuItem onClick={downloadPNG} sx={{ opacity: "0.6" }}>
          Download PNG
        </MenuItem>
        <MenuItem onClick={downloadPDF} sx={{ opacity: "0.6" }}>
          Download PDF
        </MenuItem>
        <MenuItem onClick={downloadXLS} sx={{ opacity: "0.6" }}>
          Download XLS
        </MenuItem>
      </Menu>
      {openModal ? (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <MDBox display="flex" justifyContent="flex-end">
              <CloseIcon
                sx={{ color: "black", cursor: "pointer", mr: 1, mt: 1 }}
                fontSize="large"
                onClick={handleCloseModal}
              />
            </MDBox>
            <Popup
              header="Download the Graph or Raw Data"
              subheader="Create your presentations faster with accurate data and allow your team to perform data-backed decisions."
              gif={downloadweb}
            />
          </Box>
        </Modal>
      ) : null}
    </div>
  );
}
