import { Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import OptionsMenu from "./optionsMenu";
import AppleIcon from "@mui/icons-material/Apple";
import ComputerIcon from "@mui/icons-material/Computer";
import WatchIcon from "@mui/icons-material/Watch";

const ChartsHeader = ({
  selectedMediaID,
  handleMedia,
  chartRef,
  title,
  subtitle,
  subtitle2,
  showButtons = true,
  marginLeft,
  marginRight,
}) => {
  return (
    <MDBox
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 2,
        marginBottom: 8,
        marginInline: 4,
      }}
    >
      <MDBox>
        {title && <Typography variant="h4">{title}</Typography>}
        {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
      </MDBox>
      <MDBox>
        {subtitle2 ? (
          <Typography
            variant="subtitle2"
            sx={{ ...marginLeft, ...marginRight }}
          >
            {subtitle2}
          </Typography>
        ) : (
          <Typography
            variant="subtitle2"
            sx={{ ...marginLeft, ...marginRight }}
          >
            {selectedMediaID == "22"
              ? "iOS"
              : selectedMediaID == "16"
              ? "Web"
              : "Apple Watch"}
          </Typography>
        )}
      </MDBox>

      <MDBox sx={{ display: "flex", justifyContent: "center" }}>
        {showButtons && (
          <>
            <MDButton
              sx={{ mr: 2 }}
              className={
                selectedMediaID == "22"
                  ? "AnalyticsButtonSelected"
                  : "AnalyticsButton"
              }
              // style={{backgroundColor:'transparent'}}
              title="Ios"
              disabled={selectedMediaID == "22" ? true : false}
              color={selectedMediaID == "22" ? "info" : ""}
              onClick={() => handleMedia("22")}
            >
              <AppleIcon color={selectedMediaID == "22" ? "light" : "dark"} />
            </MDButton>

            <MDButton
              className={
                selectedMediaID == "16"
                  ? "AnalyticsButtonSelected"
                  : "AnalyticsButton"
              }
              sx={{ mr: 2 }}
              title="Web"
              disabled={selectedMediaID == "16" ? true : false}
              //  style={{backgroundColor:'transparent'}}
              color={selectedMediaID == "16" ? "info" : ""}
              onClick={() => handleMedia("16")}
            >
              <ComputerIcon
                color={selectedMediaID == "16" ? "light" : "dark"}
              />
            </MDButton>

            <MDButton
              sx={{ mr: 4 }}
              className={
                selectedMediaID == "27"
                  ? "AnalyticsButtonSelected"
                  : "AnalyticsButton"
              }
              // style={{backgroundColor:'transparent'}}
              disabled={selectedMediaID == "27" ? true : false}
              color={selectedMediaID == "27" ? "info" : ""}
              title="Apple Watch"
              onClick={() => handleMedia("27")}
            >
              <WatchIcon color={selectedMediaID == "27" ? "light" : "dark"} />
            </MDButton>
          </>
        )}

        <OptionsMenu chartRef={chartRef} title={title} />
      </MDBox>
    </MDBox>
  );
};

export default ChartsHeader;
