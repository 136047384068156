import Card from "@mui/material/Card";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import MDBox from "components/MDBox";
import CircularProgress from '@mui/material/CircularProgress';
import { useState,useContext,useEffect,useRef } from "react";
import UserContext from 'context/UserContext';
import MDButton from "components/MDButton";
import AppleIcon from '@mui/icons-material/Apple';
import ComputerIcon from '@mui/icons-material/Computer';
import WatchIcon from '@mui/icons-material/Watch';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChartsHeader from "../ChartsHeader";

export default function FunctionalitiesOverview({loadPopup,mediaid,workspaceMedia }){


    const userContext = useContext(UserContext);
    const [isLoading,setIsLoading]=useState(true);
    const [dashboards,setDashboards]=useState([]);
    const [selectedBankID,setSelectedBankID]=useState(0);
    const [selectedMediaID,setSelectedMediaID]=useState(null);
    const [functionalitiesOverview,setFunctionalitiesOverview]=useState(null);
    // const defaultBank="108"; // REVOLUT V.7 (EU)
    // const defaultBankname="Revolut V.7 (EU)";
    // reload components easily
    const [seed, setSeed] = useState(1);

    const [options,setOptions] = useState({
        title: {
          text: 'Functionalities Overview'
        },   type: 'column',
        series: [{
          data: [1, 2, 3]
        }]
      });

    const [options_functionalities_breakdown,setOptions_functionalities_breakdown] = useState({
        title: {
          text: 'Functionalities Breakdown'
        },   type: 'column',
        series: [{
          data: [1, 2, 3]
        }]
      });

    // async function chart_functionalities_overview_forBankMedia(bankid,mediaid) {

    //   setSelectedBankID(bankid);

    //     setIsLoading(true)


    //    var data={};
    //    data['bankid']=bankid;
    //    data['mediaid']=selectedMediaID;
    //    var apiURL = process.env.REACT_APP_SERVER_URL;
    //      apiURL+="/api/chart_functionalities";
    //     const metadataResponse = await fetch(apiURL, {
    //       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //    mode: 'cors', // no-cors, *cors, same-origin
    //    body:JSON.stringify(data),
    //    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //       headers: {
    //         'bearer': userContext.userData.apiToken,
    //         'Content-Type': 'application/json'
    //       },
    //     });
    //     var resp = await metadataResponse.json();
    //     //console.log(resp);
    //     var fc=[];
    //     var categories=[];
    //     var data=[];
    //     resp.forEach(function(v,index) {
    //        var bank=[];
    //        fc[v.DisplayName]=v.NodeID;

    //        categories.push(v.DisplayName);
    //        data.push(10);
    //     });
    //     setFunctionalitiesOverview(fc);
    //     console.log(fc)

    //     setOptions({chart: {
    //         type: 'column'
    //     },
    //     title: {
    //         text: 'Functionalities Overview'
    //     },
    //     subtitle: {
    //         text: ''
    //     },
    //     xAxis: {
    //         categories: categories,
    //         crosshair: true
    //     },


    //     plotOptions: {
    //         column: {
    //            colorByPoint: true,
    //             pointPadding: 0.2,
    //             borderWidth: 0
    //         },
    //         series: {
    //            cursor: 'pointer',
    //            point: {
    //                events: {
    //                    click: function () {
    //                    //    chart_functionalities_overview_forBankMedia(fc[this.category]);
    //                    console.log(this);
    //                   loadPopup(selectedMediaID,"1",fc[this.category],bankid);
    //                    }
    //                }
    //            }
    //        }
    //     },
    //     series: [{
    //         name: 'Functionalities',
    //         data: data

    //     }]});
    //    // setData(resp);
    //     setIsLoading(false)

    //  }

    async function chart_functionalities_overview_forBank(bankid) {
        setSelectedBankID(bankid);

        setIsLoading(true);
        var Medianame=(selectedMediaID=="16"?"Web":"iOS");
        var Medianame=(selectedMediaID=="27"?"Apple Watch":Medianame);
       var data={};
       data['bankid']=bankid;
       data['mediaid']=selectedMediaID;
       var apiURL = process.env.REACT_APP_SERVER_URL;
         apiURL+="/api/chart_functionalities_overview";
        const metadataResponse = await fetch(apiURL, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
       mode: 'cors', // no-cors, *cors, same-origin
       body:JSON.stringify(data),
       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            'bearer': userContext.userData.apiToken,
            'Content-Type': 'application/json'
          },
        });

        var resp = await metadataResponse.json();
        //console.log(resp);
        var fc=[];
        var categories=[];
        var data=[];
        resp.forEach(function(v,index) {
           var bank=[];
           fc[v.DisplayName]=v.NodeID;

           categories.push(v.DisplayName);
           data.push(v.Functionalities);
        });
        setFunctionalitiesOverview(fc);
        //console.log(fc);
        setOptions({chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: categories,
            crosshair: true
        },
        yAxis: {
            title: { text: 'Functionalities'} ,

        },

        exporting: {
          buttons: false
        },

        plotOptions: {
            column: {
               colorByPoint: true,
                pointPadding: 0.2,
                borderWidth: 0
            },
            series: {
              label: {
              enabled: false
         },  showInLegend: false,
               cursor: 'pointer',
               point: {
                   events: {
                       click: function () {
                        //   chart_functionalities_overview_forBankMedia(bankid,fc[this.category]);
                            loadPopup(selectedMediaID,"1",fc[this.category],bankid);
                       }
                   }
               }
            }
        },
        series: [{
            name: '',
            data: data

        }]});
        // setData(resp);
        setIsLoading(false)

    }

    async function chart_functionalities_overview() {
        setSelectedBankID(0);

        setIsLoading(true)

        var data={}
        data['mediaid']=selectedMediaID;
       var apiURL = process.env.REACT_APP_SERVER_URL;
         apiURL+="/api/chart_functionalities_overview";
        const metadataResponse = await fetch(apiURL, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
       mode: 'cors', // no-cors, *cors, same-origin
       body:JSON.stringify(data),
       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            'bearer': userContext.userData.apiToken,
            'Content-Type': 'application/json'
          },
        });

        var Medianame=(selectedMediaID=="16"?"Web":"iOS");
        var Medianame=(selectedMediaID=="27"?"Apple Watch":Medianame);
        var resp = await metadataResponse.json();
        //console.log(resp);
        var fc=[];
        var categories=[];
        var data=[];
        var colors=[];

        resp.forEach(function(v,index) {
           var bank=[];
           if (v.BankID!==undefined&&v.Functionalities!==undefined) {
             fc[v.BankName]=v.BankID;

            categories.push(v.BankName);
            data.push(v.Functionalities);
              colors.push(v.colors.Color);
            }
        });

        setFunctionalitiesOverview(fc);
     //   console.log(fc);
        setOptions({chart: {
            type: 'column'
        },
              			credits: {
                        enabled: false
                      },
        title: {
            text: ''
        },

        xAxis: {
            categories: categories,
            crosshair: true
        },
        yAxis: {
                      title: { text: 'Functionalities'} ,

        },
        exporting: {
          menuItemDefinitions: {
          toggleTable: {
              onclick: function() {
                if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
                this.dataTableDiv.style.display = 'none';
                } else {
                this.viewData();
                this.dataTableDiv.style.display = '';
                }
              },
              text: 'Toggle Table'
              }
            },
            buttons: false,
        },
        plotOptions: {
            column: {
               colorByPoint: true,
                pointPadding: 0.2,
                borderWidth: 0
            },color:colors,
            series: {  label: {
              enabled: false
         },  showInLegend: false,
               cursor: 'pointer',
               point: {
                   events: {
                       click: function () {

                           chart_functionalities_overview_forBank(fc[this.category]);

                       }
                   }
               }
           }
        },
        series: [{
            name: '',
colors:colors,
            data: data

        }]});
       // setData(resp);
        setIsLoading(false)

     }

    // async function chart_functionalities_breakdown(bankid,bankname) {
    //     setOptions_functionalities_breakdown({
    //       title: {
    //           text: 'Please wait'
    //       },
    //       series:[]
    //     });

    //      setIsLoading(true);

    //     var data={};
    //     data['bankid']=bankid;
    //     var apiURL = process.env.REACT_APP_SERVER_URL;
    //       apiURL+="/api/chart_functionalities_breakdown";
    //      const metadataResponse = await fetch(apiURL, {
    //        method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //     mode: 'cors', // no-cors, *cors, same-origin
    //     body:JSON.stringify(data),
    //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //        headers: {
    //          'bearer': userContext.userData.apiToken,
    //          'Content-Type': 'application/json'
    //        },
    //      });

    //      var resp = await metadataResponse.json();
    //      //console.log(resp);



    //      setOptions_functionalities_breakdown({chart: {
    //          type: 'column'
    //      },
    //      title: {
    //          text: 'Functionalities Breakdown'
    //      },
    //      subtitle: {
    //          text: bankname
    //      },
    //      xAxis: {
    //          categories: ['Web','Mobile'],
    //          crosshair: true
    //      },

    //      tooltip: {
    //             formatter: function(d){
    //             //  console.log(this.point.index);
    //                 var rv=  '<span style="color:' + this.point.color + '">\u25CF'
    //                 if (this.series.name=="Functionalities") {
    //                   rv=bankname;
    //                 } else if (this.series.name=="Average") {
    //                   rv="Average of collection is "
    //                 } else if (this.series.name=="Worst") {
    //                   rv="Worst performer is ";
    //                   if (this.point.index==0) rv+=resp.worst_16[0].BankName; else rv+=resp.worst_23[0].BankName;
    //                 } else  {
    //                   rv="Best performer is ";
    //                   if (this.point.index==0) rv+=resp.best_16[0].BankName; else rv+=resp.best_23[0].BankName;
    //                 }

    //                 rv += '</span> <br>with <b> ' + this.y + '</b> functionalities<br/>';
    //                 return rv;
    //             }
    //         }
    //      ,

    //      plotOptions: {
    //          column: {
    //             colorByPoint: true,
    //              pointPadding: 0.2,
    //              borderWidth: 0
    //          },
    //          series: {
    //             cursor: 'pointer',
    //             point: {
    //                 events: {
    //                     click: function () {
    //                       //  chart_functionalities_overview_forBankMedia(bankid,fc[this.category]);
    //                     }
    //                 }
    //             }
    //         }
    //      },
    //      series: [{
    //          name: 'Functionalities',
    //          data: [resp.my_16[0].Functionalities,resp.my_23[0].Functionalities]

    //      }, {
    //         type: 'spline',
    //         name: 'Average',
    //         data: [resp.avg_16[0].avg, resp.avg_23[0].avg],
    //         marker: {
    //             lineWidth: 2,
    //             lineColor: Highcharts.getOptions().colors[3],
    //             fillColor: 'white'
    //         }
    //     },{
    //        type: 'spline',
    //        name: 'Worst',
    //        data: [resp.worst_16[0].Functionalities, resp.worst_23[0].Functionalities],
    //         marker: {
    //            lineWidth: 2,
    //            lineColor: Highcharts.getOptions().colors[1],
    //            fillColor: 'white'
    //        }
    //    },{
    //       type: 'spline',
    //       name: 'Best',
    //       data: [resp.best_16[0].Functionalities, resp.best_23[0].Functionalities],
    //       marker: {
    //           lineWidth: 2,
    //           lineColor: Highcharts.getOptions().colors[2],
    //           fillColor: 'white'
    //       }
    //   }]});
    //     // setData(resp);
    //      setIsLoading(false)

    //   }


      // const reset = () => {
      //   setSeed(Math.random());
      // }


      async function setUserWorkspaceState(newmedia){
        let data={};
        var newState = workspaceMedia
        console.log(newState)
        newState.functionalities_overview = newmedia
        data['charsState']=[newState];
        var apiURL = process.env.REACT_APP_SERVER_URL;
          apiURL+="/api/changeUserWorkspaceState";
         const metadataResponse = await fetch(apiURL, {
           method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        body:JSON.stringify(data),
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           headers: {
           'bearer': userContext.userData.apiToken,
           'Content-Type': 'application/json'
           },
         });
          var resp = await metadataResponse.json();
        }


    async function getDashboards() {

        // console.log("start api")
        var apiURL = process.env.REACT_APP_SERVER_URL;
          apiURL+="/api/dashboard/getall";
         const metadataResponse = await fetch(apiURL, {
           method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           headers: {
             'bearer': userContext.userData.apiToken,
             'Content-Type': 'application/json'
           },
         });
          var resp = await metadataResponse.json();
          // console.log(resp)
         setDashboards(resp);
         setSelectedMediaID(mediaid);
        //  chart_functionalities_overview();



      }
      useEffect(()=>{
        getDashboards();
      }, [])

      useEffect(()=>{
        if (selectedMediaID!=null) {
          chart_functionalities_overview()
        }
      }, [selectedMediaID])


      const handleMedia=(mediaid)=>{
        setUserWorkspaceState(mediaid)
        setSelectedMediaID(mediaid)
      }

      const chartRef = useRef();



      return (
        <Card>
          <MDBox p={1}>
            {selectedBankID != 0 &&
                <ArrowBackIcon
                  color="dark"
                  onClick={() => chart_functionalities_overview()}
                  sx={{
                    position: "absolute",
                    left: 6,
                    top: 32,
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                  fontSize="medium"
                />
            }

            <ChartsHeader
              title="Functionalities Overview"
              handleMedia={handleMedia}
              selectedMediaID={selectedMediaID}
              chartRef={chartRef}
            />

            {!isLoading ? (
              <>
                <HighchartsReact
                  ref={chartRef}
                  highcharts={Highcharts}
                  options={options}
                />
              </>
            ) : (
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ p: 25 }}
              >
                <CircularProgress />
              </MDBox>
            )}
          </MDBox>
        </Card>
      );

}
