/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState,useContext } from "react";

// react-router-dom components
import { Route, Navigate, useLocation,Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import brandWhite from "assets/images/fi-logo-ls.png";
// Images
import bgImage from "assets/images/bg-sign-in.jpg";
import * as Cookies from "js-cookie";
import UserContext from '../../../../context/UserContext';



function Logout() {

  const userContext = useContext(UserContext);

  var frm=[];
    function handleChange (e) {
        frm[e.target.id]=e.target.value;
     }

  const [rememberMe, setRememberMe] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [waitForPin,setWaitForPin]=useState(null);

  const sessionCookie = Cookies.get("session");

  if (sessionCookie !== undefined) {
    userContext.setUserDataForContext(null);
     Cookies.remove("session");
  }


//   <Navigate to="/authentication/sign-in/basic" replace={true} />
    return(
     <p>hello</p>
    );
}

export default Logout;
