import Card from "@mui/material/Card";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import MDBox from "components/MDBox";
import CircularProgress from '@mui/material/CircularProgress';
import { useState,useContext,useEffect,useRef } from "react";
import UserContext from 'context/UserContext';

import MDButton from "components/MDButton";
import AppleIcon from '@mui/icons-material/Apple';
import ComputerIcon from '@mui/icons-material/Computer';
import WatchIcon from '@mui/icons-material/Watch';
import ChartsHeader from "../ChartsHeader";

export default function SuperChart({loadPopup,mediaid,workspaceMedia}){

    const userContext = useContext(UserContext);
    const [isLoading,setIsLoading]=useState(true);
    const [dashboards,setDashboards]=useState([]);
    const [selectedMediaID,setSelectedMediaID]=useState(null);
    const [selectedExclude,setSelectedExclude]=useState(workspaceMedia.exclude_overlapping);
    // const [screenwidth, setScreenWidth] = useState(1560);

    

    const [isLoadingSuperChart, setIsLoadingSuperChart] = useState(true);

    // const [optionsMarketPosition,setOptionsMarketPosition]=useState({
    //     title: {
    //       text: 'Market Position'
    //     },   type: 'column',
    //     series: [{
    //       data: [1, 2, 3]
    //     }]
    //   });


    const [optionsSuperChart,setOptionsSuperChart] = useState({
        exporting: {
                enabled: true,
                buttons: false
              },
        title: {
          text: 'Functionalities Overview'
        },   type: 'column',
        series: [{
          data: [1, 2, 3]
        }]
      });
      const hashCode = s => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0)
    async function chart_superchart() {
      // console.log("selected :", selectedMediaID)

        setIsLoading(true)
        setIsLoadingSuperChart(true)


       var data={};
      //  console.log("super")
       data['mediaid']=selectedMediaID;
       data['exclude']=selectedExclude;
       var apiURL = process.env.REACT_APP_SERVER_URL;
         apiURL+="/api/chart_superchart";
        const metadataResponse = await fetch(apiURL, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
       mode: 'cors', // no-cors, *cors, same-origin
       body:JSON.stringify(data),
       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            'bearer': userContext.userData.apiToken,
            'Content-Type': 'application/json'
          },
        });

        var json = await metadataResponse.json();
        var resp=[];
        for(var i in json) {
          resp.push(json[i]);
         }

         var fc=[];
         var categories=[];
         var series=[];
         var banks=[];
         var bank_name=[];
         resp.forEach(function(row,index) {
             if (!categories.includes(row.DisplayName)) {
               categories.push(row.DisplayName);
             }
             if (banks[row.BankID]===undefined)  banks[row.BankID]=[];
             var data={name:row.BankName,data:row.count}
             banks[row.BankID]['BankName']=row.BankName;



             banks[row.BankID][hashCode(row.DisplayName+"treeid")]=row.ParentID;
             banks[row.BankID]['BankID']=row.BankID;
             banks[row.BankID][hashCode(row.DisplayName)]=row.count;
             banks[row.BankID]['Color']=row.colors.Color;
    
         });


         banks.forEach(function(row,index) {
           let data=[]
           let nodes=[];

           categories.forEach(function(r,i) {
             let val=0;
            let valNode=0;
             if (row[hashCode(r)]!==undefined) val=row[hashCode(r)];
             data.push(val)
                if (row[hashCode(r+"treeid")]!==undefined) valNode=row[hashCode(r+"treeid")];
              nodes.push(valNode)
           });
              series.push({name:row.BankName,bankid:row['BankID'],color:row['Color'],nodes:nodes,data:data});
         });



        setOptionsSuperChart({chart: {
            type: 'bar',
                height: 750,

        },			credits: {
                enabled: false
              },


        title: {
         text: ''
     },
     subtitle: {
         text: ''
     },
     xAxis: {

         categories: categories,


     },
     yAxis: {
       min: 0,
              title: {
                  text: ''
              }
     },

     exporting: {
      menuItemDefinitions: {
      toggleTable: {
          onclick: function() {
            if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
            this.dataTableDiv.style.display = 'none';
            } else {
            this.viewData();
            this.dataTableDiv.style.display = '';
            }
          },
          text: 'Toggle Table'
          }
        },
        buttons: false,
    },

     plotOptions: {
        series: {
              stacking: 'percent',
              cursor: 'pointer',
              point: {
                  events: {
                      click: function () {
                      //    chart_functionalities_overview_forBankMedia(fc[this.category]);
                      console.log(this);
                      let d=this.series.userOptions;
                      console.log(d);
                      if (selectedExclude==true) {
                        loadPopup(selectedMediaID,"7",d.nodes[this.index],''+d.bankid,'6',"HELLO","JHIh");
                      } else {
                        loadPopup(selectedMediaID,"1",d.nodes[this.index],''+d.bankid,'6',"IHHI","IUG");

                      }
                   //  loadPopup(selectedMediaID,"1",fc[this.category],bankid);
                      }
                  }
              }
          },
     },
     legend: {
        //  reversed: true
        enabled:false
     },

     series: series});
       // setData(resp);
        setIsLoading(false)
        setIsLoadingSuperChart(false)

     }

     async function setUserWorkspaceState(newmedia){
      let data={};
      var newState = workspaceMedia
      console.log(newState)
      newState.market_overview = newmedia
      data['charsState']=[newState];
      var apiURL = process.env.REACT_APP_SERVER_URL;
        apiURL+="/api/changeUserWorkspaceState";
       const metadataResponse = await fetch(apiURL, {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      body:JSON.stringify(data),
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
         },
       });
        var resp = await metadataResponse.json();
      }

      async function setUserWorkspaceStateExclude(new_exclude){
        let data={};
        var newState = workspaceMedia
        // console.log(newState)
        newState.exclude_overlapping = new_exclude
        data['charsState']=[newState];
        var apiURL = process.env.REACT_APP_SERVER_URL;
          apiURL+="/api/changeUserWorkspaceState";
         const metadataResponse = await fetch(apiURL, {
           method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        body:JSON.stringify(data),
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           headers: {
           'bearer': userContext.userData.apiToken,
           'Content-Type': 'application/json'
           },
         });
          var resp = await metadataResponse.json();
        }

     async function getDashboards() {

        // console.log("start api")
        var apiURL = process.env.REACT_APP_SERVER_URL;
          apiURL+="/api/dashboard/getall";
         const metadataResponse = await fetch(apiURL, {
           method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           headers: {
             'bearer': userContext.userData.apiToken,
             'Content-Type': 'application/json'
           },
         });
          var resp = await metadataResponse.json();
          // console.log(resp)
         setDashboards(resp);

         setSelectedMediaID(mediaid)

      }



      useEffect(()=>{
        getDashboards();
      }, [])


      useEffect(()=>{
        if (selectedMediaID!==null) {
          chart_superchart()
        }
      }, [selectedMediaID])
      useEffect(()=>{
        if (selectedMediaID!==null) {
          chart_superchart()
        }
      }, [selectedExclude])

      const handleMedia=(mediaid)=>{
        setSelectedMediaID(mediaid)
        setUserWorkspaceState(mediaid)

      }
      const handleExclude=(ex)=>{
        setUserWorkspaceStateExclude(ex)
        setSelectedExclude(ex)
      }

      const chartRef = useRef();

      return (
        <Card>
          <MDBox p={1}>
            <ChartsHeader
              title="Functionality Market Overview"
              handleMedia={handleMedia}
              selectedMediaID={selectedMediaID}
              chartRef={chartRef}
              marginRight={{marginRight: 7}}
            />
            {isLoadingSuperChart !== true ? (
              <>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsSuperChart}
                  ref={chartRef}
                />   
                <MDBox
                  sx={{ display: "flex", justifyContent: "center", mt: 1, mb: 3 }}
                >
                  <MDButton
                    className={
                      selectedExclude == false
                        ? "AnalyticsButtonSelected"
                        : "AnalyticsButton"
                    }
                    style={{ backgroundColor: "transparent" }}
                    disabled={selectedExclude === false ? true : false}
                    color={selectedExclude == false ? "" : ""}
                    onClick={() => {
                      handleExclude(false);
                    }}
                  >
                    Include Overlapping
                  </MDButton>
      
                  <MDButton
                    className={
                      selectedExclude == true
                        ? "AnalyticsButtonSelected"
                        : "AnalyticsButton"
                    }
                    style={{ backgroundColor: "transparent" }}
                    disabled={selectedExclude === true ? true : false}
                    color={selectedExclude == true ? "" : ""}
                    onClick={() => {
                      handleExclude(true);
                    }}
                  >
                    Exclude Overlapping
                  </MDButton>
                </MDBox>
              </>
            ) : (
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ p: 25 }}
              >
                <CircularProgress />
              </MDBox>
            )}
          </MDBox>
        </Card>
      );
      
}
