import { Typography } from "@mui/material";
import MDBox from "components/MDBox";
import Select from "react-select";
import MDButton from "components/MDButton";
import OptionsMenu from "./optionsMenu";
import AppleIcon from "@mui/icons-material/Apple";
import ComputerIcon from "@mui/icons-material/Computer";
import WatchIcon from "@mui/icons-material/Watch";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  forwardRef,
} from "react";
import UserContext from "context/UserContext";
import CustomWidthTooltip from "./CustomWidthTooltip";

const ChartsHeader = forwardRef(
  (
    {
      selectedMediaID,
      handleMedia,
      toolTipInfo,
      selectedName,
      selectedMarket,
      chartRef,
      title,
      subtitle,
      isLoading,
      setIsLoading,
      subtitle2,
      showHistoryDropdown,
      showButtons = true,
      handleOpenModal,
      setOpenModal,
      openModal,
      vid,
      dataLineDates,
      dataLineDatesBank,
      setDataLineDates,
      setDataLineDatesBank,
      selectedBankID,
      setSelectedBankID,
      colorsInnerGraph,
      setColorsInnerGraph,
      setHistoricalDataLines,
      historicalDataLines,
      historicalDataLinesNegative,
      setHistoricalDataLinesNegative,
      historicalDataPopup,
      setHistoricalDataPopup,
      historicalDataPopupPositive,
      setHistoricalDataPopupPositive,
      historicalDataPopupChanges,
      setHistoricalDataPopupChanges,
      historicalDataPopupChangesPlus,
      setHistoricalDataPopupChangesPlus,
      historicalDataPopupChangesNeg,
      setHistoricalDataPopupChangesNeg,
      runHistory,
      setRunHistory,
      selectedMonth,
      setSelectedMonth,
      selectedYear,
      setSelectedYear,
    },
    ref
  ) => {
    // Expose the function through a ref
    React.useImperativeHandle(ref, () => ({
      runHistoryChange,
    }));

    const userContext = useContext(UserContext);
    const date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

    const handleChangeMonth = async (value) => {
      if (
        value.value >= date.getMonth() + 1 &&
        selectedYear.value == date.getFullYear()
      ) {
        alert("Cannot select date in forecast");
        return;
      }
      setSelectedMonth(value);
      runHistoryChange(value?.value, null);
    };

    const handleChangeYear = async (value) => {
      if (
        selectedMonth.value >= date.getMonth() + 1 &&
        value.value == date.getFullYear()
      ) {
        alert("Cannot select date in forecast");
        return;
      }
      setSelectedYear(value);
      runHistoryChange(null, value?.value);
    };

    const runHistoryChange = async (month, year) => {
      setIsLoading(true);
      setRunHistory(true);
      const newArray = await generateArray(month, year);

      if (selectedBankID) {
        const newArray2 = await generateArrayInside(month, year);
        setDataLineDatesBank(newArray2);
      }
      setDataLineDates(newArray);
      setIsLoading(false);
      setRunHistory(false);
    };

    useEffect(() => {
      if (selectedMonth && selectedYear) runHistoryChange();
    }, [selectedMediaID]);

    const generateArray = async (month, year) => {
      let data = {};
      data["media"] = selectedMediaID;
      data["month"] = month ?? selectedMonth?.value;
      data["year"] = year ?? selectedYear?.value;
      let apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL += "/api/historyGraph";
      const metadataResponse = await fetch(apiURL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        body: JSON.stringify(data),
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          bearer: userContext.userData.apiToken,
          "Content-Type": "application/json",
        },
      });
      let resp = await metadataResponse.json();

      const newArray = [];
      resp.map((bank) => {
        let r = bank.historyCount ?? 0;
        newArray.push(r);
      });
      return newArray;
    };

    const generateArrayInside = async (month, year) => {
      let data = {};
      data["media"] = selectedMediaID;
      data["month"] = month ?? selectedMonth?.value;
      data["year"] = year ?? selectedYear?.value;
      data["bankid"] = selectedBankID;
      let apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL += "/api/historyGraph";
      const metadataResponse = await fetch(apiURL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        body: JSON.stringify(data),
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          bearer: userContext.userData.apiToken,
          "Content-Type": "application/json",
        },
      });
      let resp = await metadataResponse.json();

      const newArray = [];
      const maColors = [];
      const historicalDataPositive = [];
      const historicalDataNegative = [];
      const historicalArrayPopup = {};
      const historicalArrayPopupCurrent = {};
      const historicalArrayPopupChange = {};
      const historicalArrayPopupChangePlus = {};
      const historicalArrayPopupChangeNeg = {};
      resp.map((bank) => {
        // get ids
        let bids = [];
        if (Array.isArray(bank.history)) {
          bids = bank.history.map((catHistory) => catHistory.NodeID.toString());
        } else {
          console.error("bank.history is not an array");
        }
        historicalArrayPopup[bank.DisplayName] = bids;

        // --------  display current not history
        let bidsChanges = [];
        if (Array.isArray(bank.current)) {
          bidsChanges = bank.current.map((catHistory) =>
            catHistory.NodeID.toString()
          );
        } else {
          console.error("bank.history is not an array");
        }
        historicalArrayPopupCurrent[bank.DisplayName] = bidsChanges;
        // ---------------------
        // compare arrays to find differences
        // Find differences
        // Find the IDs that are in historicalIds but not in currentIds
        let removedIds = bids.filter((id) => !bidsChanges.includes(id));
        // Find the IDs that are in currentIds but not in historicalIds
        let addedIds = bidsChanges.filter((id) => !bids.includes(id));

        // -----------------
        newArray.push(bank.historyCount - removedIds.length + addedIds.length);

        //  if (addedIds.length) {
        maColors.push("#8fe68a");
        historicalDataPositive.push(addedIds.length);
        historicalArrayPopupChangePlus[bank.DisplayName] = addedIds;
        //  }

        // if (removedIds.length) {
        maColors.push("#ff6f61");
        historicalDataNegative.push(removedIds.length * -1);
        historicalArrayPopupChangeNeg[bank.DisplayName] = removedIds;
        //  }
      });

      setColorsInnerGraph(maColors);
      setHistoricalDataLines(historicalDataPositive);
      setHistoricalDataLinesNegative(historicalDataNegative);
      setHistoricalDataPopup(historicalArrayPopupCurrent);
      setHistoricalDataPopupPositive(historicalArrayPopupCurrent);
      setHistoricalDataPopupChanges(historicalArrayPopupChange);
      setHistoricalDataPopupChangesPlus(historicalArrayPopupChangePlus);
      setHistoricalDataPopupChangesNeg(historicalArrayPopupChangeNeg);
      return newArray;
    };

    return (
      <>
        <MDBox
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
            marginTop: 2,
            marginBottom: showHistoryDropdown ? 1 : 8,
            marginInline: 4,
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              width: "33%",
            }}
          >
            {subtitle === undefined ? (
              <>
                <MDBox sx={{ display: "flex", alignItems: "center" }}>
                  {title && <Typography variant="h5">{title}</Typography>}
                </MDBox>
                <CustomWidthTooltip
                  title={
                    "The Historical Functionality Change graph shows how many functionalities the banks/fintechs in the user’s market of focus have added to their offering or removed, in all available channels. The bar represents the current offering of the bank/fintech and the line represents the past offering based on the selection of the two dropdown menus (month and year). By clicking on a bar, the user can get a view of the distribution of functionalities in each functionality section. The bar is divided into 3 sections: the upper section coloured green represents the functionalities added to the bank’s/fintech’s offering since the selected period. The middle section colour blue shows the current offering of the bank/fintech and the red section represents the functionalities removed from the bank’s/fintech’s offering since the selected period. By clicking on any of the functionality sections, the user can see a complete list of the aforementioned functionalities. By selecting a specific functionality, the user can be redirected to the full Report and view the video of how the functionality has been implemented by that specific bank/fintech."
                  }
                >
                  <HelpOutlineIcon
                    fontSize="small"
                    sx={{ marginLeft: 1, marginTop: 0.5, cursor: "pointer" }}
                    // onClick={() => handleOpenModal(vid)}
                  />
                </CustomWidthTooltip>
              </>
            ) : (
              <MDBox>
                <MDBox sx={{ display: "flex", alignItems: "center" }}>
                  {title && <Typography variant="h5">{title}</Typography>}

                  {/* <CustomWidthTooltip title={toolTipInfo}> */}
                  {/* <HelpOutlineIcon
                    fontSize="small"
                    sx={{ marginLeft: 1, marginTop: 0.5, cursor: "pointer" }}
                    // onClick={() => handleOpenModal(vid)}
                  /> */}
                  {/* </CustomWidthTooltip> */}
                </MDBox>
                {subtitle && (
                  <Typography variant="subtitle1">{subtitle}</Typography>
                )}
              </MDBox>
            )}
          </MDBox>

          <MDBox
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {subtitle2 ? (
              <Typography variant="subtitle2" textAlign="center">
                {subtitle2}
              </Typography>
            ) : (
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  ml: 10,
                }}
              >
                <Typography variant="subtitle2">
                  {selectedMediaID == "22"
                    ? "iOS"
                    : selectedMediaID == "16"
                    ? "Web"
                    : "Apple Watch"}
                </Typography>
                <MDTypography>
                  {selectedName !== undefined &&
                    selectedName !== null &&
                    selectedName.replace("&lt;", "<")}

                  {selectedMarket !== undefined && selectedMarket !== null && (
                    // <><MDTypography>Market {selectedMarket.toUpperCase()}</MDTypography></>
                    <>
                      {selectedMarket == "a" && (
                        <MDTypography>Primary Market</MDTypography>
                      )}
                      {selectedMarket == "b" && (
                        <MDTypography>Target Market</MDTypography>
                      )}
                    </>
                  )}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>

          <MDBox
            sx={{
              width: "33%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {showButtons && (
              <>
                <MDButton
                  sx={{ mr: 2 }}
                  className={
                    selectedMediaID == "22"
                      ? "AnalyticsButtonSelected"
                      : "AnalyticsButton"
                  }
                  // style={{backgroundColor:'transparent'}}
                  title="Ios"
                  disabled={selectedMediaID == "22" ? true : false}
                  color={selectedMediaID == "22" ? "info" : ""}
                  onClick={() => handleMedia("22")}
                >
                  <AppleIcon
                    color={selectedMediaID == "22" ? "light" : "dark"}
                  />
                </MDButton>

                <MDButton
                  className={
                    selectedMediaID == "16"
                      ? "AnalyticsButtonSelected"
                      : "AnalyticsButton"
                  }
                  sx={{ mr: 2 }}
                  title="Web"
                  disabled={selectedMediaID == "16" ? true : false}
                  //  style={{backgroundColor:'transparent'}}
                  color={selectedMediaID == "16" ? "info" : ""}
                  onClick={() => handleMedia("16")}
                >
                  <ComputerIcon
                    color={selectedMediaID == "16" ? "light" : "dark"}
                  />
                </MDButton>

                <MDButton
                  sx={{ mr: 4 }}
                  // sx={{ mr: 2 }}
                  className={
                    selectedMediaID == "27"
                      ? "AnalyticsButtonSelected"
                      : "AnalyticsButton"
                  }
                  // style={{backgroundColor:'transparent'}}
                  disabled={selectedMediaID == "27" ? true : false}
                  color={selectedMediaID == "27" ? "info" : ""}
                  title="Apple Watch"
                  onClick={() => handleMedia("27")}
                >
                  <WatchIcon
                    color={selectedMediaID == "27" ? "light" : "dark"}
                  />
                </MDButton>
              </>
            )}

            <OptionsMenu chartRef={chartRef} title={title} />
          </MDBox>
        </MDBox>

        {showHistoryDropdown && (
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              marginInline: 4,
              gap: 2,
              marginBottom: 7,
            }}
          >
            <Select
              styles={{
                control: (base) => ({
                  ...base,
                  width: 135,
                }),
              }}
              options={months}
              onChange={handleChangeMonth}
              value={selectedMonth}
            />
            <Select
              styles={{
                control: (base) => ({
                  ...base,
                  width: 100,
                }),
              }}
              options={years}
              onChange={handleChangeYear}
              value={selectedYear}
            />
          </MDBox>
        )}
      </>
    );
  }
);

const months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

const years = [
  {
    label: "2024",
    value: 2024,
  },
  {
    label: "2023",
    value: 2023,
  },
  { label: "2022", value: 2022 },
];

export default ChartsHeader;
