import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PrimaryButton from "scientia/New Design/Button/PrimaryButton";
import PrevButton from "scientia/New Design/Button/PrevButton";

const View4 = ({ title, setTitle, handleNextStep, handlePreviousStep }) => {
  return (
    <>
      <MDBox sx={{ marginTop: "48px" }}>
        <MDTypography
          variant="h3"
          sx={{ fontSize: "16px", marginBottom: "12px", color: "#090324" }}
        >
          Market name
        </MDTypography>
        <input
          type="text"
          maxLength={50}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{
            width: "100%",
            padding: "18px 24px",
            backgroundColor: "#FEFEFE",
            border: "1px solid transparent",
            fontSize: "18px",
            borderRadius: "10px",
            outline: "none",
          }}
        />
      </MDBox>
      <MDBox sx={{ marginTop: "32px" }}>
        <PrimaryButton onClick={handleNextStep} fontWeight="bold">
          Create
        </PrimaryButton>

        <PrevButton onClick={handlePreviousStep}>
          Back to Previous Step
        </PrevButton>
      </MDBox>
    </>
  );
};

export default View4;
