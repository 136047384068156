
export default {
  columns: [

    { Header: " ", accessor: "img", width: "15%"},
  { Header: "Bank Name", accessor: "BankName", width: "80%"},
  { Header: "iOS", accessor: "ios" },
    { Header: "Web", accessor: "web"},
    // { Header: "Android", accessor: "android" },
    { Header: "Apple Watch", accessor: "apple_watch" },

  ],

  rows: [
  ],
};
