import Card from "@mui/material/Card";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import MDBox from "components/MDBox";
import CircularProgress from '@mui/material/CircularProgress';
import { useState,useContext,useEffect,useRef } from "react";
import UserContext from 'context/UserContext';
import OptionsMenu from "../ChartsHeader/optionsMenu";
import HighContrastDark from 'highcharts/themes/high-contrast-dark';
import Functionalities from "layouts/dashboards/functionalities2";
import { average } from "chroma-js";
import MDButton from "components/MDButton";
import AppleIcon from '@mui/icons-material/Apple';
import ComputerIcon from '@mui/icons-material/Computer';
import WatchIcon from '@mui/icons-material/Watch';
import { Typography } from "@mui/material";
import ChartsHeader from "../ChartsHeader";

export default function MarketPosition({mediaid, workspaceMedia,loadPopup}){

    const userContext = useContext(UserContext);
    const [isLoading,setIsLoading]=useState(true);
    const [dashboards,setDashboards]=useState([]);
    const [selectedMediaID,setSelectedMediaID]=useState(null);
    const [isLoadingMarketPosition, setIsLoadingMarketPosition] = useState(true);
    if (userContext.userData.darkMode!==null&userContext.userData.darkMode===true) HighContrastDark(Highcharts);
    const [optionsMarketPosition,setOptionsMarketPosition]=useState({
        title: {
          text: 'Market Position'
        },   type: 'column',
        series: [{
          data: [1, 2, 3]
        }]
      });


	// const [dataLoading, setDataLoading] = useState(true)
	// const [data, setData ] = useState([])


	function getAvg(grades) {
		const total = grades.reduce((acc, c) => acc + c, 0);
		return total / grades.length;
	  }


	// async function getMarketPositionData(media_id){



	// 	setDataLoading(false)
	// 	setData(resp)
	// }


    async function chart_marketposition() {
		var data={};
    data['mediaid']=selectedMediaID;
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/chart_market_position";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });
		var resp = await metadataResponse.json();
		// console.log(resp)
    if (resp.noaccess!==undefined) {
      window.location.href='/noaccess';
      return;
    }
    if (resp.expired!==undefined) {
      window.location.href='/logout';
      return;
    }
    if (resp.wizard!==undefined) {
        window.location.href='/workspace';
        return;
      }
        setIsLoading(true)
        setIsLoadingMarketPosition(true);

	 	var y = window.location.href.replace('analytics','analyzed/bank/')
	 	console.log(y)

		var Myfuncionalities = []
		var MyUXScores = []

		var dataMagic = []
		resp.map((i)=>{
			let el = {}
			el.name = i.BankName
			var randomColor = Math.floor(Math.random()*16777215).toString(16);
			el.color = i.colors.Color;
			el.data = [[i.FuncCount, parseFloat(i.UXScore.toFixed(0))]]
			// el.key = "https:\/\/fi.scientiaconsulting.eu\/analysis-overview\/?media_id=22&bank_id="+i.BankID+"&prm=view"
			el.key = ""+y+i.BankID+"/"+selectedMediaID
			el.marker = {"symbol":"square"}
			dataMagic.push(el)
			// console.log(el.key)

			Myfuncionalities.push(i.FuncCount)
			MyUXScores.push(parseFloat(i.UXScore.toFixed(0)))
		}
		)

		// calculate max and min

		var minFunc = Math.min.apply(Math, Myfuncionalities)
		var maxFunc = Math.max.apply(Math, Myfuncionalities)
		var avgFunc =parseFloat(getAvg(Myfuncionalities).toFixed(0))

		if(minFunc-30<=5){
			minFunc=5
		} else {
			minFunc-=30
		}

		var minUX = parseFloat(Math.min.apply(Math,MyUXScores).toFixed(0))
		var maxUX = Math.max.apply(Math,MyUXScores)
		var avgUX =parseFloat(getAvg(MyUXScores).toFixed(0))

		if(minUX-100<=5){
			minUX=5
		} else {
			minUX-=100
		}

		console.log(minUX, maxUX,avgUX)
			// {"name":"Banco del Sol (AR)","color":"#ff934a","data":[[71,815]],"key":"https:\/\/fi.scientiaconsulting.eu\/analysis-overview\/?media_id=22&bank_id=194","marker":{"radius":13,"lineColor":"rgb(100,100,100)","symbol":"square"}},

			// {"name":"Acorns (US)","color":"#47c13b","data":[[135,780]],"key":"https:\/\/fi.scientiaconsulting.eu\/analysis-overview\/?media_id=22&bank_id=216&prm=view","marker":{"symbol":"square"}},
        let dummy ={"resDataMagic":dataMagic,"bankNameDataMed":"Market Position","maxFuncAvg":maxFunc+20,"minFuncAvg":minFunc,"middleFuncAvg":avgFunc,"maxUcAvg":1000,"minUcAvg":minUX,"middleUcAvg":avgUX}



        dummy['myNotaX'] = (dummy['minFuncAvg'] + dummy['middleFuncAvg']) / 2;
      		dummy['myNotaY']= (dummy['minUcAvg'] + dummy['middleUcAvg']) / 2;
      		dummy['myNotaX2'] = (dummy['maxFuncAvg'] + dummy['middleFuncAvg']) / 2;
      		dummy['myNotaY2'] = (dummy['maxUcAvg'] + dummy['middleUcAvg']) / 2;

            let chart = {
              chart: {
            				type: 'scatter',
            				zoomType: 'xy',
                    height:650,
            				marginBottom: 120
            			},
            			title: {
            				text: "",


            			},

            			credits: {
            				enabled: false
            			},

            			xAxis: {
            				title: {
            					text: 'Completeness of Vision:<br/>Total available Functionalities offered',
            					style: {
            						fontSize: '15px',
            						opacity: 0.9
            					},
            				},

            				min: dummy['minFuncAvg'] - 5,
            				max: dummy['maxFuncAvg'] + 5,
            				tickPositions: [dummy['minFuncAvg'] - 5, dummy['middleFuncAvg'], dummy['maxFuncAvg'] + 5],
            				// tickInterval: 80,
            				tickLength: 1,
            				minorTickLength: 0,
							// xaxis
            				gridLineWidth: 0,
            				showLastLabel: true,
            				showFirstLabel: true,
            				lineColor: '#ccc',
            				lineWidth: 1
            			},
            			yAxis: {
            				title: {
            					text: 'Quality of Implementation:<br/>Average Score of all User Experience',
            					style: {
            						fontSize: '15px',
            						opacity: 0.9
            					},
            					rotation: -90
            				},
            				min: dummy['minUcAvg'],
            				max: dummy['$maxUcAvg'],
            				tickPositions: [dummy['minUcAvg'], dummy['middleUcAvg'], dummy['maxUcAvg']],
            				//	tickInterval: 120,
            				tickLength: 1,
							// yaxis
							gridLineWidth: 0,
            				minorTickLength: 0,
            				lineColor: '#ccc',
            				lineWidth: 1
            			},
            			// legend: {
            			// 	layout: 'horizontal',
            			// 	align: 'right',
            			// 	verticalAlign: 'bottom',
            			// 	maxHeight: 56,
            			// 	x: 0,
            			// 	y: 22,
            			// 	itemWidth: 150,
            			// 	floating: false,
            			// 	backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'transparent',
            			// 	borderWidth: 0
            			// },
						legend:{ enabled:false },
            			plotOptions: {
            				scatter: {
            					marker: {
            						radius: 5,
            						states: {
            							hover: {
            								enabled: true,
            								lineColor: 'rgb(100,100,100)'
            							}
            						}
            					},
            					states: {
            						hover: {
            							marker: {
            								enabled: false
            							}
            						}
            					},
            					tooltip: {
            						headerFormat: '<b>{series.name}</b><br>',
            						pointFormat: '{point.x} Functionalities, {point.y} UX Score'
            					}
            				},
            				series: {
            					cursor: 'pointer',
            					point: {
            						events: {
            							click: function() {
            								loadPopup(1,1,1,1,1)
            							}
            						}
            					}
            				}
            			},
						exporting: {
							menuItemDefinitions: {
							  toggleTable: {
								onclick: function() {
								  if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
									this.dataTableDiv.style.display = 'none';
								  } else {
									this.viewData();
									this.dataTableDiv.style.display = '';
								  }
								},
								text: 'Toggle Table'
							  }
							},
							buttons: {
							  contextButton: false
							}
						  },
            			annotations: [{
            				labelOptions: {
            					shape: 'rect',
            					backgroundColor: 'transparent',
            					borderWidth: 0,
            					verticalAlign: 'middle',
            					style: {
            						fontSize: '18px',
            						opacity: 0.6
            					},
            					x: 0,
            					y: 0
            				},
            				labels: [{
            					point: {
            						xAxis: 0,
            						yAxis: 0,
            						x: dummy['myNotaX'],
            						y: dummy['myNotaY']
            					},
            					style: {
            						color: '#f00'
            					},
            					// text: 'Niche'
								text:' '
            				}, {
            					point: {
            						xAxis: 0,
            						yAxis: 0,
            						x: dummy['myNotaX'],
            						y: dummy['myNotaY2']
            					},
            					style: {
            						color: '#000'
            					},
            					// text: 'Challengers'
								text:' '
            				}, {
            					point: {
            						xAxis: 0,
            						yAxis: 0,
            						x: dummy['myNotaX2'],
            						y: dummy['myNotaY']
            					},
            					style: {
            						color: '#00f'
            					},
            					// text: 'Potentialists'
								text:' '
            				}, {
            					point: {
            						xAxis: 0,
            						yAxis: 0,
            						x: dummy['myNotaX2'],
            						y: dummy['myNotaY2']
            					},
            					style: {
            						color: '#060'
            					},
            					// text: 'Leaders'
								text:' '
            				}]
            			}],
            			series: dummy['resDataMagic']

          };
      setOptionsMarketPosition(chart);       // setData(resp);
        setIsLoading(false)
        setIsLoadingMarketPosition(false)

     }



	 async function setUserWorkspaceState(newmedia){
		let data={};
		var newState = workspaceMedia
		console.log(newState)
		newState.market_positioning=newmedia
		data['charsState']=[newState];
		var apiURL = process.env.REACT_APP_SERVER_URL;
		  apiURL+="/api/changeUserWorkspaceState";
		 const metadataResponse = await fetch(apiURL, {
		   method: 'POST', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, *cors, same-origin
		body:JSON.stringify(data),
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		   headers: {
			 'bearer': userContext.userData.apiToken,
			 'Content-Type': 'application/json'
		   },
		 });
		  var resp = await metadataResponse.json();
	  }



     async function getDashboards() {

        // console.log("start api")
        var apiURL = process.env.REACT_APP_SERVER_URL;
          apiURL+="/api/dashboard/getall";
         const metadataResponse = await fetch(apiURL, {
           method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           headers: {
             'bearer': userContext.userData.apiToken,
             'Content-Type': 'application/json'
           },
         });
          var resp = await metadataResponse.json();
          // console.log(resp)
         setDashboards(resp);
      setSelectedMediaID(mediaid)

      }
      useEffect(()=>{

		getDashboards()
      }, [])

      useEffect(()=>{
        if (selectedMediaID!==null) {
          chart_marketposition()
        }
      }, [selectedMediaID])


      const handleMedia=(mediaid)=>{
		setUserWorkspaceState(mediaid)
       	setSelectedMediaID(mediaid)
      }

	  const chartRef = useRef();

      return (
		<Card>
		  <MDBox p={1}>
			{!isLoadingMarketPosition ? (
			  <>
				<ChartsHeader
				  title="Market Positioning"
				  subtitle="Functionalities vs UX Analysis Score"
				  handleMedia={handleMedia}
				  selectedMediaID={selectedMediaID}
				  chartRef={chartRef}
				  marginLeft={{marginLeft: 5}}
				/>
				<HighchartsReact
				  highcharts={Highcharts}
				  options={optionsMarketPosition}
				  ref={chartRef}
				/>
			  </>
			) : (
			  <MDBox
				display="flex"
				justifyContent="center"
				alignItems="center"
				sx={{ p: 25 }}
			  >
				<CircularProgress />
			  </MDBox>
			)}
		  </MDBox>
		</Card>
	  );

}
