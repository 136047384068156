
export default {
  columns: [

    { Header: " ", accessor: "img", width: "15%"},
  { Header: "Bank Name", accessor: "BankName", isSorted:true,isSortedDesc:'asc',width: "80%"},
  { Header: "Updated", accessor: "Updated"},
    { Header: "Action", accessor: "web"},

  ],

  rows: [
  ],
};
