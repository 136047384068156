
export default {
  columns: [
    { Header: "ID", accessor: "NodeID", width:"7%"},

    { Header: "Title", accessor: "title", },
    { Header: "Description", accessor: "Description",
    Cell: (props) => {
      return (<p style={{ width:'100%',overflowWrap: 'break-word' }}>{props.value}</p>
      )}
      
    },
    { Header: "Status", accessor: d => d.Status=="1"?"Active":"Not Supported",
    Cell: (props) => {
      return (
        <p style={{ color: props.value === "Active" ? "rgb(26, 115, 232)" : "#f4516c" }}><span>&#8226;</span> {props.value}</p>
        )},
        
      }
      ,
      { Header: "Actions", accessor: "actions", },



  ],

  rows: [
  ],


};
