/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useContext, useEffect } from "react";
import {
  Route,
  Navigate,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// @mui material components
import Grid from "@mui/material/Grid";
import TooltipTop from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid, GridToolbar } from "@mui/x-data-grid/index";
import Avatar from "@mui/material/Avatar";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import Footer from "scientia/Footer";
import ReportsBarChart from "scientia/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "scientia/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "scientia/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "scientia/Cards/BookingCard";
import CircularProgress from "@mui/material/CircularProgress";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import * as Cookies from "js-cookie";
import UserContext from "context/UserContext";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DragDropContext } from "react-beautiful-dnd";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { MenuProps, useStyles } from "./utils";
import useTheme from "@mui/material/styles/useTheme";

import Wizard from "../../applications/wizard";

import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import fullscreen from "highcharts/modules/full-screen";
import SettingsBar from "layouts/pages/analyzed/bank/SettingsBar";
import DataTable from "scientia/Tables/DataTableBanks";
import datatabledata from "./data/dataTableData";
import datatabledataUX from "./data/dataTableDataUX";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import PopupItem from "./components/popupItem";

import { decode } from "html-entities";

function Popup({ treeid, mediaid, where, bankid, type, nids,header,subheader,gif }) {
  const theme = useTheme();

  const userContext = useContext(UserContext);

  const [OutsideOfYourMarketCount, setOutsideOfYourMarketCount] =
    useState(null);
  const [mynode, setMyNode] = useState(null);

  useEffect(() => {}, []); // <-- empty dependency array

  // Action buttons for the BookingCard

  return (
    <>
      <center>
        <div>
          <PopupItem mediaid={mediaid} header={header} where={where}  bankid={bankid} type={type} subheader={subheader} gif={gif} />
        </div>
      </center>
    </>
  );
}

export default Popup;
