
import { useState,useContext,useEffect } from "react";
import { useParams,useLocation } from 'react-router';
import './index.css'
import PropTypes from "prop-types";
// @material-ui core components
import Card from "@mui/material/Card";
import { Divider } from "@mui/material";
import Modal from '@mui/material/Modal';
import { DataGrid, GridToolbar } from '@mui/x-data-grid/index';
import Avatar from "@mui/material/Avatar";
import DownloadIcon from '@mui/icons-material/Download';
import Switch from '@mui/material/Switch';
// import { spacing } from '@mui/system';

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
// import PieChart from "scientia/Charts/PieChart";
import PanosPieChart from "scientia/Charts/PanosPieChart";
import ShareIcon from '@mui/icons-material/Share';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

// export diagram
import domtoimage from 'dom-to-image';

import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// icon for report video
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
// import for tabs
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import InsightsIcon from '@mui/icons-material/Insights';
import SchemaIcon from '@mui/icons-material/Schema';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VideocamIcon from '@mui/icons-material/Videocam';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LockResetIcon from '@mui/icons-material/LockReset';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Link from "@mui/material/Link";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import PieChartIcon from '@mui/icons-material/PieChart';
import { useNavigate } from "react-router-dom";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';
import CategoriesList from "scientia/Lists/CategoriesList";
import CircularProgress from '@mui/material/CircularProgress';
import { DefaultPlayer as Video } from 'react-html5video';
import 'assets/css/video.css';
import DataTable from "scientia/Tables/DataTableBanksWithPagination";
import dataTableData from "./data/dataTableData";
import dataTableDataUX from "./data/dataTableDataUX";
import MDButton from "components/MDButton";
import ReactFlow from 'react-flow-renderer';
import { ContentCopy } from "@mui/icons-material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';




function ActiveTables(props) {
  const penalty_condition_array=[
  "View",
  "Omit",
  "Check",
  "Read",
  "Tap",
  "Type",
  "Scan QR Code / Barcode",
  "Scan OCR",
  "Dropdown Tap",
  "Verify",
  "Scroll",
  "Dropdown",
  "Disruptive",
  "Irrelevant",
  "Discomfort",
  "SMS",
  "Token Device",
  "Token Device with Password",
  "Token Device with Challenge Response",
  "Simple Approval iOS App with FaceID",
  "Token Device with Challenge Response & PIN",
  "Smartphone App",
  "Smartphone App with Password",
  "Button",
  "Text Link",
  "Dropdown List",
  "Information/Text",
  "Text Input Field",
  "Radio Button",
  "Date Picker",
  "Preselected Value",
  "Checkbox",
  "Take a Selfie",
  "Take a Video",
  "Take a Video With Actions",
  "Take a Video & Say a Phrase",
  "Say a Phrase",
  "Functionality Block",
  "Slider",
  "Swipe",
  "Take a Photo of a Document",
  "Live Interview",
  "Email Confirmation",
  "Phone Call & PIN Password"
];
const start_point_array={
  "18": "LOGIN_PAGE",
  "19": "HOME_PAGE",
  "20": "PAGE_1",
  "21": "PAGE_2"
};
const start_point_desc={
  "18": "Login page",
  "19": "Home page",
  "20": "Page 1 ",
  "21": "Page 2"
};
const node_background_colors={
  "0" : "#ffffff",
  "18": "#ffffff",
  "19": "#ffffff",
  "20": "#ffffff",
  "21": "#ffffff",
  "33": "#dbf2ee",
  "34": "#f2f2f2",
  "35": "#ffffff",
  "36": "#eedbf1",
  "37": "#ffffff",
  "38": "#def1db",
  "39": "#ffffff",
  "40": "#dae9f1",
  "41": "#fcf9e7",
  "42": "#ffffff",
  "43": "#ffffff",
  "44": "#ffffff",
  "45": "#ffffff",
  "67": "#ffffff",
  "73": "#ffffff",
  "74": "#ffffff",
  "75": "#ffffff",
  "76": "#ffffff",
  "77": "#ffffff",
  "81": "#ffffff",
  "83": "#ffffff",
  "84": "#ffffff",
  "47": "#ffffff",
  "48": "#ffffff",
  "49": "#ffffff",
  "50": "#ffffff",
  "51": "#ffffff",
  "52": "#ffffff",
  "53": "#ffffff",
  "54": "#ffffff",
  "65": "#ffffff",
  "79": "#ffffff",
  "80": "#ffffff",
  "22": "#ffffff",
  "23": "#ffffff",
  "24": "#ffffff",
  "25": "#ffffff",
  "26": "#ffffff",
  "27": "#ffffff",
  "28": "#ffffff",
  "66": "#ffffff",
  "85": "#ffffff",
  "86": "#ffffff",
  "1": "#ffffff",
  "2": "#ffffff",
  "3": "#ffffff",
  "4": "#ffffff"
}
const in_action_array={
  "33": "OMIT",
  "34": "VIEW",
  "35": "READ",
  "36": "CHECK",
  "37": "VERIFY",
  "38": "CLICK",
  "39": "HOVER",
  "40": "DROPDOWNMENU",
  "41": "TYPE",
  "42": "SCROLL",
  "43": "TAP",
  "44": "SCAN",
  "45": "SCAN_OCR",
  "67": "DROPDOWNMENU_TAP",
  "73": "TAKE_SELFIE",
  "74": "TAKE_VIDEO",
  "75": "TAKE_VIDEO_WITH_ACTIONS",
  "76": "TAKE_VIDEO_SAY_PHRASE",
  "77": "SAY_PHRASE",
  "81": "SWIPE",
  "83": "TAKE_PHOTO_OF_DOCUMENT",
  "84": "LIVE_INTERVIEW"
}
const in_action_step_array={
  "47": "BUTTON",
  "48": "TEXT_LINK",
  "49": "DROPDOWN_LIST",
  "50": "INFORMATION",
  "51": "TEXT_BOX",
  "52": "RADIO_BUTTON",
  "53": "DATE_PICKER",
  "54": "PRESELECTED_VALUE",
  "65": "CHECKBOX",
  "79": "FUNCTIONALITY_BLOCK",
  "80": "SLIDER"
}
const out_action_array={
  "22": "SMS",
  "23": "SMARTPHONE_APP",
  "24": "SMARTPHONE_APP_WITH_PASSWORD",
  "25": "TOKEN_DEVICE",
  "26": "TOKEN_DEVICE_WITH_PASSWORD",
  "27": "TOKEN_DEVICE_WITH_CHALLENGE_RESPONSE",
  "28": "TOKEN_DEVICE_WITH_CHALLENGE_RESPONSE_PIN",
  "66": "SMARTPHONE_APP_WITH_FACEID",
  "85": "EMAIL_CONFIRMATION",
  "86": "PHONECALL_AND_PIN"
}
const in_painpoints_type_array={
  "1": "null",
  "2": "IRRELEVANT",
  "3": "DISRUPTIVE",
  "4": "DISCOMFORT"
}
const in_painpoints_reason_array={
  "1": "User does not want to change the default page to a different layout",
  "2": "User does not want to access a menu for editing an account's arattributes"
}
const out_painpoints_type_array={
  "1": "null",
  "2": "IRRELEVANT",
  "3": "DISRUPTIVE",
  "4": "DISCOMFORT"
}
const out_painpoints_reason_array={
  "1": "User does not want to change the default page to a different layout",
  "2": "User does not want to access a menu for editing an account's arattributes"
}


  const userContext = useContext(UserContext);
  const { id } = useParams();
  const { mediaid } = useParams();
  const [displayData,setDisplayData]=useState(null)
  const [displayDataUX,setDisplayDataUX]=useState(null)
  const [displayVideo,setDisplayVideo]=useState(null)
  const [displayVideoUC,setDisplayVideoUC]=useState(null);
  const [flowChart,setFlowChart]=useState(null)
  const [flowChartDisplay,setFlowChartDisplay]=useState(null);
  const [totalUX,setTotalUX]=useState(null);
  const [pageSize, setPageSize] = React.useState(8);
  const [favorites, setFavorites] = useState(null)
  const navigate = useNavigate();

  const [modal_indx, setModalIndex] = useState({ParentID:'',NodeID:''})

  const [openModalShare, setOpenModalShare] = useState(false);
  const handleOpenModalShare = () => setOpenModalShare(true);
  const handleCloseModalShare = () => setOpenModalShare(false);

  // roadmap
  const [openModalRoadmap, setOpenModalRoadmap] = useState(false);
  const handleOpenModalRoadmap = () => setOpenModalRoadmap(true);
  const handleCloseModalRoadmap = () => setOpenModalRoadmap(false);
  const [year, setYear] = useState('');
  const handleChangeYear = (e) => {setYear(e.target.value);};
  const [quarter, setQuarter] = useState('');
  const [roadmap_FuncID, setRoadmap_FuncID] = useState('');
  const handleChangeQuarter = (e) => {setQuarter(e.target.value);};
  const [UXname, setUXname] = useState(null)

  const [openModalVideo, setOpenModalVideo] = useState(false);
  const handleOpenModalVideo = () => setOpenModalVideo(true);
  const handleCloseModalVideo = () => setOpenModalVideo(false);


  const [selectedVideo, setSelectedVideo] = useState({title:null,description:null})
  const [users, setUsers] = useState(null)
  const [mail, setMail] = useState('');


  const [fullname,setName] = useState([])
  const [emails, setEmails] = useState([])

  var names = []

  const [loadingModal, setLoadingModal] = useState(true)
  const [ modalBanks, setModalBanks] = useState(null)


  // reload components easily
  const [seed, setSeed] = useState(1);
  const reset = () => {
    setSeed(Math.random());
  }
  const [OutsideOfYourMarketCount, setOutsideOfYourMarketCount ]= useState(null)
  const [mynode, setMyNode] = useState(null)

  // for modal
  const [ openModalAntagonists, setOpenModalAntagonists ] = useState(
    {
      id:1
      ,status:false
      ,

  }
    );


    const [ openModalAntagonists2, setOpenModalAntagonists2 ] = useState(
      {
        id:1
        ,status:false
        ,data:[
          { id: 19, name: 'City', icon:'https://fi.scientiaconsulting.eu/wp-content/uploads/2019/02/alpha.png' },
          { id: 2, name: 'Alpha', icon:'https://fi.scientiaconsulting.eu/wp-content/uploads/2020/09/demo1.png' },
          { id: 3, name: 'EuroBank', icon:'https://fi.scientiaconsulting.eu/wp-content/uploads/2020/09/demo1.png' },
          { id: 1, name: 'Revolut', icon:'https://fi.scientiaconsulting.eu/wp-content/uploads/2020/09/demo1.png' },
      ]});

      const [ openModalAntagonists3, setOpenModalAntagonists3 ] = useState(
        {
          id:1
          ,status:false
          ,data:[
            { id: 13, name: 'EuroBank', icon:'https://fi.scientiaconsulting.eu/wp-content/uploads/2019/02/alpha.png' },
            { id: 14, name: 'EuroBank', icon:'https://fi.scientiaconsulting.eu/wp-content/uploads/2019/02/alpha.png' },
        ]});



  const handleOpenModalAntagonists = async (node_id) =>{

    await getOtherBanksThatHaveThisFunctionality(node_id);


    let copy = openModalAntagonists
    copy.status = true
    setOpenModalAntagonists(copy);
    reset()
  }



  const ModalLink = (id, mediaid
    ,parentid,funcid
    )=>{
    const link = window.location.origin+'/analyzed/bank/'+id+'/'+mediaid+'/'+parentid+'/'+funcid+'/'
    navigator.clipboard.writeText(link).then(function() {
      // console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      // console.error('Async: Could not copy text: ', err);
    });
    window.location.href = link
  }

  const handleRoadmap = (indx) =>{
    // console.log(indx)
    setRoadmap_FuncID(indx)
    handleOpenModalRoadmap()
  }


  const handleShare = (indx)=>{
    // const link = window.location.href + str
    // navigator.clipboard.writeText(link).then(function() {
      // console.log('Async: Copying to clipboard was successful!');
    // }, function(err) {
    //   // console.error('Async: Could not copy text: ', err);
    // });
    setModalIndex(indx)
    handleOpenModalShare()
  }

    const copyToClipboard = (text) => {
      //window.prompt("Copy to clipboard: Ctrl+C, Enter", text);
      navigator.clipboard.writeText(text)
    }


  // const copyLink = (str) =>{

  //   const link = window.location.href + str
  //   copyToClipboard(link)
  //   // alert(link)
  // }

  const copyLink = (parentid,funcid) =>{

    // const link = window.location.href + str
      const link = window.location.origin+'/analyzed/bank/'+id+'/'+mediaid+'/'+parentid+'/'+funcid+'/'

      copyToClipboard(link)
    // alert(link)
  }


  const handleCloseModalAntagonists = () => {

    let copy = openModalAntagonists
    copy.status = false
    setOpenModalAntagonists(copy);
    setLoadingModal(true)

    reset()
  }

  const handleShareWithUsers = () =>{
    console.log('hey')
  }

  var matrix;
    async function doAction_getDefaultMatrix() {
    const data={};

    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/getDefaultMatrix";
     const metadataResponse = await fetch(apiURL, {
       method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin

    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });

     var resp = await metadataResponse.json();
     var ret=[];

     resp.def.forEach((item, i) => {

       ret[""+item.MatrixID]=item.Value;
       //ret[resp.def[o].MatrixID]=resp.def[o].Value
     });




  matrix=ret;


  }

async function getOtherBanksThatHaveThisFunctionality(node_id) {
  const data={};
  data['mediaid']=mediaid;
  data['nodeid']=node_id;
  // data['nodeid']=
  var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/node_getOtherBanksWithThisFunctionality";
  const metadataResponse = await fetch(apiURL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
   body:JSON.stringify(data),
   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
      'bearer': userContext.userData.apiToken,
      'Content-Type': 'application/json'
      },
  });
  var resp = await metadataResponse.json();
  console.log(resp)
  var count = resp.all_markets_general_length -  resp.unique_your_markets_length
  // count = Math.abs(count)
  setOutsideOfYourMarketCount(count)
  setMyNode(resp.mynode)

  // SET MARKET A
  var res = []

  resp.all_marketA.map((i)=>{

   let dict = {
          id: i.BankID,
          name: i.BankName,
          icon: i.banks_data[0].ImageURL,
          parent: i.ParentID,
          node: i.NodeID
        }

  res.push(dict)
  })
  let newBanksA = openModalAntagonists
  newBanksA.data = res
  setOpenModalAntagonists(newBanksA)

  // SET MARKET B
  var res2 = []
  resp.all_marketB.map((i)=>{
   let dict2 = {
          id: i.BankID,
          name: i.BankName,
          icon: i.banks_data[0].ImageURL,
          parent: i.ParentID,
          node: i.NodeID
        }

  res2.push(dict2)
  })
  let newBanksB = openModalAntagonists2
  newBanksB.data = res2
  setOpenModalAntagonists2(newBanksB)


  // SET MARKET C
  var res3 = []
  resp.all_marketC.map((i)=>{
   let dict3 = {
          id: i.BankID,
          name: i.BankName,
          icon: i.banks_data[0].ImageURL,
          parent: i.ParentID,
          node: i.NodeID
        }

  res3.push(dict3)
  })
  let newBanksC = openModalAntagonists3
  newBanksC.data = res3
  setOpenModalAntagonists3(newBanksC)

  setLoadingModal(false)

  // console.log(openModalAntagonists)

}



  async function doAction_node_getFunctionalities() {
         const data={};
        data['mediaid']=mediaid;
        data['bankid']=id;
        var apiURL = process.env.REACT_APP_SERVER_URL;
            apiURL+="/api/node_getAllActiveFunctionalities";
        const metadataResponse = await fetch(apiURL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        body:JSON.stringify(data),
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
            'bearer': userContext.userData.apiToken,
            'Content-Type': 'application/json'
            },
        });
        var resp = await metadataResponse.json();
        // console.log("Active Func",resp)


     actions:
        resp.forEach(function(part, index) {
          var functionality = this[index].NodeID
          this[index].actions=<>

          <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          >

            <MDBox title="Who has this Functionality">
           <DynamicFeedIcon  onClick={()=>{handleOpenModalAntagonists(this[index].NodeID)}} fontSize="medium" className="icons" color="info" />
            </MDBox>

            {this[index].Status=="1"&&this[index].element[0]!==undefined?
            <>

              <MDBox sx={{ml:3}} title="Add to Favorites">
              {/* bi-heart-fill */}
               <i id="heartIcon"
               onClick={(e)=>{handleIconFavorites(e,functionality, this[index].BankID, this[index].MediaID)}}
               className={this[index].favorites.length?"bi bi-heart":"bi-heart-fill"}
               ></i>
              </MDBox>

              <MDBox sx={{ml:3}} title="Add to Roadmap">

              <i
              id="roadmapIcon"
              // onClick={(e)=>{node_addToRoadmap(this[index].MediaID,functionality, this[index].BankID)}}
              onClick={()=>{handleRoadmap(functionality)}}
              className={"bi bi-diagram-3"}
                ></i>
              </MDBox>

            </>
            :
            <></>
          }
          </Grid>


          </>;


     if (this[index].element[0]!==undefined) {
                var functionality = this[index].NodeID


                // table title
                this[index].title=<><MDBox sx={{cursor:'pointer'}}

                onClick={ (e)=> showVideo(e,'https://d1j232mdugpp1g.cloudfront.net/'+this[index].element[0].Path,this[index].element[0].MediaID,this[index].element[0].NodeID,this[index].element[0].BankID,this[index].DisplayName, this[index].Description)}


                 color='info'>
                   { this[index].DisplayName}
                  </MDBox>
                   </>;


              // table actions
               this[index].actions = <>

            <MDBox
           sx={{display:"flex",alignItems:"center",justifyContent:"flex-start"}}

            >


            {this[index].Status=="1"?
            <>  
            
            <MDBox title="Who has this Functionality">
            <DynamicFeedIcon  onClick={()=>{handleOpenModalAntagonists(this[index].NodeID)}} fontSize="medium" className="icons" color="info"/>
            </MDBox>


              <MDBox sx={{ml:3}} title="Add to Favorites">

               <i
               id="heartIcon"
                onClick={(e)=>{handleIconFavorites(e,functionality, this[index].BankID, this[index].MediaID)}}
                className={this[index].favorites.length?"bi-heart-fill":"bi bi-heart"}
                 ></i>
              </MDBox>

              <MDBox sx={{ml:3}} title="Add to Roadmap">

               <i
               id="roadmapIcon"
                // onClick={(e)=>{node_addToRoadmap(this[index].MediaID,functionality, this[index].BankID)}}
                onClick={()=>{handleRoadmap(functionality)}}
                className={"bi bi-diagram-3"}
                 ></i>
              </MDBox>

              {/* <i class="bi bi-diagram-3"></i> */}

</>
            :
            <></>
            }


            {this[index].Status=="1"?
            <>
            <MDBox title="Download">
           <DownloadIcon onClick={(e) => { location.href='https://cdn.dev.scientiaconsulting.eu/snapshot.php?id='+this[index].element[0].Path; }} title="download" fontSize="medium" className="icons" color="info" sx={{ml:3}}/>
            </MDBox>

            {/* <DownloadIcon onClick={(e) => { location.href='/analyzed/bank/'+this[index].element[0].BankID+'/'+this[index].element[0].MediaID+'/'+this[index].ParentID+'/'+this[index].NodeID }} title="copy" fontSize="medium" className="icons" color="info" sx={{ml:3}}/> */}

            </>
            :
            <></>
            }



               {/* <MDButton

                > */}
              {/* can be icon */}
              <MDBox title="Play Video">
             <PlayCircleOutlineIcon
            //  component={Link}
            //  href=""
            onClick={ (e)=> showVideo(e,'https://d1j232mdugpp1g.cloudfront.net/'+this[index].element[0].Path,this[index].element[0].MediaID,this[index].element[0].NodeID,this[index].element[0].BankID,this[index].DisplayName, this[index].Description)}
            //  rel="noreferrer"
            //  size="medium"
            color="info"
            sx={{ml:3}}
            title="play" fontSize="medium" className="icons" />
            {/* </MDButton> */}
            </MDBox>


            <MDBox title="Share">
            <ShareIcon
             color="info"
             className="icons"
             sx={{ml:3}}
             fontSize="medium"
             onClick={()=>handleShare(this[index])}
             />
             </MDBox>

              </MDBox>
            {/* <VisibilityIcon fontSize="medium"/> */}
            {/* Heart Icons */}

              {/* {author=="Panos"?<FavoriteIcon onClick={setAuthor("Nick")} color="info" fontSize="medium" />:<FavoriteBorderIcon color="info" fontSize="medium" /> } */}

            </>;
          } else {
            this[index].title=<><MDBox



            >
               { this[index].DisplayName}
              </MDBox>
               </>;


          }


          }, resp); // use arr as this
          dataTableData.rows=resp;

          // sort table - Slow!
          dataTableData.rows.sort((b,a) => a.Status - b.Status);
          // console.log(document.getElementsByClassName("material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-1neevca-MuiIcon-root"))





        //   if (resp[0]!==undefined&&resp[0].element[0]!==undefined) {
        //     showVideo(null,'https://d1j232mdugpp1g.cloudfront.net/'+resp[0].element[0].Path,resp[0].element[0].MediaID,resp[0].element[0].NodeID,resp[0].element[0].BankID);
        //   }
      setDisplayData(1);



  }
  async function doAction_node_getUseCases() {
    const data={};
  data['mediaid']=mediaid;
  data['bankid']=id;
  var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL+="/api/node_getAllActiveUseCases";
   const metadataResponse = await fetch(apiURL, {
     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  body:JSON.stringify(data),
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     headers: {
       'bearer': userContext.userData.apiToken,
       'Content-Type': 'application/json'
     },
   });
   var resp = await metadataResponse.json();
  //  console.log("Active Usecases",resp)
     actions:
        resp.forEach(function(part, index) {
          // var functionality = this[index].NodeID
          this[index].status=<>
    <MDBox display="flex" alignItems="center" justifyContent="center">

        {this[index].UXstate==="0"?
        <MDTypography sx={{ml:3}} color="info">
          Active
        </MDTypography>
        :this[index].UXstate==="1"?
        <MDTypography sx={{ml:3}} color="info">
          Not Supported
        </MDTypography>
        :this[index].UXstate==="2"?
        <MDTypography sx={{ml:3}} color="info">
          Not Completed
        </MDTypography>
        :<></>}
    </MDBox>
    </>

          this[index].title=<>
          <MDBox
                color={this[index].UXstate==="0"?'info':''}
                onClick={this[index].UXstate==="0"?()=>ModalLink(id,mediaid,this[index].ParentID,this[index].NodeID):()=>{console.log("")}}
                sx={this[index].UXstate==="0"?{cursor:"pointer"}:{cursor:'not-allowed'}}

          >
              {this[index].DisplayName}
          </MDBox>
          </>


          this[index].actions=<></>;
    //  if (this[index].Status==1) {
      if (1==1) {

this[index].actions = <>

          <MDBox display="flex" alignItems="center" justifyContent="center">

                <MDBox title="Who else has this Functionality">
               <DynamicFeedIcon title="Who has this Functionality" onClick={()=>{handleOpenModalAntagonists(this[index].NodeID)}} fontSize="medium" className="icons" color="info"/>
                </MDBox>


              {/* bi-heart-fill */}
               {/* <MDBox sx={{ml:3}}>
               <i id="heartIcon" onClick={(e)=>handleIcon(e)} className="bi bi-heart" ></i>
              </MDBox> */}

              {this[index].UXstate==="0"?

              <MDBox title="Show Flowchart">
             <PieChartIcon color="info"
             fontSize="medium"
             className="icons"
             //  open in page
             //  onClick={ (e)=> showFlowChart(e,this[index].NodeID,this[index].ReportID,this[index].DisplayName)}
             // send to page
             onClick={this[index].UXstate==="0"?()=>ModalLink(id,mediaid,this[index].ParentID,this[index].NodeID):<></>}
             sx={{ml:3}}/>
             </MDBox>


             :<></>}

              <MDBox title="Share">

              <ShareIcon
             color="info"
             className="icons"
             sx={{ml:3}}
             fontSize="medium"
             
             //  needs share method with other params
             onClick={()=>handleShare(this[index])}
             
             //  onClick={()=>console.log('/'+this[index].ParentID+'/'+this[index].NodeID)}
             />
             </MDBox>

             </MDBox>



            </>;
                }

          }, resp); // use arr as this

        dataTableDataUX.rows=resp;
      setDisplayDataUX(1);



  }

  async function node_getUsers() {
    const data={};
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/getUsers";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
       mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });
     var resp = await metadataResponse.json();

    //  multiSort
     var users_sorted = resp[0].users
     users_sorted.sort(function(a,b){
      if(a.lastName< b.lastName) return -1;
      if(a.lastName >b.lastName) return 1;
      if(a.firstName< b.firstName) return -1;
      if(a.firstName >b.firstName) return 1;
      return 0;
    });
    
     setUsers(users_sorted)

  }





  useEffect(()=>{
  //doAction_node_getBankAndMediaNav();
  // getOtherBanksThatHaveThisFunctionality(null);
  // doAction_getDefaultMatrix();
  node_getUsers();

  }, []) // <-- empty dependency array




  useEffect(() => {
  doAction_getDefaultMatrix();
    setDisplayData(null);
    setDisplayDataUX(null);
    setDisplayVideo(null);
    setFlowChart(null);
    setTotalUX(null);
    setElements(null);
    doAction_node_getFunctionalities();
    doAction_node_getUseCases();
  }, []);


  // useEffect(()=>{
  //  )
  // })


  const findParents = (arr, entityId) => {
      for (let i = 0; i < arr.length; i++) {
          if (arr[i].ID === entityId) {
              return arr[i].children;
          } else if (arr[i].children && arr[i].children.length) {
              const t = findParents(arr[i].children, entityId);

              if (t !== false) {
                  return t;
              }
          }
      }

      return false;
  };
  // add to favorite
  async function node_addToFavorites(mediaid,nodeid,bankid) {
    const data={};
    data['mediaid']=mediaid;
    data['bankid']=bankid;
    data['functionality_id']=nodeid;
    data['uc_id']=0;
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/addToFavorites";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });

     var resp = await metadataResponse.json();
  }

  async function node_addToRoadmap() {
    const data={};
    data['mediaid']=mediaid;
    data['bankid']=id;
    data['functionality_id']=roadmap_FuncID;
    data['quarter']=quarter
    data['year']=year
    data['uc_id']=0;
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/addToRoadmap";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });

     var resp = await metadataResponse.json();
     console.log(resp)
     handleCloseModalRoadmap()
     setQuarter('')
     setYear('')
  }



  async function node_removeFromFavorites(mediaid,nodeid,bankid) {
    const data={};
    data['mediaid']=mediaid;
    data['bankid']=bankid;
    data['functionality_id']=nodeid;
    data['uc_id']=0;
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/removeFromFavorites";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });

     var resp = await metadataResponse.json();
  }
  //get favorites
  async function node_getFavorites() {
    const data={};

    // console.log("enter get favorites")
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/getFavorites";
     const metadataResponse = await fetch(apiURL, {
       method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });
     var resp = await metadataResponse.json();
    //  console.log("api called")
     var ret=[];
    //  console.log(resp);
     setFavorites(resp);
    //  console.log("exit get favorites")

  }


  async function showVideo(e,url,mediaid,nodeid,bankid,funcname, description) {
    // console.log(e.target.parentNode.parentNode.parentNode.parentNode.classList.contains("MuiTableRow-root"))
    // console.log(e.target.parentNode.parentNode.parentNode.parentNode.parentNode.classList.contains("MuiTableRow-root"))
    // console.log(funcname, description)
    setSelectedVideo({title:funcname, description:description})
    // if(e.target.parentNode.parentNode.parentNode.parentNode){
    //   if (e.target.parentNode.parentNode.parentNode.parentNode.classList.contains("MuiTableRow-root")){
    //     e.target.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = "#e6e6e6"
    //   }
    // }

    // if(e.target.parentNode.parentNode.parentNode.parentNode.parentNode){
    //   if(e.target.parentNode.parentNode.parentNode.parentNode.parentNode.contains("MuiTableRow-root")) {
    //     e.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = "#e6e6e6"
    //   }
    // }



    handleOpenModalVideo()
    setDisplayVideo(null);

  setDisplayVideo(url);
  if (e!==null){
    e.preventDefault();
  }
    const data={};
    data['mediaid']=mediaid;
    data['bankid']=bankid;
    data['functionality_id']=nodeid;
    data['uc_id']=0;
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/addToHistory";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });

     var resp = await metadataResponse.json();

  }

  const handleIcon = (e) =>{
    // x.classList.toggle("bi-heart-fill");
    if(e.target.classList.contains("bi-heart")){
        e.target.classList.remove("bi-heart")
        e.target.classList.add("bi-heart-fill")
    } else {
      e.target.classList.remove("bi-heart-fill")
      e.target.classList.add("bi-heart")
    }
  }

  const handleIconFavorites = (e,functionality,bank,media) =>{
    // x.classList.toggle("bi-heart-fill");
    if(e.target.classList.contains("bi-heart")){
        e.target.classList.remove("bi-heart")
        e.target.classList.add("bi-heart-fill")
        node_addToFavorites(media,functionality, bank)
    } else {
      e.target.classList.remove("bi-heart-fill")
      e.target.classList.add("bi-heart")
      node_removeFromFavorites(media,functionality, bank)
    }
  }




  async function showFlowChart(e,nodeid,reportid,name) {
    // background
    // e.preventDefault();
    // if (e.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.contains("MuiTableRow-root")){
    //   e.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = "#f5f5f5"
    // } else {
    //   e.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = "#f5f5f5"
    // }
    // var x = document.getElementsByClassName("mainTab")[0]
    // var y = document.getElementsByClassName("showTabBtn")[0]
    // x.classList.add("mainTab-hidden")
    // y.classList.remove("Btn-hidden")

    setTotalUX(null);
    setFlowChart(null);
    e.preventDefault();
    const data={};
    data['nodeid']=nodeid;
    data['reportid']=reportid;


    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/node_getUseCaseDetails";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });

     var resp = await metadataResponse.json();

     var flowchart_nodes=[];
     var totalPoints=0;
     var x=30;
     var y=20;
     var height=400;
     var leftToRight=true;

     resp.forEach((item, i) => {

       var points=0;


       var str="";

       if(item.actions!==undefined){
         if (item.actions.in_action_screenshot !== undefined){
           setDisplayVideoUC('https://d1j232mdugpp1g.cloudfront.net/'+item.actions.in_action_screenshot)
          }

       // homepage
       if (item.actions.step_id==0) {
         str="<h1 style='color:grey;'>" +start_point_desc[item.actions.start_point]; + "</h1>"
        };


        if (item.actions.step_id==1) {


          //calculate characters in check
          if (matrix[item.actions.in_platform_action]!==undefined) {
            points=matrix[item.actions.in_platform_action];
            }
          if (item.actions.in_platform_action=="36") {
            points=points*parseInt(item.actions.in_platform_checklist_counter);
          }
          //  calculate damage points
          if (item.actions.in_platform_action=="41") {
            points+=(item.actions.in_platform_number_of_char*-3);
          }
          str=" <div style='position:absolute; right:-20px; top:-20px; background-color:rgb(138, 222, 115); color:white; width:40px; height:40px; border-radius:50%; display:flex; align-items:center; justify-content:center; font-size:16px;'>"+points+"</div>";


          // using playground conditional for the time being
          // ---------------Icons------------
          if (i==3|i==4|i==5){
            // lock icon
            str+="<div style='position:absolute; left:-15px; top:-20px; background-color:transparent; width:30px; height:30px; border-radius:50%; border:3px solid rgb(173,75,171); display:flex; align-items:center; justify-content:center; '><i class='bi bi-lock-fill' style='font-size:18px; color:rgb(173,75,171);'></i></div>"

          }
          if (i==6||i==7){
            // pain icon
            str+="<div style=' position:absolute; left:-20px; top:-23px;  width: 0; height: 0; border-style: solid; border-width: 0 24px 41.6px 24px;border-color: transparent transparent crimson transparent; color:black;'><span style='white-space: nowrap; position:relative; top:14px; right:9px; font-size:16px; color:white;'>"+25+"<span> </div>"
          }
          // -------------------------------

          //  box title
          str+="<h3 style='margin-bottom:10px'><strong>"+item.actions.in_platform_description+"</strong></h3>";


          //calculate points
          if (matrix[item.actions.in_platform_action]!==undefined) {
            // points=matrix[item.actions.in_platform_action];

            str+="<div style='border:1px solid #000000;width:99%;text-align:center;margin:1% '><strong>Action Type:</strong><br>"+in_action_array[item.actions.in_platform_action]+"</div>"

            str+="<div style='border:1px solid #000000;width:99%;text-align:center;margin:1%'><strong>Step Type:</strong><br>"+in_action_step_array[item.actions.in_platform_step_type]+"</div>"

          }
          str+="<br/>"

          str+="<div style='position:absolute; right:-16px; bottom:-23px;'><i style='font-size:30px' class='bi bi-check2-square'></i></div>"

          // get in_painpoints_type_array
        };
        if (item.actions.step_id==2) {
          if (matrix[item.actions.out_platform_action]!==undefined) {
            points+=matrix[item.actions.out_platform_action];
            //   str+"<div style='border:1px solid #000000;width:80%;'><strong>Action Type:</strong>:"+in_action_step_array[item.actions.in_platform_step_type]+"</div>"

          }

          str="OUT "+item.actions.out_platform_description;
        };
        if (item.actions.step_id==3) {
          str="<h1 style='color:grey'>Endpoint</h1>";
        };
        var action_id=parseInt(item.actions.in_platform_action);
        if (action_id==0) action_id=parseInt(item.actions.out_platform_action);
        var border="2px dashed #e4e4e4";
        // str+=" <br> "+action_id;

        var cstr=<div dangerouslySetInnerHTML={{ __html: str}} />;
        if (item.actions.step_id==0||item.actions.step_id==3) {
          action_id=0;
          border="1px solid rgb(228, 228, 228)";
        }




        var node={
          id: item.actions.action_step_no,
          data: { label: <div>{cstr}</div>  },
          type: 'default',
          position: { x:x, y: y },
          style:{
            width:250,
            background:node_background_colors[action_id],
            border:border
          }
        }


      }


       flowchart_nodes.push(node);
       totalPoints+=points;
       if (leftToRight) x+=300; else x-=300;

       if ((i+1)%3==0) {
         if (leftToRight==true) {
              x=610;
            leftToRight=false;
          } else {
              x=10;
            leftToRight=true;
          }

         y+=250;
       }
     });
     resp.forEach((item, i) => {
       var node={
            id: item.actions.action_step_no+'_connect_'+(parseInt(item.actions.action_step_no)+1),
            source: item.actions.action_step_no, target: ''+(parseInt(item.actions.action_step_no)+1),
            type:'smoothstep',
            animated: true
       }

      //  modifying one element to add new page
       if (node.source==4){
        node.label= "New"

       }

       flowchart_nodes.push(node);


     });
    setElements(flowchart_nodes);

     var res=[];
    setFlowChartDisplay(res);


    setFlowChart(resp);
    setUXname(name)
   setTotalUX(totalPoints);

  }
  const [elements,setElements]=useState([]);
  /*const elements = [
    { id: '1', type: 'input', data: { label: 'Node 1' }, position: { x: 250, y: 5 } },
    // you can also pass a React Node as a label
    { id: '2', data: { label: <div>Node 2</div> }, position: { x: 100, y: 100 } },
    { id: 'e1-2', source: '1', target: '2', animated: true },
  ];*/


  const [value, setValue] = React.useState('1');
  const [secondTabValue, setSecondTabValue] = React.useState('Description');

  // first tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // second tab
  const handleSecondTabChange = (event, newValue) =>{
    setSecondTabValue(newValue)
  }


  // open modal
  const openModal = () => {
    var x = document.getElementsByClassName("videomodal")[0]
    if(x.classList.value.includes("hidden")){
      x.classList.remove("hidden")
    }else{
      x.classList.add("hidden")
    }
  }

  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  };


  const handleChangeMail = (event) => {
    const {
      target: { value },
    } = event;
    setName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

// export
const exportThis = () =>{
  var node = document.getElementsByClassName("MyDiagram")[0]
  node.style.backgroundColor = "white"

  domtoimage.toJpeg(node, { quality: 0.99 })
    .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'diagram.jpeg';
        link.href = dataUrl;
        link.click();
    });

}

const send_mails = (str) =>{
  var people_mails = []
  fullname.map((i)=>{
    var fname = i.split(/(\s+)/);
    // console.log(fname.at(-1))
    var index = users.findIndex(object => {
      return object.lastName === fname.at(-1);
    });

      people_mails.push(users[index])

  })
  console.log('send mails',people_mails)
  const link = window.location.href + str
  console.log('to ip', link)
}



const handleFavorites=(e,functionality, bank, media)=>{
  // console.log(functionality, bank, media)

  // add to favorites
  if(e.target.classList.contains("css-1rr5so0-MuiSvgIcon-root")){
    e.target.parentNode.childNodes[0].classList.add("hidden")
    e.target.parentNode.childNodes[1].classList.remove("hidden")
    node_addToFavorites(media,functionality, bank)
  }

  // remove from favorites
  if(e.target.classList.contains("css-1qgtjl6-MuiSvgIcon-root")){
    e.target.parentNode.childNodes[1].classList.add("hidden")
    e.target.parentNode.childNodes[0].classList.remove("hidden")
    node_removeFromFavorites(media,functionality, bank)
  }

  if(e.target.nodeName=="path"){
    // console.log("path")
    e.target.parentNode.parentNode.childNodes[1].classList.add("hidden")
    e.target.parentNode.parentNode.childNodes[0].classList.remove("hidden")
    node_removeFromFavorites(media,functionality, bank)
  }
}


const removeHeightRestriction = ()=>{
  var x = document.getElementsByClassName("rh5v-DefaultPlayer_component")[0]

  if(x.style.height==="100%"){
    x.style.height='440px'
    return
  }

  if(x.style.height==="440px"){
    x.style.height='100%'
    return
  }

}


  return (
  <>

           <>
           <Modal

                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openModalAntagonists.status}
                onClose={handleCloseModalAntagonists}
                disableAutoFocus={true}
            >
              {!loadingModal?<>
                <MDBox
                id="modal-box"
                sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 500,
                      bgcolor: '#292d5f !important',
                      border: '2px solid #21244c  !important',
                      boxShadow: 24,
                      p: 4,
                      width:'70%',
                    }}>
                      <MDBox sx={{position:'absolute', top:10, right:10, cursor:'pointer'}}>

                        <CloseIcon fontSize="medium" onClick={handleCloseModalAntagonists} />
                      </MDBox>


                <MDTypography sx={{display:"flex", justifyContent:"center",alignItems:"center", mb:2}} id="modal-modal-title" variant="h6" component="h2">
                Who has this Functionalityyy?
                </MDTypography>
                <MDBox mt={2} mb={2} >
                      <Card sx={{px:2, backgroundColor:"#292d5f"}}>
                        <MDBox sx={{ display:"flex", alignItems:"center", justifyContent:"center"}}>


                      {mynode!==null?<>

                        <MDTypography color="text">
                        {mynode.NodeID} . {mynode.DisplayName}
                      </MDTypography>
                      </>:<></>}

                        </MDBox>
                      </Card>
                    </MDBox>
                  <Box
                  id="modal-subox"
                  sx={{
                    height: 500,
                    display:'flex',
                    flexDirection:'row',

                    // width: '100%'
                    }}>

                        <DataGrid
                            pagination
                            hideFooter

                            disableColumnMenu
                            disableColumnFilter
                              // components={{ Toolbar: GridToolbar }}
                              rows={openModalAntagonists.data}
                              initialState={{
                                sorting: {
                                  sortModel: [
                                    {
                                      field: 'name',
                                      sort: 'asc',
                                    },
                                  ],
                                },
                              }}
                              columns={[
                                {
                                  field: 'icon',
                                  headerName: '',
                                  width: 100,
                                  editable: false,
                                  sortable: false,
                                  renderCell: (params) => {

                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.value? <Avatar sx={{ width: 56, height: 56 }} src={params.value} />:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },
                                {
                                  field: 'name',
                                  headerName: 'Market A',
                                  // disableColumnFilter:true,
                                  width: 200,
                                  editable: false,
                                  renderCell: (params) => {

                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.value?<>{params.value}</>:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },

                              ]}
                                // pageSize={pageSize}
                                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                // rowsPerPageOptions={[5]}
                                // disableColumnFilter={true}

                                />

                              <DataGrid
                              pagination
                              hideFooter

                              disableColumnFilter
                              disableColumnMenu
                              rows={openModalAntagonists2.data}
                              initialState={{
                                sorting: {
                                  sortModel: [
                                    {
                                      field: 'name',
                                      sort: 'asc',
                                    },
                                  ],
                                },
                              }}
                              columns={[
                                {
                                  field: 'icon',
                                  headerName: '',
                                  width: 100,
                                  editable: false,
                                  sortable: false,
                                  renderCell: (params) => {
                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.value? <Avatar sx={{ width: 56, height: 56 }} src={params.value} />:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },
                                {
                                  field: 'name',
                                  headerName: 'Market ',
                                  width: 
                                  200,
                                  editable: false,
                                  renderCell: (params) => {

                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.value?<>{params.value}</>:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },

                              ]}
                                // pageSize={pageSize}
                                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                // rowsPerPageOptions={[5]}
                                />

                            <DataGrid
                            disableColumnFilter
                            hideFooter
                            disableColumnMenu
                            initialState={{
                              sorting: {
                                sortModel: [
                                  {
                                    field: 'name',
                                    sort: 'asc',
                                  },
                                ],
                              },
                            }}
                              rows={openModalAntagonists3.data}
                              columns={[
                                {
                                  field: 'icon',
                                  headerName: '',
                                  width: 100,
                                  editable: false,
                                  sortable: false,
                                  renderCell: (params) => {
                                    return (
                                      <>

                                        <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.value? <Avatar sx={{ width: 56, height: 56 }} src={params.value} />:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },
                                {
                                  field: 'name',
                                  headerName: 'Rest of the World',
                                  width: 250,
                                  editable: false,
                                  renderCell: (params) => {

                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.value?<>{params.value}</>:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }

                                },

                              ]}


                                // pageSize={pageSize}
                                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                // rowsPerPageOptions={[5]}
                                />
                    </Box>
              <MDBox mt={2} mb={2} >
                      <Card sx={{pt:2,pb:2,px:2, backgroundColor:"#8692fd"}}>
                        <MDBox sx={{ display:"flex", alignItems:"center", justifyContent:"center"}}>


                      {OutsideOfYourMarketCount!==null?<>

                        <MDTypography color="white">
                        {OutsideOfYourMarketCount} Banks out of your Subscription also have this Functionality <MDButton sx={{ml:2}} color="dark" onClick={()=>{window.open('https://meetings.hubspot.com/n-belesis/upgrade-your-fi-license', '_blank', 'noopener,noreferrer')}}>Contact Sales</MDButton>
                      </MDTypography>
                      </>:<></>}

                        </MDBox>
                      </Card>
                    </MDBox>
              </MDBox>


              </>:<><CircularProgress /></>}

            </Modal>

            <Modal
              open={openModalRoadmap}
              onClose={handleCloseModalRoadmap}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableAutoFocus={true}
            >
              <Box
              className="share-modal"
              sx={{
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 550,
              bgcolor: '#292d5f !important',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}>

                    <MDBox sx={{position:'absolute', top:10, right:10, cursor:'pointer'}}>
                      <CloseIcon fontSize="medium" onClick={handleCloseModalRoadmap} />
                    </MDBox>
                <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
                  {/* Copy this link to clipboard. <ContentCopyIcon onClick={()=>copyLink('/'+modal_indx.ParentID+'/'+modal_indx.NodeID)} fontSize="medium" sx={{cursor:'pointer', ml:2}} color='info' /> */}
                </MDTypography>
                      <MDBox sx={{ mt: 4 }} display="flex">

                                <MDTypography id="modal-modal-description" >
                                Please select Year and Quarter.
                              </MDTypography>
                       </MDBox>
                       <MDBox sx={{ mt: 4 }} display="flex" width={250}>

                       <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">Year</InputLabel> */}
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={year}
                          // label="Year"
                          onChange={handleChangeYear}
                          >
                          <MenuItem value={2022}>2022</MenuItem>
                          <MenuItem value={2023}>2023</MenuItem>
                          <MenuItem value={2024}>2024</MenuItem>
                          <MenuItem value={2024}>2025</MenuItem>

                        </Select>
                      </FormControl>

                      <FormControl fullWidth sx={{ml:3}}>
                        {/* <InputLabel id="demo-simple-select-label">Year</InputLabel> */}
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={quarter}
                          // label="Year"
                          onChange={handleChangeQuarter}
                          >
                          <MenuItem value={1}>Q1</MenuItem>
                          <MenuItem value={2}>Q2</MenuItem>
                          <MenuItem value={3}>Q3</MenuItem>
                          <MenuItem value={4}>Q4</MenuItem>

                        </Select>
                      </FormControl>


                          </MDBox>
                       <MDBox sx={{ mt: 4 }} display="flex">
                            <MDButton
                            color="info"
                            onClick={()=>node_addToRoadmap()}
                            >Add to Roadmap</MDButton>
                       </MDBox>
              </Box>
            </Modal>

            <Modal
              open={openModalShare}
              onClose={handleCloseModalShare}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableAutoFocus={true}
            >
              <Box
              className="share-modal"
              sx={{
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 550,
              bgcolor: '#292d5f !important',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}>

                    <MDBox sx={{position:'absolute', top:10, right:10, cursor:'pointer'}}>
                      <CloseIcon fontSize="medium" onClick={handleCloseModalShare} />
                    </MDBox>

                <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
                  Copy this link to clipboard. <ContentCopyIcon onClick={()=>copyLink(modal_indx.ParentID,modal_indx.NodeID)} fontSize="medium" sx={{cursor:'pointer', ml:2}} color='info' />
                </MDTypography>

                      <MDBox sx={{ mt: 4 }} display="flex">

                                <MDTypography id="modal-modal-description" >
                                You can now share with your peers.
                              </MDTypography>

                        {users!==null?users.map((i)=>{
                            names.push(i.firstName+" "+i.lastName)
                          }):<></>}


                        <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Users</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={fullname}
                          // defaultOpen={true}
                          defaultOpen={true}
                          onChange={handleChangeMail}
                          input={<OutlinedInput label="Users" />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                        >
                          {names!==null?names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={fullname.indexOf(name) > -1} />

                              <ListItemText primary={name} />

                            </MenuItem>
                          )):<></>}
                        </Select>
                      </FormControl>

                       </MDBox>

                       <MDBox sx={{ mt: 4 }} display="flex">

                            <MDButton color="info" onClick={()=>send_mails('/'+modal_indx.ParentID+'/'+modal_indx.NodeID)}>Send Link</MDButton>

                       </MDBox>


              </Box>
            </Modal>
            </>


  <MDBox className="mainTab" sx={{ width: '100%', typography: 'body1' }}>

          <MDTypography variant="h4" fontWeight="medium"></MDTypography>
          {displayData!==null?
            <>

            {displayVideo!==null?<>
              {/* <MDBox mt={4}> */}
                  <Modal
                    open={openModalVideo}
                    onClose={handleCloseModalVideo}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableAutoFocus={true}
                  >
                    <MDBox id="modal-box" sx={{
                      position: 'absolute',
                      top: '45%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      bgcolor: '#292d5f !important',
                      border: '2px solid #21244c  !important',
                      boxShadow: 24,
                      p:4

                    }}>

                    <MDBox sx={{position:'absolute', top:10, right:10, cursor:'pointer'}}>
                      <CloseIcon fontSize="medium" onClick={handleCloseModalVideo} />
                    </MDBox>

                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <MDBox sx={{p:3}}>
                        <MDBox className="videomodal hidden"
                        bgColor="dark"
                        sx={{position:"absolute",zIndex:1, opacity:0.8, p:2, borderRadius:2}}>
                           <MDTypography color="light" variant="h6">
                                 Report Broken Video
                           </MDTypography>
                      </MDBox>



                      {/* Something is wrong icon */}
                      <ReportGmailerrorredIcon
                      fontSize="large"
                      color=""
                      className="videoIcon"
                      sx={{position:"absolute", right:56, top:75, zIndex:1}}
                      onClick={openModal}

                      />





                    <Video
                     key={displayVideo}
                     loop
                     muted
                     controls={['PlayPause',
                     'Seek',
                     'Time',
                     //  'Volume',
                     'Fullscreen']}

                     onCanPlayThrough={() => {
                       // Do stufff
                       var x = document.getElementsByClassName("rh5v-DefaultPlayer_component")[0]
                       x.style.height="440px"
                       var y = document.getElementsByClassName("rh5v-Fullscreen_icon")[0]
                       y.addEventListener("click", ()=>removeHeightRestriction())
                      }}>
                            <source src={displayVideo} type="video/mp4" />

                        </Video>

                        <MDTypography sx={{ mt: 3 }} id="modal-modal-title" variant="h6" component="h2">
                        {selectedVideo.title}
                      </MDTypography>
                      <MDTypography id="modal-modal-description">
                        {selectedVideo.description}
                      </MDTypography>


                      </MDBox>
                  </Grid>
              </Grid>
              </MDBox>
              </Modal>
              {/* </MDBox> */}
              </>:<></>
            }
              <DataTable  canSearch={true} isSorted={true} table={dataTableData} />


            </>:<>
            <MDBox display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
            </MDBox>
            </>
          }



        {/* use cases */}
            <MDBox mt={3}>
              <MDTypography variant="h4" fontWeight="medium" ></MDTypography>
              {displayDataUX!==null?
                <>


                  <DataTable  canSearch={true} isSorted={true} table={dataTableDataUX} />
                </>:<>
                <MDBox display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
                </MDBox>
                </>
              }
          </MDBox>
  </MDBox>




      {flowChart!==null?<>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <MDBox mb={3}>
              {totalUX!==null?
              <Card>
                <MDBox mb={3}>

                <Grid container spacing={2} mt={2}>


                  <Grid item xs={3}
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="center"
                  >
                  <MDTypography
                  position="absolute"
                  left="0"
                  ml={3}

                  >
                    {UXname}
                  </MDTypography>
                  </Grid>

                  {/* <Grid item xs={4}>
                  </Grid>
                  <Grid item
                  xs={5}
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="center"
                  flexDirection="row"

                  >
                  <MDTypography variant="subtitle2" >
                    Include Login Steps
                  <Switch color="info"/>
                  </MDTypography>
                  <MDTypography variant="subtitle2" >
                    Active
                  <Switch color="info" defaultChecked/>
                  </MDTypography>
                  <MDTypography variant="subtitle2" >
                    Passive
                  <Switch color="info" defaultChecked/>
                  </MDTypography>
                  </Grid> */}


                </Grid>
                <Divider variant='fullWidth'/>

                <Grid
                mt={2}
                container
                spacing={3}


                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                      <Grid item xs={6} ml={4}>
                      <MDTypography  bold>
                      State: {flowChart[0]['state']}
                      </MDTypography>
                      <MDTypography variant="subtitle2">
                        {/* Use Case Code: */}
                        {/* <br/>
                        Starting Point: "placeholder" */}
                        {/* <br/> */}

                        Status:  {flowChart[0]['state']===1?'Completed':'Not Working'}

                      </MDTypography>
                      </Grid>

                      <Grid item xs={3}>
                      <MDBox mb={2} width={150}>
                        <PanosPieChart
                        total_score={1000+totalUX}
                        penalty={-totalUX}
                        />
                      </MDBox>
                      </Grid>

                      <Grid item xs>
                      <MDTypography >
                        Total Score : {totalUX+1000}
                      </MDTypography>
                      <MDTypography variant="subtitle2">
                        Step Score : {-totalUX}
                        {/* <br/>
                        Pain Points Score: "placeholder"
                        <br/>
                        Disruptive Score: "placeholder" */}
                      </MDTypography>
                      </Grid>

                </Grid>
                {/* <PanosPieChart total={100}/> */}

                </MDBox>
              </Card>:<></>}
                <br/>
                <Card mt={4}>
                  <MDBox sx={{p:4}}>
                      <TabContext value={secondTabValue}>
                          <TabList onChange={handleSecondTabChange} aria-label="lab API tabs example">
                                <Tab label="Description" value="Description" />
                                <Tab label="Preconditions" value="Preconditions" />
                                <Tab label="Postconditions" value="Postconditions" />
                                <Tab label="Painpoints" value="Painpoints" />

                        </TabList>

                        <Divider />
                        <TabPanel value="Description">
                          <MDTypography variant='subtitle2'>
                              {flowChart[0]['description']}
                          </MDTypography>
                          </TabPanel>
                        <TabPanel value="Preconditions">
                            <MDTypography variant='subtitle2'>
                              Preconditions
                            </MDTypography>
                          </TabPanel>
                        <TabPanel value="Postconditions">Postconditions</TabPanel>
                        <TabPanel value="SystemState">System State</TabPanel>
                        <TabPanel value="Painpoints">Painpoints</TabPanel>
                        <TabPanel value="Bugs">{flowChart[0]['bug_description']}</TabPanel>
                        <TabPanel value="TechnicalDetails">
                            <MDTypography variant='subtitle2'>
                              Analysts's OS: {flowChart[0]['analyst_os_id']}
                              <br />
                              Analysts's Browser: {flowChart[0]['analyst_browser']}
                              <br />
                              Analysts's Screen Resolution: {flowChart[0]['analyst_screen_resolution']}
                              <br />

                            </MDTypography>
                        </TabPanel>

                      </TabContext>
                  </MDBox>
                </Card>

                  <br/>
                {/* video */}
                  <Card mt={3} sx={{p:4}}>

                    <MDBox>
                      <MDTypography variant='subtitle1' sx={{display:"flex", alignItems:"center"}}>
                        <VideocamIcon
                        fontSize="medium"
                         sx={{color: "lightgrey",backgroundColor: "transparent"}}
                         /> &#160; Video of the Actual Journey
                      </MDTypography>
                    </MDBox>

                    <Divider variant="fullWidth" />

                    <MDBox mt={1}>
                    {displayVideoUC!==null?<>
                      <Video key={displayVideoUC} loop muted  width={320} height={240}
                              controls={['PlayPause',
                               'Seek',
                               'Time',
                              //  'Volume',
                                'Fullscreen']}

                              onCanPlayThrough={() => {
                                  // Do stufff
                              }}>
                              <source src={displayVideoUC} type="video/mp4" />

                          </Video>
                          </>:<></>}
                    </MDBox>

                    <MDBox mt={6}>
                    <MDTypography variant='subtitle1' sx={{display:"flex", alignItems:"center"}}>
                        <SchemaIcon
                        fontSize="medium"
                         sx={{color: "lightgrey",backgroundColor: "transparent"}}
                         /> &#160; UX Analysis of the Complete Process
                      </MDTypography>
                    </MDBox>

                    <Divider variant="fullWidth" />

                      <MDBox mt={4} ml={4}>




                              <Card style={{ height: 1000, pt:10 }}>

                              <ReactFlow
                              elements={elements}
                              snapToGrid={true}
                              snapGrid={[15, 15]}
                              fitView={true}
                              className="MyDiagram"
                              />
                            <MDButton color="light" onClick={exportThis}>
                              Export to JPEG
                            </MDButton>
                              </Card>


                      </MDBox>


                      <MDBox  mt={9} mb={2} >
                        <Grid container spacing={2} ml={2}>
                          <Grid item xs sx={{display:"flex", alignItems:"flex-start"}}>
                            {/* <MDTypography sx={{display:"flex", alignItems:"center",justifyContent:"center"}} className="rotate270">
                              Symbols &#160;&#160; &#160; &#160;
                            </MDTypography> */}
                            <MDTypography mt={3} sx={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                              Symbols &#160; &#160;&#160; &#160;
                            </MDTypography>

                            <Grid display='flex' flexDirection='column'>
                                <MDTypography variant="subtitle2" md={2} display="flex" alignItems="center">
                                  <HelpOutlineIcon
                                  fontSize="medium"
                                  sx={{color: "grey",backgroundColor: "transparent"}}
                                  />&#160; execution points &#160;&#160;&#160;&#160; <ReportProblemIcon
                                  fontSize="medium"
                                  x={{color: "grey",backgroundColor: "transparent"}}
                                  /> &#160; pain points
                                </MDTypography>
                                <br/>
                                <MDTypography variant="subtitle2" md={2} display="flex" alignItems="center">
                                  <HowToRegIcon
                                  fontSize="medium"
                                  x={{color: "grey",backgroundColor: "transparent"}}
                                  />&#160; verified field &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<LockResetIcon
                                   fontSize="medium"
                                   x={{color: "grey",backgroundColor: "transparent"}}
                                   /> &#160; security step
                                </MDTypography>
                            </Grid>

                          </Grid>
                          {/* scoring */}
                          <Grid item xs sx={{display:"flex", alignItems:"flex-start"}}>
                            {/* <MDTypography sx={{display:"flex", alignItems:"center",justifyContent:"center"}} className="rotate270">
                            Scoring &#160;&#160; &#160; &#160;
                            </MDTypography> */}
                            <MDTypography mt={3} sx={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                              Scoring &#160; &#160;&#160; &#160;
                            </MDTypography>
                            <Grid sx={{display:"flex", flexDirection:"column"}}>
                                  <Grid sx={{display:"flex", flexDirection:"row"}}>
                                      <MDTypography variant="subtitle2" mr={3}>
                                        Click: -20
                                        {/* <br/>
                                        Click: -20
                                        <br/>
                                      Omit: -5 */}
                                      </MDTypography>
                                      <MDTypography variant="subtitle2">
                                        Omit: -5
                                      </MDTypography>
                                  </Grid>


                                    <Grid mt={3.5} sx={{display:"flex", flexDirection:"row"}}>
                                        <MDTypography variant="subtitle2" mr={3}>
                                          Type: -20
                                          {/* <br/>
                                          Click: -20
                                          <br/>
                                        Omit: -5 */}
                                        </MDTypography>
                                        <MDTypography variant="subtitle2">
                                          Irrelevant: -25
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                          </Grid>
                        </Grid>
                      </MDBox>


                  </Card>
                  </MDBox>
                  {/* -----
                <Card>
                  <MDBox mb={3}>

                  {Object.keys(flowChart[0]).map((keyName, i) => (
                  <>  {keyName!="actions"?
                  <div className="travelcompany-input" key={i}>
                      <span className="input-label">{keyName} : {flowChart[0][keyName]}</span>
                  </div>
                  :<></>}
                  </>
              ))}
                  </MDBox>
                </Card> */}

            </Grid>
        </Grid>
        </MDBox>
        </>:<></>
      }
  </>
  );
}


export default ActiveTables;
