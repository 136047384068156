import Card from "@mui/material/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext, useEffect, useRef } from "react";
import UserContext from "context/UserContext";

import MDButton from "components/MDButton";
import AppleIcon from "@mui/icons-material/Apple";
import ComputerIcon from "@mui/icons-material/Computer";
import WatchIcon from "@mui/icons-material/Watch";
import ChartsHeader from "../ChartsHeader";
import gif from "assets/images/marketwebp.webp";

export default function SuperChart({ loadPopup, mediaid, workspaceMedia }) {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboards, setDashboards] = useState([]);
  const [selectedMediaID, setSelectedMediaID] = useState(null);
  const [selectedExclude, setSelectedExclude] = useState(
    workspaceMedia.exclude_overlapping
  );
  // const [screenwidth, setScreenWidth] = useState(1560);
  const [toolTipInfo, setToolTipInfo] = useState(
    "Functionality Market Overview shows the number of functionalities each bank has implemented for any given functionality section. The coloured bars represent the number of functionalities each bank/fintech offers compared to the total number of functionalities in a given functionality section offered by the entire market. The length that each coloured bar occupies is expressed in percentage form compared to the selected market. This means that the longer the coloured bar, the more functionalities one bank/fintech has implemented in that specific section and vice versa. Thus, one can acquire an easy input of where a bank/fintech excels or falls behind compared to their peers in the chosen market."
  );

  const [isLoadingSuperChart, setIsLoadingSuperChart] = useState(true);

  // const [optionsMarketPosition,setOptionsMarketPosition]=useState({
  //     title: {
  //       text: 'Market Position'
  //     },   type: 'column',
  //     series: [{
  //       data: [1, 2, 3]
  //     }]
  //   });

  const [optionsSuperChart, setOptionsSuperChart] = useState({
    exporting: {
      enabled: true,
      buttons: false,
    },
    title: {
      text: "Functionalities Overview",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });
  const hashCode = (s) =>
    s.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  async function chart_superchart() {
    setIsLoading(true);
    setIsLoadingSuperChart(true);

    var data = {};
    data["mediaid"] = selectedMediaID;
    data["exclude"] = selectedExclude;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_superchart";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var json = await metadataResponse.json();
    var resp = [];
    for (var i in json) {
      resp.push(json[i]);
    }

    var fc = [];
    var categories = [];
    var series = [];
    var banks = [];
    var bank_name = [];
    resp.forEach(function (row, index) {
      if (!categories.includes(row.DisplayName)) {
        categories.push(row.DisplayName);
      }
      if (banks[row.BankID] === undefined) banks[row.BankID] = [];
      var data = { name: row.BankName, data: row.count };
      banks[row.BankID]["BankName"] = row.BankName;

      banks[row.BankID][hashCode(row.DisplayName + "treeid")] = row.ParentID;
      banks[row.BankID]["BankID"] = row.BankID;
      banks[row.BankID][hashCode(row.DisplayName)] = row.count;
      banks[row.BankID]["Color"] = row.colors.Color;
    });

    banks.forEach(function (row, index) {
      let data = [];
      let nodes = [];

      categories.forEach(function (r, i) {
        let val = 0;
        let valNode = 0;
        if (row[hashCode(r)] !== undefined) val = row[hashCode(r)];
        data.push(val);
        if (row[hashCode(r + "treeid")] !== undefined)
          valNode = row[hashCode(r + "treeid")];
        nodes.push(valNode);
      });
      series.push({
        name: row.BankName,
        bankid: row["BankID"],
        color: row["Color"],
        nodes: nodes,
        data: data,
      });
    });

    setOptionsSuperChart({
      chart: {
        type: "bar",
        height: 750,
      },
      credits: {
        enabled: false,
      },

      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },

      exporting: {
        menuItemDefinitions: {
          toggleTable: {
            onclick: function () {
              if (
                this.dataTableDiv &&
                this.dataTableDiv.style.display !== "none"
              ) {
                this.dataTableDiv.style.display = "none";
              } else {
                this.viewData();
                this.dataTableDiv.style.display = "";
              }
            },
            text: "Toggle Table",
          },
        },
        buttons: false,
      },

      plotOptions: {
        series: {
          stacking: "percent",
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                //    chart_functionalities_overview_forBankMedia(fc[this.category]);
                let d = this.series.userOptions;
                if (selectedExclude == true) {
                  loadPopup(
                    selectedMediaID,
                    "1",
                    d.nodes[this.index],
                    "" + d.bankid,
                    "6",
                    "5",
                    "Functionality Market Overview",
                    "Identify which exact features fall under each category by the institution of interest and how they are implemented, captured in video and UX evaluated.",
                    gif
                  );
                } else {
                  loadPopup(
                    selectedMediaID,
                    "1",
                    d.nodes[this.index],
                    "" + d.bankid,
                    "6",
                    "5",
                    "Functionality Market Overview",
                    "Identify which exact features fall under each category by the institution of interest and how they are implemented, captured in video and UX evaluated.",
                    gif
                  );
                }
                //  loadPopup(selectedMediaID,"1",fc[this.category],bankid);
              },
            },
          },
        },
      },
      legend: {
        //  reversed: true
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          let category = `<span>${this.x}</span><br>`;
          let bankName =
            '<span style="color: ' +
            this.point.color +
            '">\u25CF</span> ' +
            `<span">${this.series.name}: </span>`;
          let desc = "<b>" + this.point.y + "</b>";
          if (this.point.y == 1) {
            desc += " Functionality";
          } else {
            desc += " Functionalities";
          }
          return category + bankName + desc;
        },
      },

      series: series,
    });
    // setData(resp);
    setIsLoading(false);
    setIsLoadingSuperChart(false);
  }

  async function setUserWorkspaceState(newmedia) {
    let data = {};
    var newState = workspaceMedia;
    newState.market_overview = newmedia;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  async function setUserWorkspaceStateExclude(new_exclude) {
    let data = {};
    var newState = workspaceMedia;
    newState.exclude_overlapping = new_exclude;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  async function getDashboards() {
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/getall";
    const metadataResponse = await fetch(apiURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
    setDashboards(resp);

    setSelectedMediaID(mediaid);
  }

  useEffect(() => {
    getDashboards();
  }, []);

  useEffect(() => {
    if (selectedMediaID !== null) {
      chart_superchart();
    }
  }, [selectedMediaID]);
  useEffect(() => {
    if (selectedMediaID !== null) {
      chart_superchart();
    }
  }, [selectedExclude]);

  const handleMedia = (mediaid) => {
    setSelectedMediaID(mediaid);
    setUserWorkspaceState(mediaid);
  };

  const handleExclude = (ex) => {
    setUserWorkspaceStateExclude(ex);
    setSelectedExclude(ex);
  };

  const chartRef = useRef();

  return (
    <Card>
      <MDBox p={1}>
        <ChartsHeader
          title="Functionality Market Overview"
          handleMedia={handleMedia}
          selectedMediaID={selectedMediaID}
          toolTipInfo={toolTipInfo}
          chartRef={chartRef}
          marginRight={{ marginRight: 7 }}
        />
        {isLoadingSuperChart !== true ? (
          <>
            <HighchartsReact
              highcharts={Highcharts}
              options={optionsSuperChart}
              ref={chartRef}
            />
            <MDBox
              sx={{ display: "flex", justifyContent: "center", mt: 1, mb: 3 }}
            >
              <MDButton
                className={
                  selectedExclude == false
                    ? "AnalyticsButtonSelected"
                    : "AnalyticsButton"
                }
                style={{ backgroundColor: "transparent" }}
                disabled={selectedExclude === false ? true : false}
                color={selectedExclude == false ? "" : ""}
                onClick={() => {
                  handleExclude(false);
                }}
              >
                Include Overlapping
              </MDButton>

              <MDButton
                className={
                  selectedExclude == true
                    ? "AnalyticsButtonSelected"
                    : "AnalyticsButton"
                }
                style={{ backgroundColor: "transparent" }}
                disabled={selectedExclude === true ? true : false}
                color={selectedExclude == true ? "" : ""}
                onClick={() => {
                  handleExclude(true);
                }}
              >
                Exclude Overlapping
              </MDButton>
            </MDBox>
          </>
        ) : (
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ p: 25 }}
          >
            <CircularProgress />
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}
