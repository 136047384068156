/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext, useEffect, useRef } from "react";

// react-router-dom components
import { Route, Navigate, useLocation, Link } from "react-router-dom";
// import "./index.css";
import "./index.scss";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import brandWhite from "assets/images/fi-logo-ls.png";
// Images
import bgImage from "assets/images/bg-sign-in.jpg";
import * as Cookies from "js-cookie";
import UserContext from "../../../../context/UserContext";
import PinInput from "react-pin-input";
import PhoneInput from "react-phone-input-2";
import TextField from "@mui/material/TextField";
import "react-phone-input-2/lib/material.css";
import "../basic/phoneVerification.css";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import NewBasicLayout from "layouts/authentication/components/NewBasicLayout";
import { NonceProvider } from "react-select";
import Timer from "scientia/verifyPhoneModal/components/timer";

function NewLogin() {
  const [timer, setTimer] = useState(30);
  const captchaRef = useRef(null);
  const userContext = useContext(UserContext);
  const [frm, setFrm] = useState({});

  function handleChange(e) {
    let x = frm;
    x[e.target.id] = e.target.value;
    setFrm(x);
    // console.log(frm)
  }

  const handleTryAgain = () => {
    setFailedLogin(false);
    setResponseData(
      "Welcome back, please check your email to find your magic link"
    );
    setWaitForPin(1);
  };

  const [rememberMe, setRememberMe] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [email, setEmail] = useState("");
  const [uid, setUid] = useState(null);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [waitForPin, setWaitForPin] = useState(null);
  const [signUp, setSignUp] = useState(null);
  const [userInactive, setUserInactive] = useState(null);
  const [organizationInactive, setOrganizationInactive] = useState(null);
  const [failedLogin, setFailedLogin] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loginScreen, setLoginScreen] = useState(true);

  // PHONE Verification
  const [pin, setPIN] = useState("");
  const [correctPIN, setCorrectPin] = useState("");
  const [phone, setPhone] = useState(null);
  const [openVerify, setOpenVerify] = useState(false);
  const [openPIN, setOpenPIN] = useState(false);
  const [phoneNotVerified, setPhoneNotVerified] = useState(true);

  // PIN Fields
  const [pin_value1, setPin_value1] = useState(null);
  const [pin_value2, setPin_value2] = useState(null);
  const [pin_value3, setPin_value3] = useState(null);
  const [pin_value4, setPin_value4] = useState(null);
  const [pin_value5, setPin_value5] = useState(null);
  const [pin_value6, setPin_value6] = useState(null);

  const handleset_Pin1 = (e) => {
    const otpInputs = document.querySelectorAll(
      '.form-input-number[type="password"]'
    );
    if (e.target.value === "") {
      otpInputs[0].focus();
    } else {
      otpInputs[1].focus();
      setPin_value1(e.target.value);
      console.log(e.target.value);
    }
  };

  const handleset_Pin2 = (e) => {
    const otpInputs = document.querySelectorAll(
      '.form-input-number[type="password"]'
    );
    if (e.target.value === "") {
      otpInputs[0].focus();
    } else {
      otpInputs[2].focus();
      setPin_value2(e.target.value);
    }
    console.log(e.target.value);
  };
  const handleset_Pin3 = (e) => {
    const otpInputs = document.querySelectorAll(
      '.form-input-number[type="password"]'
    );
    if (e.target.value === "") {
      otpInputs[1].focus();
    } else {
      otpInputs[3].focus();
      setPin_value3(e.target.value);
    }
    console.log(e.target.value);
  };
  const handleset_Pin4 = (e) => {
    const otpInputs = document.querySelectorAll(
      '.form-input-number[type="password"]'
    );
    if (e.target.value === "") {
      otpInputs[2].focus();
    } else {
      otpInputs[4].focus();
      setPin_value4(e.target.value);
    }
    console.log(e.target.value);
  };
  const handleset_Pin5 = (e) => {
    const otpInputs = document.querySelectorAll(
      '.form-input-number[type="password"]'
    );
    if (e.target.value === "") {
      otpInputs[3].focus();
    } else {
      otpInputs[5].focus();
      setPin_value5(e.target.value);
    }
    console.log(e.target.value);
  };

  const handleset_Pin6 = (e) => {
    const otpInputs = document.querySelectorAll(
      '.form-input-number[type="password"]'
    );
    if (e.target.value === "") {
      otpInputs[4].focus();
    } else {
      setPin_value6(e.target.value);
      console.log(
        pin_value1,
        pin_value2,
        pin_value3,
        pin_value4,
        pin_value5,
        e.target.value
      );
      frm["pin"] =
        pin_value1 +
        pin_value2 +
        pin_value3 +
        pin_value4 +
        pin_value5 +
        e.target.value;
      console.log(frm["pin"]);
      doAction_Verify(frm["pin"], null);
    }
  };

  const handleSMSPIN = (e) => {
    console.log(e.target.value);
    setPIN(e.target.value);
  };
  const handlePhone = (e) => {
    e = "+" + e;
    setPhone(e);
    //setOpenVerify(true)
    setOpenPIN(false);
    setOpenVerify(false);
    setPhoneNotVerified(false);
  };

  //validate email for comas, uppercase, and obvious mistakes
  const validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmail = (e) => {
    setFrm({ email: e.target.value });
    if (validateEmail(e.target.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const resendEmail = async () => {
    try {
      const data = {};
      data.uname = frm.email.toLowerCase();
      let apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL += "/api/auth/resend";
      const metadataResponse = await fetch(apiURL, {
        method: "POST",
        body: JSON.stringify(data),
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (metadataResponse.ok) setTimer(10);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendSMS = async () => {
    let data = {};
    data["username"] = email;
    data["email"] = email;
    data["phone"] = phone;
    data["uid"] = uid;
    if (uid !== undefined) {
      var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL += "/api/SignUpCreateNewPINtoken";
      const metadataResponse = await fetch(apiURL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        body: JSON.stringify(data),
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          // 'bearer': userContext.userData.apiToken,
          "Content-Type": "application/json",
        },
      });
      var resp = await metadataResponse.json();
      console.log(resp);
    }

    setOpenPIN(true);
    // if no pin entereted retry to send sms
    //setTimeout(() => {setOpenPIN(false)}, 15000)
  };

  const checkPINValidity = async () => {
    let data = {};
    data["pin"] = pin;
    data["uid"] = uid;
    data["phone"] = phone;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/SignUpCheck_if_PIN_is_valid";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
    console.log(resp);
    if (resp.result) {
      setOpenPIN(false);
      setOpenVerify(false);
      setPhoneNotVerified(false);
    } else {
      alert("Invalid PIN try again . . .");
    }
  };

  function handlePIN(value) {
    frm["pin"] = value;
    // console.log(frm)
  }

  async function doAction_Login() {
    event.preventDefault();

    const data = {};
    data["email"] = frm.email.toLowerCase();
    data["username"] = frm.email.toLowerCase();
    data["captcha"] = token;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/auth/auto-login";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const resp = await metadataResponse.json();
    if (resp.status == 0) {
      setResponseData(resp.message);
    } else {
      if (resp.new_user == 1) {
        setUid(resp.uid);
        setSignUp(1);
        setEmail(frm.email);
        setLoginScreen(false);
      } else {
        setEmail(frm.email);
        setLoginScreen(false);

        setWaitForPin(1);
        // setTimeout(() => {console.log('tick');setDisplayPin(true)}, 10000)
      }
      setResponseData(resp.message);
    }
  }
  async function doAction_SignUp() {
    event.preventDefault();
    const data = {};
    data["username"] = email.toLowerCase();
    data["email"] = email.toLowerCase();
    data["firstname"] = frm.firstname;
    data["lastname"] = frm.lastname;
    data["phone"] = phone;
    console.log(data);

    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/auth/auto-signup";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const resp = await metadataResponse.json();
    if (resp.status == 0) {
      setResponseData(resp.message);
      setWaitForPin(null);
    } else {
      setSignUp(null);
      setLoginScreen(false);
      setResponseData(
        "Welcome, check your inbox for your PIN or magic link to login"
      );
      setWaitForPin(1);
    }
    // console.log(resp);
  }
  async function doAction_Pin() {
    event.preventDefault();
    const data = {};
    data["username"] = email.toLowerCase();
    setDisplayPin(false);
    setDisplayPin2(true);
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/auth/pin";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const resp = await metadataResponse.json();

    // console.log(resp);
  }
  async function doAction_Verify(value, em) {
    // event.preventDefault();
    const data = {};
    // data['pin']=frm.pin;
    data["pin"] = value;
    data["username"] = email.toLowerCase();
    if (em !== null) data["username"] = em;

    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/auth/verify";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const resp = await metadataResponse.json();
    if (resp.accessToken === null) {
      setFailedLogin(true);
      setResponseData(resp.message);
      setWaitForPin(null);
    } else {
      if (resp.organizationIsActive != true) {
        setResponseData("");
        setOrganizationInactive(1);
        setLoginScreen(true);

        setWaitForPin(null);
        return;
      }
      // // checks for org end date
      // const today = new Date();
      // const givenDate = new Date(resp.org);
      // if (today > givenDate) {
      //   setResponseData("");
      //   setOrganizationInactive(1);
      //   setLoginScreen(true);
      //   setWaitForPin(null);
      //   return;
      // }
      if (resp.isActive != true) {
        setResponseData("");
        setWaitForPin(null);
        setUserInactive(1);
        setLoginScreen(true);

        return;
      }
      var dx = {
        apiToken: resp.accessToken,
        email: resp.email,
        roles: resp.roles,
        darkMode: resp.darkMode,
        firstname: resp.firstname,
        lastname: resp.lastname,
        tk: resp.tk,
      };
      userContext.setUserDataForContext(dx);
      Cookies.set("session", JSON.stringify(dx), { expires: 1 });
    }
    // console.log(resp);
  }
  const [log, setLog] = useState(true);
  const sessionCookie = Cookies.get("session");

  if (sessionCookie !== undefined) {
    userContext.setUserDataForContext(JSON.parse(sessionCookie));
  }

  if (userContext.userData) {
    Cookies.set("session", JSON.stringify(userContext.userData), {
      expires: 14,
    });
    return <Navigate to="/home" replace={true} />;
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const pi = queryParams.get("pin");
    const em = queryParams.get("email");
    const re_pi = queryParams.get("request_pin");
    if (pi !== null && em !== null) {
      doAction_Verify(pi, em);
    }
    if (re_pi !== null) {
      setResponseData(
        "Welcome back, please check your email to find your magic link"
      );
      setEmail(re_pi);
      setLoginScreen(false);

      setWaitForPin(1);
    }

    //if (token) {
    //    captchaRef.current.reset();
    //}
  }, []);
  function goRecap() {
    const token = captchaRef.current.getValue();

    setLog(false);
  }
  const [displayPin, setDisplayPin] = useState(false);
  const [displayPin2, setDisplayPin2] = useState(false);

  // auto focus on pin
  const pinRef = useRef();
  useEffect(() => {
    const otpInputs = document.querySelectorAll(
      '.form-input-number[type="password"]'
    );

    for (let i = 0; i < otpInputs.length; i++) {
      otpInputs[i].addEventListener("keydown", function (event) {
        if (event.key === "ArrowLeft") {
          otpInputs[i - 1].focus();
        }
      });

      otpInputs[i].addEventListener("paste", function (event) {
        event.preventDefault();
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData("text");
        let pin0 = pastedData[0];
        otpInputs[0].value = pin0;
        let pin1 = pastedData[1];
        otpInputs[1].value = pin1;
        let pin2 = pastedData[2];
        otpInputs[2].value = pin2;
        let pin3 = pastedData[3];
        otpInputs[3].value = pin3;
        let pin4 = pastedData[4];
        otpInputs[4].value = pin4;
        let pin5 = pastedData[5];
        otpInputs[5].value = pin5;
        console.log(pastedData);
        if (pastedData?.length >= 6) {
          frm["pin"] = pastedData;
          doAction_Verify(frm["pin"], null);
        }
      });
    }

    pinRef?.current?.focus();
  }, [waitForPin]);

  const [token, setToken] = useState(null);
  useEffect(() => {
    if (token !== null) {
      setLog(false);
    }
  }, [token]);
  return (
    <NewBasicLayout>
      <header className="myloginheader header-main">
        <img
          src="/images/header-bg.svg"
          alt="header-bg"
          className="header-bg"
        />
        <div className="container">
          <img src="/images/logo-main-header.svg" alt="logo" />
        </div>
      </header>

      <Card sx={{ background: "transparent", boxShadow: "none" }}>
        {loginScreen ? (
          <>
            {responseData === null ? (
              <main>
                <div class="container">
                  <div class="flex-row">
                    <a
                      href="https://www.fintechinsights.io/"
                      target="_blank"
                      class="link-div"
                    >
                      <img src="/images/arrow-nav-title.svg" alt="arrow" />
                    </a>
                    <a href="https://www.fintechinsights.io/" target="_blank">
                      <h1 class="title-header">Back to FinTech Insights</h1>
                    </a>
                  </div>
                  <div class="form-container-login">
                    <form class="sign-up-form">
                      <h2 class="title-form">Sign in</h2>
                      <div class="form-content flex-table">
                        <div class="form-input flex-table">
                          <label class="label-email" for="email-input">
                            Email
                          </label>
                          <input
                            type="text"
                            id="email-input"
                            name="email-input"
                            placeholder="Enter your email address"
                            onChange={handleEmail}
                          />
                        </div>
                      </div>
                      <GoogleReCaptchaProvider
                        reCaptchaKey="6Le9tZMjAAAAAO_8nnNhHdIq3X4Pr_n3fO8Ib3YO"
                        container={{
                          element: "gcap",
                          parameters: {
                            badge: "bottomleft", // optional, default undefined
                          },
                        }}
                      >
                        <GoogleReCaptcha
                          onVerify={(token) => {
                            setToken(token);
                          }}
                        />
                        <MDBox>
                          {emailError ? (
                            <button
                              onClick={doAction_Login}
                              class="form-submit-btn"
                              type="submit"
                              disabled={log}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              onClick={doAction_Login}
                              class="form-submit-btn"
                              type="submit"
                              disabled={log}
                            >
                              Next
                            </button>
                          )}
                          {/* <br />
                          &#9432; By logging in you are accepting FinTech
                          Insights’{" "}
                          <a href="https://www.fintechinsights.io/terms-and-conditions">
                            T&C
                          </a>{" "}
                          and{" "}
                          <a href="https://www.fintechinsights.io/privacy-policy">
                            Privacy Policy
                          </a> */}
                        </MDBox>
                      </GoogleReCaptchaProvider>
                    </form>
                  </div>
                </div>
              </main>
            ) : (
              <>
                <main>
                  <div class="container">
                    <div class="flex-row">
                      <a
                        href="https://www.fintechinsights.io/"
                        target="_blank"
                        class="link-div"
                      >
                        <img src="/images/arrow-nav-title.svg" alt="arrow" />
                      </a>
                      <a href="https://www.fintechinsights.io/" target="_blank">
                        <h1 class="title-header">Back to FinTech Insights</h1>
                      </a>
                    </div>
                    <div class="form-container-login">
                      <form class="sign-up-form">
                        <h2 class="title-form">Sign in</h2>
                        {/* Errors */}
                        {organizationInactive !== null ? (
                          <div class="form-content flex-table verification-status">
                            <p class="form-text">
                              Your organisation is currently inactive. Please
                              contact our{" "}
                              <a href="mailto:support@fintechinsights.io">
                                Support team
                              </a>{" "}
                              for further support.
                            </p>
                          </div>
                        ) : userInactive !== null ? (
                          <div class="form-content flex-table verification-status">
                            <p class="form-text">
                              Your account is currently disabled.
                            </p>
                          </div>
                        ) : (
                          <div class="form-content flex-table verification-status">
                            <p class="form-text">
                              FinTech Insights is not yet available for your
                              organisation. Please contact our{" "}
                              <a href="mailto:sales@fintechinsights.io">
                                Sales team
                              </a>{" "}
                              for more information.
                            </p>
                          </div>
                        )}

                        <button class="form-submit-btn back-btn" type="submit">
                          Back to Homepage
                        </button>
                      </form>
                    </div>
                  </div>
                </main>
              </>
            )}
          </>
        ) : (
          <></>
        )}

        {signUp !== null ? (
          <>
            <main>
              <div class="container">
                <div class="flex-row">
                  <a
                    href="https://www.fintechinsights.io/"
                    target="_blank"
                    class="link-div"
                  >
                    <img src="/images/arrow-nav-title.svg" alt="arrow" />
                  </a>
                  <a href="https://www.fintechinsights.io/" target="_blank">
                    <h1 class="title-header">Back to FinTech Insights</h1>
                  </a>
                </div>
                <div class="form-container">
                  <form class="sign-up-form">
                    <h2 class="title-form">Sign up</h2>
                    <div class="form-content flex-table">
                      <div class="form-input flex-table">
                        <label class="label-firstname" for="first-name">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="firstname"
                          name="first-name"
                          placeholder="Enter your first name"
                          onChange={handleChange}
                        />
                      </div>
                      <div class="form-input flex-table">
                        <label class="label-lastname" for="last-name">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="lastname"
                          name="last-name"
                          placeholder="Enter your last name"
                          onChange={handleChange}
                        />
                      </div>
                      <div class="form-input flex-table">
                        <label class="label-phonenumber" for="phone-number">
                          Phone number
                        </label>
                        <div class="input-phone-container">
                          <PhoneInput
                            className="input-phone"
                            id="phone-number"
                            country={"us"}
                            value={phone}
                            onChange={(e) => handlePhone(e)}
                            enableSearch={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="submit_btn_signup">
                      <button
                        disabled={phoneNotVerified}
                        onClick={() => doAction_SignUp()}
                        class="form-submit-btn btn-for-signup"
                        type="submit"
                      >
                        Next
                      </button>
                      <button class="PrevButtonLogin" type="submit">
                        Back to Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </main>
          </>
        ) : (
          <></>
        )}

        {waitForPin !== null ? (
          <>
            <main>
              <div class="container">
                <div class="flex-row">
                  <a
                    href="https://www.fintechinsights.io/"
                    target="_blank"
                    class="link-div"
                  >
                    <img src="/images/arrow-nav-title.svg" alt="arrow" />
                  </a>
                  <a href="https://www.fintechinsights.io/" target="_blank">
                    <h1 class="title-header">Back to FinTech Insights</h1>
                  </a>
                </div>
                <div class="form-container-password">
                  <form class="sign-up-form2up">
                    <div class="title-form-div">
                      <h2 class="title-form">Sign in</h2>
                    </div>
                    <div class="sign-up-form2">
                      <div class="form-content flex-table verification-status">
                        {!failedLogin ? (
                          <p class="form-text">
                            Welcome, check your inbox for your PIN or magic link
                            to login
                          </p>
                        ) : (
                          <>
                            {" "}
                            <p class="form-text">Invalid PIN try again</p>
                          </>
                        )}

                        <div class="flex-table form-input">
                          <label for="code-verification" class="form-label">
                            Enter PIN code
                          </label>
                          <input
                            type="text"
                            id="code-verification"
                            class="form-input"
                            placeholder="Enter PIN code"
                          />
                          <div class="flex-row verification-grid">
                            <input
                              className="form-input-number"
                              type="password"
                              maxlength="1"
                              onChange={(e) => {
                                handleset_Pin1(e);
                              }}
                              autofocus
                            />
                            <input
                              className="form-input-number"
                              type="password"
                              maxlength="1"
                              onChange={(e) => {
                                handleset_Pin2(e);
                              }}
                            />
                            <input
                              className="form-input-number"
                              type="password"
                              maxlength="1"
                              onChange={(e) => {
                                handleset_Pin3(e);
                              }}
                            />
                            <input
                              className="form-input-number"
                              type="password"
                              maxlength="1"
                              onChange={(e) => {
                                handleset_Pin4(e);
                              }}
                            />
                            <input
                              className="form-input-number"
                              type="password"
                              maxlength="1"
                              onChange={(e) => {
                                handleset_Pin5(e);
                              }}
                            />
                            <input
                              className="form-input-number"
                              type="password"
                              maxlength="1"
                              onChange={(e) => handleset_Pin6(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <MDBox>
                        {timer > 0 ? (
                          <MDTypography
                            className="resend-pin-text"
                            sx={{ marginBottom: "1px" }}
                          >
                            Resend PIN in: <b>0:</b>
                            <Timer timer={timer} setTimer={setTimer} />
                          </MDTypography>
                        ) : (
                          <MDTypography
                            className="resend-pin-text"
                            sx={{
                              marginBottom: "1px",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={resendEmail}
                          >
                            Resend Magic Link
                          </MDTypography>
                        )}
                      </MDBox>
                      <button class="form-submit-btn2" type="submit">
                        Next
                      </button>

                      {/* <br /> */}
                      <button class="PrevButtonLogin" type="submit">
                        Back to Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </main>
          </>
        ) : (
          <></>
        )}
      </Card>

      <footer class="footer-main footer-minify">
        <div class="container flex-table">
          <div class="footer-bottom-content flex-row">
            <p class="footer-text">
              © {new Date().getFullYear()} - All Rights Reserved by Scientia
              FinTech Insights LTD
            </p>
            <div class="flex-row">
              <a
                href="https://www.fintechinsights.io/privacy-policy?hsLang=en"
                class="footer-link"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.fintechinsights.io/terms-and-conditions?hsLang=en"
                class="footer-link"
              >
                Terms &amp; Conditions
              </a>
            </div>
          </div>
        </div>
      </footer>
    </NewBasicLayout>
  );
}

export default NewLogin;
