import React from "react";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";

// const options = 

const PanosPieChart = (props) => {
  return (
    <div>
      <PieChart 
      highcharts={Highcharts} 
      options={{
        chart: {
          type: "pie",
          // size
          height:"90%"
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            allowPointSelect: false,
            cursor: "pointer",
            dataLabels: {
              enabled: false
            },
            showInLegend: false,
            size:"100%"
          }
        },
        series: [
          {
            name: "",
            color: "#006600",
            lineWidth: 1,
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 3,
              states: {
                hover: {
                  enabled: true,
                  lineWidth: 1
                }
              }
            },
            data: [
              {
                name:"Final Score",
                y: props.total_score,
                sliced: false
              },
              {
                name:"Step Score",
                y: props.penalty,
                sliced: false
              },
              {
                name:"Pain Score",
                y: 100,
                sliced: false
              },

            ]
          }
        ]
      }} 
      />
    </div>
  );
};

export default PanosPieChart;
