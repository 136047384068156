import { useState,useContext,useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Box from '@mui/material/Box';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';




import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandIcon from '@mui/icons-material/Expand';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';










class Widget extends React.Component {
    
    state = {
        checked: this.props.nodeValues,
        expanded: [],
    };

    nodes = this.props.mynodes


    
    constructor(props) {
        super(props);
        this.onCheck = this.onCheck.bind(this);
        this.onExpand = this.onExpand.bind(this);
    }
    
    onCheck(checked) {
        this.setState({ checked });
    }
    
    onExpand(expanded) {
        this.setState({ expanded });
    }


    save(){
        // console.log("save")
        // console.log(this.state.checked)
    }


render() {
    
    
    const { checked, expanded } = this.state;    
    

    return (
        <>
        
      <CheckboxTree
        nodes={this.nodes}
        checked={checked}
        expanded={expanded}
        onCheck={this.onCheck}
        onExpand={this.onExpand}
        showExpandAll={true}
        nativeCheckboxes = {true}
        
        icons={{
            check: <CheckBoxIcon />,
            uncheck: <CheckBoxOutlineBlankIcon />,
            halfCheck: <CheckIcon />,
            expandClose: <ExpandLessIcon fontSize="medium" />,
            expandOpen: <ExpandMoreIcon fontSize="medium" />,
            expandAll: <FeaturedPlayListIcon fontSize="medium"/>,
            collapseAll: <FolderOffIcon fontSize="medium" />,
            parentClose: <FolderIcon />,
            parentOpen: <FolderOpenIcon/>,
            leaf: <FolderIcon />
        }}
        />


        <MDBox display="flex" justifyContent="flex-end" p={2}>
        <MDButton 
        color="info"
        onClick={()=>this.save()}
        >
            Save Weights
        </MDButton>
        </MDBox>


        </>
    );
}
  
}

export default Widget;
