import Card from "@mui/material/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext, useEffect, useRef } from "react";
import UserContext from "context/UserContext";
import MDButton from "components/MDButton";
import AppleIcon from "@mui/icons-material/Apple";
import ComputerIcon from "@mui/icons-material/Computer";
import WatchIcon from "@mui/icons-material/Watch";
import Slider, {
  SliderThumb,
  SliderValueLabelProps,
} from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ChartsHeader from "../ChartsHeader";
import gif from "assets/images/marketwebp.webp";
import "./styles.css";
import MDTypography from "components/MDTypography";

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "#3a8589",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
    "& .MuiSlider-valueLabel": {
      backgroundColor: "#ffffff",
    },
    "& .MuiSlider-valueLabelLabel:after": {
      content: '"%"',
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

export default function Partdiff({
  loadPopup,
  mediaid,
  workspaceMedia,
  parity_slider_start,
  parity_slider_finish,
}) {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboards, setDashboards] = useState([]);
  const [selectedBankID, setSelectedBankID] = useState(0);
  const [selectedMediaID, setSelectedMediaID] = useState(null);
  const [functionalitiesOverview, setFunctionalitiesOverview] = useState(null);
  // const defaultBank="108"; // REVOLUT V.7 (EU)
  // const defaultBankname="Revolut V.7 (EU)";
  // reload components easily
  const [seed, setSeed] = useState(1);
  const [sliderArr, setSliderArr] = useState({ min: 0.25, max: 0.75 });
  const [toolTipInfo, setToolTipInfo] = useState(
    "The Points or Parity/Differentiation graph shows which are the most common and which are the most scarce functionalities in the selected market. To do that, the user must utilise the percentage pointers at the bottom of the graph and set the interval to how many banks/fintechs the user wants to have implemented the functionality sections. For example, if one wants to see which of the functionalities are the most uncommon within a market, they must set the pointers to 0% and 25%, so as to find how many functionalities are implemented by 0%-25% of the banks/fintech inside their chosen market. The resulting functionalities are presented grouped in their respective functionality sections. By clicking a section, the user can view the names of the offered functionalities, how many banks/fintechs are implementing them in the user's selected market or outside of it. One further click on a chosen functionality will present the banks/fintechs implementing the chosen functionality. Lastly, by clicking any bank/fintech that appears on the list, the user can be redirected to the full Report and view the video of how the functionality has been implemented by that specific bank/fintech.",
    "assets/images/ezgif.com-gif-maker.webp"
  );

  const [options, setOptions] = useState({
    title: {
      text: "Functionalities Overview",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });

  const [
    options_functionalities_breakdown,
    setOptions_functionalities_breakdown,
  ] = useState({
    title: {
      text: "Functionalities Breakdown",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });

  async function setUserWorkspaceStartFinish(start, finish) {
    let data = {};
    var newState = workspaceMedia;
    newState.parity_slider_start = start;
    newState.parity_slider_finish = finish;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  async function setUserWorkspaceState(newmedia) {
    let data = {};
    var newState = workspaceMedia;
    newState.parity = newmedia;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  async function chart_parity() {
    setSelectedBankID(0);

    setIsLoading(true);

    var data = {};
    data["mediaid"] = selectedMediaID;
    data["min"] = sliderArr.min;
    data["max"] = sliderArr.max;

    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_parity";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var Medianame = selectedMediaID == "16" ? "Web" : "iOS";
    var Medianame = selectedMediaID == "27" ? "Apple Watch" : Medianame;
    var resp = await metadataResponse.json();

    var fc = [];
    var categories = [];
    var data = [];
    var c = [];
    var d = [];
    resp.forEach(function (v, index) {
      if (fc[v.nodes._id.NodeID] === undefined) {
        fc[v.nodes._id.NodeID] = [];
        fc[v.nodes._id.NodeID]["name"] = "";
        fc[v.nodes._id.NodeID]["count"] = 0;
      }

      fc[v.nodes._id.NodeID]["name"] = v.nodes._id.DisplayName;
      fc[v.nodes._id.NodeID]["count"]++;
      c[v.nodes._id.DisplayName] = v.nodes._id.NodeID;

      if (d[v.nodes._id.DisplayName] === undefined) {
        d[v.nodes._id.DisplayName] = [];
      }
      d[v.nodes._id.DisplayName].push(v.NodeID);
    });

    fc.forEach((item, i) => {
      categories.push(item.name);
      data.push(item.count);
    });

    setFunctionalitiesOverview(fc);
    setOptions({
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },

      xAxis: {
        categories: categories,
        crosshair: true,
      },
      yAxis: {
        title: { text: "Functionalities" },
      },
      exporting: {
        menuItemDefinitions: {
          toggleTable: {
            onclick: function () {
              if (
                this.dataTableDiv &&
                this.dataTableDiv.style.display !== "none"
              ) {
                this.dataTableDiv.style.display = "none";
              } else {
                this.viewData();
                this.dataTableDiv.style.display = "";
              }
            },
            text: "Toggle Table",
          },
        },
        buttons: false,
      },
      plotOptions: {
        column: {
          colorByPoint: true,
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          label: {
            enabled: false,
          },
          showInLegend: false,
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                loadPopup(
                  selectedMediaID,
                  "3",
                  c[this.category],
                  undefined,
                  undefined,
                  d[this.category],
                  "Common and Uncommon Features per Category",
                  "Identify which are the must-have features in your market and the ones that are key differentiators that will set you apart from your competitors.",
                  gif
                );
              },
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          let bankName =
            '<span style="color: ' +
            this.points[0].color +
            '">\u25CF</span> ' +
            `<span">${this.x}: </span>`;
          let desc = "<b>" + this.y + "</b>";
          if (this.y == 1) {
            desc += " Functionality";
          } else {
            desc += " Functionalities";
          }
          return bankName + desc;
        },
        shared: true,
      },
      series: [
        {
          name: "",
          data: data,
        },
      ],
    });
    // setData(resp);
    setIsLoading(false);
  }

  async function getDashboards() {
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/getall";
    const metadataResponse = await fetch(apiURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
    setDashboards(resp);
    setSelectedMediaID(mediaid);
    //  chart_functionalities_overview();
  }
  useEffect(() => {
    getDashboards();
  }, []);

  useEffect(() => {
    if (selectedMediaID != null) {
      chart_parity();
    }
  }, [selectedMediaID]);

  const handleMedia = (mediaid) => {
    setUserWorkspaceState(mediaid);
    setSelectedMediaID(mediaid);
  };

  function handleSlider(e, v) {
    setSliderArr({ min: v[0] / 100, max: v[1] / 100 });
    setUserWorkspaceStartFinish(v[0] / 100, v[1] / 100);
  }
  useEffect(() => {
    chart_parity();
  }, [sliderArr]);

  const chartRef = useRef();

  return (
    <Card>
      <MDBox p={1}>
        {selectedBankID != 0 && (
          <>
            <MDButton onClick={() => chart_functionalities_overview()}>
              Back
            </MDButton>
            <br />
          </>
        )}

        <ChartsHeader
          title="Common and Uncommon Features per Category"
          handleMedia={handleMedia}
          selectedMediaID={selectedMediaID}
          toolTipInfo={toolTipInfo}
          chartRef={chartRef}
        />

        {!isLoading ? (
          <>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartRef}
            />
          </>
        ) : (
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ p: 25 }}
          >
            <CircularProgress />
          </MDBox>
        )}
        <MDBox sx={{ display: "flex", justifyContent: "center", mt: 1, mb: 3 }}>
          <MDBox sx={{ width: 320 }}>
            <MDBox sx={{ paddingInline: 1 }}>
              {!isLoading && (
                <AirbnbSlider
                  components={{ Thumb: AirbnbThumbComponent }}
                  getAriaLabel={(index) =>
                    index === 0 ? "Minimum price" : "Maximum price"
                  }
                  step={5}
                  onChangeCommitted={(e, v) => {
                    handleSlider(e, v);
                  }}
                  valueLabelDisplay="auto"
                  marks
                  defaultValue={[sliderArr.min * 100, sliderArr.max * 100]}
                />
              )}
            </MDBox>
            {!isLoading && (
              <div className="parDifHelper">
                <div className="arrowLeft">
                  <div className="tip"></div>
                  <div className="line">
                    <MDTypography sx={{ textAlign: "center" }}>
                      Uncommon
                    </MDTypography>
                  </div>
                </div>
                <div className="seper"></div>
                <div className="arrowRight">
                  <div className="line">
                    <MDTypography sx={{ textAlign: "center" }}>
                      Common
                    </MDTypography>
                  </div>
                  <div className="tip"></div>
                </div>
              </div>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}
