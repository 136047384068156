import Card from "@mui/material/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext, useEffect, useRef } from "react";
import UserContext from "context/UserContext";
import MDButton from "components/MDButton";
import AppleIcon from "@mui/icons-material/Apple";
import ComputerIcon from "@mui/icons-material/Computer";
import WatchIcon from "@mui/icons-material/Watch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChartsHeader from "../ChartsHeader";
export default function UXOverviewScores({
  loadPopup,
  mediaid,
  workspaceMedia,
}) {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboards, setDashboards] = useState([]);
  const [selectedBankID, setSelectedBankID] = useState(0);
  const [selectedMediaID, setSelectedMediaID] = useState(mediaid);
  const [functionalitiesOverview, setFunctionalitiesOverview] = useState(null);
  // const defaultBank="108"; // REVOLUT V.7 (EU)
  // const defaultBankname="Revolut V.7 (EU)";

  const [options, setOptions] = useState({
    title: {
      text: "Functionalities Overview",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });

  async function chart_ux_overview_forBank(bankid) {
    setSelectedBankID(bankid);

    setIsLoading(true);
    var Medianame = selectedMediaID == "16" ? "Web" : "iOS";
    var Medianame = selectedMediaID == "27" ? "Apple Watch" : Medianame;

    var data = {};
    data["bankid"] = bankid;
    data["mediaid"] = selectedMediaID;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_ux_overview_scores";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();
    //console.log(resp);
    var fc = [];
    var categories = [];
    var data = [];
    resp.forEach(function (v, index) {
      var bank = [];
      fc[v._id.category.DisplayName] = v._id.category.NodeID;

      categories.push(v._id.category.DisplayName);
      let score = parseFloat(v.AverageScore.toFixed(0));
      data.push(score);
    });
    setFunctionalitiesOverview(fc);
    //console.log(fc);
    setOptions({
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },

      xAxis: {
        categories: categories,
        crosshair: true,
      },
      yAxis: {
        // min: 0,
        title: {
          text: "UX Journeys",
        },
      },
      exporting: {
        buttons: false,
      },

      plotOptions: {
        column: {
          colorByPoint: true,
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          label: {
            enabled: false,
          },
          showInLegend: false,
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                //   chart_functionalities_overview_forBankMedia(bankid,fc[this.category]);
                loadPopup(selectedMediaID, "8", fc[this.category], bankid, "5");
              },
            },
          },
        },
      },
      series: [
        {
          name: "",
          data: data,
        },
      ],
    });
    // setData(resp);
    setIsLoading(false);
  }

  async function chart_ux_overview() {
    setSelectedBankID(0);

    setIsLoading(true);

    var data = {};
    data["mediaid"] = selectedMediaID;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_ux_overview_scores";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();
    //console.log(resp);
    var fc = [];
    var categories = [];
    var data = [];
    var colors = [];

    // populate data and names
    resp.forEach(function (v, index) {
      var bank = [];
      fc[v._id.BankName] = v._id.BankID;
      categories.push(v._id.BankName);
      let score = parseFloat(v.AverageScore.toFixed(0));
      data.push(score);
      colors.push(v.colors.Color);
    });

    var Medianame = selectedMediaID == "16" ? "Web" : "iOS";
    var Medianame = selectedMediaID == "27" ? "Apple Watch" : Medianame;
    setFunctionalitiesOverview(fc);
    //   console.log(fc);
    setOptions({
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      // text: 'UX Overview'
      title: {
        text: "",
      },

      xAxis: {
        categories: categories,
        crosshair: true,
      },
      yAxis: {
        // min: 0,
        max: 1000,
        title: {
          text: "UX Score",
        },
      },
      exporting: {
        menuItemDefinitions: {
          toggleTable: {
            onclick: function () {
              if (
                this.dataTableDiv &&
                this.dataTableDiv.style.display !== "none"
              ) {
                this.dataTableDiv.style.display = "none";
              } else {
                this.viewData();
                this.dataTableDiv.style.display = "";
              }
            },
            text: "Toggle Table",
          },
        },
        buttons: false,
      },

      plotOptions: {
        column: {
          colorByPoint: true,
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          label: {
            enabled: false,
          },
          showInLegend: false,
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                chart_ux_overview_forBank(fc[this.category]);
              },
            },
          },
        },
      },
      series: [
        {
          name: "",
          colors: colors,
          data: data,
        },
      ],
    });
    // setData(resp);
    setIsLoading(false);
  }

  async function setUserWorkspaceState(newmedia) {
    let data = {};
    var newState = workspaceMedia;
    console.log(newState);
    newState.ux_overview_with_scores = newmedia;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  // async function getDashboards() {

  //     // console.log("start api")
  //     var apiURL = process.env.REACT_APP_SERVER_URL;
  //       apiURL+="/api/dashboard/getall";
  //      const metadataResponse = await fetch(apiURL, {
  //        method: 'GET', // *GET, POST, PUT, DELETE, etc.
  //     mode: 'cors', // no-cors, *cors, same-origin
  //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //        headers: {
  //          'bearer': userContext.userData.apiToken,
  //          'Content-Type': 'application/json'
  //        },
  //      });
  //       var resp = await metadataResponse.json();
  //       // console.log(resp)
  //      setDashboards(resp);

  //     //  chart_ux_overview();

  //   }
  // useEffect(()=>{
  // getDashboards()
  // chart_ux_overview()
  // }, [])

  useEffect(() => {
    if (selectedMediaID != null) {
      chart_ux_overview();
    }
  }, [selectedMediaID]);
  useEffect(() => {
    if (mediaid == null) {
      setSelectedMediaID("22");
    }
  }, []);

  const handleMedia = (mediaid) => {
    setUserWorkspaceState(mediaid);
    setSelectedMediaID(mediaid);
  };

  const chartRef = useRef();

  return (
    <MDBox>
      <Card>
        <MDBox p={1}>
          {selectedBankID != 0 && (
            <ArrowBackIcon
              color="dark"
              onClick={() => chart_ux_overview()}
              sx={{
                position: "absolute",
                left: 6,
                top: 32,
                cursor: "pointer",
                zIndex: 1,
              }}
              fontSize="medium"
            />
          )}

          <ChartsHeader
            title="UX Scoring"
            handleMedia={handleMedia}
            selectedMediaID={selectedMediaID}
            chartRef={chartRef}
          />
          {!isLoading ? (
            <>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
              />
            </>
          ) : (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ p: 25 }}
            >
              <CircularProgress />
            </MDBox>
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}
