import SwipeableViews from "react-swipeable-views";
import MarketsList from "./MarketsList";
import MDBox from "components/MDBox";
import FuncsList from "./FuncsList";
import WizardButtons from "./WizardButtons";
import { useState } from "react";
import SearchResult from "./SearchResult";

const WizardContainer = ({
  marketA,
  marketB,
  setMarketA,
  setMarketB,
  value,
  setValue,
  tempMarketA,
  tempMarketB,
  setTempMarketA,
  setTempMarketB,
  funcs,
  setFuncs,
  banks,
  functionalities,
  setDrawer,
  doSave,
  searchMarketA,
  searchMarketB,
  title,
  setTitle,
  isCopy
}) => {
  // appbar index
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // masterClear is a number, so it only runs after pressed
  const [masterClearA, setMasterClearA] = useState(0);
  const [masterClearB, setMasterClearB] = useState(0);
  const [masterClearF, setMasterClearF] = useState(0);

  // clear all on a specific tab
  const handleClear = (value) => {
    value === 0
      ? setMasterClearA(masterClearA + 1)
      : value === 1
      ? setMasterClearB(masterClearB + 1)
      : setMasterClearF(masterClearF + 1);
  };

  return (
    <>
      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        {/* MARKET A */}
        <MDBox
          className="scrollhost" // <----- an thelo na fainetai to scrollbar
          sx={{
            maxHeight: "100vh",
            overflow: "auto",
            paddingTop: 14,
            paddingBottom: 17,
          }}
        >
          {searchMarketA.length > 0 ? (
            <SearchResult
              marketA={marketA}
              setMarketA={setMarketA}
              setTempMarketA={setTempMarketA}
              tempMarketB={tempMarketB}
              banks={banks}
              query={searchMarketA}
            />
          ) : (
            <>
              {banks &&
                banks.length > 0 &&
                banks
                  .filter((bank) => bank.banks.length > 0)
                  .map((bank) => (
                    <MarketsList
                      key={bank._id.mid}
                      masterClear={masterClearA}
                      marketA={marketA}
                      setMarketA={setMarketA}
                      setTempMarketA={setTempMarketA}
                      tempMarketB={tempMarketB}
                      listLabel={bank?._id?.GroupTitle}
                      data={bank.banks}
                      setMasterClearA={setMasterClearA}
                    />
                  ))}
            </>
          )}
        </MDBox>

        {/* MARKET B */}
        <MDBox
          className="scrollhost" // <----- an thelo na fainetai to scrollbar
          sx={{
            maxHeight: "100vh",
            overflow: "auto",
            paddingTop: 14,
            paddingBottom: 17,
          }}
        >
          {searchMarketB.length > 0 ? (
            <SearchResult
              marketB={marketB}
              setMarketB={setMarketB}
              setTempMarketB={setTempMarketB}
              tempMarketA={tempMarketA}
              banks={banks}
              query={searchMarketB}
            />
          ) : (
            <>
              {banks &&
                banks.length > 0 &&
                banks
                  .filter((bank) => bank.banks.length > 0)
                  .map((bank) => (
                    <MarketsList
                      key={bank._id.mid}
                      masterClear={masterClearB}
                      marketB={marketB}
                      setMarketB={setMarketB}
                      setTempMarketB={setTempMarketB}
                      tempMarketA={tempMarketA}
                      listLabel={bank._id.GroupTitle}
                      data={bank.banks}
                      setMasterClearB={setMasterClearB}
                    />
                  ))}
            </>
          )}
        </MDBox>

        {/* SECTIONS */}
        <MDBox
          className="scrollhost" // <----- an thelo na fainetai to scrollbar
          sx={{
            maxHeight: "100vh",
            overflow: "auto",
            paddingTop: 6,
            paddingBottom: 17,
          }}
        >
          {functionalities &&
            functionalities.length > 0 &&
            functionalities.map((func) => (
              <FuncsList
                key={func._id}
                listLabel={func.DisplayName}
                masterClear={masterClearF}
                funcs={funcs}
                setFuncs={setFuncs}
                data={func.children}
                setMasterClearF={setMasterClearF}
              />
            ))}
        </MDBox>
      </SwipeableViews>

      <WizardButtons
        index={value}
        handleClear={handleClear}
        setDrawer={setDrawer}
        doSave={doSave}
        title={title}
        setTitle={setTitle}
        isCopy={isCopy}
        handleChangeIndex={handleChangeIndex}
      />
    </>
  );
};

export default WizardContainer;
