/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import Popup from "layouts/dashboards/popup";
import MDButton from "components/MDBox";
// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import SidenavCollapse from "scientia/Sidenav/SidenavCollapse";
import SidenavList from "scientia/Sidenav/SidenavList";
import SidenavItem from "scientia/Sidenav/SidenavItem";
import "../../assets/admin_routes.css";
// Custom styles for the Sidenav
import SidenavRoot from "scientia/Sidenav/SidenavRoot";
import sidenavLogoLabel from "scientia/Sidenav/styles/sidenav";
import UserContext from "context/UserContext";
import * as Cookies from "js-cookie";
import MDAvatar from "components/MDAvatar";
// images
import favimg from "assets/images/favwebp2.webp";
import roadimg from "assets/images/roadmapicon.jpg";
import worldimg from "assets/images/a-to-z-worldwide.png";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

function Sidenav({ color, brand, brandBig, brandName, routes, ...rest }) {
  var md5 = require("md5");
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const userContext = useContext(UserContext);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [clickedName, setClickedName] = useState("");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "1px solid #e4e4e4",
    overflow: "block",
    display: "block",
    minHeight: "80%",
    maxHeight: "100%",
  };

  function loadPopup(
    mediaid,
    where,
    treeid,
    bankid,
    tp,
    nids,
    header,
    subheader,
    gif
  ) {
    var data = {};
    data.mediaid = mediaid;
    data.where = where;
    data.treeid = treeid;
    data.bankid = bankid;
    data.tp = tp;
    data.nids = nids;
    data.header = header;
    data.subheader = subheader;
    data.gif = gif;
    setPopup_data(data);
  }

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      //  setMiniSidenav(dispatch, window.innerWidth < 1200);
      //   setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      //     setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
      if (window.location.pathname !== "/home") {
        setMiniSidenav(dispatch, true);
      } else setMiniSidenav(dispatch, false);
      setTransparentSidenav(dispatch, false);
      setWhiteSidenav(dispatch, false);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, icon, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ color: "gray", textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink
          to={route}
          key={key}
          sx={{ color: "gray", textDecoration: "none" }}
        >
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, icon, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key &&
              currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ color: "gray", textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink
            to={route}
            key={key}
            sx={{ color: "gray", textDecoration: "none" }}
          >
            <SidenavItem
              icon={icon}
              color={color}
              name={name}
              active={key === itemName}
            />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, target }) => {
      let returnValue;
      if (target === undefined) target = "_blank";
      if (key == "fi-user") {
        name = brandName;
        if (userContext !== undefined) {
          if (userContext.userData !== null) {
            if (userContext.userData.email !== null) {
              icon = (
                <MDAvatar
                  src={
                    "https://www.gravatar.com/avatar/" +
                    md5(userContext.userData.email) +
                    "?"
                  }
                  alt={brandName}
                  size="sm"
                />
              );
            }
          }
        }
      }
      if (type === "collapse") {
        returnValue = href ? (
          <>
            <MDBox
              onClick={() => {
                if (name !== "Comparisons") {
                  if (name === "Favorites") setModal1(true);
                  else if (name === "Analyzed banks") setModal2(true);
                  else if (name === "Roadmap") setModal3(true);
                  setClickedName(name);
                } else {
                  window.location.href = "/analytics";
                }
              }}
              key={key}
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </MDBox>

            <>
              {name === "Favorites" ? (
                <Modal
                  open={modal1}
                  onClose={() => setModal1(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDBox sx={style}>
                    <MDBox display="flex" justifyContent="flex-end">
                      <CloseIcon
                        sx={{
                          cursor: "pointer",
                          color: "black",
                          mt: 1,
                          mr: 1,
                        }}
                        fontSize="large"
                        onClick={() => setModal1(false)}
                      />
                    </MDBox>
                    <Popup
                      header="Add a competitors’ implementation to your Favorites"
                      subheader="Pin the most important competitors' implementations in your Favorites’ dashboard, for you and your team to access quickly, any time."
                      gif={favimg}
                    />
                  </MDBox>
                </Modal>
              ) : name === "Analyzed banks" ? (
                <Modal
                  open={modal2}
                  onClose={() => setModal2(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDBox sx={style}>
                    <MDBox display="flex" justifyContent="flex-end">
                      <CloseIcon
                        sx={{
                          cursor: "pointer",
                          color: "black",
                          mt: 1,
                          mr: 1,
                        }}
                        fontSize="large"
                        onClick={() => setModal2(false)}
                      />
                    </MDBox>
                    <Popup
                      header="View the full list of analyzed banks"
                      subheader="Dive into tremendous analysis. If a bank or fintech does it, we cover it. From KYC to transfers, investing, and canceling a card, FinTech Insights records every step customers must take to complete a task."
                      gif={worldimg}
                    />
                  </MDBox>
                </Modal>
              ) : name === "Roadmap" ? (
                <Modal
                  open={modal3}
                  onClose={() => setModal3(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDBox sx={style}>
                    <MDBox display="flex" justifyContent="flex-end">
                      <CloseIcon
                        sx={{
                          cursor: "pointer",
                          color: "black",
                          mt: 1,
                          mr: 1,
                        }}
                        fontSize="large"
                        onClick={() => setModal3(false)}
                      />
                    </MDBox>
                    <Popup
                      header="Add a feature to your Roadmap"
                      subheader="Add and manage your product roadmap through FinΤech Insights and compare your projected self against the current state of the market."
                      gif={roadimg}
                    />
                  </MDBox>
                </Modal>
              ) : null}
            </>
          </>
        ) : (
          <SidenavCollapse
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            open={openCollapse === key}
            onClick={() =>
              openCollapse === key
                ? setOpenCollapse(false)
                : setOpenCollapse(key)
            }
          >
            {collapse ? renderCollapse(collapse) : null}
          </SidenavCollapse>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <img src={brand} width={35} />}
          {miniSidenav === true ? (
            <></>
          ) : (
            <>
              {brandBig && (
                <img
                  src={brandBig}
                  width={165}
                  style={{ marginLeft: 3, marginTop: "10px" }}
                />
              )}
            </>
          )}
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      {/* map the routes and according to the name of the route return a component */}
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
