import WizardContainer from "./WizardContainer";
import WizardAppBarr from "./WizardAppBarr";
import MDBox from "components/MDBox";
import { useState } from "react";

const WizardLayout = ({
  marketA,
  marketB,
  setMarketA,
  setMarketB,
  tempMarketA,
  tempMarketB,
  setTempMarketA,
  setTempMarketB,
  setDrawer,
  funcs,
  setFuncs,
  banks,
  functionalities,
  doSave,
  title,
  setTitle,
  isCopy
}) => {
  const [searchMarketA, setSearchMarketA] = useState("");
  const [searchMarketB, setSearchMarketB] = useState("");

  // appbar index
  const [value, setValue] = useState(0);

  // event is not used but mui needs it
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MDBox
      className="wizard"
      sx={{
        width: 550,
      }}
    >
      <WizardAppBarr
        value={value}
        handleChange={handleChange}
        setSearchMarketA={setSearchMarketA}
        setSearchMarketB={setSearchMarketB}
        searchMarketA={searchMarketA}
        searchMarketB={searchMarketB}
      />

      <WizardContainer
        marketA={marketA}
        setMarketA={setMarketA}
        marketB={marketB}
        setMarketB={setMarketB}
        value={value}
        setValue={setValue}
        tempMarketA={tempMarketA}
        tempMarketB={tempMarketB}
        setTempMarketA={setTempMarketA}
        setTempMarketB={setTempMarketB}
        funcs={funcs}
        setFuncs={setFuncs}
        banks={banks}
        functionalities={functionalities}
        setDrawer={setDrawer}
        doSave={doSave}
        searchMarketA={searchMarketA}
        searchMarketB={searchMarketB}
        title={title}
        setTitle={setTitle}
        isCopy={isCopy}
      />
    </MDBox>
  );
};

export default WizardLayout;
