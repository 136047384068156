import Card from "@mui/material/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext, useEffect, useRef } from "react";
import UserContext from "context/UserContext";
import MDButton from "components/MDButton";
import AppleIcon from "@mui/icons-material/Apple";
import ComputerIcon from "@mui/icons-material/Computer";
import WatchIcon from "@mui/icons-material/Watch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChartsHeader from "../ChartsHeader";
import Modal from "@mui/material/Modal";
import PopupItem from "layouts/dashboards/popup/components/popupItem";
import Popup from "layouts/dashboards/popup";
import Button from "@mui/material/Button";
import MDTypography from "components/MDTypography";
import Link from "@mui/material/Link";
import CloseIcon from "@mui/icons-material/Close";
import gif from "assets/images/marketwebp.webp";

export default function FunctionalitiesOverviewHistory({
  loadPopup,
  mediaid,
  workspaceMedia,
  setDisplayVideo,
  handleOpenModal,
  setOpenModal,
  openModal,
  dataLineDates,
  setDataLineDates,
  dataLineDatesBank,
  setDataLineDatesBank,
}) {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState({
    label: "February",
    value: 2,
  });
  const [selectedYear, setSelectedYear] = useState({
    label: "2024",
    value: 2024,
  });
  const [dashboards, setDashboards] = useState([]);
  const [selectedBankID, setSelectedBankID] = useState(0);
  const [selectedMediaID, setSelectedMediaID] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [functionalitiesOverview, setFunctionalitiesOverview] = useState(null);
  const [colorsInnerGraph, setColorsInnerGraph] = useState([]);
  const [historicalDataLines, setHistoricalDataLines] = useState([]);
  const [runHistory, setRunHistory] = useState(false);
  const [historicalDataLinesNegative, setHistoricalDataLinesNegative] =
    useState([]);

  const [historicalDataPopup, setHistoricalDataPopup] = useState([]);
  const [historicalDataPopupPositive, setHistoricalDataPopupPositive] =
    useState([]);
  const [historicalDataPopupChanges, setHistoricalDataPopupChanges] = useState(
    []
  );
  const [historicalDataPopupChangesPlus, setHistoricalDataPopupChangesPlus] =
    useState([]);
  const [historicalDataPopupChangesNeg, setHistoricalDataPopupChangesNeg] =
    useState([]);

  const [historicalDataPopupPositiveIds, setHistoricalDataPopupPositiveIds] =
    useState([]);

  // const defaultBank="108"; // REVOLUT V.7 (EU)
  // const defaultBankname="Revolut V.7 (EU)";
  // reload components easily
  const [seed, setSeed] = useState(1);
  const [toolTipInfo, setToolTipInfo] = useState(
    "The Historical Functionality Change graph shows how many functionalities the banks/fintechs in the user’s market of focus have added to their offering or removed, in all available channels. The bar represents the current offering of the bank/fintech and the line represents the past offering based on the selection of the two dropdown menus (month and year). By clicking on a bar, the user can get a view of the distribution of functionalities in each functionality section. The bar is divided into 3 sections: the upper section coloured green represents the functionalities added to the bank’s/fintech’s offering since the selected period. The middle section colour blue shows the current offering of the bank/fintech and the red section represents the functionalities removed from the bank’s/fintech’s offering since the selected period. By clicking on any of the functionality sections, the user can see a complete list of the aforementioned functionalities. By selecting a specific functionality, the user can be redirected to the full Report and view the video of how the functionality has been implemented by that specific bank/fintech."
  );

  const [vid, setVid] = useState(
    "https://s3.amazonaws.com/training.fintechinsights.io/FunctionalitiesOverview-SHORT.mp4"
  );

  const [options, setOptions] = useState({
    title: {
      text: "Historical Functionality Change",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });

  const [
    options_functionalities_breakdown,
    setOptions_functionalities_breakdown,
  ] = useState({
    title: {
      text: "Functionalities Breakdown",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });

  const [popup_data, setPopup_data] = useState([
    {
      mediaid: "16",
      where: undefined,
      treeid: undefined,
      bankid: undefined,
      tp: undefined,
      nids: undefined,
      header: "Historical Functionality Change",
      subheader:
        "Track your competitors' evolution and see which features they have added or removed from their digital banking offering in a given period.",
      gif: gif,
    },
  ]);
  async function chart_functionalities_overview_forBank(bankid, bankName) {
    setSelectedBankID(bankid);

    setIsLoading(true);
    var Medianame = selectedMediaID == "16" ? "Web" : "iOS";
    var Medianame = selectedMediaID == "27" ? "Apple Watch" : Medianame;
    var data = {};
    data["bankid"] = bankid;
    data["mediaid"] = selectedMediaID;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_functionalities_overview";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();
    var fc = [];
    var categories = [];
    var data = [];
    var data2 = [];
    let theColors = [];
    resp.forEach(function (v, index) {
      var bank = [];
      fc[v.DisplayName] = v.NodeID;

      categories.push(v.DisplayName);
      data.push(v.Functionalities);
      data2.push(v.Functionalities + 5);
      theColors.push("#4d5efc");
    });
    if (historicalDataLines.length === 0) {
      setHistoricalDataLines(data);
    }
    setFunctionalitiesOverview(fc);
    setOptions({
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categories,
        crosshair: true,
      },
      yAxis: {
        title: { text: "Functionalities" },
      },

      exporting: {
        buttons: false,
      },

      legend: {
        enabled: true,
      },

      plotOptions: {
        column: {
          // colorByPoint: true,
          pointPadding: 0.2,
          // colorByPoint: true,
          borderWidth: 0,
          stacking: "normal",
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (
                this.series.name ===
                  "Functionalities Added Since Selected Period" &&
                this.y !== 0
              ) {
                return "+" + this.y;
              } else {
                return this.y;
              }
            },
          },
        },
        color: colorsInnerGraph,
        series: {
          label: {
            enabled: true,
          },
          showInLegend: true,
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                setModalState(true);
              },
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          let bankName =
            '<span style="color: ' +
            this.point.color +
            '">\u25CF</span> ' +
            `<span>${this.x}</span>`;
          let desc = "<b>" + this.point.y + "</b>";
          if (this.point.y == 1) {
            desc += " Functionality";
          } else {
            desc += " Functionalities";
          }
          return bankName + ": " + desc;
        },
      },
      series: [
        {
          name: "Functionalities Added Since Selected Period",
          data: historicalDataLines,
          color: "#8fe68a",
        },

        {
          name: "Functionalities Offered at Selected Period",
          // data: data2,
          data: dataLineDatesBank.length !== 0 ? dataLineDatesBank : data,
          color: "#4d5efc",
        },
        {
          name: "Functionalities Removed Since Selected Period",
          data:
            historicalDataLinesNegative.length !== 0
              ? historicalDataLinesNegative
              : [],
          color: "#ff6f61",
        },
      ],
    });
    // setData(resp);
    setIsLoading(false);
  }

  async function chart_functionalities_overview() {
    // Handle History
    if (workspaceMedia.functionalities_overview_bank_selected !== "off") {
      setSelectedName(workspaceMedia.functionalities_overview_bank_selected);
      chart_functionalities_overview_forBank(
        workspaceMedia.functionalities_overview_id_selected,
        workspaceMedia.functionalities_overview_bank_selected
      );
    } else {
      setSelectedBankID(0);
      setSelectedName(null);

      setIsLoading(true);

      var data = {};
      data["mediaid"] = selectedMediaID;
      var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL += "/api/chart_functionalities_overview";
      const metadataResponse = await fetch(apiURL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        body: JSON.stringify(data),
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          bearer: userContext.userData.apiToken,
          "Content-Type": "application/json",
        },
      });

      var Medianame = selectedMediaID == "16" ? "Web" : "iOS";
      var Medianame = selectedMediaID == "27" ? "Apple Watch" : Medianame;
      var resp = await metadataResponse.json();
      var fc = [];
      var categories = [];
      var data = [];
      var colors = [];
      const dataLine = [];
      resp.map((v) => {
        var bank = [];

        if (v._id.BankID !== undefined && v.Functionalities !== undefined) {
          fc[v._id.BankName] = v._id.BankID;
          categories.push(v._id.BankName);
          data.push(v.Functionalities);
          colors.push(v._id.colors.Color);
          dataLine.push(v.Functionalities + 10);
        }
      });

      // resp.forEach(function(v,index) {
      //    var bank=[];
      //    if (v._id.BankID!==undefined&&v._id.Functionalities!==undefined) {
      //      fc[v._id.BankName]=v._id.BankID;

      //     categories.push(v._id.BankName);
      //     data.push(v._id.Functionalities);
      //       colors.push(v._id.colors.Color);
      //     }
      // });

      setFunctionalitiesOverview(fc);
      setOptions({
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },

        xAxis: {
          categories: categories,
          crosshair: true,
        },
        yAxis: {
          title: { text: "Functionalities" },
        },

        exporting: {
          menuItemDefinitions: {
            toggleTable: {
              onclick: function () {
                if (
                  this.dataTableDiv &&
                  this.dataTableDiv.style.display !== "none"
                ) {
                  this.dataTableDiv.style.display = "none";
                } else {
                  this.viewData();
                  this.dataTableDiv.style.display = "";
                }
              },
              text: "Toggle Table",
            },
          },
          buttons: false,
        },

        legend: {
          enabled: true,
        },

        plotOptions: {
          column: {
            colorByPoint: true,
            pointPadding: 0.2,
            borderWidth: 0,
          },
          color: colors,
          series: {
            label: {
              enabled: true,
            },
            showInLegend: true,
            cursor: "pointer",
            point: {
              events: {
                click: function () {
                  setSelectedName(this.category);
                  setUserWorkspaceStateSelectedBank(
                    this.category,
                    fc[this.category]
                  );
                  chart_functionalities_overview_forBank(
                    fc[this.category],
                    this.category
                  );

                  headerRef.current.runHistoryChange();
                },
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            let bankName =
              '<span style="color: ' +
              this.point.color +
              '">\u25CF</span> ' +
              `<span>${this.x}</span>`;
            let desc = "<b>" + this.point.y + "</b>";
            if (this.point.y == 1) {
              desc += " Functionality";
            } else {
              desc += " Functionalities";
            }
            return bankName + ": " + desc;
          },
        },
        series: [
          {
            name: "Current Offering",
            colors: colors,
            data: data,
          },
          {
            type: "spline",
            name: "Past Offering",
            data: dataLineDates,
            color: "#020746",
            marker: {
              enabled: false,
              lineWidth: 2,
              lineColor: "#020746",
              fillColor: "white",
            },
          },
        ],
      });
      // setData(resp);
      setIsLoading(false);
    }
  }

  async function setUserWorkspaceState(newmedia) {
    let data = {};
    var newState = workspaceMedia;
    newState.functionalities_overview = newmedia;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  async function setUserWorkspaceStateSelectedBank(bank, id) {
    let data = {};
    var newState = workspaceMedia;
    newState.functionalities_overview_bank_selected = bank;
    newState.functionalities_overview_id_selected = id;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    return;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "1px solid #e4e4e4",
    overflow: "block",
    display: "block",
    minHeight: "80%",
    maxHeight: "100%",
  };

  async function getDashboards() {
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/getall";
    const metadataResponse = await fetch(apiURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
    setDashboards(resp);
    setSelectedMediaID(mediaid);
    // headerRef.current.runHistoryChange();
    //  chart_functionalities_overview();
  }
  useEffect(() => {
    getDashboards();
  }, []);

  useEffect(() => {
    if (selectedMediaID != null) {
      chart_functionalities_overview();
    }
  }, [selectedMediaID]);

  useEffect(() => {
    chart_functionalities_overview();
  }, [dataLineDates]);

  const handleMedia = (mediaid) => {
    setUserWorkspaceState(mediaid);
    setSelectedMediaID(mediaid);
  };

  const chartRef = useRef();
  const headerRef = useRef();
  const goPreviousGraph = () => {
    setUserWorkspaceStateSelectedBank("off", "off");
    setDataLineDatesBank([]);
    setHistoricalDataLines([]);
    chart_functionalities_overview();
  };

  return (
    <>
      {" "}
      <Card>
        <MDBox p={1}>
          {selectedBankID != 0 && (
            <ArrowBackIcon
              color="dark"
              onClick={() => goPreviousGraph()}
              sx={{
                position: "absolute",
                left: 6,
                top: 35,
                cursor: "pointer",
                zIndex: 1,
              }}
              fontSize="medium"
            />
          )}

          {/* {selectedName!==null?<>{selectedName}</>:<></>} */}

          <ChartsHeader
            ref={headerRef}
            title="Historical Functionality Change"
            handleMedia={handleMedia}
            toolTipInfo={toolTipInfo}
            selectedMediaID={selectedMediaID}
            selectedName={selectedName}
            chartRef={chartRef}
            setDisplayVideo={setDisplayVideo}
            handleOpenModal={handleOpenModal}
            vid={vid}
            showHistoryDropdown={true}
            setOpenModal={setOpenModal}
            openModal={openModal}
            dataLineDates={dataLineDates}
            setDataLineDates={setDataLineDates}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            selectedBankID={selectedBankID}
            setSelectedBankID={setSelectedBankID}
            dataLineDatesBank={dataLineDatesBank}
            setDataLineDatesBank={setDataLineDatesBank}
            colorsInnerGraph={colorsInnerGraph}
            setColorsInnerGraph={setColorsInnerGraph}
            historicalDataLines={historicalDataLines}
            setHistoricalDataLines={setHistoricalDataLines}
            historicalDataLinesNegative={historicalDataLinesNegative}
            setHistoricalDataLinesNegative={setHistoricalDataLinesNegative}
            historicalDataPopup={historicalDataPopup}
            setHistoricalDataPopup={setHistoricalDataPopup}
            historicalDataPopupPositive={historicalDataPopupPositive}
            setHistoricalDataPopupPositive={setHistoricalDataPopupPositive}
            historicalDataPopupChanges={historicalDataPopupChanges}
            setHistoricalDataPopupChanges={setHistoricalDataPopupChanges}
            historicalDataPopupChangesPlus={historicalDataPopupChangesPlus}
            setHistoricalDataPopupChangesPlus={
              setHistoricalDataPopupChangesPlus
            }
            historicalDataPopupChangesNeg={historicalDataPopupChangesNeg}
            setHistoricalDataPopupChangesNeg={setHistoricalDataPopupChangesNeg}
            runHistory={runHistory}
            setRunHistory={setRunHistory}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
          />

          {!isLoading && !runHistory ? (
            <>
              <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                options={options}
              />
            </>
          ) : (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ p: 23 }}
            >
              <CircularProgress />
            </MDBox>
          )}
        </MDBox>
      </Card>
      <Modal
        open={modalState}
        onClose={() => setModalState(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <>
            <MDBox display="flex" justifyContent="flex-end">
              <CloseIcon
                sx={{ color: "black", cursor: "pointer", mr: 1, mt: 1 }}
                fontSize="large"
                onClick={() => setModalState(false)}
              />
            </MDBox>
            <Popup
              mediaid="16"
              treeid={popup_data.treeid}
              where={popup_data.where}
              bankid={popup_data.bankid}
              type={popup_data.tp}
              nids={popup_data.nids}
              header="Historical Functionality Change"
              subheader="Track your competitors' evolution and see which features they have added or removed from their digital banking offering in a given period."
              gif={gif}
            />
          </>
        </MDBox>
      </Modal>
    </>
  );
}
