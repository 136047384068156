/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Drawer } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import WizardLayout from "./components/WizardLayout";
import "./style.css";
import UserContext from "context/UserContext";
import { useState, useEffect, useContext } from "react";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";

const WizardNew = ({ drawer, setDrawer, dashBoardID, isCopy }) => {
  const userContext = useContext(UserContext);
  const [marketA, setMarketA] = useState([]);
  const [marketB, setMarketB] = useState([]);
  const [tempMarketA, setTempMarketA] = useState([]);
  const [tempMarketB, setTempMarketB] = useState([]);
  const [funcs, setFuncs] = useState([]);
  const [functionalities, setFunctionalities] = useState([]);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [emptyMarkets, setEmptyMarkets] = useState("");
  const [numberOfBanksError, setNumberOfBanksError] = useState(false);

  async function doAction_getAnalyzedBanks() {
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/node_getBanksWizard";
    const metadataResponse = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    let resp = await metadataResponse.json();

    // text manipulation for special chars
    resp.map((v) => {
      v.banks.map((vv) => {
        if (vv.BankName.includes("&lt;")) {
          vv.BankName = vv.BankName.replace("&lt;", " < ");
        }
      });
    });
    setBanks(resp);
  }

  async function doAction_getFunctionalitiesTemplate() {
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/node_getFunctionalitiesTemplate";
    let data = {};
    data["parentid"] = "0";
    data["mediaid"] = "16";
    data["bankid"] = "107";
    const metadataResponse = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      cache: "no-cache",
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    let resp = await metadataResponse.json();
    resp.map((f) => {
      if (f.DisplayName.includes("amp;"))
        f.DisplayName = f.DisplayName.replace("amp;", "");

      f?.children?.forEach((ff) => {
        if (ff.DisplayName.includes("amp;"))
          ff.DisplayName = ff.DisplayName.replace("amp;", "");
      });
    });
    setFunctionalities(resp);
  }

  async function getDashboard(dashboardId) {
    let data = {};
    if (!isCopy) data["dashboardId"] = dashboardId;
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/get";
    const metadataResponse = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      cache: "no-cache",
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    let resp = await metadataResponse.json();
    if (metadataResponse.ok) {
      let tempA = [];
      let tempB = [];
      let tempF = [];
      banks
        .filter((bank) => bank._id.GroupTitle != "Admin ALL")
        .forEach((banks) => {
          banks.banks.forEach((bank) => {
            if (resp[0].MarketA.includes(bank.BankID)) {
              tempA.push(bank);
            }
            if (resp[0].MarketB.includes(bank.BankID)) {
              tempB.push(bank);
            }
          });
        });
      functionalities.forEach((func) => {
        func.children.forEach((item) => {
          if (resp[0].Functionalities.includes(item.ID)) {
            tempF.push(item);
          }
        });
      });
      setMarketA(tempA);
      setTempMarketA(tempA);
      setMarketB(tempB);
      setTempMarketB(tempB);
      setFuncs(tempF);
      setTitle(resp[0].Title);
      setLoading(false);
    }
  }

  async function selectDashboard(dashboard) {
    let data = {};
    data["dashboardId"] = dashboard;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/setActive";
    const metadataResponse = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      cache: "no-cache",
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
    window.location.reload();
  }

  async function doSave() {
    if (!marketA.length > 0 && !funcs.length > 0) {
      return setEmptyMarkets(
        `Please add at least one Section and one bank/fintech in Primary Bank(s)`
      );
    }
    if (!marketA.length > 0)
      return setEmptyMarkets(
        "Please add at least one bank/fintech in Primary Bank(s)"
      );
    if (!funcs.length > 0)
      return setEmptyMarkets("Please select at least one Section");

    let data = {};
    data["Title"] = title;
    // data["isPrivate"] = priv;
    // data["isGlobal"] = org;
    let tempA = [];
    let tempB = [];
    let tempF = [];

    marketA.forEach((item) => {
      if (!tempA.includes(item.BankID)) tempA.push(item.BankID);
    });
    marketB.forEach((item) => {
      if (!tempB.includes(item.BankID) && !tempA.includes(item.BankID))
        tempB.push(item.BankID);
    });
    funcs.forEach((item) => {
      tempF.push(item.ID);
      tempF.push(item.ParentID);
    });

    let uniqueF = [...new Set(tempF)];

    if (tempA.length + tempB.length > 230) return setNumberOfBanksError(true);

    data["MarketA"] = tempA;
    data["MarketB"] = tempB;
    data["Functionalities"] = uniqueF;
    if (isCopy !== undefined && isCopy === false)
      data["dashboardId"] = dashBoardID;

    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/save";
    const metadataResponse = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      cache: "no-cache",
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    const resp = await metadataResponse.json();
    if (isCopy) selectDashboard(resp.dashId);
    else window.location.reload();
  }

  // fetch data
  useEffect(() => {
    doAction_getAnalyzedBanks();
    doAction_getFunctionalitiesTemplate();
  }, []);

  useEffect(() => {
    if (dashBoardID && banks.length > 0 && functionalities.length > 0)
      getDashboard(dashBoardID);
  }, [dashBoardID, banks, functionalities]);

  return (
    <Drawer
      className="wizardDrawer"
      anchor="right"
      open={drawer}
      onClose={() => setDrawer(false)}
    >
      {numberOfBanksError && (
        <MDAlert
          onClick={() => setNumberOfBanksError(false)}
          color="dark"
          dismissible
          sx={{
            width: "90%",
            marginInline: "auto",
            marginTop: 1,
            marginBottom: 1,
            cursor: "pointer",
          }}
        >
          You can add to your Market (Primary & Target) up to 230 banks/fintechs
        </MDAlert>
      )}
      {emptyMarkets.length > 0 && (
        <MDAlert
          onClick={() => setEmptyMarkets("")}
          color="dark"
          dismissible
          sx={{
            width: "90%",
            marginInline: "auto",
            marginTop: 1,
            marginBottom: 1,
            cursor: "pointer",
          }}
        >
          {emptyMarkets}
        </MDAlert>
      )}
      {banks.length > 0 && functionalities.length > 0 && !loading ? (
        <WizardLayout
          marketA={marketA}
          marketB={marketB}
          tempMarketA={tempMarketA}
          tempMarketB={tempMarketB}
          setTempMarketA={setTempMarketA}
          setTempMarketB={setTempMarketB}
          setMarketA={setMarketA}
          setMarketB={setMarketB}
          funcs={funcs}
          setFuncs={setFuncs}
          dashBoardID={dashBoardID}
          setDrawer={setDrawer}
          banks={banks}
          functionalities={functionalities}
          doSave={doSave}
          title={title}
          setTitle={setTitle}
          isCopy={isCopy}
        />
      ) : (
        <MDBox
          sx={{
            width: 550,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => (
              <Skeleton width="90%" height="5em" animation="wave" />
            ))}
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {[1, 2, 3].map((i) => (
              <Skeleton width="32%" height="6em" />
            ))}
          </MDBox>
        </MDBox>
      )}
    </Drawer>
  );
};

export default WizardNew;
