import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckIcon from "@mui/icons-material/Check";
import MDButton from "components/MDButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MDBox from "components/MDBox";
import { useState } from "react";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import MDTypography from "components/MDTypography";
import { TextField } from "@mui/material";
import { useEffect } from "react";

const WizardButtons = ({
  index,
  handleClear,
  setDrawer,
  doSave,
  title,
  setTitle,
  isCopy,
  handleChangeIndex,
}) => {
  const [showTitle, setShowTitle] = useState(false);
  const [emptyTitleError, setEmptyTitleError] = useState(false);
  const [largeTitleError, setLargeTitleError] = useState(false);

  const handleCloseDrawer = (e) => {
    e.currentTarget.blur();
    setDrawer(false);
    setShowTitle(false);
  };

  const handleSaveClick = (e) => {
    e.currentTarget.blur();
    if (!title.length > 0) return setEmptyTitleError(true);
    if (largeTitleError) return;
    setEmptyTitleError(false);
    doSave();
  };

  useEffect(() => {
    if (title.length > 50) setLargeTitleError(true);
    else setLargeTitleError(false);
  }, [title]);

  return (
    <MDBox
      className="wizardButtons"
      sx={{
        width: "100%",
        marginTop: 2,
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        bottom: 0,
        left: 0,
        paddingLeft: 1,
        paddingRight: 1,
        paddingBottom: 1,
        paddingTop: 2,
        gap: 2,
      }}
    >
      {showTitle && isCopy && (
        <MDTypography
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            color: "#1a73e8",
            fontSize: "0.82rem",
          }}
        >
          You have selected to edit another user's Market. A new Market will be
          created on Save
        </MDTypography>
      )}
      {showTitle && !emptyTitleError && !largeTitleError && (
        <TextField
          fullWidth
          value={title}
          label="Market Name"
          variant="standard"
          onChange={(e) => setTitle(e.target.value)}
          inputRef={(input) => input && input.focus()}
        />
      )}

      {showTitle && largeTitleError && (
        <TextField
          fullWidth
          value={title}
          label="Market Name"
          error
          inputRef={(input) => input && input.focus()}
          helperText="Market Name needs to be less than 50 characters long."
          variant="standard"
          onChange={(e) => setTitle(e.target.value)}
        />
      )}
      {showTitle && emptyTitleError && (
        <TextField
          fullWidth
          value={title}
          label="Market Name"
          error
          // inputRef={(input) => input && input.focus()}
          helperText="Market Name can't be empty."
          variant="standard"
          onChange={(e) => {
            setEmptyTitleError(false);
            setTitle(e.target.value);
          }}
        />
      )}

      <MDBox sx={{ display: "flex", gap: 1 }}>
        {index === 0 && !showTitle ? (
          <MDButton
            className="Btn"
            disableElevation
            onClick={(e) => {
              handleChangeIndex(1);
              e.currentTarget.blur();
            }}
            sx={{ width: "17em", lineHeight: 0 }}
            variant="outlined"
            endIcon={<NavigateNextOutlinedIcon />}
          >
            To Target Market
          </MDButton>
        ) : index === 1 && !showTitle ? (
          <MDButton
            className="Btn"
            disableElevation
            onClick={(e) => {
              handleChangeIndex(2);
              e.currentTarget.blur();
            }}
            sx={{ width: "17em", lineHeight: 0 }}
            variant="outlined"
            endIcon={<NavigateNextOutlinedIcon />}
          >
            To Sections
          </MDButton>
        ) : index === 2 && !showTitle ? (
          <MDButton
            className="Btn"
            disableElevation
            onClick={(e) => {
              e.currentTarget.blur();
              setShowTitle(true);
            }}
            sx={{ width: "17em", lineHeight: 0 }}
            variant="outlined"
            // endIcon={<CheckIcon />}
          >
            Save
          </MDButton>
        ) : (
          <MDButton
            className="Btn"
            disableElevation
            onClick={handleSaveClick}
            sx={{ width: "17em", lineHeight: 0 }}
            variant="outlined"
            endIcon={<CheckIcon />}
          >
            Save
          </MDButton>
        )}
        <MDButton
          className="Btn"
          disableElevation
          onClick={handleCloseDrawer}
          sx={{ width: "17em", lineHeight: 0 }}
          variant="outlined"
          endIcon={<CancelOutlinedIcon />}
        >
          Cancel
        </MDButton>

        <MDButton
          className="Btn"
          disableElevation
          sx={{ width: "17em", lineHeight: 0 }}
          variant="outlined"
          onClick={(e) => {
            e.currentTarget.blur();
            handleClear(index);
          }}
          endIcon={<DeleteForeverIcon />}
        >
          Clear All
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

export default WizardButtons;
