import Card from "@mui/material/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import gif from "assets/images/marketwebp.webp";
import { useState, useContext, useEffect, useRef } from "react";
import UserContext from "context/UserContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChartsHeader from "../ChartsHeader";
export default function UXOverview({
  loadPopup,
  mediaid,
  workspaceMedia,
  UXLabel1,
  UXLabel2,
  UXLabel3,
  UXLabel4,
  UXLabel5,
  UXInnerLabel1,
  UXInnerLabel2,
  UXInnerLabel3,
  UXInnerLabel4,
  UXInnerLabel5,
}) {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboards, setDashboards] = useState([]);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedBankID, setSelectedBankID] = useState(0);
  const [selectedMediaID, setSelectedMediaID] = useState(mediaid);
  const [functionalitiesOverview, setFunctionalitiesOverview] = useState(null);
  // const defaultBank="108"; // REVOLUT V.7 (EU)
  // const defaultBankname="Revolut V.7 (EU)";
  const [toolTipInfo, setToolTipInfo] = useState(
    "Available UX Journeys per Institute/Bank graph shows the number of available user journeys captured and analysed in FinTech Insights for all banks/fintechs inside the user's chosen market. Clicking on a bank/fintech, the available user journeys are presented for each functionality section. By further clicking a selected bar, the user can see a complete list of the user journeys are analysed, their UX scores, as well as the average UX score in the primary market, target market and the rest of the world. Moreover, the user can also see the Best UX Score in both the primary and target market. Additionally, by clicking a chosen journey the user can see which are the banks/fintechs implementing it, along with their respective UX scores. Lastly, by clicking any bank/fintech, they can be redirected to the full Report and view the UX analysis of that journey."
  );

  const [label1, setLabel1] = useState(UXLabel1);
  const [label2, setLabel2] = useState(UXLabel2);
  const [label3, setLabel3] = useState(UXLabel3);
  const [label4, setLabel4] = useState(UXLabel4);
  const [label5, setLabel5] = useState(UXLabel5);
  const [innerLabel1, setInnerLabel1] = useState(UXInnerLabel1);
  const [innerLabel2, setInnerLabel2] = useState(UXInnerLabel2);
  const [innerLabel3, setInnerLabel3] = useState(UXInnerLabel3);
  const [innerLabel4, setInnerLabel4] = useState(UXInnerLabel4);
  const [innerLabel5, setInnerLabel5] = useState(UXInnerLabel5);

  const [options, setOptions] = useState({
    title: {
      text: "Functionalities Overview",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });

  const setUserWorkspaceStateLabel = async (l1, l2, l3, l4, l5) => {
    let data = {};
    let newState = workspaceMedia;
    if (l1 !== null) {
      newState.UXLabel1 = l1;
      setLabel1(l1);
    }
    if (l2 !== null) {
      newState.UXLabel2 = l2;
      setLabel2(l2);
    }
    if (l3 !== null) {
      newState.UXLabel3 = l3;
      setLabel3(l3);
    }
    if (l4 !== null) {
      newState.UXLabel4 = l4;
      setLabel4(l4);
    }
    if (l5 !== null) {
      newState.UXLabel5 = l5;
      setLabel5(l5);
    }
    data["charsState"] = [newState];
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    let resp = await metadataResponse.json();
  };

  const setUserWorkspaceStateInnerLabel = async (l1, l2, l3, l4, l5) => {
    let data = {};
    let newState = workspaceMedia;
    if (l1 !== null) {
      newState.UXInnerLabel1 = l1;
      setInnerLabel1(l1);
    }
    if (l2 !== null) {
      newState.UXInnerLabel2 = l2;
      setInnerLabel2(l2);
    }
    if (l3 !== null) {
      newState.UXInnerLabel3 = l3;
      setInnerLabel3(l3);
    }
    if (l4 !== null) {
      newState.UXInnerLabel4 = l4;
      setInnerLabel4(l4);
    }
    if (l5 !== null) {
      newState.UXInnerLabel5 = l5;
      setInnerLabel5(l5);
    }
    data["charsState"] = [newState];
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    let resp = await metadataResponse.json();
  };

  async function chart_ux_overview_forBank(bankid, bankName) {
    setSelectedBankID(bankid);

    setIsLoading(true);
    var Medianame = selectedMediaID == "16" ? "Web" : "iOS";
    var Medianame = selectedMediaID == "27" ? "Apple Watch" : Medianame;

    var data = {};
    data["bankid"] = bankid;
    data["mediaid"] = selectedMediaID;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_ux_overview_split_markets";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();
    var fc = [];
    var categories = [];
    var data = [];
    var avgA = [];
    var avgB = [];
    var bestA = [];
    var bestAnames = [];
    var bestB = [];
    var bestBnames = [];
    resp.market.forEach(function (v, index) {
      var bank = [];
      fc[v.DisplayName] = v.NodeID;

      categories.push(v.DisplayName);
      data.push(v.Functionalities);
      avgA.push(resp.avgA[index].Functionalities);
      avgB.push(resp.avgB[index].Functionalities);
      bestA.push(resp.bestA[index].Functionalities);
      bestB.push(resp.bestB[index].Functionalities);
      bestAnames.push(resp.bestA[index].tempBest[0]);
      bestBnames.push(resp.bestB[index].tempBest[0]);
    });
    setFunctionalitiesOverview(fc);
    setOptions({
      chart: {
        type: "column",
        events: {
          load: function () {
            if (innerLabel1 === false) this.series[0].hide();
            if (innerLabel2 === false) this.series[1].hide();
            if (innerLabel3 === false) this.series[2].hide();
            if (innerLabel4 === false) this.series[3].hide();
            if (innerLabel5 === false) this.series[4].hide();
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },

      xAxis: {
        categories: categories,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "UX Journeys",
        },
      },
      exporting: {
        buttons: false,
      },

      plotOptions: {
        column: {
          colorByPoint: true,
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          label: {
            enabled: false,
          },
          showInLegend: true,
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                if (typeof this.category.replace !== "function") return;
                //   chart_functionalities_overview_forBankMedia(bankid,fc[this.category]);
                loadPopup(
                  selectedMediaID,
                  "8",
                  fc[this.category],
                  bankid,
                  "5",
                  "1",
                  "Available UX Journeys per Bank chart ",
                  "Find how each User Journey scores. Uncover fast the best-in class implementation and learn from others’ mistakes.",
                  gif
                );
              },
            },
          },
          events: {
            legendItemClick: function () {
              if (this.visible) {
                if (this.name == "Sections")
                  setUserWorkspaceStateInnerLabel(
                    false,
                    null,
                    null,
                    null,
                    null
                  );
                else if (this.name == "Average Primary Market")
                  setUserWorkspaceStateInnerLabel(
                    null,
                    false,
                    null,
                    null,
                    null
                  );
                else if (this.name == "Average Target Market")
                  setUserWorkspaceStateInnerLabel(
                    null,
                    null,
                    false,
                    null,
                    null
                  );
                else if (this.name == "Best Primary Market")
                  setUserWorkspaceStateInnerLabel(
                    null,
                    null,
                    null,
                    false,
                    null
                  );
                else if (this.name == "Best Target Market")
                  setUserWorkspaceStateInnerLabel(
                    null,
                    null,
                    null,
                    null,
                    false
                  );
              } else {
                if (this.name == "Sections")
                  setUserWorkspaceStateInnerLabel(true, null, null, null, null);
                else if (this.name == "Average Primary Market")
                  setUserWorkspaceStateInnerLabel(null, true, null, null, null);
                else if (this.name == "Average Target Market")
                  setUserWorkspaceStateInnerLabel(null, null, true, null, null);
                else if (this.name == "Best Primary Market")
                  setUserWorkspaceStateInnerLabel(null, null, null, true, null);
                else if (this.name == "Best Target Market")
                  setUserWorkspaceStateInnerLabel(null, null, null, null, true);
              }
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          let bankName;
          if (this.point.series.name == "Market") {
            bankName =
              '<span style="color: ' +
              this.point.color +
              '">\u25CF</span> ' +
              `<span>${this.x}</span>`;
          } else {
            bankName =
              '<span style="color: ' +
              this.point.color +
              '">\u25CF</span> ' +
              `<span>${this.point.series.name}</span>`;
          }
          let desc = "<b>" + this.y.toString().slice(0, 4) + "</b>";
          if (this.y == 1) {
            desc += " UX Journey";
          } else {
            desc += " UX Journeys";
          }
          return bankName + ": " + desc;
        },
      },
      series: [
        {
          name: "Sections",
          data: data,
        },
        {
          type: "spline",
          name: "Average Primary Market",
          data: avgA,
          color: "#020746",
          marker: {
            enabled: false,
            lineWidth: 2,
            lineColor: "#020746",
            fillColor: "white",
          },
        },
        {
          type: "spline",
          name: "Average Target Market",

          data: avgB,
          color: "#ffc8e6",
          marker: {
            enabled: false,
            lineWidth: 2,
            lineColor: "#ffc8e6",
            fillColor: "white",
          },
        },
        {
          type: "spline",
          name: "Best Primary Market",

          data: bestA,

          marker: {
            enabled: false,
            lineWidth: 2,
            lineColor: "#020746",
            fillColor: "white",
          },
        },
        {
          type: "spline",
          name: "Best Target Market",
          data: bestB,

          marker: {
            enabled: false,
            lineWidth: 2,
            lineColor: "#020746",
            fillColor: "white",
          },
        },
      ],
    });
    // setData(resp);
    setIsLoading(false);
  }
  async function chart_ux_overview_forBank2(bankid) {
    setSelectedBankID(bankid);

    setIsLoading(true);
    var Medianame = selectedMediaID == "16" ? "Web" : "iOS";
    var Medianame = selectedMediaID == "27" ? "Apple Watch" : Medianame;

    var data = {};
    data["bankid"] = bankid;
    data["mediaid"] = selectedMediaID;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_ux_overview";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();
    var fc = [];
    var categories = [];
    var data = [];
    resp.forEach(function (v, index) {
      var bank = [];
      fc[v.DisplayName] = v.NodeID;

      categories.push(v.DisplayName);
      data.push(v.Functionalities);
    });
    setFunctionalitiesOverview(fc);
    setOptions({
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },

      xAxis: {
        categories: categories,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "UX Journeys",
        },
      },
      exporting: {
        buttons: false,
      },

      plotOptions: {
        column: {
          colorByPoint: true,
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          label: {
            enabled: false,
          },
          showInLegend: false,
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                //   chart_functionalities_overview_forBankMedia(bankid,fc[this.category]);
                loadPopup(
                  selectedMediaID,
                  "8",
                  fc[this.category],
                  bankid,
                  "5",
                  "1",
                  "Available UX Journeys per Bank chart ",
                  "Find how each User Journey scores. Uncover fast the best-in class implementation and learn from others’ mistakes.",
                  gif
                );
              },
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          let bankName =
            '<span style="color: ' +
            this.point.color +
            '">\u25CF</span> ' +
            `<span>${this.x}</span>`;
          let desc = "<b>" + this.point.y + "</b>";
          if (this.point.y == 1) {
            desc += " UX Journey";
          } else {
            desc += " UX Journeys";
          }
          return bankName + ": " + desc;
        },
      },
      series: [
        {
          name: "",
          data: data,
        },
      ],
    });
    // setData(resp);
    setIsLoading(false);
  }

  async function chart_ux_overview() {
    // Handle History
    if (workspaceMedia.ux_overview_bank_selected !== "off") {
      setSelectedName(workspaceMedia.ux_overview_bank_selected);
      chart_ux_overview_forBank(
        workspaceMedia.ux_overview_id_selected,
        workspaceMedia.ux_overview_bank_selected
      );
    } else {
      setSelectedBankID(0);
      setSelectedName(null);
      setIsLoading(true);

      var data = {};
      data["mediaid"] = selectedMediaID;
      var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL += "/api/chart_ux_overview_split_markets";
      const metadataResponse = await fetch(apiURL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        body: JSON.stringify(data),
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          bearer: userContext.userData.apiToken,
          "Content-Type": "application/json",
        },
      });

      var resp = await metadataResponse.json();
      var fc = [];
      var categories = [];
      var data = [];
      var colors = [];
      var avgA = [];
      var avgB = [];
      var bestA = [];
      var bestB = [];
      var bestAname = "";
      var bestBname = "";
      var bestAColor = "";
      var bestBColor = "";
      resp.marketA.forEach(function (v, index) {
        var bank = [];
        fc[v._id.BankName] = v._id.BankID;

        categories.push(v._id.BankName);
        data.push(v.Functionalities);
        colors.push(v.colors.Color);
        avgA.push(resp.avgA);
        avgB.push(resp.avgB);
        bestA.push(resp.bestA.Functionalities);
        bestB.push(resp.bestB.Functionalities);
      });
      resp.marketB.forEach(function (v, index) {
        var bank = [];
        fc[v._id.BankName] = v._id.BankID;

        categories.push(v._id.BankName);
        data.push(v.Functionalities);
        colors.push(v.colors.Color);
        avgA.push(resp.avgA);
        avgB.push(resp.avgB);
        bestA.push(resp.bestA.Functionalities);
        bestB.push(resp.bestB.Functionalities);
      });
      bestAname =
        resp.bestA.BankName + " has the most UX Journeys in Primary market";
      bestBname =
        resp.bestB.BankName + " has the most UX Journeys in Target market";
      bestAColor = resp.bestA.Color;
      bestBColor = resp.bestB.Color;
      var Medianame = selectedMediaID == "16" ? "Web" : "iOS";
      var Medianame = selectedMediaID == "27" ? "Apple Watch" : Medianame;
      setFunctionalitiesOverview(fc);
      setOptions({
        chart: {
          type: "column",
          events: {
            load: function () {
              if (label1 === false) this.series[0].hide();
              if (label2 === false) this.series[1].hide();
              if (label3 === false) this.series[2].hide();
              if (label4 === false) this.series[3].hide();
              if (label5 === false) this.series[4].hide();
            },
          },
        },
        credits: {
          enabled: false,
        },
        // text: 'UX Overview'
        title: {
          text: "",
        },

        xAxis: {
          categories: categories,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number of UX Journeys",
          },
        },
        exporting: {
          menuItemDefinitions: {
            toggleTable: {
              onclick: function () {
                if (
                  this.dataTableDiv &&
                  this.dataTableDiv.style.display !== "none"
                ) {
                  this.dataTableDiv.style.display = "none";
                } else {
                  this.viewData();
                  this.dataTableDiv.style.display = "";
                }
              },
              text: "Toggle Table",
            },
          },
          buttons: false,
        },

        plotOptions: {
          column: {
            colorByPoint: true,
            pointPadding: 0.2,
            borderWidth: 0,
          },

          series: {
            label: {
              enabled: true,
            },
            showInLegend: true,
            cursor: "pointer",
            point: {
              events: {
                click: function () {
                  setSelectedName(this.category);
                  setUserWorkspaceStateSelectedBank(
                    this.category,
                    fc[this.category]
                  );
                  chart_ux_overview_forBank(fc[this.category], this.category);
                },
              },
            },
            events: {
              legendItemClick: function () {
                if (this.visible) {
                  if (this.name == "Market")
                    setUserWorkspaceStateLabel(false, null, null, null, null);
                  else if (this.name == "Average Primary Market")
                    setUserWorkspaceStateLabel(null, false, null, null, null);
                  else if (this.name == "Average Target Market")
                    setUserWorkspaceStateLabel(null, null, false, null, null);
                  else if (
                    this.name.includes("the most UX Journeys in Primary market")
                  )
                    setUserWorkspaceStateLabel(null, null, null, false, null);
                  else if (
                    this.name.includes(
                      "has the most UX Journeys in Target market"
                    )
                  )
                    setUserWorkspaceStateLabel(null, null, null, null, false);
                } else {
                  if (this.name == "Market")
                    setUserWorkspaceStateLabel(true, null, null, null, null);
                  else if (this.name == "Average Primary Market")
                    setUserWorkspaceStateLabel(null, true, null, null, null);
                  else if (this.name == "Average Target Market")
                    setUserWorkspaceStateLabel(null, null, true, null, null);
                  else if (
                    this.name.includes("the most UX Journeys in Primary market")
                  )
                    setUserWorkspaceStateLabel(null, null, null, true, null);
                  else if (
                    this.name.includes(
                      "has the most UX Journeys in Target market"
                    )
                  )
                    setUserWorkspaceStateLabel(null, null, null, null, true);
                }
              },
            },
          },
        },

        tooltip: {
          formatter: function () {
            let bankName;
            if (this.point.series.name == "Market") {
              bankName =
                '<span style="color: ' +
                this.point.color +
                '">\u25CF</span> ' +
                `<span>${this.x}</span>`;
            } else {
              bankName =
                '<span style="color: ' +
                this.point.color +
                '">\u25CF</span> ' +
                `<span>${this.point.series.name}</span>`;
            }
            let desc = "<b>" + this.y + "</b>";
            if (this.y == 1) {
              desc += " UX Journey";
            } else {
              desc += " UX Journeys";
            }
            return bankName + ": " + desc;
          },
        },
        series: [
          {
            type: "column",
            name: "Market",
            colors: colors,
            data: data,
          },
          {
            type: "spline",
            name: "Average Primary Market",
            data: avgA,
            color: "#020746",
            marker: {
              enabled: false,
              lineWidth: 2,
              lineColor: "#020746",
              fillColor: "white",
            },
          },
          {
            type: "spline",
            name: "Average Target Market",
            data: avgB,
            color: "#ffc8e6",
            marker: {
              enabled: false,
              lineWidth: 2,
              lineColor: "#ffc8e6",
              fillColor: "white",
            },
          },
          {
            type: "spline",
            name: bestAname,
            data: bestA,
            color: bestAColor,
            marker: {
              enabled: false,
              lineWidth: 2,
              lineColor: bestAColor,
              fillColor: "white",
            },
          },
          {
            type: "spline",
            name: bestBname,
            data: bestB,
            color: bestBColor,
            marker: {
              enabled: false,
              lineWidth: 2,
              lineColor: bestBColor,
              fillColor: "white",
            },
          },
        ],
      });
      // setData(resp);
      setIsLoading(false);
    }
  }

  async function setUserWorkspaceState(newmedia) {
    let data = {};
    var newState = workspaceMedia;
    newState.ux_overview = newmedia;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  async function setUserWorkspaceStateSelectedBank(bank, bid) {
    let data = {};
    var newState = workspaceMedia;
    newState.ux_overview_bank_selected = bank;
    newState.ux_overview_id_selected = bid;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  async function getDashboards() {
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/getall";
    const metadataResponse = await fetch(apiURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
    setDashboards(resp);

    //  chart_ux_overview();
  }
  useEffect(() => {
    getDashboards();
  }, []);

  useEffect(() => {
    if (selectedMediaID != null) {
      chart_ux_overview();
    }
  }, [selectedMediaID]);

  const handleMedia = (mediaid) => {
    setUserWorkspaceState(mediaid);
    setSelectedMediaID(mediaid);
  };

  const chartRef = useRef();

  const goPreviousGraph = () => {
    setUserWorkspaceStateSelectedBank("off", "off");
    chart_ux_overview();
  };

  return (
    <MDBox mt={10}>
      <Card>
        <MDBox p={1}>
          {selectedBankID != 0 && (
            <ArrowBackIcon
              color="dark"
              onClick={() => goPreviousGraph()}
              sx={{
                position: "absolute",
                left: 6,
                top: 32,
                cursor: "pointer",
                zIndex: 1,
              }}
              fontSize="medium"
            />
          )}

          <ChartsHeader
            title="Available UX Journeys per Bank"
            handleMedia={handleMedia}
            selectedMediaID={selectedMediaID}
            selectedName={selectedName}
            toolTipInfo={toolTipInfo}
            chartRef={chartRef}
          />
          {!isLoading ? (
            <>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
              />
            </>
          ) : (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ p: 25 }}
            >
              <CircularProgress />
            </MDBox>
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}
