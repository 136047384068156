/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import ChatIcon from "@mui/icons-material/Chat";
import Badge from "@mui/material/Badge";
// redux
import { connect } from "react-redux";
// import { changeLastSideNavNode } from '../../../../context/actions/allActions'
import { change_user_dark_mode } from "context/actions/allActions";

import { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";
import Notifications from "layouts/pages/notifications";
// Material Dashboard 2 PRO React example components
import Breadcrumbs from "scientia/Breadcrumbs";
import NotificationItem from "scientia/Items/NotificationItem";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
import CircularProgress from "@mui/material/CircularProgress";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "scientia/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import * as Cookies from "js-cookie";
import UserContext from "context/UserContext";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";

function DashboardNavbar(
  props
  // {props, absolute, light, isMini, state }
) {
  const navigate = useNavigate();

  const { terms } = useParams();
  const userContext = useContext(UserContext);
  const [isRunning, setIsRunning] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [value, setValue] = useState("1");
  const [searchBanks, setSearchBanks] = useState(null);
  const [searchFunctions, setSearchFunctions] = useState(null);
  const [searchFunctionsIos, setSearchFunctionsIos] = useState(null);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [absolute, setAbsolute] = useState(false);
  const [light, setLight] = useState(false);
  const [isMini, setIsMini] = useState(false);

  const [userDarkMode, setUserDarkMode] = useState(false);

  const [matrixName, setMatrixName] = useState("Default Matrix");

  const handleChange = (event) => {
    setMatrixName(event.target.value);
  };
  useEffect(() => {
    if (terms !== null && terms !== undefined) {
      setSearchValue(terms);
      // get_userMode();
    }
  }, []); // <-- empty dependency array
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  async function selectMatrix() {
    let data = {};
    data["dashboardId"] = dashboard;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/setActive";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>home</Icon>} title="Check out new banks" />
      <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title="Check out new functionality"
      />
      <NotificationItem
        icon={<Icon>people</Icon>}
        title="New User in your organization"
      />
    </Menu>
  );

  // GET USER MODE DARK - LIGHT
  const get_userMode = async () => {
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/getUserDarkMode";
    const metadataResponse = await fetch(apiURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached

      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();
    setUserDarkMode(resp.darkMode);
    console.log(userDarkMode);
    props.change_user_dark_mode(userDarkMode);
  };

  // Settings function
  const handleSettings = () => {
    const x = document.getElementsByClassName("settingAddition")[0];
    x.classList.remove("hidden");

    const y = document.getElementsByClassName("settingsIconInactive")[0];
    y.classList.add("hidden");

    const z = document.getElementsByClassName("settingsIconActive")[0];
    z.classList.remove("hidden");
  };

  // Settings function
  const disableSettings = () => {
    const x = document.getElementsByClassName("settingAddition")[0];
    x.classList.add("hidden");

    const y = document.getElementsByClassName("settingsIconActive")[0];
    y.classList.add("hidden");

    const z = document.getElementsByClassName("settingsIconInactive")[0];
    z.classList.remove("hidden");
  };

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const handleInputSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleChat = () => {};

  const activateChat = () => {
    //window.HubSpotConversations.widget.load();
    //  document.getElementsByClassName("rcw-widget-container")[0].classList.remove("hidden")
    //  document.getElementsByClassName("chatIconInactive")[0].classList.add("hidden")
    //  document.getElementsByClassName("chatIconActive")[0].classList.remove("hidden")
  };

  async function searchMe(e) {
    //loadOptions(searchValue);
    navigate("/search/" + searchValue);
  }
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  async function loadOptions(inputValue) {
    // perform a request
    var d = {};
    d["search"] = inputValue;
    var res = [];
    //if (isRunning) return;
    setIsRunning(true);
    setSearchBanks(null);
    setSearchFunctions(null);
    setSearchFunctionsIos(null);
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/search_bank";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      body: JSON.stringify(d),

      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();
    var banks = [];
    resp.forEach(function (v, index) {
      var r = (
        <p>
          label : {v["_id"]["BankName"]}, value: {v["_id"]["BankID"]}
        </p>
      );
      banks.push(r);
    });
    setSearchBanks(banks);

    res.push({ label: "Banks", options: banks });

    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/search_functionalities_web";
    const metadataResponse2 = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      body: JSON.stringify(d),

      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse2.json();
    var funcs = [];
    resp.forEach(function (v, index) {
      var r = (
        <p>
          label : {v["_id"]["DisplayName"]}, value: {v["_id"]["NodeID"]}
        </p>
      );
      funcs.push(r);
    });

    setSearchFunctions(funcs);

    res.push({ label: "Functionalities Web", options: funcs });

    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/search_functionalities_mobile";
    const metadataResponse3 = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      body: JSON.stringify(d),

      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse3.json();
    var funcsMobile = [];
    resp.forEach(function (v, index) {
      var r = (
        <p>
          label : {v["_id"]["DisplayName"]}, value: {v["_id"]["NodeID"]}
        </p>
      );
      funcsMobile.push(r);
    });
    res.push({ label: "Functionalities Mobile", options: funcsMobile });

    setSearchFunctionsIos(funcsMobile);

    setIsRunning(false);
  }
  return (
    <>
      {/* {console.log('haram', props)} */}
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) =>
          navbar(theme, { transparentNavbar, absolute, light, darkMode })
        }
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox
            title="Toggle Menu"
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <Breadcrumbs
              icon="home"
              title={route[route.length - 1]}
              route={route}
              light={light}
            />

            <IconButton
              sx={navbarDesktopMenu}
              onClick={handleMiniSidenav}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium" sx={iconsStyle}>
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton>
            <IconButton
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium" sx={iconsStyle}>
                menu
              </Icon>
            </IconButton>
          </MDBox>

          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              {userContext.userData.firstname} {userContext.userData.lastname}
              <MDBox color={light ? "white" : "inherit"}>
                <Link title="Profile" to="/pages/account/settings">
                  <IconButton
                    id="prof-icon"
                    sx={{ fontSize: 22 }}
                    // sx={navbarIconButton}
                    disableRipple
                  >
                    <Icon
                    //  sx={iconsStyle}
                    >
                      account_circle
                    </Icon>
                  </IconButton>
                </Link>

                {/* settings buttons */}
                {document.getElementsByClassName("SettingBarComponent")[0] ? (
                  <>
                    <MDBadge
                      title="Open Settings"
                      className="settingsIcon settingsIconInactive hidden"
                      color="error"
                      sx={{ mr: 1 }}
                    >
                      <SettingsIcon
                        fontSize="medium"
                        //  color="action"
                        onClick={handleSettings}
                      />
                    </MDBadge>

                    <MDBadge
                      title="Close Settings"
                      className="settingsIcon settingsIconActive"
                      color="error"
                      sx={{ mr: 1 }}
                    >
                      <SettingsIcon
                        fontSize="medium"
                        className="StnButton"
                        //  color="info"
                        onClick={disableSettings}
                      />
                    </MDBadge>
                  </>
                ) : (
                  <></>
                )}

                {/* <IconButton

                  disableRipple
                  color="inherit"
                  // sx={navbarIconButton}
                  sx={{fontSize:22}}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >

                <Link to="/notifications">
                <MDBadge badgeContent={props.ReduxState.notifications.length} color="error" size="md"  circular>
                  <Icon
                  sx={iconsStyle}
                  >notifications</Icon>
                </MDBadge>
                </Link>
              </IconButton> */}
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
      </AppBar>

      {isRunning !== null ? (
        <Card mt={3} mb={3}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Banks" value="1" />
                  <Tab label="Functionalities Web" value="2" />
                  <Tab label="Functionalities Mobile" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                {searchBanks !== null ? searchBanks : <CircularProgress />}
              </TabPanel>
              <TabPanel value="2">
                {searchFunctions !== null ? (
                  searchFunctions
                ) : (
                  <CircularProgress />
                )}
              </TabPanel>
              <TabPanel value="3">
                {searchFunctionsIos !== null ? (
                  searchFunctionsIos
                ) : (
                  <CircularProgress />
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}

// injecting state
const mapStateToProps = (state) => {
  // console.log(state)
  return state;
};

export default connect(mapStateToProps, { change_user_dark_mode })(
  DashboardNavbar
);

// export default DashboardNavbar;
