/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState,useContext,useEffect } from "react";
import { Route, Navigate, useLocation,useNavigate,Link} from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// @mui material components
import Grid from "@mui/material/Grid";
import TooltipTop from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid, GridToolbar } from '@mui/x-data-grid/index';
import Avatar from "@mui/material/Avatar";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import Footer from "scientia/Footer";
import ReportsBarChart from "scientia/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "scientia/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "scientia/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "scientia/Cards/BookingCard";
import CircularProgress from '@mui/material/CircularProgress';

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';


import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { DragDropContext } from 'react-beautiful-dnd';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";


import { MenuProps, useStyles } from "./utils";
import useTheme from "@mui/material/styles/useTheme";


import Wizard from "../../applications/wizard";

import GridLayout from "react-grid-layout";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import fullscreen from "highcharts/modules/full-screen";
import SettingsBar from "layouts/pages/analyzed/bank/SettingsBar";
import DataTable from "scientia/Tables/DataTableBanks";
import datatabledata from "./data/dataTableData";
import datatabledataUX from "./data/dataTableDataUX";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
function PopupWithScore({treeid,mediaid,where,bankid,type,nids}) {


  const theme = useTheme();

  const userContext = useContext(UserContext);

  const [popupAntagonists, setPopupAntagonists] = useState(false)
// antagonists states
const [ openModalAntagonists, setOpenModalAntagonists ] = useState(
  {
    id:1,status:false, data:[]
}
  );
  const [ openModalAntagonists2, setOpenModalAntagonists2 ] = useState(
    {
      id:1
      ,status:false
      ,data:[
        { id: 19, name: 'City', icon:'https://fi.scientiaconsulting.eu/wp-content/uploads/2019/02/alpha.png' },
        { id: 2, name: 'Alpha', icon:'https://fi.scientiaconsulting.eu/wp-content/uploads/2020/09/demo1.png' },
        { id: 3, name: 'EuroBank', icon:'https://fi.scientiaconsulting.eu/wp-content/uploads/2020/09/demo1.png' },
        { id: 1, name: 'Revolut', icon:'https://fi.scientiaconsulting.eu/wp-content/uploads/2020/09/demo1.png' },
    ]});
    const [ openModalAntagonists3, setOpenModalAntagonists3 ] = useState(
      {
        id:1
        ,status:false
        ,data:[
          { id: 13, name: 'EuroBank', icon:'https://fi.scientiaconsulting.eu/wp-content/uploads/2019/02/alpha.png' },
          { id: 14, name: 'EuroBank', icon:'https://fi.scientiaconsulting.eu/wp-content/uploads/2019/02/alpha.png' },
      ]});

  const [isLoading,setIsLoading]=useState(true);
  const [OutsideOfYourMarketCount, setOutsideOfYourMarketCount ]= useState(null)

  const [popupData,setPopupData]=useState(null)

  const [loadingModal, setLoadingModal] = useState(true)

  const closePopupAntagonists =()=>{
    setPopupAntagonists(false)
    setLoadingModal(true)
  }

  const ModalLink = (id, mediaid
    ,parentid,funcid
    )=>{
    const link = window.location.origin+'/analyzed/bank/'+id+'/'+mediaid+'/'+parentid+'/'+funcid+'/'
    navigator.clipboard.writeText(link).then(function() {
      // console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      // console.error('Async: Could not copy text: ', err);
    });
    window.location.href = link
  }

  async function getPopupAntagonists(fid){
    setPopupAntagonists(true)
    const data={};
    data['mediaid']=mediaid;
    data['nodeid']=fid;
    // data['nodeid']=
    var apiURL = process.env.REACT_APP_SERVER_URL;
        apiURL+="/api/node_getOtherBanksWithThisFunctionality";
    const metadataResponse = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
     body:JSON.stringify(data),
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
        'bearer': userContext.userData.apiToken,
        'Content-Type': 'application/json'
        },
    });
    var resp = await metadataResponse.json();
    console.log(resp)
    var count = resp.all_markets_general_length - resp.all_marketA.length - resp.all_marketB.length - resp.all_marketC.length
    setOutsideOfYourMarketCount(count)

    // SET MARKET A
  var res = []

  resp.all_marketA.map((i)=>{

   let dict = {
          id: i.BankID,
          name: i.BankName,
          icon: i.banks_data[0].ImageURL,
          parent: i.ParentID,
          node: i.NodeID,
          score:0
        }

  res.push(dict)
  })


  // SET MARKET B
  var res2 = []
  resp.all_marketB.map((i)=>{
   let dict2 = {
          id: i.BankID,
          name: i.BankName,
          icon: i.banks_data[0].ImageURL,
          parent: i.ParentID,
          node: i.NodeID
        }

  res2.push(dict2)
  })
  let newBanksB = openModalAntagonists2
  newBanksB.data = res2
  setOpenModalAntagonists2(newBanksB)


  // SET MARKET C
  var res3 = []
  resp.all_marketC.map((i)=>{
   let dict3 = {
          id: i.BankID,
          name: i.BankName,
          icon: i.banks_data[0].ImageURL,
          parent: i.ParentID,
          node: i.NodeID
        }

  res3.push(dict3)
  })
  let newBanksC = openModalAntagonists3
  newBanksC.data = res3
  setOpenModalAntagonists3(newBanksC)




  var mybanks = []
  res.map((i)=>{
    mybanks.push(i.id)
  })
  // Get Banks Scores
  const data2={};
  data2['mediaid']=mediaid;
  data2['nodeid']=fid;
  data2['banks']=mybanks
  // data['nodeid']=
  var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/getScoresForBanks";
  const metadataResponse2 = await fetch(apiURL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
   body:JSON.stringify(data2),
   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
      'bearer': userContext.userData.apiToken,
      'Content-Type': 'application/json'
      },
  });
  var resp2 = await metadataResponse2.json();


  resp2.map((score)=>{
    res.map((i)=>{
      if(score.BankID===i.id){
        // console.log(score.Points)
        i.score = score.Points
      }

    })
  })

  let newBanksA = openModalAntagonists

  newBanksA.data = res

  setOpenModalAntagonists(newBanksA)

  console.log(openModalAntagonists)

  setLoadingModal(false)

  }




  async function getPopup(){
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL+="/api/chart_popup";
    var data={};
    data['treeid']=treeid;
    data['mediaid']=mediaid;
    data['where']=where;
    data['bankid']=bankid;
    data['type']=type;
    if (nids!==undefined) {
        data['nids']=nids;
    }

   const metadataResponse = await fetch(apiURL, {
     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  body:JSON.stringify(data),
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     headers: {
       'bearer': userContext.userData.apiToken,
       'Content-Type': 'application/json'
     },
   });
   var resp = await metadataResponse.json();
   var nodes=[];

   resp.forEach((item, i) => {
     if (nodes[item.NodeID]===undefined) {
       nodes[item.NodeID]=[];
       nodes[item.NodeID]['Title']=item.DisplayName;
       nodes[item.NodeID]['ID']=item.NodeID;
       nodes[item.NodeID]['OutOfGroup']=item.OutOfGroup;
       nodes[item.NodeID]['Points']=item.Points;
       nodes[item.NodeID]['PointsAVG']=item.PointsAVG;
       nodes[item.NodeID]['Description']=item.Description;
       nodes[item.NodeID]['Banks']=[];
     }
       let bank=[];
       bank['BankID']=item.BankID;
       bank['BankName']=item.BankName;
        nodes[item.NodeID]['Banks'].push(bank);
   });
   let dt=[];
   nodes.forEach((item, i) => {

    var x = item['Banks'].length
    if (bankid!==undefined) {
     //video se auto to if
   item['Title']= <MDBox  onClick={ (e)=> getPopupAntagonists(item.ID)}color={"info"}  sx={{cursor:'pointer'}} >{ item.Title }   </MDBox>
      item['count']= x;
    } else {
      item['Title']= <MDBox  onClick={ (e)=> getPopupAntagonists(item.ID)}color={"info"}  sx={{cursor:'pointer'}} >{ item.Title }   </MDBox>
         item['count']= x;

    }
     item['Points']=item.Points;
     item['PointsAVG']=item.PointsAVG;
     item['OutOfGroup']=item.OutOfGroup;

     dt.push(item);
   });

   console.log(dt);
   if (where=="8") {

     datatabledataUX.rows=dt;
   }
   datatabledata.rows=dt;

   // sort table - Slow!
  datatabledata.rows.sort((b,a) => a.count - b.count);
  // setPopupData(dt);
   setIsLoading(false);

  }


  useEffect(()=>{

    getPopup();


  }, []) // <-- empty dependency array

  // Action buttons for the BookingCard


  return (



<>


          {isLoading!==true?<>



          {popupAntagonists?<>
            {!loadingModal?<>

          <MDBox
                id="modal-box"
                sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 500,
                      // bgcolor: 'background.paper',
                      border: '2px solid #000',
                      boxShadow: 24,
                      p: 4,
                      width:'100%',
                    }}>
                      <MDBox sx={{position:'absolute', top:20, right:10, cursor:'pointer'}}>

                        <CloseIcon fontSize="medium" onClick={()=>{closePopupAntagonists()}} />
                      </MDBox>


                <MDTypography sx={{display:"flex", justifyContent:"center",alignItems:"center", mb:2}} id="modal-modal-title" variant="h6" component="h2">
                Who has this Functionality?
                </MDTypography>
                  <MDBox
                  id="modal-subox"
                  sx={{
                    height: 600,
                    display:'flex',
                    flexDirection:'row',
                    }}>

                        <DataGrid
                            pagination
                            hideFooter

                            disableColumnMenu
                            disableColumnFilter
                              // components={{ Toolbar: GridToolbar }}
                              rows={openModalAntagonists.data}
                              initialState={{
                                sorting: {
                                  sortModel: [
                                    {
                                      field: 'name',
                                      sort: 'asc',
                                    },
                                  ],
                                },
                              }}
                              columns={[
                                {
                                  field: 'icon',
                                  headerName: '',
                                  width: 100,
                                  editable: false,
                                  sortable: false,
                                  renderCell: (params) => {

                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.value? <Avatar sx={{ width: 56, height: 56 }} src={params.value} />:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },
                                {
                                  field: 'id',
                                  headerName: 'ID',
                                  // disableColumnFilter:true,
                                  width: 60,
                                  editable: false,
                                  renderCell: (params) => {

                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.id?<>{params.id}</>:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },
                                {
                                  field: 'name',
                                  headerName: 'Market A',
                                  // disableColumnFilter:true,
                                  width: 200,
                                  editable: false,
                                  renderCell: (params) => {

                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.value?<>{params.value}</>:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },

                                {
                                  field: 'score',
                                  headerName: 'score',
                                  // disableColumnFilter:true,
                                  width: 100,
                                  editable: false,
                                  renderCell: (params) => {
                                    return (
                                      <>
                                      {console.log(params)}
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.row.score?<>{params.row.score}</>:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },

                              ]}
                                />

                              <DataGrid
                              pagination
                              hideFooter

                              disableColumnFilter
                              disableColumnMenu
                              rows={openModalAntagonists2.data}
                              initialState={{
                                sorting: {
                                  sortModel: [
                                    {
                                      field: 'name',
                                      sort: 'asc',
                                    },
                                  ],
                                },
                              }}
                              columns={[
                                {
                                  field: 'icon',
                                  headerName: '',
                                  width: 100,
                                  editable: false,
                                  sortable: false,
                                  renderCell: (params) => {
                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.value? <Avatar sx={{ width: 56, height: 56 }} src={params.value} />:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },
                                {
                                  field: 'id',
                                  headerName: 'ID',
                                  // disableColumnFilter:true,
                                  width: 60,
                                  editable: false,
                                  renderCell: (params) => {

                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.id?<>{params.id}</>:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },
                                {
                                  field: 'name',
                                  headerName: 'Market B',
                                  width: 200,
                                  editable: false,
                                  renderCell: (params) => {
                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.value?<>{params.value}</>:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },

                              ]}
                                />

                            <DataGrid
                            disableColumnFilter
                            hideFooter
                            disableColumnMenu
                            initialState={{
                              sorting: {
                                sortModel: [
                                  {
                                    field: 'name',
                                    sort: 'asc',
                                  },
                                ],
                              },
                            }}
                              rows={openModalAntagonists3.data}
                              columns={[
                                {
                                  field: 'icon',
                                  headerName: '',
                                  width: 100,
                                  editable: false,
                                  sortable: false,
                                  renderCell: (params) => {
                                    return (
                                      <>

                                        <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.value? <Avatar sx={{ width: 56, height: 56 }} src={params.value} />:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },
                                {
                                  field: 'id',
                                  headerName: 'ID',
                                  // disableColumnFilter:true,
                                  width: 60,
                                  editable: false,
                                  renderCell: (params) => {

                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.id?<>{params.id}</>:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }
                                },
                                {
                                  field: 'name',
                                  headerName: 'Rest of the World',
                                  width: 250,
                                  editable: false,
                                  renderCell: (params) => {
                                    return (
                                      <>
                                      <MDBox onClick={()=>{ModalLink(params.id,mediaid, params.row.parent, params.row.node)}} sx={{cursor:'pointer'}}>
                                        {params.value?<>{params.value}</>:<></>}
                                      </MDBox>
                                      </>
                                    );
                                  }

                                },

                              ]}
                                />
                    </MDBox>
                    <MDBox mt={2} mb={2}>
                      {OutsideOfYourMarketCount!==null?<>
                        <MDTypography>
                          {OutsideOfYourMarketCount} Banks out of your Markets also have this Functionality <MDButton sx={{ml:2}} color="info">Contact Sales</MDButton>
                      </MDTypography>
                      </>:<></>}

                    </MDBox>
              </MDBox>
              </>:<></>}
              </>
              :<>
        {where=="8"?
        <DataTable  canSearch={true} isSorted={true} table={datatabledataUX} />:
          <DataTable  canSearch={true} isSorted={true} table={datatabledata} />}</>
          }


          </>:
           <MDBox display="flex" width={"100%"} alignItems="center" justifyContent="center">

           <CircularProgress/>
           </MDBox>
          }

</>
  );
}

export default PopupWithScore;
