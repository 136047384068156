import Card from "@mui/material/Card";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import MDBox from "components/MDBox";
import CircularProgress from '@mui/material/CircularProgress';
import { useState,useContext,useEffect,useRef } from "react";
import UserContext from 'context/UserContext';
import MDButton from "components/MDButton";
import AppleIcon from '@mui/icons-material/Apple';
import ComputerIcon from '@mui/icons-material/Computer';
import WatchIcon from '@mui/icons-material/Watch';
import ChartsHeader from "../ChartsHeader";
export default function FunctionalitiesGap({loadPopup,mediaid, workspaceMedia}){


    const userContext = useContext(UserContext);
    const [dashboards,setDashboards]=useState([]);
    const [selectedMediaID,setSelectedMediaID]=useState(null);

    const [options_functionalities_gap,setOptions_functionalities_gap] = useState({
        title: {
          text: 'Functionality Gap Analysis'
        },   type: 'column',
        series: [{
          data: [1, 2, 3]
        }]
      });


    const [isLoadingFuncGap,setIsLoadingFuncGap]=useState(true);

    async function chart_functionalities_gap() {
        var categories=[];
        var series=[];

         setIsLoadingFuncGap(true)

        var data={};

        data['mediaid']=selectedMediaID;

        var apiURL = process.env.REACT_APP_SERVER_URL;
          apiURL+="/api/chart_functionality_gap";
         const metadataResponse = await fetch(apiURL, {
           method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        body:JSON.stringify(data),
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           headers: {
             'bearer': userContext.userData.apiToken,
             'Content-Type': 'application/json'
           },
         });
         var avail=[];
         var not_avail=[];
         var unique=[];
          var fc=[];
         var json = await metadataResponse.json();
           json.forEach((item, i) => {
             categories.push(item.DisplayName)
             avail.push(item.available);
             not_avail.push(item.not_available);
             unique.push(item.unique);
            fc[item.DisplayName]=item.ID;
           });



        const chart={chart: {
            type: 'column',


        },	credits: {
              enabled: false
            },
        title: {
         text: ''
        },

        xAxis: {

         categories: categories,


        },
        yAxis: {
            min: 0,
            title: {
                text: 'Total functionalities'
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },

        plotOptions: {
          column: {
                      stacking: 'normal',
                      dataLabels: {
                          enabled: true
                      }
                  },
                  series: {
                     cursor: 'pointer',
                     point: {
                         events: {
                             click: function () {
                                console.log(this)
                                if (this.color=="#fcdce4") loadPopup(selectedMediaID,4,fc[this.category]);
                                if (this.color=="#020746") loadPopup(selectedMediaID,6,fc[this.category]);
                                if (this.color=="#4d5efc") loadPopup(selectedMediaID,5,fc[this.category]);


                             }
                         }
                     }
                 }
        },
        legend: {
         reversed: true
        },
        
        exporting: {
          menuItemDefinitions: {
          toggleTable: {
              onclick: function() {
                if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
                this.dataTableDiv.style.display = 'none';
                } else {
                this.viewData();
                this.dataTableDiv.style.display = '';
                }
              },
              text: 'Toggle Table'
              }
            },
            buttons: false,
        },

        series:  [{
            name: 'Unique Market B',
            data: avail,
            color:"#4d5efc"
        },{
            name: 'Common Market A&B',
            data: not_avail,
            color:"#020746"
        },{
            name: 'Unique Market A',
            data: unique,
            color:"#fcdce4"
        } ]};
        // console.log(chart);
         setOptions_functionalities_gap(chart);
         // setData(resp);
         setIsLoadingFuncGap(false)
        // setData(resp);


      }

    async function getDashboards() {

        // console.log("start api")
        var apiURL = process.env.REACT_APP_SERVER_URL;
          apiURL+="/api/dashboard/getall";
         const metadataResponse = await fetch(apiURL, {
           method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           headers: {
             'bearer': userContext.userData.apiToken,
             'Content-Type': 'application/json'
           },
         });
          var resp = await metadataResponse.json();
          // console.log(resp)
         setDashboards(resp);
         setSelectedMediaID(mediaid);


      }

      async function setUserWorkspaceState(newmedia){
        let data={};
        var newState = workspaceMedia
        console.log(newState)
        newState.functionality_gap_analysis = newmedia
        data['charsState']=[newState];
        var apiURL = process.env.REACT_APP_SERVER_URL;
          apiURL+="/api/changeUserWorkspaceState";
         const metadataResponse = await fetch(apiURL, {
           method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        body:JSON.stringify(data),
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           headers: {
           'bearer': userContext.userData.apiToken,
           'Content-Type': 'application/json'
           },
         });
          var resp = await metadataResponse.json();
        }

    useEffect(()=>{
        getDashboards();
      }, [])

      useEffect(()=>{
        if (selectedMediaID!==null)
          chart_functionalities_gap();
        }, [selectedMediaID])

        const handleMedia=(mediaid)=>{
          setUserWorkspaceState(mediaid)
          setSelectedMediaID(mediaid)
        }

        const chartRef = useRef();

        return (
          <Card>
            <MDBox p={1}>
              <ChartsHeader
              title="Functionality Gap Analysis"
              handleMedia={handleMedia}
              selectedMediaID={selectedMediaID}
              chartRef={chartRef}
               />
            {isLoadingFuncGap !== true ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options_functionalities_gap}
                  ref={chartRef}
                />                
            ) : (
                <MDBox display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress />
                </MDBox>
            )}
            </MDBox>
          </Card>
        );
        
}
