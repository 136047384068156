/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useState,useEffect,useContext} from 'react';
import { useParams,useLocation } from 'react-router';
import {Link} from "react-router-dom";
// import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
// @mui material components
import Grid from "@mui/material/Grid";
import './index.css'
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import SettingsBar from './SettingsBar';
// Settings page components
import BaseLayout from "layouts/pages/analyzed/bank/components/BaseLayout";
import Sidenav from "layouts/pages/analyzed/bank/components/Sidenav";

import Header from "layouts/pages/analyzed/bank/components/Header";
import BasicInfo from "layouts/pages/analyzed/bank/components/BasicInfo";
// import ChangePassword from "layouts/pages/analyzed/bank/components/ChangePassword";
// import Authentication from "layouts/pages/analyzed/bank/components/Authentication";
// import Accounts from "layouts/pages/analyzed/bank/components/Accounts";
// import Notifications from "layouts/pages/analyzed/bank/components/Notifications";
// import Sessions from "layouts/pages/analyzed/bank/components/Sessions";
// import DeleteAccount from "layouts/pages/analyzed/bank/components/DeleteAccount";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MDButton from "components/MDButton";
import CircularProgress from '@mui/material/CircularProgress';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TooltipTop from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import BarChartIcon from '@mui/icons-material/BarChart';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';
import ActiveTables from './components/ActiveTables';
import Card from "@mui/material/Card";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import fullscreen from "highcharts/modules/full-screen";
import annotations from "highcharts/modules/annotations";
import MDTypography from 'components/MDTypography';
import Statistics from './components/Statistics';
import HighContrastDark from 'highcharts/themes/high-contrast-dark';
// redux
import { connect } from "react-redux"
import { changeLastSideNavNode } from '../../../../context/actions/allActions'
import { useRef } from 'react';
import ChartsHeader from 'layouts/dashboards/analytics/components/ChartsHeader';


exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
annotations(Highcharts);

function Bank(props) {
  const [activeTreeItem, setActiveTreeItem] = useState(null)
  const userContext = useContext(UserContext);
 const { id } = useParams();
 const { mediaid } = useParams();
 const { treeid } = useParams();
 const { functionality_id } = useParams();
 const [selectedNode,setSelectedNode]=useState(null);
   const [dashboards,setDashboards]=useState(null);
   const [activeFunctionalities, setActiveFunctionalities] = useState(null);
   const [activeUseCases, setActiveUseCases] = useState(null);
  const [marketPosition,setMarketPosition]=useState(null);
  const [isLoadingMarketPosition, setIsLoadingMarketPosition] = useState(true);
  if (userContext.userData.darkMode!==null&userContext.userData.darkMode===true) HighContrastDark(Highcharts);

   const [action, setAction] = useState(false);
   const handleChange = () => {
     setAction(!action);
   };

   // matrix
  const [matrixName, setMatrixName] = useState("Default Matrix");
  const handleMatrixChange = (event: SelectChangeEvent) => {
    setMatrixName(event.target.value);
  };



  function loadFunctionalities(fun, activeTreeItem) {
    setSelectedNode(null);
    setSelectedNode(fun);
    if (activeTreeItem) setActiveTreeItem(activeTreeItem)
  }

  function loadFunctionalitiesInside(fun) {
    setSelectedNode(null);
    setSelectedNode(fun);
  }


 async function getDashboards() {

   var apiURL = process.env.REACT_APP_SERVER_URL;
     apiURL+="/api/dashboard/getall";
    const metadataResponse = await fetch(apiURL, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
   mode: 'cors', // no-cors, *cors, same-origin
   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'bearer': userContext.userData.apiToken,
        'Content-Type': 'application/json'
      },
    });
     var resp = await metadataResponse.json();

    setDashboards(resp);



 }

 async function selectDashboard() {
  let data={};
  data['dashboardId']=dashboard;
  data['originId']=selectedOriginID;
  var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL+="/api/dashboard/setActive";
   const metadataResponse = await fetch(apiURL, {
     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  body:JSON.stringify(data),
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     headers: {
       'bearer': userContext.userData.apiToken,
       'Content-Type': 'application/json'
     },
   });
    var resp = await metadataResponse.json();
   chart_marketposition("16")
    chart_superchart("16");
    if (dashboard!=="") {
      chart_functionalities_gap("16");
    }
    chart_functionalities_overview();
}


async function node_getActiveFunctionalities() {

  const data={};
  data['mediaid']=mediaid;
  data['bankid']=id;
  var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL+="/api/node_getAllActiveFunctionalities";
   const metadataResponse = await fetch(apiURL, {
     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  body:JSON.stringify(data),
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     headers: {
       'bearer': userContext.userData.apiToken,
       'Content-Type': 'application/json'
     },
   });
   var resp = await metadataResponse.json();
  //  console.log("Active Func",resp)
   setActiveFunctionalities(resp);
}

async function node_getActiveUseCases() {
  const data={};
  data['mediaid']=mediaid;
  data['bankid']=id;
  var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL+="/api/node_getAllActiveUseCases";
   const metadataResponse = await fetch(apiURL, {
     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  body:JSON.stringify(data),
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     headers: {
       'bearer': userContext.userData.apiToken,
       'Content-Type': 'application/json'
     },
   });
   var resp = await metadataResponse.json();
  //  console.log("Active Usecases",resp)
   setActiveUseCases(resp)
}

function getAvg(grades) {
  const total = grades.reduce((acc, c) => acc + c, 0);
  return total / grades.length;
  }


async function node_getMarketPosition() {

  var data={};
  data['mediaid']=mediaid;
  data['bankid']=id;
  data['inside']=true;
    data['tk']=userContext.userData.tk;
  var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL+="/api/chart_market_position";
   const metadataResponse = await fetch(apiURL, {
     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  body:JSON.stringify(data),
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     headers: {
       'bearer': userContext.userData.apiToken,
       'Content-Type': 'application/json'
     },
   });
  var resp = await metadataResponse.json();
  if (resp.noaccess!==undefined) {
    window.location.href='/noaccess';
    return;
  }
  if (resp.expired!==undefined) {
    window.location.href='/logout';
    return;
  }
  if (resp.wizard!==undefined) {
      window.location.href='/welcome';
      return;
    }
  var Myfuncionalities = []
  var MyUXScores = []
  var dataMagic = []

	var y = window.location.href
  // console.log(y)


  resp.map((i)=>{
    let el = {}
    el.name = i.BankName
    var randomColor = Math.floor(Math.random()*16777215).toString(16);
    el.color = 		 i.colors.Color;
    el.data = [[i.FuncCount, parseFloat(i.UXScore.toFixed(0))]]
    // el.key = "https:\/\/fi.scientiaconsulting.eu\/analysis-overview\/?media_id=22&bank_id="+i.BankID+"&prm=view"
    el.key = y.replace(id,i.BankID)
    el.marker = {"symbol":"square"}
    if(i.BankID===id){
      el.marker = {"radius":13,"lineColor":"rgb(100,100,100)","symbol":"triangle"}
    }
    dataMagic.push(el)
    // console.log(el.key)
    Myfuncionalities.push(i.FuncCount)
    MyUXScores.push(parseFloat(i.UXScore.toFixed(0)))
  }
  )

  // calculate max and min

  var minFunc = Math.min.apply(Math, Myfuncionalities)
  var maxFunc = Math.max.apply(Math, Myfuncionalities)
  var avgFunc =parseFloat(getAvg(Myfuncionalities).toFixed(0))

  if(minFunc-30<=5){
    minFunc=5
  } else {
    minFunc-=30
  }

  var minUX = parseFloat(Math.min.apply(Math,MyUXScores).toFixed(0))
  var maxUX = Math.max.apply(Math,MyUXScores)
  var avgUX =parseFloat(getAvg(MyUXScores).toFixed(0))

  if(minUX-100<=5){
    minUX=5
  } else {
    minUX-=100
  }


  let dummy ={"resDataMagic":dataMagic,"bankNameDataMed":"Market Position","maxFuncAvg":maxFunc+20,"minFuncAvg":minFunc,"middleFuncAvg":avgFunc,"maxUcAvg":1000,"minUcAvg":minUX,"middleUcAvg":avgUX}

  dummy['myNotaX'] = (dummy['minFuncAvg'] + dummy['middleFuncAvg']) / 2;
		dummy['myNotaY']= (dummy['minUcAvg'] + dummy['middleUcAvg']) / 2;
		dummy['myNotaX2'] = (dummy['maxFuncAvg'] + dummy['middleFuncAvg']) / 2;
		dummy['myNotaY2'] = (dummy['maxUcAvg'] + dummy['middleUcAvg']) / 2;

  setIsLoadingMarketPosition(true);
      let chart = {
        chart: {
      				type: 'scatter',
      				zoomType: 'xy',
              height:650,
      				marginBottom: 120
      			},
      			title: {
      				text: "",
      				

      			},

      			credits: {
      				enabled: false
      			},
      			subtitle: {
      				text: '',
      				
      			},
      			xAxis: {
      				title: {
      					text: 'Completeness of Vision:<br/>Total available Functionalities offered',
      					style: {
      						fontSize: '15px',
      						opacity: 0.9
      					},
      				},

      				min: dummy['minFuncAvg'] - 5,
      				max: dummy['maxFuncAvg'] + 5,
      				tickPositions: [dummy['minFuncAvg'] - 5, dummy['middleFuncAvg'], dummy['maxFuncAvg'] + 5],
      				// tickInterval: 80,
      				tickLength: 1,
      				minorTickLength: 0,
      				gridLineWidth: 0,
      				showLastLabel: true,
      				showFirstLabel: true,
      				lineColor: '#ccc',
      				lineWidth: 1
      			},
      			yAxis: {
      				title: {
      					text: 'Quality of Implementation:<br/>Average Score of all User Experience',
      					style: {
      						fontSize: '15px',
      						opacity: 0.9
      					},
      					rotation: -90
      				},
      				min: dummy['minUcAvg'],
      				max: dummy['$maxUcAvg'],
      				tickPositions: [dummy['minUcAvg'], dummy['middleUcAvg'], dummy['maxUcAvg']],
      				//	tickInterval: 120,
      				tickLength: 1,
              gridLineWidth: 0,
      				minorTickLength: 0,
      				lineColor: '#ccc',
      				lineWidth: 1
      			},
            exporting: {
              buttons: false
            },
      			// legend: {
      			// 	layout: 'horizontal',
      			// 	align: 'right',
      			// 	verticalAlign: 'bottom',
      			// 	maxHeight: 56,
      			// 	x: 0,
      			// 	y: 22,
      			// 	itemWidth: 150,
      			// 	floating: false,
      			// 	backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
      			// 	borderWidth: 0
      			// },
            legend:{ enabled:false},
      			plotOptions: {
      				scatter: {
      					marker: {
      						radius: 5,
      						states: {
      							hover: {
      								enabled: true,
      								lineColor: 'rgb(100,100,100)'
      							}
      						}
      					},
      					states: {
      						hover: {
      							marker: {
      								enabled: false
      							}
      						}
      					},
      					tooltip: {
      						headerFormat: '<b>{series.name}</b><br>',
      						pointFormat: '{point.x} Functionalities, {point.y} UX Score'
      					}
      				},
      				series: {
      					cursor: 'pointer',
      					point: {
      						events: {
      							click: function() {
      								window.location.href = this.series.options.key;
      							}
      						}
      					}
      				}
      			},
      			annotations: [{
      				labelOptions: {
      					shape: 'rect',
      					backgroundColor: 'transparent',
      					borderWidth: 0,
      					verticalAlign: 'middle',
      					style: {
      						fontSize: '18px',
      						opacity: 0.6
      					},
      					x: 0,
      					y: 0
      				},
      				labels: [{
      					point: {
      						xAxis: 0,
      						yAxis: 0,
      						x: dummy['myNotaX'],
      						y: dummy['myNotaY']
      					},
      					style: {
      						color: '#f00'
      					},
      					// text: 'Niche'
                text: ' '
      				}, {
      					point: {
      						xAxis: 0,
      						yAxis: 0,
      						x: dummy['myNotaX'],
      						y: dummy['myNotaY2']
      					},
      					style: {
      						color: '#000'
      					},
      					// text: 'Challengers'
                text: ' '

      				}, {
      					point: {
      						xAxis: 0,
      						yAxis: 0,
      						x: dummy['myNotaX2'],
      						y: dummy['myNotaY']
      					},
      					style: {
      						color: '#00f'
      					},
      					// text: 'Potentialists'
                text: ' '

      				}, {
      					point: {
      						xAxis: 0,
      						yAxis: 0,
      						x: dummy['myNotaX2'],
      						y: dummy['myNotaY2']
      					},
      					style: {
      						color: '#060'
      					},
      					// text: 'Leaders'
                text: ' '

      				}]
      			}],
      			series: dummy['resDataMagic']

    };
    setMarketPosition(chart);
  setIsLoadingMarketPosition(false);

}

function myfunc(){

  var x = document.getElementsByClassName('MuiAutocomplete-root MuiAutocomplete-hasPopupIcon ')[0]
  var y = document.getElementById('media-control')
  // document.getElementsByClassName('MuiBreadcrumbs-ol')[0].childNodes[6].replaceWith(x)
  // document.getElementsByClassName('MuiBreadcrumbs-ol')[0].childNodes[8].replaceWith(y)

  var paths = document.getElementsByClassName('MuiBreadcrumbs-ol')[0].childNodes

  if (paths.length>9){
    var i = 9
    while(i<13){
      //console.log(paths[i].classList.add('hidden'))
      i++;
    }
  }


//console.log(document.getElementById('demo-select-small'))


};

useEffect(()=>{
  getDashboards();

  // Remarked due to double api call

  // node_getActiveFunctionalities();
  // node_getActiveUseCases();
  node_getMarketPosition();
  myfunc();


  // changePath();
 }, []) // <-- empty dependency array
 const handleDashboard = (SelectChangeEvent) => {
    setDashboard(event.target.value);
  };

  const chartRef = useRef();

  return (
    <BaseLayout>

    <SettingsBar />





      <MDBox mt={2}>
        <Grid container spacing={3}>
         <Grid item xs={12} lg={3}>
          <Sidenav
          // key={props.ReduxState.lastSidenavNode}
          state={props}
          loadFunctionalities={loadFunctionalities}

           treeid={treeid}
            functionality_id={functionality_id} />
        </Grid>
            <Grid item xs={12} lg={9}>
              <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header  selectedNode={selectedNode}/>
                </Grid>
                <Grid item xs={12}>
                {selectedNode!==null?<>

                  <BasicInfo key={props.ReduxState.showActiveFunctionalities} state={props.ReduxState} selectedNode={selectedNode}  treeid={treeid} activeTreeItem={activeTreeItem}  functionality_id={functionality_id} />


                  </>:<>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {/* Market Position */}
                     <MDBox mt={2} mb={2}>
                      <Card>
                        <ChartsHeader
                          title="Market Positioning"
                          subtitle="Functionalities vs UX Analysis Score"
                          selectedMediaID = {mediaid}
                          chartRef={chartRef}
                          showButtons={false}
                          marginRight={{marginRight: 17}}
                        />
                     {!isLoadingMarketPosition?<>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={marketPosition}
                      ref={chartRef}
                       />
                       </>:<>
                       <MDBox display="flex" justifyContent="center" alignItems="center" sx={{p:25}}>
                               <CircularProgress />
                               </MDBox>
                       </>}
                      </Card>
                    </MDBox>
                    </Grid>

                  </Grid>

                  <Grid item xs={12}>

                  <ActiveTables
                  activeFunctionalities={activeFunctionalities}
                  activeUseCases={activeUseCases}
                  treeid={treeid}
                  functionality_id={functionality_id}
                  />

                  </Grid>

                  </>}
                </Grid>




              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

// injecting state to props
const mapStateToProps = (state) =>{
  return state
}

// export default Bank;

export default connect(mapStateToProps, {changeLastSideNavNode}) (Bank)
