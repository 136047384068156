import { Skeleton } from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import InputWithIcon from "./inputWithIcon";

const FuncsList = ({
  data,
  selection,
  handleSelection,
  toggleChildren,
  handleParentClassName,
  bucketSelection,
}) => {
  const [active, setActive] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => setQuery(""), [selection]);

  return (
    <MDBox
      sx={{
        display: "flex",
        height: "338px",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        width: "800px",
        p: 1,
      }}
    >
      {data?.length === 0 ? (
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "50%",
            height: "100%",
          }}
        >
          <Skeleton width="100%" animation="wave" height="3em" />
          <Skeleton width="100%" animation="wave" height="3em" />
          <Skeleton width="100%" animation="wave" height="3em" />
          <Skeleton width="100%" animation="wave" height="3em" />
          <Skeleton width="100%" animation="wave" height="3em" />
          <Skeleton width="100%" animation="wave" height="3em" />
        </MDBox>
      ) : (
        <>
          <MDBox className="type-list">
            <ul className="ul">
              {data.map((i) => (
                <li
                  className={
                    active.DisplayName == i.DisplayName
                      ? "active"
                      : bucketSelection.some(
                          (item) =>
                            item.title == i.DisplayName &&
                            item.children.length > 0
                        )
                      ? "items-chosen"
                      : null
                  }
                  onClick={() => setActive(i)}
                  key={i.title}
                >
                  {i.DisplayName}
                </li>
              ))}
            </ul>
          </MDBox>
          <MDBox className="checkbox-content">
            {active?.children?.length && (
              <>
                {/* <TextField
                  variant="standard"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                /> */}
                <InputWithIcon
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <ul className="ul checkbox-list">
                  <li
                    onClick={() =>
                      toggleChildren(active.DisplayName, active.children)
                    }
                  >
                    <label htmlFor="Select All">
                      <input
                        name="Select All"
                        type="checkbox"
                        checked={handleParentClassName(
                          active.DisplayName,
                          active.children
                        )}
                      />
                      <div className="checkbox-block"></div>
                      Select All
                    </label>
                  </li>
                </ul>

                <ul className="ul checkbox-list">
                  {active?.children?.length &&
                    active?.children
                      ?.filter((i) =>
                        i.DisplayName.toLowerCase().includes(
                          query.toLowerCase()
                        )
                      )
                      ?.map((inner) => (
                        <li
                          key={inner.BankID}
                          onClick={() =>
                            handleSelection(active.DisplayName, inner)
                          }
                        >
                          <label htmlFor={inner.DisplayName}>
                            <input
                              name={inner.DisplayName}
                              type="checkbox"
                              checked={selection.some(
                                (e) =>
                                  e.title === active.DisplayName &&
                                  e.inner === inner
                              )}
                              readOnly
                            />
                            <div className="checkbox-block"></div>
                            {inner.DisplayName}
                          </label>
                        </li>
                      ))}
                </ul>
              </>
            )}
          </MDBox>
        </>
      )}
    </MDBox>
  );
};

export default FuncsList;
