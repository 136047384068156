import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import { useEffect } from "react";
import { useState } from "react";

const SearchResult = ({
  banks,
  query,
  marketA,
  setMarketA,
  tempMarketB,
  tempMarketA,
  setTempMarketA,
  setMarketB,
  setTempMarketB,
  marketB,
}) => {
  const [tempSearch, setTempSearch] = useState([]);

  const handleToggle = (value) => () => {
    // handle if info comes from Market A tab
    if (marketA !== undefined) {
      const currentIndex = marketA.indexOf(value);
      const newMarketA = [...marketA];
      if (currentIndex === -1) {
        newMarketA.push(value);
      } else {
        newMarketA.splice(currentIndex, 1);
      }
      setMarketA(newMarketA);
      setTempMarketA(newMarketA);
    }

    // handle if info comes from Market B tab
    if (marketB !== undefined) {
      const currentIndex = marketB.indexOf(value);
      const newMarketB = [...marketB];
      if (currentIndex === -1) {
        newMarketB.push(value);
      } else {
        newMarketB.splice(currentIndex, 1);
      }
      setMarketB(newMarketB);
      setTempMarketB(newMarketB);
    }
  };

  useEffect(() => {
    let temp = [];
    banks
      .filter((bank) => bank._id.GroupTitle != "Admin ALL")
      .forEach((banks) => {
        banks.banks.forEach((bank) => {
          if (!temp.includes(bank)) temp.push(bank);
        });
      });
    setTempSearch(temp);
  }, [banks]);

  return (
    <>
      {marketA && (
        <List>
          {banks &&
            banks.length > 0 &&
            tempSearch
              ?.filter((item) => {
                if (item.BankName.toLowerCase().includes(query.toLowerCase())) {
                  return item;
                }
              })
              ?.map((value, i) => (
                <ListItem key={value.BankID + i} disablePadding>
                  <ListItemButton
                    onClick={handleToggle(value)}
                    dense
                    disabled={tempMarketB.includes(value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          marketA.includes(value) &&
                          !tempMarketB.includes(value)
                        }
                        tabIndex={-1}
                        disableRipple
                        disabled={tempMarketB.includes(value)}
                      />
                    </ListItemIcon>
                    <ListItemText>{value.BankName}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
        </List>
      )}

      {marketB && (
        <List>
          {banks &&
            banks.length > 0 &&
            tempSearch
              ?.filter((item) => {
                if (query === "") {
                  return item;
                } else if (
                  item.BankName.toLowerCase().includes(query.toLowerCase())
                ) {
                  return item;
                }
              })
              //   ?.map((item) => <div key={item.BankID}>{item.BankName}</div>)
              ?.map((value, i) => (
                <ListItem key={value.BankID + i} disablePadding>
                  <ListItemButton
                    onClick={handleToggle(value)}
                    dense
                    disabled={tempMarketA.includes(value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          marketB.includes(value) &&
                          !tempMarketA.includes(value)
                        }
                        tabIndex={-1}
                        disableRipple
                        disabled={tempMarketA.includes(value)}
                      />
                    </ListItemIcon>
                    <ListItemText>{value.BankName}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
        </List>
      )}
    </>
  );
};

export default SearchResult;
