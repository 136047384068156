import Bucket from "../components/bucket";
import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MDTypography from "components/MDTypography";
import List from "../components/List";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { useRef } from "react";
import BucketInactive from "../components/bucketInactive";
import PrimaryButton from "scientia/New Design/Button/PrimaryButton";
import PrevButton from "scientia/New Design/Button/PrevButton";

const View22 = ({
  emptyBucketSelection,
  world,
  selection,
  handleSelection,
  toggleChildren,
  handleParentClassName,
  bucketSelection,
  setInnerStep,
  bucketSelectionA,
  handleNextStep,
  handlePreviousStep,
  errorModal,
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setInnerStep(1), errorModal);

  return (
    <MDBox ref={wrapperRef} sx={{ marginTop: "48px" }}>
      {bucketSelectionA.some((item) => item.banks.length > 0) && (
        <>
          <MDBox
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              marginBottom: "12px",
            }}
          >
            <MDTypography
              sx={{
                color: "#090324",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Select Primary Banks
            </MDTypography>
            {/* <Tooltip
              title="Add either your Organisation or the banks/fintechs you want to compare against with the Target market."
              sx={{ cursor: "pointer" }}
            >
              <HelpOutlineIcon fontSize="small" />
            </Tooltip> */}
          </MDBox>
          <BucketInactive bucketSelection={bucketSelectionA} />
        </>
      )}

      <MDBox
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <MDTypography
          sx={{
            color: "#090324",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          Select Target Market
        </MDTypography>
        {/* <Tooltip
          title="Add the banks/fintechs that you want to compare against with the Primary."
          sx={{ cursor: "pointer" }}
        >
          <HelpOutlineIcon fontSize="small" />
        </Tooltip> */}
      </MDBox>

      {bucketSelection.some((item) => item.banks.length > 0) ? (
        <Bucket
          title="Add in the Target Section"
          emptyBucketSelection={emptyBucketSelection}
          bucketSelection={bucketSelection}
          handleSelection={handleSelection}
        />
      ) : (
        <MDBox
          sx={{
            padding: "14px 24px",
            border: "1px solid #4A59FF",
            marginBottom: "14px",
            backgroundColor: "#FEFEFE",
            borderRadius: "10px",
          }}
        >
          <MDTypography
            sx={{ fontSize: "16px", lineHeight: "24px", color: "#9093B5" }}
          >
            Select Banks
          </MDTypography>
        </MDBox>
      )}
      <List
        data={world}
        selection={selection}
        handleSelection={handleSelection}
        toggleChildren={toggleChildren}
        handleParentClassName={handleParentClassName}
        bucketSelection={bucketSelection}
      />
      <MDBox sx={{ marginTop: "32px" }}>
        <PrimaryButton onClick={handleNextStep} fontWeight="bold">
          Continue
        </PrimaryButton>

        <PrevButton onClick={handlePreviousStep}>
          Back to Previous Step
        </PrevButton>
      </MDBox>
    </MDBox>
  );
};

export default View22;
