/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import './index.css'
// redux
import { connect } from "react-redux"
import { showFunctionalitiesActive, showFunctionalitiesInactive } from '../../../../../../context/actions/allActions'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useState,useContext,useEffect } from "react";
import { useParams,useLocation } from 'react-router';
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

// Images
import burceMars from "assets/images/bruce-mars.jpg";

import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';
import MDButton from "components/MDButton";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import AppleIcon from '@mui/icons-material/Apple';
import ComputerIcon from '@mui/icons-material/Computer';
import WatchIcon from '@mui/icons-material/Watch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { fabClasses } from '@mui/material';
import {decode} from 'html-entities';


function Header({props,selectedNode}) {
  const { id } = useParams();
  const { mediaid } = useParams();
  const { treeid } = useParams();
  const { functionality_id } = useParams();

  const [visible, setVisible] = useState(true);
  const [displayName,setDisplayName]=useState(null);
  const handleSetVisible = () => setVisible(!visible);
  const userContext = useContext(UserContext);

  const [media, setMedia] = useState('');
  const [mediaSupported, setMediaSupported] = useState([])

  const [openMedia, setOpenMedia] = useState(false);
  const [openBanks, setOpenBanks] = useState(false)

  const handleOpenMedia = () => {
    var ChangeOpenMedia = !openMedia
    setOpenMedia(ChangeOpenMedia);
  };
  const handleOpenBanks = () => {
    var ChangeOpenBanks = !openBanks
    setOpenBanks(ChangeOpenBanks);
  };

  const [banksMarketA, setBanksMarketA] = useState([])
  const [banksMarketB, setBanksMarketB] = useState([])
  const [valueName, setValueName] = useState(null)

  const handleMediaChange = (event) => {

    setMedia(event.target.value);
  /*  var path = window.location.href
    console.log(path, event.target.value, mediaid)
    path = path.replace(mediaid, event.target.value)
    console.log(path)
    window.location.href = path
  */
  var path;
  
  path=window.location.origin+"/analyzed/bank/"+id+"/"+event.target.value;
  
  // check for node
  if (selectedNode!==null&&selectedNode!==undefined) {
    path=window.location.origin+"/analyzed/bank/"+id+"/"+event.target.value+"/"+selectedNode;
  }

  // check for funcid
  if (functionality_id!==null&&functionality_id!==undefined) {
    path=window.location.origin+"/analyzed/bank/"+id+"/"+event.target.value+"/"+treeid+"/"+functionality_id;
  }

  // check for both
  if (functionality_id!==null&&functionality_id!==undefined&&selectedNode!==null&&selectedNode!==undefined) {
    path=window.location.origin+"/analyzed/bank/"+id+"/"+event.target.value+"/"+selectedNode
  }

  // if (path===undefined){

  // }
  window.location.href=path
  };


  const handleMediaChange2 = (media) => {
    setMedia(media);
  var path;
  path=window.location.origin+"/analyzed/bank/"+id+"/"+media;
  // check for node
  if (selectedNode!==null&&selectedNode!==undefined) {
    path=window.location.origin+"/analyzed/bank/"+id+"/"+media+"/"+selectedNode;
  }
  // check for funcid
  if (functionality_id!==null&&functionality_id!==undefined) {
    path=window.location.origin+"/analyzed/bank/"+id+"/"+media+"/"+treeid+"/"+functionality_id;
  }
  // check for both
  if (functionality_id!==null&&functionality_id!==undefined&&selectedNode!==null&&selectedNode!==undefined) {
    path=window.location.origin+"/analyzed/bank/"+id+"/"+media+"/"+selectedNode
  }
  window.location.href=path
  };



  async function doAction_node_getBankAndMediaDetails() {
    const data={};
    data['mediaid']=mediaid;
    data['bankid']=id;
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/node_getBankAndMediaDetails";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });

     var resp = await metadataResponse.json();
     setDisplayName(resp);
  }


  // get banks for dropdown
  async function doAction_node_getBanks() {
    const data={};
    data["mediaid"]=mediaid;
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/node_getBanks";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });
     var resp = await metadataResponse.json();


    var uniqueA = [
      ...new Map(resp.all[0].MarketA.map((item) => [item["BankID"], item])).values(),
    ];

    var uniqueB = [
      ...new Map(resp.all[0].MarketB.map((item) => [item["BankID"], item])).values(),
    ];

    uniqueA.sort((a, b) => a.BankName > b.BankName ? 1 : -1);
    uniqueB.sort((a, b) => a.BankName > b.BankName ? 1 : -1);
    
    uniqueA = uniqueA.filter((i)=>{return i.BankName!==undefined})
    uniqueB = uniqueB.filter((i)=>{return i.BankName!==undefined})

    // filtering media id
    uniqueA = uniqueA.filter((i)=>{return resp.bankids_that_exist_in_this_media[i.BankID]!==undefined})
    uniqueB = uniqueB.filter((i)=>{return resp.bankids_that_exist_in_this_media[i.BankID]!==undefined})

    if(treeid!==undefined){
      console.log("filter node ", treeid)
    }

    console.log(uniqueA)

     setBanksMarketA(uniqueA)
     setBanksMarketB(uniqueB)

    }




  async function doAction_node_getBankAndMediaSupported(){
    // console.log('enter')

    const data={};
    data['bankid']=id;
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/node_getBankAndMediaSupported";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
     body:JSON.stringify(data),
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });

     var resp = await metadataResponse.json();
    //  console.log(resp)
    //  setMediaSupported(resp)

     var supported = []
     resp.map((i)=>{
      supported.push(i._id.MediaID)
     })
    //  console.log(supported)
     setMediaSupported(supported)
    //  console.log(supported.includes())

  }

  useEffect(()=>{
  doAction_node_getBankAndMediaDetails();
  doAction_node_getBankAndMediaSupported();
  doAction_node_getBanks();
  myJavascript()
  
  // findBankName()

 }, []) // <-- empty dependency array

 useEffect(()=>{
  changePath();
 }, [displayName])

 useEffect(()=>{
  findBankName();
}, [banksMarketA, banksMarketB,media])

 function showMainTab(){
  var x = document.getElementsByClassName("mainTab-hidden")[0]
  x.classList.remove("mainTab-hidden")
  var y = document.getElementsByClassName("showTabBtn")[0]
  y.classList.add("Btn-hidden")
}

// const options = top100Films.map((option) => {
//   // const firstLetter = option.title[0].toUpperCase();
//   const marketCategory = option.title[0].toUpperCase();
//   return {
//     marketCategory: marketCategory!=='E' ? 'Market A' : 'Market B',
//     ...option,
//   };
// });


const optionsA = banksMarketA.map((option)=>{
  return{
    marketCategory:'Market A',
    ...option
  }
})

const optionsB = banksMarketB.map((option)=>{
  return{
    marketCategory:'Market B',
    ...option
  }
})

const final_options = optionsA.concat(optionsB)






const changePath = () =>{
  // console.log("Work")
  if(displayName && document.getElementsByClassName("css-g2qiks-MuiTypography-root")[2] !== undefined){
    document.getElementsByClassName("css-g2qiks-MuiTypography-root")[2].textContent = decode(displayName.BankName);
  }
}


const myJavascript = () =>{

  if(mediaid==16){
    document.getElementById('demo-select-small').childNodes[0].textContent = "Web"
  } else if (mediaid==22){
    document.getElementById('demo-select-small').childNodes[0].textContent = "iOS"
  } else if (mediaid==27){
    document.getElementById('demo-select-small').childNodes[0].textContent = "Apple Watch"
  }

  console.log(document.getElementById('grouped-demo'))

}

const handleChangeSupported = () =>{
  // console.log(props.ReduxState)
  if(props.ReduxState.showActiveFunctionalities){
    props.showFunctionalitiesInactive()
  } else {
    props.showFunctionalitiesActive()
  }
}


const findBankName = () =>{
  const index = final_options.findIndex(object => {
    return object.BankID === id;
  });
  setValueName(final_options[index])
}


const handleBankChange = (e) =>{
  console.log(e.target.innerHTML)
  const index = final_options.findIndex(object => {
    return object.BankName === e.target.innerHTML;
  });
  console.log(final_options[index])
  if (final_options[index]==undefined||final_options[index]==null) return;
  /*var path = window.location.href
  path = path.replace(id,final_options[index].BankID)
  // console.log(path)
    console.log(selectedNode);
    //window.location.href = path*/
    var path;
    console.log(functionality_id)
    if (selectedNode!==null&&selectedNode!==undefined) {
     path=window.location.origin+"/analyzed/bank/"+final_options[index].BankID+"/"+mediaid+"/"+selectedNode;
    }
     if (functionality_id!==null&&functionality_id!==undefined) {
      path=window.location.origin+"/analyzed/bank/"+final_options[index].BankID+"/"+mediaid+"/"+treeid+"/"+functionality_id;

    }
    if (path===undefined){
      path=window.location.origin+"/analyzed/bank/"+final_options[index].BankID+"/"+mediaid;

    }
    window.location.href=path
}





  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">

          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>


            <MDBox display="flex" alignItems="center">



              <MDTypography variant="h4" fontWeight="medium" id="header-bankname" onClick={handleOpenBanks} sx={{cursor:'pointer'}}>
                {/* Bank Name */}
                {displayName!==null?decode(displayName.BankName):""}

                {openBanks?<>
                  &nbsp;&nbsp;<i  class="bi bi-caret-up-fill">
                  </i></>:<>
                  &nbsp;&nbsp;<i class="bi bi-caret-down-fill">
                    </i></>}

                </MDTypography>


              

                <FormControl className='hidden' variant="standard" sx={{width:100, ml:12, mt:1}} id="media-control">
                            <Select
                            
                              labelId="demo-select-small"
                              id="demo-select-small"
                              value=""
                              onChange={handleMediaChange}
                              // defaultOpen
                              >
                              <MenuItem value={16} disabled={(!mediaSupported.includes('16') || mediaid==16)?true:false} >Web</MenuItem>
                              <MenuItem value={22} disabled={(!mediaSupported.includes('22') || mediaid==22)?true:false}>iOS</MenuItem>
                              <MenuItem value={27} disabled={(!mediaSupported.includes('27')|| mediaid==27)?true:false}>Apple Watch</MenuItem>

                            </Select>
                    </FormControl>

                  <MDBox sx={{ml:18}}>


                  <MDButton 
                    disabled={(!mediaSupported.includes('22'))?true:false} 
                                    // color={(mediaid==22)?"info":"light"}
                    className={(mediaid==22)?"HeaderBtnActive":"HeaderBtnInactive"}
                onClick={()=>handleMediaChange2("22")}  
                title="Ios"
                >
                  <AppleIcon 
                  className={(mediaid==22)?'icon-header-basic-info-active':'icon-header-basic-info-inactive'}
                 
                  />
                  </MDButton>
                    
                    <MDButton 
                    sx={{ml:2}} 
                    disabled={(!mediaSupported.includes('16'))?true:false} 
                onClick={()=>handleMediaChange2("16")} 
                // color={(mediaid==16)?"info":"light"}
                className={(mediaid==16)?"HeaderBtnActive":"HeaderBtnInactive"}
                title="Web"
                 ><ComputerIcon 
                 className={(mediaid==16)?'icon-header-basic-info-active':'icon-header-basic-info-inactive'}
                  />
                 </MDButton>
                    
               
                <MDButton 
                // color={(mediaid==27)?"info":"light"}
                className={(mediaid==27)?"HeaderBtnActive":"HeaderBtnInactive"}
                sx={{ml:2}} 
                title="Apple Watch"
                onClick={()=>handleMediaChange2("27")}  
                disabled={(!mediaSupported.includes('27'))?true:false} 
                >
                  <WatchIcon 
                  className={(mediaid==27)?'icon-header-basic-info-active':'icon-header-basic-info-inactive'}
                 
                  />
                  </MDButton>
                
                
                  </MDBox>





              </MDBox>


              <MDBox display="flex" alignItems="center">

              {openBanks?<>
                <Autocomplete

                        id="grouped-demo"
                        options={final_options.sort((a, b) => -b.marketCategory.localeCompare(a.marketCategory))}
                        groupBy={(option) => option.marketCategory}
                        getOptionLabel={(option) => option.BankName}
                        sx={{ width: 200, height:40, }}
                        value = {valueName}
                        onChange={handleBankChange}
                        open={openBanks}
                        // disableListWrap={true}

                        renderInput={(params) =>

                          <TextField

                          variant="standard"
                          {...params}
                          />}
                          />
                </>:<></>}

              {/* <MDTypography
              variant="button"
               color="text"
                fontWeight="medium"
                className="media_tree_header"
                onClick={handleOpenMedia}
                sx={{cursor:'pointer', mt:2}}
                > */}
              {/* Media */}
              {/* {displayName!==null?displayName.MediaName:""}
                </MDTypography> */}

                {/* {openMedia?<> */}

                {/* </>:<></>} */}


                </MDBox>


            </MDBox>
          </Grid>


          <Grid item sx={{position:'absolute', right:200}}>
          {/* remove moutsatsos pending
          <FormGroup>
            <FormControlLabel
            control={
            <Switch
            checked={props.ReduxState.showActiveFunctionalities}
            onChange={handleChangeSupported}

            />
            } label="Supported" />
          </FormGroup> */}
          </Grid>




          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >







            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

const top100Films = [
  { title: 'Alpha Bank', year: 1994 },
  { title: 'Revolut', year: 1972 },
  { title: 'City', year: 1974 },
  { title: 'EuroBank', year: 2008 },
  { title: 'EuroBank', year: 2008 },
  { title: 'EuroBank', year: 2008 },
  { title: 'EuroBank', year: 2008 },
  { title: 'EuroBank', year: 2008 },
  { title: 'EuroBank', year: 2008 },
  { title: 'EuroBank', year: 2008 },
  { title: 'EuroBank', year: 2008 },
  { title: 'EuroBank', year: 2008 },
  { title: 'EuroBank', year: 2008 },

]

// injecting state to props
const mapStateToProps = (state) =>{
  return state
}

// export default Bank;
export default connect(mapStateToProps, {showFunctionalitiesActive,showFunctionalitiesInactive}) (Header)

// export default Header;
