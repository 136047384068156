/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import UserContext from "context/UserContext";
import SvgIcon from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import { useSpring, animated } from "react-spring";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider, createTheme } from "@mui/material/styles";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
  <TreeItem
    {...props}
    sx={{ fontSize: 9 }}
    TransitionComponent={TransitionComponent}
  />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

function Sidenav({ loadFunctionalities, treeid }) {
  const userContext = useContext(UserContext);
  const { id } = useParams();
  const { mediaid } = useParams();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [parentList, setParentList] = useState(null);
  const [expC, setExpC] = useState([]);
  const [activeFunctionalities, setActiveFunctionalities] = useState(null);
  const [trackClick, setTrackClick] = useState(false)

  let itemsRef = [];
  async function doAction_node_getBankAndMediaNav() {
    const data = {};
    data["mediaid"] = mediaid;
    data["bankid"] = id;

    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/node_getBankAndMediaNav";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();
    console.log(resp)

    var ct = [];

    resp.forEach(function (v, i) {
      var r = {};
      r["name"] = v.DisplayName;
      r["color"] = "dark";
      r["icon"] = "info";
      r["description"] = "";
      r["id"] = v.ID;
      r["parent_id"] = 0;
      ct.push(r);
    });

    //buildTree(resp);
    //  var out=menu.join('\n')

    var ex = [];
    if (treeid !== undefined) {
      loadFunctionalities(treeid);

      while ((treeid = getParent(resp, treeid))) {
        ex.push(treeid);
      }
      //get parenti
      var a = getArrayById(resp, ex[ex.length - 1]);

      a.children.forEach((item, i) => {
        item.children.forEach((item2, i2) => {
          ex.push(item2.ID);
          if (item2.children !== undefined) {
            item2.children.forEach((item3, i3) => {
              ex.push(item3.ID);
            });
          }
        });
        ex.push(item.ID);
      });
      setExpC(ex);
    }
    setParentList(resp);
  }

  async function node_getActiveFunctionalities() {
    const data = {};
    data["mediaid"] = mediaid;
    data["bankid"] = id;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/node_getAllActiveFunctionalities";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
    setActiveFunctionalities(resp);
  }

  useEffect(() => {
    node_getActiveFunctionalities();
    doAction_node_getBankAndMediaNav();
  }, []); // <-- empty dependency array

  const buildTree = (ii, arr) => {
    if (arr !== undefined) {
      var ct = [];
      let aa = 0;
      for (let i = 0; i < arr.length; i++) {
        var it = arr[i];
        if (
          it.ID !== "functionality_" &&
          it.children !== undefined &&
          it.children[0].children !== undefined
        ) {
          if (ii == 0) {
            // parent
            var key = i + 1;
          } else {
            // child
            var key = ii + "." + (aa + 1);
          }
          aa++;
          let ds = it.DisplayName.replace("&amp;", "&");

          if (
            it?.children?.every((item) =>
              item?.children[0].ID.startsWith("functionality_")
            )
          ) {
            // nodes with only funcs inside that are active
            let foundCount = 0;
            for (const element of activeFunctionalities) {
              if (element.ParentID === it.ID) {
                foundCount++;
              }
            }
            if (foundCount > 0) {
              // nodes that include funcs only (funcs are active)
              itemsRef[it.ID] = `${key}. ${ds}`;
              ct.push(
                <StyledTreeItem
                  sx={{ fontSize: 14, color: "#1A73E8" }}
                  key={it.ID}
                  nodeId={it.ID}
                  label={
                    `${key}. ${ds} - ` + foundCount + " / " + it.children.length
                  }
                >
                  {" "}
                </StyledTreeItem>
              );
            } else {
              // nodes that include funcs only (funcs are inactive)
              itemsRef[it.ID] = `${key}. ${ds}`;
              ct.push(
                <StyledTreeItem
                  sx={{ fontSize: 14, color: "#6e6f70" }}
                  key={it.ID}
                  nodeId={it.ID}
                  label={`${key}. ${ds}`}
                >
                  {" "}
                </StyledTreeItem>
              );
            }
          } else if (
            it?.children?.some((item) =>
              item?.children[0].ID.startsWith("functionality_")
            )
          ) {
            let foundCount = 0;
            for (const element of activeFunctionalities) {
              if (element.ParentID === it.ID) {
                foundCount++;
              }
            }
            if (foundCount > 0) {
              // nodes that include funcs and nodes (funcs are active)
              itemsRef[it.ID] = `${key}. ${ds}`;
              ct.push(
                <StyledTreeItem
                  sx={{ fontSize: 14, color: "#1A73E8" }}
                  key={it.ID}
                  nodeId={it.ID}
                  label={
                    `${key}. ${ds} - ` + foundCount + " / " + it.children.length
                  }
                >
                  {buildTree(key, it.children)}{" "}
                </StyledTreeItem>
              );
            } else {
              // nodes that include funcs and nodes (funcs are inactive)
              itemsRef[it.ID] = `${key}. ${ds}`;
              ct.push(
                <StyledTreeItem
                  sx={{ fontSize: 14, color: "#6e6f70" }}
                  key={it.ID}
                  nodeId={it.ID}
                  label={`${key}. ${ds}`}
                >
                  {buildTree(key, it.children)}{" "}
                </StyledTreeItem>
              );
            }
          } else {
            // navigation nodes, no immediate funcs / handle find children
            // flag will show if this nav node, have any children with active funcs
            let flag = false;
            let children = it.children;
            while (children !== undefined) {
              for (const element of activeFunctionalities) {
                if (
                  children.some(item=>item.ID == element.ParentID) ||
                  element.ParentID === children[0].children[0].children[0].ID ||
                  element.ParentID === children[0].children[0].ID ||
                  element.ParentID === children[0].ID
                ) {
                  flag = true;
                }
              }
              children = children.children;
            }

            if (flag) {
              itemsRef[it.ID] = `${key}. ${ds}`;
              ct.push(
                <StyledTreeItem
                  sx={{ fontSize: 14, color: "#1A73E8" }}
                  key={it.ID}
                  nodeId={it.ID}
                  label={`${key}. ${ds}`}
                >
                  {" "}
                  {buildTree(key, it.children)}{" "}
                </StyledTreeItem>
              );
            } else {
              itemsRef[it.ID] = `${key}. ${ds}`;
              ct.push(
                <StyledTreeItem
                  sx={{ fontSize: 14, color: "#6e6f70" }}
                  key={it.ID}
                  nodeId={it.ID}
                  label={`${key}. ${ds}`}
                >
                  {" "}
                  {buildTree(key, it.children)}{" "}
                </StyledTreeItem>
              );
            }
          }
        }
      }
      return ct;
    }
    return false;
  };

  const findParents = (arr, entityId) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].ID === entityId) {
        return arr[i].children;
      } else if (arr[i].children && arr[i].children.length) {
        const t = findParents(arr[i].children, entityId);
        if (t !== false) {
          return t;
        }
      }
    }
    return false;
  };

  const getParent = (arr, entityId) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].ID === entityId) {
        return arr[i].ParentID;
      } else if (arr[i].children && arr[i].children.length) {
        const t = getParent(arr[i].children, entityId);
        if (t !== false) {
          return t;
        }
      }
    }
    return false;
  };

  const getArrayById = (arr, entityId) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].ID === entityId) {
        return arr[i];
      } else if (arr[i].children && arr[i].children.length) {
        const t = getArrayById(arr[i].children, entityId);
        if (t !== false) {
          return t;
        }
      }
    }
    return false;
  };

  async function actionToggle(event, nodeId) {
    var ex = [];
    var upd = [];
    nodeId.forEach((item, i) => {
      if (!expC.includes(item)) upd.push(item);
    });

    ex = ex.concat(upd);
    var r = getArrayById(parentList, nodeId[0]);
    if (r.children !== undefined) {
      r.children.forEach((item, i) => {
        ex.push(item.ID);
        if (item.children !== undefined) {
          item.children.forEach((item2, i2) => {
            ex.push(item2.ID);
            if (item2.children !== undefined) {
              item2.children.forEach((item3, i3) => {
                ex.push(item3.ID);
              });
            }
          });
        }
      });
    }



    setExpC(ex);
  }

  function action(event, nodeId) {
    loadFunctionalities(nodeId, itemsRef[nodeId]);
    setTrackClick(true)
    var r = getArrayById(parentList, nodeId);

    if (r.children !== undefined) {
      let ex = [];
      if (r.children[0].children[0].ID.startsWith("functionality_")) {
        //alert('a');

        while ((nodeId = getParent(parentList, nodeId))) {
          ex.push(nodeId);
        }
      } else {
        ex.push(nodeId);
        while ((nodeId = getParent(parentList, nodeId))) {
          ex.push(nodeId);
        }
      }

      //get parenti
      var a = getArrayById(parentList, ex[ex.length - 1]);
      if (a.children !== undefined) {
        a.children.forEach((item, i) => {
          item.children.forEach((item2, i2) => {
            ex.push(item2.ID);
            if (item2.children !== undefined) {
              item2.children.forEach((item3, i3) => {
                ex.push(item3.ID);
              });
            }
          });
          ex.push(item.ID);
        });
      }
      setExpC(ex);
    }
  }

  const theme = createTheme({
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "0.5rem",
          },
        },
      },
    },
  });

  useEffect(() => {
    if (treeid !== undefined && !trackClick) {
      loadFunctionalities(treeid, itemsRef[treeid]);
    }
  }, [itemsRef]);

  return (
    <Card>
      <MDBox mt={2} mb={3}>
        {parentList !== null && activeFunctionalities ? (
          <ThemeProvider theme={theme}>
            <TreeView
              aria-label="customized"
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              defaultEndIcon={<CloseSquare />}
              sx={{ flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
              onNodeToggle={actionToggle}
              onNodeSelect={action}
              expanded={expC}
            >
              {buildTree(0, parentList)}
            </TreeView>{" "}
          </ThemeProvider>
        ) : (
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ p: 18 }}
          >
            <CircularProgress />
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}
Sidenav.propTypes = {
  loadFunctionalities: PropTypes.func.isRequired,
};

export default Sidenav;
