
import MDButton from "components/MDButton";


export default {
  columns: [
   { Header: "ID", accessor: "ID", width: "5%" },
    { Header: "Title", accessor: "Title", width: "15%"},
  { Header: "Description", accessor: "Description", isSorted:true,isSortedDesc:'asc',width: "60%"},

    { Header: "Implemented By", accessor: "count" ,width: "15%"},
  { Header: "Implemented By Rest of the World ", accessor: "OutOfGroup" ,width: "15%"},
  ],

  rows: [
  ],
};
