/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState,useContext,useEffect } from "react";
import { Route, Navigate, useLocation,useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import './index.css'
// import icons
import AppleIcon from '@mui/icons-material/Apple';
import ComputerIcon from '@mui/icons-material/Computer';
import AndroidIcon from '@mui/icons-material/Android';
import WatchIcon from '@mui/icons-material/Watch';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Link from "@mui/material/Link";
import {OpenInNew, ReadMore} from "@mui/icons-material";
// Material Dashboard 2 PRO React example components
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import Footer from "scientia/Footer";
import DataTable from "./DataTable";
import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';
import CircularProgress from '@mui/material/CircularProgress';
// Data
import dataTableData from "./data/dataTableData";
import org_dataTableData from "./data/org_dataTableData";
import Grid from "@mui/material/Grid";

function AvailableBanks() {

  const userContext = useContext(UserContext);
  const [isLoading,setIsLoading]=useState(true);
  const [isLoadingOrg,setIsLoadingOrg]=useState(true);
   const navigate = useNavigate();
  const options = {
    sort: true,
    sortOrder: { name: 'BankName', direction: 'asc' },
  };
   function doAction_goWeb(bankid) {
  //   console.log(bankid);
     let path = '/analyzed/bank/'+bankid+'/16';
     navigate(path);

  }
   function doAction_goIos(bankid) {

     let path = '/analyzed/bank/'+bankid+'/22';
     navigate(path);
  }

//   function doAction_goAndroid(bankid) {

//     let path = '/analyzed/bank/'+bankid+'/23';
//     navigate(path);
//  }

 function doAction_goAppleWatch(bankid) {

  let path = '/analyzed/bank/'+bankid+'/27';
  navigate(path);
}

  async function doAction_getAnalyzedBanks() {




    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/node_getAnalyzedBanks";
      // console.log("EDW ->")
      // console.log(apiURL);
     const metadataResponse = await fetch(apiURL, {
       method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });

     var resp = await metadataResponse.json();
    //  console.log(resp);
    var d={};
      resp.forEach(function(v,index) {
        if (d[v.BankID]===undefined) {
          d[v.BankID]={};
        }
        d[v.BankID]['BankID']=v.BankID;
        d[v.BankID]['BankName']=v.BankName;
            d[v.BankID]['ImageURL']=v.BankName;
        if (d[v.BankID]['web']===undefined) {
          d[v.BankID]['web']="";
        }
        if (d[v.BankID]['ios']===undefined) {
          d[v.BankID]['ios']="";
        }
        // if (d[v.BankID]['android']===undefined) {
        //   d[v.BankID]['android']="";
        // }
        if (d[v.BankID]['apple_watch']===undefined) {
          d[v.BankID]['apple_watch']="";
        }

        if (v.MediaID=="16") {
            d[v.BankID]['web']=<>
            <MDButton
             component={Link}
             onClick={ ()=> doAction_goWeb(v.BankID)}
             rel="noreferrer"
             size=""
             color="dark"
             themeType="outline"
             >
          <ComputerIcon/>
         </MDButton>
         </>;
        }
        if (v.MediaID=="22") {
            d[v.BankID]['ios']=<>
            <MDButton
             component={Link}

             onClick={ ()=> doAction_goIos(v.BankID)}
             rel="noreferrer"
             size=""
             color="dark"
             >
          <AppleIcon />
         </MDButton>
         </>;
        }
      //   if (v.MediaID=="23") {
      //     d[v.BankID]['android']=<>
      //     <MDButton
      //      component={Link}

      //      onClick={ ()=> doAction_goAndroid(v.BankID)}
      //      rel="noreferrer"
      //      size=""
      //      color="dark"
      //      >
      //   <AndroidIcon/>
      //  </MDButton>
      //  </>;
      // }

      if (v.MediaID=="27") {
        d[v.BankID]['apple_watch']=<>
        <MDButton
         component={Link}

         onClick={ ()=> doAction_goAppleWatch(v.BankID)}
         rel="noreferrer"
         size=""
         color="dark"
         >
      <WatchIcon/>
     </MDButton>
     </>;
    }

        d[v.BankID]['img']=<><img src={v.ImageURL} width={50}/></>
      });
      // console.log(d);
      var r=[];
      for(var row in d) {
        r.push(d[row]);
      };

      // console.log(r);
     org_dataTableData.rows=r;
    //  console.log(org_dataTableData);
     setIsLoadingOrg(false)

  }


  useEffect(()=>{
  doAction_getAnalyzedBanks();

 }, []) // <-- empty dependency array

  return (
      <MDBox pt={3} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Available Banks
              </MDTypography>
              <MDTypography variant="button" color="text">
              Check out available banks
              </MDTypography>
            </MDBox>
            {isLoadingOrg===false?(
              <>
                <DataTable table={org_dataTableData} canSearch={true} isSorted={true}  options={options}/>
              </>
            ):(
              <>
              <MDBox
              sx={{p:3}}
              display="flex"
              justifyContent="center"
              alignItems="center"
              >
              <CircularProgress/>
              </MDBox>
              </>

          )}
          </Card>
        </MDBox>

      </MDBox>

  );
}

export default AvailableBanks;
