import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const FuncBucket = ({
  title,
  emptyBucketSelection,
  bucketSelection,
  handleSelection,
}) => {
  return (
    <MDBox
      sx={{
        border: "1px solid #4A59FF",
        marginBottom: "14px",
        backgroundColor: "#FEFEFE",
        borderRadius: "10px",
      }}
    >
      {/* Header */}
      <MDBox
        sx={{
          display: "flex",
          margin: "14px 24px 8px 14px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MDTypography
          sx={{
            fontSize: "12px",
            lineHeight: "16px",
            color: "#090324",
            cursor: "default",
          }}
        >
          {title}
        </MDTypography>
        <MDTypography
          sx={{
            color: "#4A59FF",
            cursor: "pointer",
            fontSize: "12px",
            lineHeight: "16px",
          }}
          onClick={emptyBucketSelection}
        >
          Remove All
        </MDTypography>
      </MDBox>

      {/* scroll container */}
      <div className="tag-parent">
        {bucketSelection.map((i) => {
          if (i?.children?.length > 0)
            return (
              <div key={i.title}>
                <MDTypography
                  sx={{
                    color: "#9093B5",
                    fontSize: "12px",
                    marginLeft: "14px",
                  }}
                >
                  {i.title}
                </MDTypography>
                <div className="tags-container">
                  {i.children.map((e) => (
                    <div
                      className="tag"
                      onClick={() => handleSelection(i.title, e)}
                    >
                      {e.DisplayName}
                    </div>
                  ))}
                </div>
              </div>
            );
        })}
      </div>
    </MDBox>
  );
};

export default FuncBucket;
