import Card from "@mui/material/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext, useEffect, useRef } from "react";
import UserContext from "context/UserContext";
import { func } from "prop-types";
import ChartsHeader from "../ChartsHeader";
import { Pagination } from "@mui/material";
import gif from "assets/images/marketwebp.webp";

export default function SuperChart2({ loadPopup, mediaid, workspaceMedia }) {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMediaID, setSelectedMediaID] = useState(mediaid);
  const [selectedExclude, setSelectedExclude] = useState(false);
  const [superChartLoading, setsuperChartLoading] = useState(true);
  const [options, setOptions] = useState(null);
  const [seed, setSeed] = useState(1);
  const [banksPagination, setBanksPagination] = useState([]);
  const chartRef = useRef();
  const [toolTipInfo, setToolTipInfo] = useState(
    "The Functionalities by Bank graph shows the number of functionalities each bank/fintech has implemented for any given functionality section. The length of the coloured bar of each functionality section shows the percentage that this functionality section occupies compared to the total available functionalities sections offered by that bank/fintech. This means that the longer the coloured bar, the more functionalities have been implemented in that specific section for the selected bank/fintech and vice versa. Thus, one can easy understand where a bank/fintech has invested the most (functionality wise), where there is room for improvement or which are the new functionality sections that can be further explored by the bank/fintech."
  );

  const reset = () => {
    var x = Math.random() * 100;
    setSeed(x);
  };
  // const [screenwidth, setScreenWidth] = useState(1560);

  const hashCode = (s) =>
    s.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);

  const loadchart = async () => {
    setsuperChartLoading(true);
    let mydata = {};
    if (selectedMediaID !== undefined) {
      mydata["mediaid"] = selectedMediaID;
    } else {
      mydata["mediaid"] = "22";
    }

    var data2 = {};
    data2["mediaid"] = selectedMediaID;
    data2["exclude"] = selectedExclude;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_superchartv2";
    const metadataResponse2 = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data2),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse2.json();

    var mybanks = resp.mybanks;
    setBanksPagination(mybanks);

    var objects = [];

    var j = 0;
    resp.correct_data_format.map((i) => {
      let object = {
        name: i.DisplayName,
        color: "#" + Math.floor(Math.random() * 16777215).toString(16),
        data: i.data,
        data_more: i.data_more,
      };

      objects.push(object);
      j++;
    });

    setOptions({
      chart: {
        type: "bar",
        height: 850,
      },

      title: {
        text: "",
      },
      xAxis: {
        // softMin:20,
        // softMax:20,
        categories: mybanks,
        // lineWidth:10,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Functionalities",
        },
      },
      exporting: {
        buttons: false,
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        bar: {
          stacking: "percent",
        },
        series: {
          stacking: "percent",
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                //    chart_functionalities_overview_forBankMedia(fc[this.category]);

                let d = this.series.userOptions;
                var bid;
                var treeid;
                d.data_more.map((i) => {
                  if (i.BankName) {
                    if (i.BankName === this.category) {
                      bid = i.BankID;
                      treeid = i.treeid;
                    }
                  }
                });

                if (selectedExclude == true) {
                  loadPopup(
                    selectedMediaID,
                    "7",
                    treeid,
                    "" + bid,
                    "6",
                    "1",
                    "Functionalities by Institution/Bank",
                    "Identify which exact features fall under each category by the institution of interest and how they are implemented, captured in video and UX evaluated.",
                    gif
                  );
                } else {
                  loadPopup(
                    selectedMediaID,
                    "1",
                    treeid,
                    "" + bid,
                    "6",
                    "1",
                    "Functionalities by Institution/Bank",
                    "Identify which exact features fall under each category by the institution of interest and how they are implemented, captured in video and UX evaluated.",
                    gif
                  );
                }
                // loadPopup(selectedMediaID,"1",fc[this.category],bankid);
              },
            },
          },
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> Functionalities ({point.percentage:.0f}%)<br/>',
        shared: true,
        backgroundColor: "white",
      },
      series: objects,
    });

    setsuperChartLoading(false);

    // start pagination if there are more banks than PER_PAGE
    if (Math.ceil(mybanks.length / PER_PAGE) > 1) {
      chartRef?.current?.chart?.xAxis[0]?.setExtremes(0, PER_PAGE);
    }
  };

  useEffect(() => {
    loadchart();
  }, []);

  useEffect(() => {
    loadchart();
    setPage(1);
  }, [selectedMediaID]);

  // const handleMedia = (newmedia) => {
  //   setSelectedMediaID(newmedia);
  // };

  async function setUserWorkspaceState(newmedia) {
    let data = {};
    var newState = workspaceMedia;
    newState.superchart2 = newmedia;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  const handleMedia = (mediaid) => {
    // setUserWorkspaceState(mediaid)
    setUserWorkspaceState(mediaid);
    setSelectedMediaID(mediaid);
    // getDashboards()
  };

  // pagination
  const [page, setPage] = useState(1);
  const PER_PAGE = 9;
  const count = Math.ceil(banksPagination.length / PER_PAGE);

  const handlePagination = (e, p) => {
    setPage(p);
    let begin = (p - 1) * PER_PAGE;
    let end = begin + PER_PAGE;
    chartRef?.current?.chart?.xAxis[0]?.setExtremes(begin, end);
  };

  return (
    <Card key={seed}>
      <MDBox p={1}>
        <ChartsHeader
          title="Functionalities by Bank"
          handleMedia={handleMedia}
          toolTipInfo={toolTipInfo}
          selectedMediaID={selectedMediaID}
          chartRef={chartRef}
        />

        {setOptions !== null && !superChartLoading ? (
          <>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartRef}
            />
            {count > 1 && (
              <MDBox
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  onChange={handlePagination}
                />
              </MDBox>
            )}
          </>
        ) : (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 15,
            }}
          >
            <CircularProgress />
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}
