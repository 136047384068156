import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 1,
    width: 300,
    menuPaper: {
    maxHeight: 100
  },

  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      minHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    },
    sx: {
      minHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};



export { useStyles, MenuProps };
