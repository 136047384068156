import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MDTypography from "components/MDTypography";
import BucketInactive from "../components/bucketInactive";
import PrimaryButton from "scientia/New Design/Button/PrimaryButton";
import PrevButton from "scientia/New Design/Button/PrevButton";

const View21 = ({
  setInnerStep,
  bucketSelection,
  bucketSelectionA,
  handleNextStep,
  handlePreviousStep,
}) => {
  return (
    <>
      <MDBox sx={{ marginTop: "48px" }}>
        {bucketSelectionA.some((item) => item.banks.length > 0) && (
          <>
            <MDBox
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <MDTypography
                sx={{
                  color: "#090324",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Select Primary Banks
              </MDTypography>
              {/* <Tooltip
                title="Add either your Organisation or the banks/fintechs you want to compare against with the Target market."
                sx={{ cursor: "pointer" }}
              >
                <HelpOutlineIcon fontSize="small" />
              </Tooltip> */}
            </MDBox>
            <BucketInactive bucketSelection={bucketSelectionA} />
          </>
        )}
        <MDBox
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            marginBottom: "12px",
          }}
        >
          <MDTypography
            sx={{
              color: "#090324",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Select Target Market
          </MDTypography>
          {/* <Tooltip
            title="Add the banks/fintechs that you want to compare against with the Primary."
            sx={{ cursor: "pointer" }}
          >
            <HelpOutlineIcon fontSize="small" />
          </Tooltip> */}
        </MDBox>

        {bucketSelection.some((item) => item.banks.length > 0) ? (
          <BucketInactive
            onClick={() => setInnerStep(2)}
            bucketSelection={bucketSelection}
          />
        ) : (
          <MDTypography
            onClick={() => setInnerStep(2)}
            sx={{
              padding: "14px 24px",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#9093B5",
              paddingBottom: "14px",
              backgroundColor: "#FEFEFE",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          >
            Select Banks
          </MDTypography>
        )}
      </MDBox>
      <MDBox sx={{ marginTop: "32px" }}>
        <PrimaryButton onClick={handleNextStep} fontWeight="bold">
          Continue
        </PrimaryButton>

        <PrevButton onClick={handlePreviousStep}>
          Back to Previous Step
        </PrevButton>
      </MDBox>
    </>
  );
};

export default View21;
