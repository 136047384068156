/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useState,useEffect,useContext} from 'react';
import { useParams,useLocation } from 'react-router';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Link from "@mui/material/Link";
import VideocamIcon from '@mui/icons-material/Videocam';
import DeleteIcon from '@mui/icons-material/Delete';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "layouts/pages/account/components/FormField";
// Invoice page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';
import CircularProgress from '@mui/material/CircularProgress';
// Images
import logoCT from "assets/images/logo-ct.png";
import logoCTDark from "assets/images/logo-ct-dark.png";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { DefaultPlayer as Video } from 'react-html5video';
import 'assets/css/video.css';
import AppleIcon from '@mui/icons-material/Apple';
import ComputerIcon from '@mui/icons-material/Computer';
import AndroidIcon from '@mui/icons-material/Android';
import WatchIcon from '@mui/icons-material/Watch';



function UserRoadmap() {
  const userContext = useContext(UserContext);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [data,setData]=useState(null);
  const [favorites, setFavorites] = useState(null)
  const [rows, setRows] = useState([])
  const [seed, setSeed] = useState(1);
       const reset = () => {
            setSeed(Math.random());
        }
 

  const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
      `${borderWidth[1]} solid ${light.main}`,
  };

  async function node_removeFromFavorites(mediaid,nodeid,bankid) {
    const data={};
    data['mediaid']=mediaid;
    data['bankid']=bankid;
    data['functionality_id']=nodeid;
    data['uc_id']=0;
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/removeFromRoadmap";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });

     var resp = await metadataResponse.json();
  }

  //get favorites
  async function node_getFavorites() {
    const data={};

    // console.log("enter get favorites")
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/getRoadmap";
     const metadataResponse = await fetch(apiURL, {
       method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });
     var resp = await metadataResponse.json();
    //  console.log("api called")
     var ret=[];
    //  console.log(resp);
     setFavorites(resp);
     console.log(resp)
   
      
   

    
}

const years = [2023, 2024, 2025]
const quarters = ['1','2','3','4']

async function handleremove(media,functionality, bank){
  // console.log(media, functionality,bank)
  await node_removeFromFavorites(media, functionality,bank).then(console.log("deleted"))
  node_getFavorites();
  reset()
}

 
const handleShare = (id, mediaid,parentid,funcid)=>{
  const link = window.location.origin+'/analyzed/bank/'+id+'/'+mediaid+'/'+parentid+'/'+funcid
  navigator.clipboard.writeText(link).then(function() {
    // console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    // console.error('Async: Could not copy text: ', err);
  });
  window.location.href = link
}
 
  useEffect(()=>{
    node_getFavorites();
  // doAction_getUserHistory();
  }, []) // <-- empty dependency array



  return (
    <DashboardLayout>
      <DashboardNavbar />



      <MDBox mb={2} mt={3}>
      <Timeline position="alternate">
       {/* {data===null? */}
       {null!==null?

      //  may result in crash?
       <MDBox display="flex" alignItems="center" justifyContent="center">
       <CircularProgress/>
       </MDBox>

       :<>

          
         {/* {data.map((record) =>(

           <>{JSON.stringify(record)}</>

         ))} */}

<MDBox mt={{ xs: 4, md: 0 }} mb={{ xs: 4, md: 8 }} key={seed}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={8}>

            <MDTypography variant="h3" sx={{p:3, display:'flex', justifyContent:'center'}}>
              Build your Roadmap
            </MDTypography>
            
            <Card>
              <MDBox sx={{p:2}}>
                    <MDTypography variant='h5' sx={{pl:2, pt:1, pd:4}}>
                      2022 Q4
                    </MDTypography>
                    <Table sx={{ minWidth: "32rem" }}>
                          <MDBox component="thead">
                            <TableRow>
                              <MDBox
                                component="th"
                                width={{ xs: "25%", md: "30%" }}
                                py={1.5}
                                px={1}
                                textAlign="left"
                                sx={borderBottom}
                              >
                                <MDTypography variant="h6" color="text" fontWeight="medium">
                                  Functionality Name
                                </MDTypography>
                              </MDBox>
                              <MDBox
                                component="th"
                                width={{ xs: "15%", md: "20%" }}
                                py={1.5}
                                pl={3}
                                pr={1}
                                textAlign="left"
                                sx={borderBottom}
                              >
                                <MDTypography variant="h6" color="text" fontWeight="medium">
                                  Functionality ID
                                </MDTypography>
                              </MDBox>
                              <MDBox
                                component="th"
                                py={1.5}
                                pl={3}
                                pr={1}
                                textAlign="left"
                                sx={borderBottom}
                              >
                                <MDTypography variant="h6" color="text" fontWeight="medium">
                                  Description
                                </MDTypography>
                              </MDBox>
                              <MDBox
                                component="th"
                                width={{ xs: "10%", md: "15%" }}
                                py={1.5}
                                pl={3}
                                pr={1}
                                textAlign="left"
                                sx={borderBottom}
                              >
                                <MDTypography variant="h6" color="text" fontWeight="medium">
                                  Media
                                </MDTypography>
                              </MDBox>
                              <MDBox
                                component="th"
                                width={{ xs: "5%", md: "10%" }}
                                py={1.5}
                                pl={3}
                                pr={1}
                                textAlign="left"
                                sx={borderBottom}
                              >
                                <MDTypography variant="h6" color="text" fontWeight="medium">
                                  Actions
                                </MDTypography>
                              </MDBox>
                            </TableRow>
                          </MDBox>
                          <TableBody>
                      {favorites!==null?
                              
                          
                            favorites.map((el)=>{
                            if(el.Year==2022&&el.Quarter=='4'){
                              return(
                                <>
                                <TableRow key={el._id}>
                                <MDBox component="td" textAlign="left" p={1} sx={borderBottom}>
                                <MDTypography variant="body2" color="text" fontWeight="regular">
                            {el.node[0].DisplayName}
                            </MDTypography>
                            </MDBox>
                            <MDBox
                            component="td"
                            textAlign="left"
                            py={1}
                            pr={1}
                            pl={3}
                            sx={borderBottom}
                            >
                            <MDTypography variant="body2" color="text" fontWeight="regular">
                            {el.FunctionalityID}
                            </MDTypography>
                            </MDBox>
                            <MDBox
                            component="td"
                            textAlign="left"
                            py={1}
                            pr={1}
                            pl={3}
                            sx={borderBottom}
                            >
                            <MDTypography variant="body2" color="text" fontWeight="regular">
                            {el.node[0].Description}
                            </MDTypography>
                            </MDBox>
                            <MDBox
                            component="td"
                            textAlign="left"
                            py={1}
                            pr={1}
                            pl={3}
                            sx={borderBottom}
                            >
                            <MDTypography variant="body2" color="text" fontWeight="regular">
                            {el.MediaID=="16"? <>
                              <MDButton
                              title="View Functionality"
                              component={Link}
                              onClick={ ()=> handleShare(el.node[0].BankID, el.MediaID,el.node[0].ParentID,el.FunctionalityID)}
                              rel="noreferrer"
                              size=""
                              color="dark"
                              themeType="outline"
                              >
                                <ComputerIcon/>
                          </MDButton>
                          </>:<></>}
                            
                            {(el.MediaID=="22")?
                                <>
                                <MDButton
                                 title="View Functionality"  
                                component={Link}
                                onClick={ ()=> handleShare(el.node[0].BankID, el.MediaID,el.node[0].ParentID,el.FunctionalityID)}
                                rel="noreferrer"
                                size=""
                                color="dark"
                                >
                              <AppleIcon />
                            </MDButton>
                            </>:<></>
                            }

                            {(el.MediaID=="27")?
                                <>
                                <MDButton
                                title="View Functionality"

                                component={Link}
                                // onClick={ ()=> doAction_goIos(el.BankID)}
                                rel="noreferrer"
                                size=""
                                color="dark"
                                >
                              <WatchIcon />
                            </MDButton>
                            </>:<></>
                            }

              
                            </MDTypography>
                            </MDBox>
                            <MDBox
                            title="Remove from Roadmap"
                            component="td"
                            textAlign="left"
                            py={1}
                            pr={1}
                            pl={3}
                            sx={borderBottom}
                            >
                            <MDTypography variant="body2" color="text" fontWeight="regular">
                          
                            <DeleteIcon
                            color="info"
                            fontSize="medium"
                            sx={{cursor:'pointer'}}
                            onClick={()=>handleremove(el.MediaID, el.FunctionalityID, el.BankID)}
                            />
                          
                            </MDTypography>
                            </MDBox>
                            </TableRow>
                            </>
                          )
                        }}

                          
                          )
                          
                            
                            :<>
                      <MDBox display="flex" alignItems="center" justifyContent="center">
                      <CircularProgress/>
                      </MDBox>
                      </>}

                          </TableBody>
                          </Table>

              </MDBox>
            </Card>

                {quarters.map((i)=>{
                  return (
                    <MDBox sx={{mt:6}}>
                    <Card>
                    <MDBox sx={{p:2}}>

                      <MDTypography variant='h5' sx={{pl:2, pt:1, pd:4}}>
                      2023 Q{i}

                      </MDTypography>
                      <Table sx={{ minWidth: "32rem" }}>
                    <MDBox component="thead">
                      <TableRow>
                        <MDBox
                          component="th"
                          width={{ xs: "25%", md: "30%" }}
                          py={1.5}
                          px={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography variant="h6" color="text" fontWeight="medium">
                            Functionality Name
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="th"
                          width={{ xs: "15%", md: "20%" }}
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography variant="h6" color="text" fontWeight="medium">
                            Functionality ID
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="th"
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography variant="h6" color="text" fontWeight="medium">
                          Description
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="th"
                          width={{ xs: "10%", md: "15%" }}
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography variant="h6" color="text" fontWeight="medium">
                          Media
                                </MDTypography>
                              </MDBox>
                              <MDBox
                                component="th"
                                width={{ xs: "5%", md: "10%" }}
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography variant="h6" color="text" fontWeight="medium">
                            Actions
                          </MDTypography>
                        </MDBox>
                      </TableRow>
                    </MDBox>
                    <TableBody>
                {favorites!==null?
                        
                    
                      favorites.map((el)=>{
                      if(el.Year==2023&&el.Quarter==i){
                        return(
                          <>
                          <TableRow key={el._id}>
                          <MDBox component="td" textAlign="left" p={1} sx={borderBottom}>
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                      {el.node[0].DisplayName}
                      </MDTypography>
                      </MDBox>
                      <MDBox
                      component="td"
                      textAlign="left"
                      py={1}
                      pr={1}
                      pl={3}
                      sx={borderBottom}
                      >
                      <MDTypography variant="body2" color="text" fontWeight="regular">
                      {el.FunctionalityID}
                      </MDTypography>
                      </MDBox>
                      <MDBox
                      component="td"
                      textAlign="left"
                      py={1}
                      pr={1}
                      pl={3}
                      sx={borderBottom}
                      >
                      <MDTypography variant="body2" color="text" fontWeight="regular">
                      {el.node[0].Description}
                      </MDTypography>
                      </MDBox>
                      <MDBox
                      component="td"
                      textAlign="left"
                      py={1}
                      pr={1}
                      pl={3}
                      sx={borderBottom}
                      >
                      <MDTypography variant="body2" color="text" fontWeight="regular">
                      {el.MediaID=="16"? <>
                        <MDButton
                        component={Link}
                        onClick={ ()=> handleShare(el.node[0].BankID, el.MediaID,el.node[0].ParentID,el.FunctionalityID)}
                        rel="noreferrer"
                        size=""
                        color="dark"
                        themeType="outline"
                        >
                          <ComputerIcon/>
                    </MDButton>
                    </>:<></>}
                      
                      {(el.MediaID=="22")?
                          <>
                          <MDButton
                          component={Link}
                          onClick={ ()=> handleShare(el.node[0].BankID, el.MediaID,el.node[0].ParentID,el.FunctionalityID)}
                          rel="noreferrer"
                          size=""
                          color="dark"
                          >
                        <AppleIcon />
                      </MDButton>
                      </>:<></>
                      }

                      {(el.MediaID=="27")?
                          <>
                          <MDButton
                          component={Link}
                          // onClick={ ()=> doAction_goIos(el.BankID)}
                          rel="noreferrer"
                          size=""
                          color="dark"
                          >
                        <WatchIcon />
                      </MDButton>
                      </>:<></>
                      }

         
                      </MDTypography>
                      </MDBox>
                      <MDBox
                      component="td"
                      textAlign="left"
                      py={1}
                      pr={1}
                      pl={3}
                      sx={borderBottom}
                      >
                      <MDTypography variant="body2" color="text" fontWeight="regular">
                     
                      <DeleteIcon
                      color="info"
                      fontSize="medium"
                      sx={{cursor:'pointer'}}
                      onClick={()=>handleremove(el.MediaID, el.FunctionalityID, el.BankID)}
                      />
                    
                      </MDTypography>
                      </MDBox>
                      </TableRow>
                      </>
                    )
                  }}

                    
                    )
                    
                      
                      :<>
                <MDBox display="flex" alignItems="center" justifyContent="center">
                <CircularProgress/>
                </MDBox>
                </>}

                    </TableBody>
                    </Table>
                    </MDBox>

                    </Card>
                  </MDBox>
                  )
                })}

                {quarters.map((i)=>{
                  return (
                    <MDBox sx={{mt:6}}>
                    <Card>
                    <MDBox sx={{p:2}}>
                      <MDTypography variant='h5' sx={{pl:2, pt:1, pd:4}}>
                      2024 Q{i}
                      </MDTypography>
                      <Table sx={{ minWidth: "32rem" }}>
                    <MDBox component="thead">
                      <TableRow>
                        <MDBox
                          component="th"
                          width={{ xs: "25%", md: "30%" }}
                          py={1.5}
                          px={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography variant="h6" color="text" fontWeight="medium">
                            Functionality Name
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="th"
                          width={{ xs: "15%", md: "20%" }}
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography variant="h6" color="text" fontWeight="medium">
                            Functionality ID
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="th"
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography variant="h6" color="text" fontWeight="medium">
                          Description
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="th"
                          width={{ xs: "10%", md: "15%" }}
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography variant="h6" color="text" fontWeight="medium">
                          Media
                                </MDTypography>
                              </MDBox>
                              <MDBox
                                component="th"
                                width={{ xs: "5%", md: "10%" }}
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography variant="h6" color="text" fontWeight="medium">
                            Actions
                          </MDTypography>
                        </MDBox>
                      </TableRow>
                    </MDBox>
                    <TableBody>
                {favorites!==null?
                        
                    
                      favorites.map((el)=>{
                      if(el.Year==2024&&el.Quarter==i){
                        return(
                          <>
                          <TableRow key={el._id}>
                          <MDBox component="td" textAlign="left" p={1} sx={borderBottom}>
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                      {el.node[0].DisplayName}
                      </MDTypography>
                      </MDBox>
                      <MDBox
                      component="td"
                      textAlign="left"
                      py={1}
                      pr={1}
                      pl={3}
                      sx={borderBottom}
                      >
                      <MDTypography variant="body2" color="text" fontWeight="regular">
                      {el.FunctionalityID}
                      </MDTypography>
                      </MDBox>
                      <MDBox
                      component="td"
                      textAlign="left"
                      py={1}
                      pr={1}
                      pl={3}
                      sx={borderBottom}
                      >
                      <MDTypography variant="body2" color="text" fontWeight="regular">
                      {el.node[0].Description}
                      </MDTypography>
                      </MDBox>
                      <MDBox
                      component="td"
                      textAlign="left"
                      py={1}
                      pr={1}
                      pl={3}
                      sx={borderBottom}
                      >
                      <MDTypography variant="body2" color="text" fontWeight="regular">
                      {el.MediaID=="16"? <>
                        <MDButton
                        component={Link}
                        onClick={ ()=> handleShare(el.node[0].BankID, el.MediaID,el.node[0].ParentID,el.FunctionalityID)}
                        rel="noreferrer"
                        size=""
                        color="dark"
                        themeType="outline"
                        >
                          <ComputerIcon/>
                    </MDButton>
                    </>:<></>}
                      
                      {(el.MediaID=="22")?
                          <>
                          <MDButton
                          component={Link}
                          onClick={ ()=> handleShare(el.node[0].BankID, el.MediaID,el.node[0].ParentID,el.FunctionalityID)}
                          rel="noreferrer"
                          size=""
                          color="dark"
                          >
                        <AppleIcon />
                      </MDButton>
                      </>:<></>
                      }

                      {(el.MediaID=="27")?
                          <>
                          <MDButton
                          component={Link}
                          // onClick={ ()=> doAction_goIos(el.BankID)}
                          rel="noreferrer"
                          size=""
                          color="dark"
                          >
                        <WatchIcon />
                      </MDButton>
                      </>:<></>
                      }

         
                      </MDTypography>
                      </MDBox>
                      <MDBox
                      component="td"
                      textAlign="left"
                      py={1}
                      pr={1}
                      pl={3}
                      sx={borderBottom}
                      >
                      <MDTypography variant="body2" color="text" fontWeight="regular">
                     
                      <DeleteIcon
                      color="info"
                      fontSize="medium"
                      sx={{cursor:'pointer'}}
                      onClick={()=>handleremove(el.MediaID, el.FunctionalityID, el.BankID)}
                      />
                    
                      </MDTypography>
                      </MDBox>
                      </TableRow>
                      </>
                    )
                  }}

                    
                    )
                    
                      
                      :<>
                <MDBox display="flex" alignItems="center" justifyContent="center">
                <CircularProgress/>
                </MDBox>
                </>}

                    </TableBody>
                    </Table>
                    </MDBox>
                    </Card>
                  </MDBox>
                  )
                })}

                {quarters.map((i)=>{
                                  return (
                                    <MDBox sx={{mt:6}}>
                                    <Card>
                                    <MDBox sx={{p:2}}>
                                      <MDTypography variant='h5' sx={{pl:2, pt:1, pd:4}}>
                                      2025 Q{i}
                                      </MDTypography>
                                      <Table sx={{ minWidth: "32rem" }}>
                                    <MDBox component="thead">
                                      <TableRow>
                                        <MDBox
                                          component="th"
                                          width={{ xs: "25%", md: "30%" }}
                                          py={1.5}
                                          px={1}
                                          textAlign="left"
                                          sx={borderBottom}
                                        >
                                          <MDTypography variant="h6" color="text" fontWeight="medium">
                                            Functionality Name
                                          </MDTypography>
                                        </MDBox>
                                        <MDBox
                                          component="th"
                                          width={{ xs: "15%", md: "20%" }}
                                          py={1.5}
                                          pl={3}
                                          pr={1}
                                          textAlign="left"
                                          sx={borderBottom}
                                        >
                                          <MDTypography variant="h6" color="text" fontWeight="medium">
                                            Functionality ID
                                          </MDTypography>
                                        </MDBox>
                                        <MDBox
                                          component="th"
                                          py={1.5}
                                          pl={3}
                                          pr={1}
                                          textAlign="left"
                                          sx={borderBottom}
                                        >
                                          <MDTypography variant="h6" color="text" fontWeight="medium">
                                          Description
                                          </MDTypography>
                                        </MDBox>
                                        <MDBox
                                          component="th"
                                          width={{ xs: "10%", md: "15%" }}
                                          py={1.5}
                                          pl={3}
                                          pr={1}
                                          textAlign="left"
                                          sx={borderBottom}
                                        >
                                          <MDTypography variant="h6" color="text" fontWeight="medium">
                                          Media
                                                </MDTypography>
                                              </MDBox>
                                              <MDBox
                                                component="th"
                                                width={{ xs: "5%", md: "10%" }}
                                          py={1.5}
                                          pl={3}
                                          pr={1}
                                          textAlign="left"
                                          sx={borderBottom}
                                        >
                                          <MDTypography variant="h6" color="text" fontWeight="medium">
                                            Actions
                                          </MDTypography>
                                        </MDBox>
                                      </TableRow>
                                    </MDBox>
                                    <TableBody>
                                {favorites!==null?
                                        
                                    
                                      favorites.map((el)=>{
                                      if(el.Year==2025&&el.Quarter==i){
                                        return(
                                          <>
                                          <TableRow key={el._id}>
                                          <MDBox component="td" textAlign="left" p={1} sx={borderBottom}>
                                          <MDTypography variant="body2" color="text" fontWeight="regular">
                                      {el.node[0].DisplayName}
                                      </MDTypography>
                                      </MDBox>
                                      <MDBox
                                      component="td"
                                      textAlign="left"
                                      py={1}
                                      pr={1}
                                      pl={3}
                                      sx={borderBottom}
                                      >
                                      <MDTypography variant="body2" color="text" fontWeight="regular">
                                      {el.FunctionalityID}
                                      </MDTypography>
                                      </MDBox>
                                      <MDBox
                                      component="td"
                                      textAlign="left"
                                      py={1}
                                      pr={1}
                                      pl={3}
                                      sx={borderBottom}
                                      >
                                      <MDTypography variant="body2" color="text" fontWeight="regular">
                                      {el.node[0].Description}
                                      </MDTypography>
                                      </MDBox>
                                      <MDBox
                                      component="td"
                                      textAlign="left"
                                      py={1}
                                      pr={1}
                                      pl={3}
                                      sx={borderBottom}
                                      >
                                      <MDTypography variant="body2" color="text" fontWeight="regular">
                                      {el.MediaID=="16"? <>
                                        <MDButton
                                        component={Link}
                                        onClick={ ()=> handleShare(el.node[0].BankID, el.MediaID,el.node[0].ParentID,el.FunctionalityID)}
                                        rel="noreferrer"
                                        size=""
                                        color="dark"
                                        themeType="outline"
                                        >
                                          <ComputerIcon/>
                                    </MDButton>
                                    </>:<></>}
                                      
                                      {(el.MediaID=="22")?
                                          <>
                                          <MDButton
                                          component={Link}
                                          onClick={ ()=> handleShare(el.node[0].BankID, el.MediaID,el.node[0].ParentID,el.FunctionalityID)}
                                          rel="noreferrer"
                                          size=""
                                          color="dark"
                                          >
                                        <AppleIcon />
                                      </MDButton>
                                      </>:<></>
                                      }

                                      {(el.MediaID=="27")?
                                          <>
                                          <MDButton
                                          component={Link}
                                          // onClick={ ()=> doAction_goIos(el.BankID)}
                                          rel="noreferrer"
                                          size=""
                                          color="dark"
                                          >
                                        <WatchIcon />
                                      </MDButton>
                                      </>:<></>
                                      }

                        
                                      </MDTypography>
                                      </MDBox>
                                      <MDBox
                                      component="td"
                                      textAlign="left"
                                      py={1}
                                      pr={1}
                                      pl={3}
                                      sx={borderBottom}
                                      >
                                      <MDTypography variant="body2" color="text" fontWeight="regular">
                                    
                                      <DeleteIcon
                                      color="info"
                                      fontSize="medium"
                                      sx={{cursor:'pointer'}}
                                      onClick={()=>handleremove(el.MediaID, el.FunctionalityID, el.BankID)}
                                      />
                                    
                                      </MDTypography>
                                      </MDBox>
                                      </TableRow>
                                      </>
                                    )
                                  }}

                                    
                                    )
                                    
                                      
                                      :<>
                                <MDBox display="flex" alignItems="center" justifyContent="center">
                                <CircularProgress/>
                                </MDBox>
                                </>}

                                    </TableBody>
                                    </Table>
                                    </MDBox>
                                    </Card>
                                  </MDBox>
                                  )
                                })}
                                
          

           


            
          </Grid>
        </Grid>
      </MDBox>

        </>}
       </Timeline>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserRoadmap;
