import FunctionalitiesBreakdown from "./components/FunctionalitiesBreakdown";
import { useState, useContext, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Route,
  Navigate,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// @mui material components
import Grid from "@mui/material/Grid";
import TooltipTop from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import BarChartIcon from "@mui/icons-material/BarChart";
import DeleteIcon from "@mui/icons-material/Delete";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import Footer from "scientia/Footer";
import Spidergraph from "./components/Spidergraph";
import UXSpidergraph from "./components/UXSpidergraph";
import * as Cookies from "js-cookie";
import UserContext from "context/UserContext";

import Highcharts from "highcharts";

import { MenuProps, useStyles } from "./utils";
import useTheme from "@mui/material/styles/useTheme";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import fullscreen from "highcharts/modules/full-screen";

import UserOnboarding from "react-user-onboarding";
import "assets/css/onboarding.css";
import { Dashboard } from "@mui/icons-material";
import SettingsBar from "layouts/pages/analyzed/bank/SettingsBar";
import UXBreakdown from "./components/UXBreakdown";
import FunctionalitiesOverview from "./components/FunctionalitiesOverview";
import FunctionalitiesGap from "./components/FunctionalitiesGap";
import UXOverview from "./components/UXOverview";
import UXOverviewScores from "./components/UXOverviewScores";
import UXGap from "./components/UXGap";
import MarketPosition from "./components/MarketPosition";
import SuperChart from "./components/SuperChart";
import Modal from "@mui/material/Modal";
import Popup from "layouts/dashboards/popup";
import PopupWithScore from "../popup_with_score";
import Pardiff from "./components/Pardiff";
import AnalyticsHistoryInside from "../analyticsHistoryInside";
import CircularProgress from "@mui/material/CircularProgress";
import SuperChart2 from "./components/SuperChart2";
import FuncsBreakdown from "./components/FuncsBreakdown";
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
function Analytics() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "1px solid #e4e4e4",
    overflow: "block",
    display: "block",
    minHeight: "80%",
    maxHeight: "100%",
  };

  const styleInfo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const layout = [
    { i: "a", x: 0, y: 0, w: 12, h: 23, minW: 2, maxW: 12 },

    { i: "b", x: 0, y: 1, w: 12, h: 28, minW: 12, maxW: 12 },
    { i: "d1", x: 0, y: 3, w: 12, h: 28, minW: 2, maxW: 12 },

    { i: "c", x: 0, y: 4, w: 12, h: 15, minW: 2, maxW: 12 },
    { i: "c5", x: 0, y: 4, w: 12, h: 16, minW: 2, maxW: 12 },
    { i: "e", x: 0, y: 4, w: 12, h: 15, minW: 2, maxW: 12 },

    { i: "c3", x: 0, y: 5, w: 12, h: 17, minW: 2, maxW: 12 },
    { i: "c1", x: 0, y: 5, w: 12, h: 18, minW: 2, maxW: 12 },
    // c2 was h:18 with spidergraph
    { i: "c2", x: 0, y: 5, w: 12, h: 16, minW: 2, maxW: 12 },
    { i: "c4", x: 0, y: 5, w: 12, h: 21, minW: 2, maxW: 12 },
    { i: "d2", x: 0, y: 5, w: 12, h: 17, minW: 2, maxW: 12 },
    { i: "d3", x: 0, y: 5, w: 12, h: 21, minW: 2, maxW: 12 },

    /*
      { i: "d2", x: 6, y: 3, w: 6, h: 12,minW:2,maxW:12 },
*/
    { i: "f", x: 0, y: 6, w: 12, h: 12, minW: 2, maxW: 12 },
  ];
  const userContext = useContext(UserContext);
  const [dashboards, setDashboards] = useState([]);
  const [selectedBankID, setSelectedBankID] = useState(0);
  const [selectedMediaID, setSelectedMediaID] = useState(0);
  const [selectedOriginID, setSelectedOriginID] = useState(
    "62456e67813ff77e9dddc20b"
  );
  //popup getElementsByClassName
  const [popup_loading, setPopup_loading] = useState(true);
  const [popup_data, setPopup_data] = useState([]);

  const [popup_loading_with_score, setPopup_loading_with_score] =
    useState(true);
  const [popup_data_with_score, setPopup_dataWithScore] = useState([]);

  const [workspaceMedia, setWorkspaceMedia] = useState({
    market_positioning: null,
    market_overview: null,
    functionalities_overview: null,
    functionalities_overview_bank_selected: null,
    functionalities_overview_id_selected: null,
    functionalities_breakdown: null,
    superchart2: null,
    parity: null,
    ux_overview: null,
    ux_overview_bank_selected: null,
    ux_overview_id_selected: null,
    ux_breakdown: null,
    ux_overview_with_scores: null,
    ux_overview_with_scores_bank_selected: null,
    ux_overview_with_scores_bank_id_selected: null,
    functionality_gap_analysis: null,
    parity_slider_start: null,
    parity_slider_finish: null,
    exclude_overlapping: null,
    functionalities_breakdown_market_selection: null,
    ux_breakdown_market_selection: null,
    spidergraph: null,
    ux_spidergraph: null,
  });

  // matrix
  const [matrixName, setMatrixName] = useState("Default Matrix");
  const handleMatrixChange = (event) => {
    setMatrixName(event.target.value);
  };

  // modal
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openScore, setOpenScore] = useState(false);
  const handleOpenScore = () => setOpenScore(true);
  const handleCloseScore = () => setOpenScore(false);

  const [dimensions, setDimensions] = useState({
    // height: window.innerHeight,
    width: window.innerWidth,
  });

  const defaultBank = "108"; // REVOLUT V.7 (EU)
  const defaultBankname = "Revolut V.7 (EU)";
  const multi_options = [
    "Online Account Opening",
    "Login & Security",
    "Accounts",
    "Junior Account",
    "Money Transfer",
    "Recipients",
    "Transfer Templates",
    "Payments",
    "Cards",
    "Electronic Wallet(eWallet)",
    "Open Banking",
    "Personal Finance Management(PFM)",
    "Wealth Management",
    "Alerts",
    "Support",
    "User Profile & Settings",
    "Platform Extras",
  ];
  const [options, setOptions] = useState({
    title: {
      text: "Functionalities Overview",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });
  const [
    options_functionalities_breakdown,
    setOptions_functionalities_breakdown,
  ] = useState({
    title: {
      text: "Functionalities Breakdown",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });

  const [superChart, setSuperchart] = useState(null);
  const [superChart2, setSuperchart2] = useState(null);
  // dropdown
  const [action, setAction] = useState(false);
  const handleChange = () => {
    setAction(!action);
  };

  function updateChart() {
    setOptions({
      chart: {
        type: "column",
      },
      title: {
        text: "Functionalities Overview",
      },
      subtitle: {
        text: "Web & Mobile",
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Functionalities",
        },
      },

      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Functionalities",
          data: [
            49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
            95.6, 54.4,
          ],
        },
      ],
    });
  }
  function editDashboard() {
    navigate("/wizard/" + dashboard);
  }

  async function getUserWorkspace() {
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/getUserWorkspace";
    const metadataResponse = await fetch(apiURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();

    if (resp[0].chartsState.length !== 0) {
      var obj = resp[0].chartsState[0];
      if (obj.parity_slider_start === undefined) {
        obj.parity_slider_start = 0.25;
      }
      if (obj.parity_slider_finish === undefined) {
        obj.parity_slider_finish = 0.75;
      }
      if (obj.exclude_overlapping === undefined) {
        obj.exclude_overlapping = false;
      }
      if (obj.ux_breakdown_market_selection === undefined) {
        obj.ux_breakdown_market_selection = "off";
      }
      if (obj.spidergraph === undefined) {
        obj.spidergraph = "off";
      }
      if (obj.ux_spidergraph === undefined) {
        obj.ux_spidergraph = "off";
      }
      if (obj.functionalities_breakdown_market_selection === undefined) {
        obj.functionalities_breakdown_market_selection = "off";
      }
      if (obj.functionalities_overview_bank_selected === undefined) {
        obj.functionalities_overview_bank_selected = "off";
      }
      if (obj.functionalities_overview_id_selected === undefined) {
        obj.functionalities_overview_id_selected = "off";
      }
      if (obj.ux_overview_bank_selected === undefined) {
        obj.ux_overview_bank_selected = "off";
      }
      if (obj.ux_overview_id_selected === undefined) {
        obj.ux_overview_id_selected = "off";
      }
      if (obj.ux_overview_with_scores_bank_selected === undefined) {
        obj.ux_overview_with_scores_bank_selected = "off";
      }
      if (obj.ux_overview_with_scores_bank_id_selected === undefined) {
        obj.ux_overview_with_scores_bank_id_selected = "off";
      }
      if (obj.funcGapLabel1 === undefined) {
        obj.funcGapLabel1 = true;
      }
      if (obj.funcGapLabel2 === undefined) {
        obj.funcGapLabel2 = true;
      }
      if (obj.funcGapLabel3 === undefined) {
        obj.funcGapLabel3 = true;
      }
      if (obj.UXLabel1 === undefined) {
        obj.UXLabel1 = true;
      }
      if (obj.UXLabel2 === undefined) {
        obj.UXLabel2 = true;
      }
      if (obj.UXLabel3 === undefined) {
        obj.UXLabel3 = true;
      }
      if (obj.UXLabel4 === undefined) {
        obj.UXLabel4 = true;
      }
      if (obj.UXLabel5 === undefined) {
        obj.UXLabel5 = true;
      }
      if (obj.UXInnerLabel1 === undefined) {
        obj.UXInnerLabel1 = true;
      }
      if (obj.UXInnerLabel2 === undefined) {
        obj.UXInnerLabel2 = true;
      }
      if (obj.UXInnerLabel3 === undefined) {
        obj.UXInnerLabel3 = true;
      }
      if (obj.UXInnerLabel4 === undefined) {
        obj.UXInnerLabel4 = true;
      }
      if (obj.UXInnerLabel5 === undefined) {
        obj.UXInnerLabel5 = true;
      }
      if (obj.UXJlabel1 === undefined) {
        obj.UXJlabel1 = true;
      }
      if (obj.UXJlabel2 === undefined) {
        obj.UXJlabel2 = true;
      }
      if (obj.UXJlabel3 === undefined) {
        obj.UXJlabel3 = true;
      }
      if (obj.UXJlabel4 === undefined) {
        obj.UXJlabel4 = true;
      }
      if (obj.UXJlabel5 === undefined) {
        obj.UXJlabel5 = true;
      }
      if (obj.spiderLabel1 === undefined) {
        obj.spiderLabel1 = true;
      }
      if (obj.spiderLabel2 === undefined) {
        obj.spiderLabel2 = true;
      }
      if (obj.spiderUXLabel1 === undefined) {
        obj.spiderUXLabel1 = true;
      }
      if (obj.spiderUXLabel2 === undefined) {
        obj.spiderUXLabel2 = true;
      }
      setWorkspaceMedia(obj);
    } else {
      var obj = {
        market_positioning: "22",
        market_overview: "22",
        functionalities_overview: "22",
        functionalities_overview_bank_selected: "off",
        functionalities_overview_id_selected: "off",
        functionalities_breakdown: "22",
        superchart2: "22",
        parity: "22",
        ux_overview: "22",
        ux_overview_bank_selected: "off",
        ux_overview_id_selected: "off",
        ux_breakdown: "22",
        ux_overview_with_scores: "22",
        ux_overview_with_scores_bank_selected: "off",
        ux_overview_with_scores_bank_id_selected: "off",
        functionality_gap_analysis: "22",
        parity_slider_start: 0.25,
        parity_slider_finish: 0.75,
        exclude_overlapping: false,
        ux_breakdown_market_selection: "off",
        functionalities_breakdown_market_selection: "off",
        spidergraph: "22",
        ux_spidergraph: "22",
        funcGapLabel1: true,
        funcGapLabel2: true,
        funcGapLabel3: true,
        UXLabel1: true,
        UXLabel2: true,
        UXLabel3: true,
        UXLabel4: true,
        UXLabel5: true,
        UXInnerLabel1: true,
        UXInnerLabel2: true,
        UXInnerLabel3: true,
        UXInnerLabel4: true,
        UXInnerLabel5: true,
        UXJLabel1: true,
        UXJLabel2: true,
        UXJLabel3: true,
        UXJLabel4: true,
        UXJLabel5: true,
        spiderLabel1: true,
        spiderLabel2: true,
        spiderUXLabel1: true,
        spiderUXLabel2: true,
      };
      setWorkspaceMedia(obj);
    }
  }

  async function getDashboards() {
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/getall";
    const metadataResponse = await fetch(apiURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    if (metadataResponse.status !== 200) {
      location.href = "/logout";
      return;
    }
    var resp = await metadataResponse.json();
    setDashboards(resp);
  }

  function handleResize() {
    setDimensions({
      // height: window.innerHeight,
      width: window.innerWidth,
    });
  }
  function loadPopup(
    mediaid,
    where,
    treeid,
    bankid,
    tp,
    nids,
    header,
    subheader,
    gif
  ) {
    var data = {};
    data.mediaid = mediaid;
    data.where = where;
    data.treeid = treeid;
    data.bankid = bankid;
    data.tp = tp;
    data.nids = nids;
    data.header = header;
    data.subheader = subheader;
    data.gif = gif;
    setPopup_data(data);
  }

  function loadPopupWithScore(mediaid, where, treeid, bankid, tp, nids) {
    var data = {};
    data.mediaid = mediaid;
    data.where = where;
    data.treeid = treeid;
    data.bankid = bankid;
    data.tp = tp;
    data.nids = nids;
    setPopup_dataWithScore(data);
  }

  useEffect(() => {
    if (popup_data.mediaid !== undefined) {
      setOpen(true);
      setPopup_loading(false);
    }
  }, [popup_data]); // <-- empty dependency array

  useEffect(() => {
    if (popup_data_with_score.mediaid !== undefined) {
      setOpenScore(true);
      setPopup_loading_with_score(false);
    }
  }, [popup_data_with_score]); // <-- empty dependency array

  useEffect(() => {
    getDashboards();
    getUserWorkspace();
    // setUserWorkspaceState();
    window.addEventListener("resize", handleResize);
    document.documentElement.style.overflowX = "hidden";
  }, []); // <-- empty dependency array

  const [marketA, setMarketA] = useState([]);
  const [marketB, setMarketB] = useState([]);
  const [dashboard, setDashboard] = useState(null);
  const handleMarketA = (SelectChangeEvent) => {
    setMarketA(event.target.value);
  };
  const handleMarketB = (SelectChangeEvent) => {
    setMarketB(event.target.value);
  };
  const handleDashboard = (SelectChangeEvent) => {
    setDashboard(event.target.value);
  };
  const classes = useStyles(theme);
  const [selectedMarketA, setSelectedMarketA] = useState([]);
  const isAllSelectedA =
    multi_options.length > 0 && selectedMarketA.length === multi_options.length;

  const handleChangeSelectedMarketA = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedMarketA(
        selectedMarketA.length === multi_options.length ? [] : multi_options
      );
      return;
    }
    setSelectedMarketA(value);
  };

  async function selectDashboard() {
    let data = {};
    data["dashboardId"] = dashboard;
    data["originId"] = selectedOriginID;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/setActive";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
    chart_marketposition("16");
    chart_superchart("16");
    if (dashboard !== "") {
      chart_functionalities_gap("16");
    }
    chart_functionalities_overview();
  }
  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <TooltipTop title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </TooltipTop>
      <TooltipTop title="Edit" placement="bottom">
        <MDTypography
          variant="body1"
          color="info"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </TooltipTop>
    </>
  );
  const elem1 = useRef();
  const story = [
    {
      component: "modal",
      intro: true,
      className: "hello",
      children: (
        <div>
          <p>
            <b>Hi there</b>{" "}
            <span role="img" aria-label="hello">
              👋
            </span>
          </p>

          <p>Welcome to new fintech insights!</p>

          <p>
            Would you like to have a tour to see how it works? (If you skip, you
            can click the "Quick Onboarding Tour" button to get started again)
          </p>
        </div>
      ),
    },
    {
      component: "speech-bubble",
      ref: elem1,
      children: (
        <div>
          <p>This shows the list of all the tasks you have added</p>
        </div>
      ),
    },
    {
      component: "modal",
      tooltipID: "#getStarted",
      verticalPosition: "center",
      horizontalPosition: "center",
      intro: false,
      children: (
        <div>
          <p>Thank you!</p>

          <p>You have now come to the end of the onboarding demo.</p>
        </div>
      ),
    },
  ];

  const resetToDefault = async (e) => {
    if (!confirm("Are you sure to reset to default settings?")) return;

    e.currentTarget.blur();
    let data = {};
    data.charsState = [];
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    let resp = await metadataResponse.json();

    if (metadataResponse.ok) window.location.href = window.location.href;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SettingsBar />
      <MDButton
        // variant="outlined"
        color="dark"
        onClick={resetToDefault}
        sx={{
          position: "absolute",
          top: "8.8em",
          right: "5.5em",
          background: "rgb(2,8,65) !important",
        }}
      >
        Reset View
      </MDButton>
      <GridLayout
        className="layout"
        layout={layout}
        cols={12}
        rowHeight={30}
        width={
          window.innerWidth < 1200
            ? window.innerWidth - 50
            : window.innerWidth - 200
        }
        autoSize={true}
        isDraggable={false}
        isResizable={false}
      >
        <div key="a">
          {workspaceMedia.market_positioning !== null && (
            <MarketPosition
              loadPopup={loadPopup}
              mediaid={workspaceMedia.market_positioning}
              workspaceMedia={workspaceMedia}
            />
          )}
        </div>

        <div key="b">
          <MDTypography variant="h5" color="secondary">
            Functionalities Section <hr />
            <br />
            <br />
          </MDTypography>

          {workspaceMedia.market_overview !== null && (
            <SuperChart
              loadPopup={loadPopup}
              mediaid={workspaceMedia.market_overview}
              workspaceMedia={workspaceMedia}
            />
          )}
        </div>

        <div key="c">
          {workspaceMedia.functionalities_overview !== null && (
            <FunctionalitiesOverview
              loadPopup={loadPopup}
              workspaceMedia={workspaceMedia}
              mediaid={workspaceMedia.functionalities_overview}
            />
          )}
        </div>
        <div key="c5">
          {workspaceMedia.functionalities_overview !== null && (
            <AnalyticsHistoryInside
              
            />
          )}
        </div>
        <div key="e">
          {workspaceMedia.functionality_gap_analysis !== null && (
            <FunctionalitiesGap
              loadPopup={loadPopup}
              workspaceMedia={workspaceMedia}
              mediaid={workspaceMedia.functionality_gap_analysis}
              funcGapLabel1={workspaceMedia.funcGapLabel1}
              funcGapLabel2={workspaceMedia.funcGapLabel2}
              funcGapLabel3={workspaceMedia.funcGapLabel3}
            />
          )}
        </div>

        <div key="c3">
          {workspaceMedia.parity !== null && (
            <Pardiff
              loadPopup={loadPopup}
              workspaceMedia={workspaceMedia}
              parity_slider_start={workspaceMedia.parity_slider_start}
              parity_slider_finish={workspaceMedia.parity_slider_finish}
              mediaid={workspaceMedia.parity}
            />
          )}
        </div>

        {/* by banks */}
        <div key="d1">
          {workspaceMedia.superchart2 !== null && (
            <SuperChart2
              loadPopup={loadPopup}
              workspaceMedia={workspaceMedia}
              mediaid={
                workspaceMedia.superchart2 !== undefined
                  ? workspaceMedia.superchart2
                  : "22"
              }
            />
          )}
        </div>

        {/* <div key="d3">
          {workspaceMedia.functionalities_breakdown !== null && (
            <FuncsBreakdown
              loadPopup={loadPopup}
              workspaceMedia={workspaceMedia}
              mediaid={
                workspaceMedia.functionalities_breakdown !== undefined
                  ? workspaceMedia.functionalities_breakdown
                  : "22"
              }
            />
          )}
        </div> */}

        <div key="c4">
          {workspaceMedia.spidergraph !== null && (
            <Spidergraph
              loadPopup={loadPopup}
              workspaceMedia={workspaceMedia}
              mediaid={workspaceMedia.spidergraph}
              spiderLabel1={workspaceMedia.spiderLabel1}
              spiderLabel2={workspaceMedia.spiderLabel2}
            />
          )}
        </div>

        {/* <div key="d3">
             <FunctionalitiesBreakdown/>
           </div> */}

        <div key="d2">
          <MDTypography variant="h5" color="secondary">
            <br />
            UX Analysis Section
            <hr />
            <br />
            <br />
          </MDTypography>
          {workspaceMedia.ux_breakdown !== null && (
            <UXBreakdown
              loadPopup={loadPopup}
              workspaceMedia={workspaceMedia}
              mediaid={
                workspaceMedia.ux_breakdown !== undefined
                  ? workspaceMedia.ux_breakdown
                  : "22"
              }
            />
          )}
        </div>

        {/* Redesign it with ID */}
        <div key="c1">
          {workspaceMedia.ux_overview !== null && (
            <UXOverview
              loadPopup={loadPopup}
              workspaceMedia={workspaceMedia}
              mediaid={
                workspaceMedia.ux_overview !== undefined
                  ? workspaceMedia.ux_overview
                  : "22"
              }
              UXLabel1={workspaceMedia.UXLabel1}
              UXLabel2={workspaceMedia.UXLabel2}
              UXLabel3={workspaceMedia.UXLabel3}
              UXLabel4={workspaceMedia.UXLabel4}
              UXLabel5={workspaceMedia.UXLabel5}
              UXInnerLabel1={workspaceMedia.UXInnerLabel1}
              UXInnerLabel2={workspaceMedia.UXInnerLabel2}
              UXInnerLabel3={workspaceMedia.UXInnerLabel3}
              UXInnerLabel4={workspaceMedia.UXInnerLabel4}
              UXInnerLabel5={workspaceMedia.UXInnerLabel5}
            />
          )}
        </div>

        {/* Redesign it with ID */}
        <div key="c2">
          {workspaceMedia.ux_overview_with_scores !== null && (
            <UXOverviewScores
              loadPopup={loadPopup}
              workspaceMedia={workspaceMedia}
              mediaid={workspaceMedia.ux_overview_with_scores}
              UXJLabel1={workspaceMedia.UXJLabel1}
              UXJLabel2={workspaceMedia.UXJLabel2}
              UXJLabel3={workspaceMedia.UXJLabel3}
              UXJLabel4={workspaceMedia.UXJLabel4}
              UXJLabel5={workspaceMedia.UXJLabel5}
            />
          )}
        </div>

        <div key="d3">
          {workspaceMedia.ux_spidergraph !== null && (
            <UXSpidergraph
              loadPopup={loadPopup}
              workspaceMedia={workspaceMedia}
              mediaid={workspaceMedia.ux_spidergraph}
              spiderUXLabel1={workspaceMedia.spiderUXLabel1}
              spiderUXLabel2={workspaceMedia.spiderUXLabel2}
            />
          )}
        </div>
      </GridLayout>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus={true}
      >
        <MDBox sx={style}>
          {popup_loading !== true ? (
            <>
              <MDBox display="flex" justifyContent="flex-end">
                <CloseIcon
                  sx={{ color: "black", cursor: "pointer", mr: 1, mt: 1 }}
                  fontSize="large"
                  onClick={handleClose}
                />
              </MDBox>
              <Popup
                mediaid={popup_data.mediaid}
                treeid={popup_data.treeid}
                where={popup_data.where}
                bankid={popup_data.bankid}
                type={popup_data.tp}
                nids={popup_data.nids}
                header={popup_data.header}
                subheader={popup_data.subheader}
                gif={popup_data.gif}
              />
            </>
          ) : (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </MDBox>
          )}
        </MDBox>
      </Modal>

      <Modal
        open={openScore}
        onClose={handleCloseScore}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          {popup_loading_with_score !== true ? (
            <>
              <PopupWithScore
                mediaid={popup_data_with_score.mediaid}
                treeid={popup_data_with_score.treeid}
                where={popup_data_with_score.where}
                bankid={popup_data_with_score.bankid}
                type={popup_data_with_score.tp}
                nids={popup_data_with_score.nids}
              />
            </>
          ) : (
            <>
              <MDBox display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </MDBox>
            </>
          )}
        </MDBox>
      </Modal>

      <UserOnboarding
        story={story}
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
