/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// add store
import { Provider } from "react-redux"
// import { legacy_createStore as createStore } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import Allreducers from './context/reducer/Allreducers';


// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";

ReactDOM.render(


<Provider store={configureStore({reducer:Allreducers})}>

  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>

</Provider>,


  document.getElementById("root")
);
