/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import { useContext } from "react";
import Home from "layouts/dashboards/home";
import Analytics from "layouts/dashboards/analytics";
import PopupEmpty from "layouts/dashboards/PopupEmpty";
import DefaultMatrix from "layouts/pages/matrix/default";
import UserHistory from "layouts/pages/history/default";
import UserFavorite from "layouts/pages/favorites/default";
import UserRoadmap from "layouts/pages/roadmap/default";
import img1 from "assets/images/favoritesgif.webp"
import img2 from "assets/images/Roadmap.png"
import img3 from "assets/images/a-to-z-worldwide.png"

import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import ManageOrganizations from "layouts/pages/users/manage-organizations";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";

import Wizard_Weights from "layouts/applications/wizard_weights";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import Logout from "layouts/authentication/sign-in/logout";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";
import AnalyzedBanks from "layouts/pages/analyzed/banks";
import Analyzed_BanksAdmin from "layouts/pages/analyzed/banksAdmin";

import Collections from "layouts/pages/collections/collections";
// import Notifications from "layouts/pages/notifications";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// @mui icons
import Icon from "@mui/material/Icon";
import FavoriteIcon from '@mui/icons-material/Favorite';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleIcon from '@mui/icons-material/People';
import AddRoadIcon from '@mui/icons-material/AddRoad';
// Images
import profilePicture from "assets/images/team.jpg";
import Groups from "layouts/pages/usergroups";
import Manage_AllUsers from "layouts/pages/users/all-users";
import OrgRoadmap from "layouts/pages/roadmap_org/default";
import WizardCreate from "layouts/applications/wizard3.0";
import Popup from "layouts/dashboards/popup";
import PopupItem from "layouts/dashboards/popup/components/popupItem";


const admin_routes = [
  {
    type: "collapse",
    name: "FI user",
    key: "fi-user",
    icon: <MDAvatar src={profilePicture} alt="Fi User"/>,
    collapse: [

      {
        name: "My Profile",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },


      {
        name: "Markets Management",
        key: "wizard",
        route: "/wizard",
        component: <WizardCreate />,
      },

      {
        name: "Logout",
        key: "logout",
        route: "/logout",
        component: <Logout />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },

    {
    type: "collapse",
      href:"/analyzed/banks",
      name: "Analyzed banks",
      key: "analyzed",
      route: "/analyzed/banks",
      component: <PopupItem header="View the full list of analyzed banks" subheader="Dive into tremendous analysis. If a bank or fintech does it, we cover it. From KYC to transfers, investing, and canceling a card, FinTech Insights records every step customers must take to complete a task." gif={img3} />,
      target:"",
      noCollapse: true,
      icon: <AccountBalanceIcon style={{ color: 'gray' }} fontSize="medium"/>,
    },

  {
    type: "collapse",
    name: "Comparisons",
    href:"/analytics",
    key: "analytics",
    route: "/analytics",
    component: <Analytics />,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    target:"",
    noCollapse: true,
  },

  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Favorites",
    target:"",
    noCollapse: true,
    href:"/analytics",
    key: "favorites",
    route: "/analytics",
    icon: <FavoriteIcon style={{ color: 'gray' }} fontSize="medium"/>,
    component: <PopupEmpty header="Add a competitors’ implementation to your Favorites"
    subheader="Pin the most important competitors' implementations in your Favorites’ dashboard, for you and your team to access quickly, any time."
    gif={img1} />,
  },
  // {
  //     type: "collapse",
  //     name: "Roadmap",
  //     target:"",
  //     noCollapse: true,
  //     href:"/roadmap",
  //     key: "roadmap",
  //     route: "/roadmap",
  //     icon: <AddRoadIcon fontSize="medium"/>,
  //     component: <UserRoadmap />,
  //   },
  //   {
  //     type: "collapse",
  //     name: "Organization Roadmap",
  //     target:"",
  //     noCollapse: true,
  //     href:"/roadmap_org",
  //     key: "roadmap_org",
  //     route: "/roadmap_org",
  //     icon: <AddRoadIcon fontSize="medium"/>,
  //     component: <OrgRoadmap />,
  //   },

    { type: "divider", key: "divider-3" },
    {
      type: "collapse",
      name: "Roadmap",
      target:"",
      noCollapse: true,
      href:"/roadmap",
      key: "roadmap",
      route: "/roadmap",
      icon: <AddRoadIcon style={{ color: 'gray' }} fontSize="medium"/>,
      component: <PopupEmpty header="Add a feature to your Roadmap" subheader="Add and manage your product roadmap through FinΤech Insights and compare your projected self against the current state of the market."gif={img2} />,
    },







];

export default admin_routes;
