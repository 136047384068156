import IconButton from "@mui/material/IconButton";
import HighchartsExporting from "highcharts/modules/exporting";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";

const ITEM_HEIGHT = 48;

export default function LongMenu({ chartRef, title }) {
  useEffect(() => {
    const cb = () => {
      if (!document.fullscreenElement) {
        chartRef?.current?.chart?.setTitle({ text: "" });
      }
    };
    document.addEventListener("fullscreenchange", cb);
    return () => {
      document.removeEventListener("fullscreenchange", cb);
    };
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadXLS = () => {
    chartRef?.current?.chart?.setTitle({ text: title });
    chartRef?.current?.chart?.downloadXLS();
    chartRef?.current?.chart?.setTitle({ text: "" });
    handleClose();
  };

  const downloadPDF = () => {
    chartRef?.current?.chart?.setTitle({ text: title });
    chartRef?.current?.chart?.exportChart({
      type: "application/pdf",
    });
    chartRef?.current?.chart?.setTitle({ text: "" });
    handleClose();
  };

  const downloadPNG = () => {
    chartRef?.current?.chart?.setTitle({ text: title });
    chartRef?.current?.chart?.exportChart();
    chartRef?.current?.chart?.setTitle({ text: "" });
    handleClose();
  };

  const fullscreen = () => {
    chartRef?.current?.chart?.fullscreen?.toggle();

    // timeout to compensate for the time it takes to get in fullscreen, so the title does not flash
    setTimeout(() => {
      chartRef?.current?.chart?.setTitle({ text: title });
    }, 150);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon color="dark" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        disableScrollLock={true}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "19ch",
          },
        }}
      >
        <MenuItem onClick={fullscreen}>Fullscreen</MenuItem>
        <MenuItem onClick={downloadPNG}>Download PNG</MenuItem>
        <MenuItem onClick={downloadPDF}>Download PDF</MenuItem>
        <MenuItem onClick={downloadXLS}>Download XLS</MenuItem>
      </Menu>
    </div>
  );
}
