/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState,useContext,useEffect } from "react";
import { Route, Navigate, useLocation,useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import './index.css'
import SettingsBar from '../bank/SettingsBar';

// import icons
import AppleIcon from '@mui/icons-material/Apple';
import ComputerIcon from '@mui/icons-material/Computer';
import AndroidIcon from '@mui/icons-material/Android';
import WatchIcon from '@mui/icons-material/Watch';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Link from "@mui/material/Link";
import {OpenInNew, ReadMore} from "@mui/icons-material";
// Material Dashboard 2 PRO React example components
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import Footer from "scientia/Footer";
import DataTable from "./DataTable";
import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';
import CircularProgress from '@mui/material/CircularProgress';
// Data
import dataTableData from "./data/dataTableData";
import org_dataTableData from "./data/org_dataTableData";
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';

// banks
import { connect } from "react-redux"
import { change_allbanks } from "../../../../context/actions/allActions"

import {decode} from 'html-entities';
function Analyzed_BanksAdmin(props) {
 const [open, setOpen] = useState(false);
 const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const userContext = useContext(UserContext);
  const [isLoading,setIsLoading]=useState(true);

  const [isLoadingOrg,setIsLoadingOrg]=useState(true);
  const [isLoadingOrgB,setIsLoadingOrgB]=useState(true);
  const [isLoadingOrgC,setIsLoadingOrgC]=useState(true);


  const [dataTableMarketA, setDataTableMarketA] = useState([])
  const [dataTableMarketB, setDataTableMarketB] = useState([])
  const [dataTableMarketC, setDataTableMarketC] = useState([])



   const navigate = useNavigate();
  const options = {
    sort: true,
    sortOrder: { name: 'BankName', direction: 'asc' },
    pagination: false
  };

   function doAction_goWeb(bankid) {
  //   console.log(bankid);
     let path = '/analyzed/bank/'+bankid+'/16';
     navigate(path);

  }
   function doAction_goIos(bankid) {

     let path = '/analyzed/bank/'+bankid+'/22';
     navigate(path);
  }



 function doAction_goAppleWatch(bankid) {

  let path = '/analyzed/bank/'+bankid+'/27';
  navigate(path);
}

// node_getAnalyzedBanks
// async function doActionNode_getAnalyzedBanksForDashboard(){
//   var apiURL = process.env.REACT_APP_SERVER_URL;
//   apiURL+="/api/node_getAnalyzedBanks";
//  const metadataResponse = await fetch(apiURL, {
//   method: 'GET', // *GET, POST, PUT, DELETE, etc.
//   mode: 'cors', // no-cors, *cors, same-origin
//   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//   headers: {
//      'bearer': userContext.userData.apiToken,
//      'Content-Type': 'application/json'
//    },
//  });

//  var resp = await metadataResponse.json();
//  console.log(resp)
// }

async function doAction_updateNodesAll() {
  if (confirm('Are you sure ?')) {
  handleToggle();
  var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL+="/api/updateAllNodes";
    // console.log("EDW ->")
    // console.log(apiURL);
   const metadataResponse = await fetch(apiURL, {
     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     headers: {
       'bearer': userContext.userData.apiToken,
       'Content-Type': 'application/json'
     },
   });
    var resp = await metadataResponse.json();
    setOpen(false);
    //setTimeout(() => {setOpen(false)}, 50000);
  }
};
async function doAction_calculateUX() {
  if (confirm('Are you sure calculate UX ?')) {
  handleToggle();
  var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL+="/api/node_CalculateUX";
    // console.log("EDW ->")
    // console.log(apiURL);
   const metadataResponse = await fetch(apiURL, {
     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     headers: {
       'bearer': userContext.userData.apiToken,
       'Content-Type': 'application/json'
     },
   });
    var resp = await metadataResponse.text();
    setOpen(false);
    //setTimeout(() => {setOpen(false)}, 50000);
  }
};
async function doAction_updateNodes(bankid) {
  handleToggle()
  let data={};
  data['bankid']=bankid;
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/updateNodes";
      // console.log("EDW ->")
      // console.log(apiURL);
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  body:JSON.stringify(data),
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });
      var resp = await metadataResponse.json();
    setTimeout(() => {setOpen(false)}, 30000)

}
async function doAction_getAnalyzedBanks() {

    var apiURL = process.env.REACT_APP_SERVER_URL;
//      apiURL+="/api/node_getAnalyzedBanksForDashboard";
      apiURL+="/api/node_GetBanksAll";

      // console.log("EDW ->")
      // console.log(apiURL);
     const metadataResponse = await fetch(apiURL, {
       method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });
     if(metadataResponse.status!==200) {
       location.href="/logout";
       return;
     }
     var resp = await metadataResponse.json();


    //  props.change_allbanks(resp)
    //  console.log(resp);
    // Market A
    var d={};
    resp.forEach(function(v,index) {
      if (d[v.BankID]===undefined) {
        d[v.BankID]={};
      }
      d[v.BankID]['BankID']=v.BankID;
      d[v.BankID]['BankName']=decode(v.BankName);
                d[v.BankID]['Updated']=v.Updated;
          d[v.BankID]['ImageURL']=v.BankName;
      if (d[v.BankID]['web']===undefined) {
        d[v.BankID]['web']="";
      }
      if (d[v.BankID]['ios']===undefined) {
        d[v.BankID]['ios']="";
      }
      // if (d[v.BankID]['android']===undefined) {
      //   d[v.BankID]['android']="";
      // }
      if (d[v.BankID]['apple_watch']===undefined) {
        d[v.BankID]['apple_watch']="";
      }


          d[v.BankID]['web']=<>
          <MDButton
           component={Link}
           onClick={ ()=> doAction_updateNodes(v.BankID)}
           rel="noreferrer"
   size="small"
           color="dark"
           themetype="outline"
           >
        <ComputerIcon/>
       </MDButton>
       </>;




      d[v.BankID]['img']=<><img src={v.ImageURL} width={50} height={50}/></>
    });

      // console.log(d);
      var r=[];
      for(var row in d) {
        r.push(d[row]);
      };

     org_dataTableData.rows=r;
     setDataTableMarketA(org_dataTableData)
     setIsLoadingOrg(false)



      //  Market C


  }

  const myJavascript=()=>{
    var x = document.getElementsByClassName("title_sort")
    var i = 0
    while(i<x.length-1){
      // console.log(x[i].childNodes[1])

        // x[i].childNodes[1].style.display="none"
      i++
    }

  }


  useEffect(()=>{
  doAction_getAnalyzedBanks();

  // doActionNode_getAnalyzedBanksForDashboard();

 }, []) // <-- empty dependency array

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SettingsBar />


      <MDBox pt={3} pb={3}>
        <MDBox mb={3}>
            <MDTypography variant="h3" fontWeight="medium">
            Update Nodes<br/><br/>
                </MDTypography>
                <MDButton
                 component={Link}
                 onClick={ ()=> doAction_updateNodesAll()}
                 rel="noreferrer"
         size="small"
                 color="dark"
                 themetype="outline"
                 >
            Update All
             </MDButton>
             <br/><br/>
             <MDTypography variant="h3" fontWeight="medium">

                 </MDTypography>
                 <MDButton
                  component={Link}
                  onClick={ ()=> doAction_calculateUX()}
                  rel="noreferrer"
          size="small"
                  color="dark"
                  themetype="outline"
                  >
              Calculate UX
              </MDButton>
              <br/><br/>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
          Banks
              </MDTypography>
              <MDTypography variant="button" color="text">
                Banks
              </MDTypography>
            </MDBox>
            {isLoadingOrg===false?(
              <>
                <DataTable table={dataTableMarketA} canSearch={true} isSorted={true}/>
                {myJavascript()}
              </>
            ):(
              <>
              {console.log('props',props)}
              <MDBox
              sx={{p:3}}
              display="flex"
              justifyContent="center"
              alignItems="center"
              >
              <CircularProgress/>
              </MDBox>
              </>

          )}
          </Card>
        </MDBox>



      </MDBox>
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}

            >
              <CircularProgress color="inherit" />
            </Backdrop>
      <Footer />
    </DashboardLayout>
  );
}

// injecting state to props
const mapStateToProps = (state) =>{
  return state
}

// export default Bank;
export default connect(mapStateToProps, {change_allbanks}) (Analyzed_BanksAdmin)


// export default Analyzed_Banks;
