/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useContext } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import UserContext from "context/UserContext";
import WelcomeLayout from "layouts/authentication/components/WelcomeLayout";
import handIcon from "./hand-icon.svg";
import logo from "./logo-main-header.svg";
import "./style.css";

import "../../../scientia/New Design/Select/style.css";
import PrimaryButton from "scientia/New Design/Button/PrimaryButton";
import { useNavigate } from "react-router-dom";

function FirstPage() {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [showContinueBtn, setShowContinueBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  const [activeDashboard, setActiveDashboard] = useState({
    Title: "",
    _id: "",
  });
  const [dashboards, setDashboards] = useState([]);
  const [unique_users, setUniqueUsers] = useState([]);

  async function selectDashboard(did) {
    setLoading(true);
    let data = {};
    data["dashboardId"] = did;
    data["originId"] = "62456e67813ff77e9dddc20b";
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/setActive";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    const resp = await metadataResponse.json();
    if (metadataResponse.ok) {
      setShowContinueBtn(true);
      setLoading(false);
    }
  }

  async function getDashboards() {
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/getall";
    const metadataResponse = await fetch(apiURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    const resp = await metadataResponse.json();

    setDashboards(resp);

    //  Get unique users
    var array = [];

    resp
      .filter((v) => {
        return v._id !== activeDashboard._id;
      })
      .map((item) => {
        if (item.us !== undefined) {
          var obj = {
            id: item.us._id,
            firstName: item.us.firstName,
            lastName: item.us.lastName,
            email: item.us.email,
          };
          array.push(obj);
        }
      });
    let unique = [...new Map(array.map((item) => [item["id"], item])).values()];

    setUniqueUsers(unique);
  }

  useEffect(() => {
    getDashboards();
  }, []);

  return (
    <WelcomeLayout>
      <MDBox
        sx={{
          padding: "0 20px",
          paddingLeft: "28px",
          paddingTop: "34px",
          maxWidth: "1176px",
          margin: "0 auto",
        }}
      >
        <img src={logo} alt="logo" />
      </MDBox>
      <MDBox sx={{ paddingTop: "40px", maxWidth: "640px", margin: "0 auto" }}>
        <MDTypography
          sx={{
            marginTop: "40px",
            marginBottom: "24px",
            fontSize: "18px",
            lineHeight: "27px",
            fontWeight: "bold",
            color: "#090324",
          }}
        >
          Hi, {userContext.userData.firstname}{" "}
          <img src={handIcon} alt="Greet icon" />
          <br />
          Welcome to FinTech Insights!
        </MDTypography>

        <MDTypography
          sx={{ lineHeight: "24px", fontSize: "16px", color: "#394055" }}
        >
          To start using FinTech Insights, you'll need to define your Market of
          focus. <br /> <br />
          You can either select a colleague's already existing Market or create
          your own. A quick wizard will help you create your Market and guide
          you through selecting the Banks & FinTechs, as well as the
          Functionality areas of your choice.
        </MDTypography>

        <MDBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "60px",
            marginBottom: "75px",
            gap: "60px",
          }}
        >
          <MDBox sx={{ flex: 1 }} className="newDesingSelectParent">
            <select
              className="newDesignSelect"
              onChange={(e) => selectDashboard(e.target.value)}
            >
              <option value="" disabled selected>
                Select Market
              </option>

              <optgroup label="By You">
                {dashboards
                  .filter((v) => {
                    return v._id !== activeDashboard._id;
                  })
                  .map((item) => {
                    if (
                      item.us !== undefined &&
                      userContext.userData.email === item.us.email
                    ) {
                      return (
                        <option key={item._id} value={item._id}>
                          {item.Title}
                        </option>
                      );
                    }
                  })}
              </optgroup>

              {unique_users
                .filter((v) => {
                  return v.email !== userContext.userData.email;
                })
                .map((user) => {
                  return (
                    <optgroup label={`By ${user.firstName} ${user.lastName}`}>
                      {dashboards
                        .filter((v) => {
                          return v._id !== activeDashboard._id;
                        })
                        .map((item) => {
                          if (
                            item.us !== undefined &&
                            user.email === item.us.email
                          ) {
                            return (
                              <option key={item._id} value={item._id}>
                                {item.Title}
                              </option>
                            );
                          }
                        })}
                    </optgroup>
                  );
                })}
            </select>
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "41px",
              height: "41px",
              fontSize: "15px",
              lineHeight: "17px",
              textAlign: "center",
              borderRadius: "50%",
              color: "#FEFEFE",
              backgroundColor: "#020746",
            }}
          >
            or
          </MDBox>
          <MDBox sx={{ flex: 1 }}>
            <PrimaryButton onClick={() => navigate("/welcome_wizard")}>
              Create Market
            </PrimaryButton>
          </MDBox>
        </MDBox>

        {showContinueBtn && (
          <PrimaryButton
            fontWeight="bold"
            disabled={loading}
            onClick={() => navigate("/workspace")}
          >
            Continue
          </PrimaryButton>
        )}
      </MDBox>
    </WelcomeLayout>
  );
}

export default FirstPage;
