export const showFunctionalitiesActive = () =>{
    return {
        type: 'SHOW_FUNCTIONALITIES_ACTIVE',
    }
}

export const showFunctionalitiesInactive = () =>{
    return {
        type: 'SHOW_FUNCTIONALITIES_INACTIVE',
    }
}


export const changeLastSideNavNode = (node) =>{
    return {
        type: 'CHANGE_LAST_SIDENAV_NODE',
        payload: node
    }
}

export const change_notifications = (num) =>{
    return {
        type: 'CHANGE_NOTIFICATIONS',
        payload: num
    }
}

export const change_allbanks = (data) =>{
    return {
        type: 'CHANGE_ALLBANKS',
        payload: data
    }
}

export const change_user_dark_mode = (data) =>{
    return {
        type:'CHANGE_USER_DARK_MODE',
        payload: data

    }
}