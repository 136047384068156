import Card from "@mui/material/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext, useEffect, useRef } from "react";
import UserContext from "context/UserContext";
import MDButton from "components/MDButton";
import AppleIcon from "@mui/icons-material/Apple";
import ComputerIcon from "@mui/icons-material/Computer";
import WatchIcon from "@mui/icons-material/Watch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChartsHeader from "../ChartsHeader";
import gif from "assets/images/marketwebp.webp";

export default function FunctionalitiesOverview({
  loadPopup,
  mediaid,
  workspaceMedia,
}) {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboards, setDashboards] = useState([]);
  const [selectedBankID, setSelectedBankID] = useState(0);
  const [selectedMediaID, setSelectedMediaID] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [functionalitiesOverview, setFunctionalitiesOverview] = useState(null);
  // const defaultBank="108"; // REVOLUT V.7 (EU)
  // const defaultBankname="Revolut V.7 (EU)";
  // reload components easily
  const [seed, setSeed] = useState(1);
  const [toolTipInfo, setToolTipInfo] = useState(
    "The Functionalities Overview graph shows how many functionalities the banks/fintechs in the user's market of focus have implemented, in all available channels. By clicking on a bar, the user can get a view of the distribution of functionalities in each functionality section. By clicking in any of the functionality sections, the user can see a complete list of the aforementioned functionalities that banks/fintechs both in the market of focus but also outside of it have implemented. By selecting a specific functionality, a user can view which are the banks/fintechs that have implemented it. Lastly, by clicking any bank/fintech that appears on the list, the user can be redirected to the full Report and view the video of how the functionality has been implemented by that specific bank/fintech."
  );

  const [options, setOptions] = useState({
    title: {
      text: "Functionalities Overview",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });

  const [
    options_functionalities_breakdown,
    setOptions_functionalities_breakdown,
  ] = useState({
    title: {
      text: "Functionalities Breakdown",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });

  async function chart_functionalities_overview_forBank(bankid) {
    setSelectedBankID(bankid);

    setIsLoading(true);
    var Medianame = selectedMediaID == "16" ? "Web" : "iOS";
    var Medianame = selectedMediaID == "27" ? "Apple Watch" : Medianame;
    var data = {};
    data["bankid"] = bankid;
    data["mediaid"] = selectedMediaID;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_functionalities_overview";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();
    var fc = [];
    var categories = [];
    var data = [];
    resp.forEach(function (v, index) {
      var bank = [];
      fc[v.DisplayName] = v.NodeID;

      categories.push(v.DisplayName);
      data.push(v.Functionalities);
    });
    setFunctionalitiesOverview(fc);
    setOptions({
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categories,
        crosshair: true,
      },
      yAxis: {
        title: { text: "Functionalities" },
      },

      exporting: {
        buttons: false,
      },

      plotOptions: {
        column: {
          colorByPoint: true,
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          label: {
            enabled: false,
          },
          showInLegend: false,
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                //   chart_functionalities_overview_forBankMedia(bankid,fc[this.category]);
                loadPopup(
                  selectedMediaID,
                  "1",
                  fc[this.category],
                  bankid,
                  1,
                  1,
                  "Functionalities Overview",
                  "Identify which exact features fall under each category by the institution of interest and how they are implemented, captured in video and UX evaluated.",
                  gif
                );
              },
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          let bankName =
            '<span style="color: ' +
            this.point.color +
            '">\u25CF</span> ' +
            `<span>${this.x}</span>`;
          let desc = "<b>" + this.point.y + "</b>";
          if (this.point.y == 1) {
            desc += " Functionality";
          } else {
            desc += " Functionalities";
          }
          return bankName + ": " + desc;
        },
      },
      series: [
        {
          name: "",
          data: data,
        },
      ],
    });
    // setData(resp);
    setIsLoading(false);
  }

  async function chart_functionalities_overview() {
    // Handle History
    if (workspaceMedia.functionalities_overview_bank_selected !== "off") {
      setSelectedName(workspaceMedia.functionalities_overview_bank_selected);
      chart_functionalities_overview_forBank(
        workspaceMedia.functionalities_overview_id_selected
      );
    } else {
      setSelectedBankID(0);
      setSelectedName(null);

      setIsLoading(true);

      var data = {};
      data["mediaid"] = selectedMediaID;
      var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL += "/api/chart_functionalities_overview";
      const metadataResponse = await fetch(apiURL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        body: JSON.stringify(data),
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          bearer: userContext.userData.apiToken,
          "Content-Type": "application/json",
        },
      });

      var Medianame = selectedMediaID == "16" ? "Web" : "iOS";
      var Medianame = selectedMediaID == "27" ? "Apple Watch" : Medianame;
      var resp = await metadataResponse.json();
      var fc = [];
      var categories = [];
      var data = [];
      var colors = [];
      resp.map((v) => {
        var bank = [];

        if (v._id.BankID !== undefined && v.Functionalities !== undefined) {
          fc[v._id.BankName] = v._id.BankID;
          categories.push(v._id.BankName);
          data.push(v.Functionalities);
          colors.push(v._id.colors.Color);
        }
      });
      // resp.forEach(function(v,index) {
      //    var bank=[];
      //    if (v._id.BankID!==undefined&&v._id.Functionalities!==undefined) {
      //      fc[v._id.BankName]=v._id.BankID;

      //     categories.push(v._id.BankName);
      //     data.push(v._id.Functionalities);
      //       colors.push(v._id.colors.Color);
      //     }
      // });

      setFunctionalitiesOverview(fc);
      setOptions({
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },

        xAxis: {
          categories: categories,
          crosshair: true,
        },
        yAxis: {
          title: { text: "Functionalities" },
        },
        exporting: {
          menuItemDefinitions: {
            toggleTable: {
              onclick: function () {
                if (
                  this.dataTableDiv &&
                  this.dataTableDiv.style.display !== "none"
                ) {
                  this.dataTableDiv.style.display = "none";
                } else {
                  this.viewData();
                  this.dataTableDiv.style.display = "";
                }
              },
              text: "Toggle Table",
            },
          },
          buttons: false,
        },
        plotOptions: {
          column: {
            colorByPoint: true,
            pointPadding: 0.2,
            borderWidth: 0,
          },
          color: colors,
          series: {
            label: {
              enabled: false,
            },
            showInLegend: false,
            cursor: "pointer",
            point: {
              events: {
                click: function () {
                  setSelectedName(this.category);
                  setUserWorkspaceStateSelectedBank(
                    this.category,
                    fc[this.category]
                  );
                  chart_functionalities_overview_forBank(fc[this.category]);
                },
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            let bankName =
              '<span style="color: ' +
              this.point.color +
              '">\u25CF</span> ' +
              `<span>${this.x}</span>`;
            let desc = "<b>" + this.point.y + "</b>";
            if (this.point.y == 1) {
              desc += " Functionality";
            } else {
              desc += " Functionalities";
            }
            return bankName + ": " + desc;
          },
        },
        series: [
          {
            name: "",
            colors: colors,
            data: data,
          },
        ],
      });
      // setData(resp);
      setIsLoading(false);
    }
  }

  async function setUserWorkspaceState(newmedia) {
    let data = {};
    var newState = workspaceMedia;
    newState.functionalities_overview = newmedia;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  async function setUserWorkspaceStateSelectedBank(bank, id) {
    let data = {};
    var newState = workspaceMedia;
    newState.functionalities_overview_bank_selected = bank;
    newState.functionalities_overview_id_selected = id;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  async function getDashboards() {
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/getall";
    const metadataResponse = await fetch(apiURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
    setDashboards(resp);
    setSelectedMediaID(mediaid);
    //  chart_functionalities_overview();
  }
  useEffect(() => {
    getDashboards();
  }, []);

  useEffect(() => {
    if (selectedMediaID != null) {
      chart_functionalities_overview();
    }
  }, [selectedMediaID]);

  const handleMedia = (mediaid) => {
    setUserWorkspaceState(mediaid);
    setSelectedMediaID(mediaid);
  };

  const chartRef = useRef();

  const goPreviousGraph = () => {
    setUserWorkspaceStateSelectedBank("off", "off");
    chart_functionalities_overview();
  };

  return (
    <Card>
      <MDBox p={1}>
        {selectedBankID != 0 && (
          <ArrowBackIcon
            color="dark"
            onClick={() => goPreviousGraph()}
            sx={{
              position: "absolute",
              left: 6,
              top: 32,
              cursor: "pointer",
              zIndex: 1,
            }}
            fontSize="medium"
          />
        )}

        {/* {selectedName!==null?<>{selectedName}</>:<></>} */}

        <ChartsHeader
          title="Functionalities Overview"
          handleMedia={handleMedia}
          toolTipInfo={toolTipInfo}
          selectedMediaID={selectedMediaID}
          selectedName={selectedName}
          chartRef={chartRef}
        />

        {!isLoading ? (
          <>
            <HighchartsReact
              ref={chartRef}
              highcharts={Highcharts}
              options={options}
            />
          </>
        ) : (
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ p: 25 }}
          >
            <CircularProgress />
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}
