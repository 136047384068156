/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState,useContext,useEffect } from "react";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';

import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CircularProgress from '@mui/material/CircularProgress';

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';
import MDButton from "components/MDButton";
// import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import '../BasicInfo/index.css'

function BasicInfo() {
  const navigate = useNavigate();

  const userContext = useContext(UserContext);

  const [fname, setFname] = useState(userContext.userData.firstname)
  const [lname, setLname] = useState(userContext.userData.lastname)
  const [email, setEmail] = useState(userContext.userData.email)
  const [conf_email, setConf_email] = useState(userContext.userData.email)


  const [pin, setPIN] = useState("")
  const [correctPIN, setCorrectPin] = useState("")
  const [phone, setPhone] = useState(null)
  const [openVerify, setOpenVerify] = useState(false)
  const [openPIN, setOpenPIN] = useState(false)

  const handlePIN = (e) =>{
    console.log(e.target.value)
    setPIN(e.target.value)
  }


  const handlePhone = (e) =>{
    e ="+" + e
    setPhone(e)
    setOpenVerify(true)
  }





  async function doAction_getMyDetails(){
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL+="/api/getMyDetails";
   const metadataResponse = await fetch(apiURL, {
     method: 'GET', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  // body:JSON.stringify(data),
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     headers: {
       'bearer': userContext.userData.apiToken,
       'Content-Type': 'application/json'
     },
   });
    var resp = await metadataResponse.json();
    setPhone(resp.phone)

  }

  async function save(){
    console.log(fname, lname, email, conf_email)
    let data={};
    data['firstName']=fname;
    data['lastName']=lname;
    data['email']=conf_email;
    data['phone']=phone;

    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/edit_user_info";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });
      var resp = await metadataResponse.json();
      console.log(resp)

      // navigate("/logout")
      var dx=userContext.userData;
     dx.firstname=fname;
     dx.lastname=lname;
     dx.email=conf_email;
     //  console.log(dx);
     userContext.setUserDataForContext(dx);
     Cookies.set("session", JSON.stringify(dx), { expires: 1 });
     window.location=window.location;
  }



  const handleSendSMS = async () =>{

    // create new pin token for user

    let data={};
    data['phone']= phone;

    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/CreateNewPINtoken";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });
      var resp = await metadataResponse.json();

      setOpenPIN(true)
  }

  const checkPINValidity = async () =>{
    let data={};
    data['pin']= pin;
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/Check_if_PIN_is_valid";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });
      var resp = await metadataResponse.json();
      console.log(resp)
    if (resp.result){
      setOpenPIN(false)
      setOpenVerify(false)
    } else {
      alert("Invalid PIN try again . . .")
    }
  }



  useEffect(()=>{
    doAction_getMyDetails();



    }, []) // <-- empty dependency array



  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5" color="text">Basic Info</MDTypography>
      </MDBox>

      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
            label="First Name"
            placeholder={userContext.userData.firstname}
            onChange={(e)=>{setFname(e.target.value)}}

             />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
            label="Last Name"
            onChange={(e)=>{setLname(e.target.value)}}
            placeholder={userContext.userData.lastname} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Email"
              onChange={(e)=>{setEmail(e.target.value)}}
              placeholder={userContext.userData.email}
              inputProps={{ type: "email" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>

          </Grid>

          <Grid item xs={12} md={6} sx={{mt:1, display:"flex", alignItems:"center"}}>


              <PhoneInput
              className="basic_phone_input"
              country={'us'}
              value={phone}
              onChange={(e)=>handlePhone(e)}
              enableSearch={true}
              />


            {openPIN &&
              <TextField sx={{ml:2,mr:2}} id="outlined-basic" label="PIN" value={pin} variant="outlined" onChange={handlePIN} />
              }

              {openVerify?<>
                {openPIN?<>
                  <MDButton sx={{height:20,ml:2, mr:2, width:150}} color="info" onClick={()=>{checkPINValidity()}}>Verify</MDButton>
                </>:<>
                <MDButton sx={{height:20, ml:2,mr:2, width:150}} color="info" onClick={()=>{handleSendSMS()}}>Send SMS</MDButton>
                </>}
              </>:<></>}




          </Grid>




           <Grid item xs={12} md={6} sx={{display:"flex",justifyContent:"flex-end", alignItems:"center"}}>
            {/* <FormField label="Language" placeholder="English" />  */}
          <MDButton disabled={openVerify} sx={{mr:2, height:20}} color="info" onClick={()=>{save()}}>

            Save
          </MDButton>
          </Grid>

        </Grid>
      </MDBox>


    </Card>
  );
}

export default BasicInfo;
