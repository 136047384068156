/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Card from '@mui/material/Card';

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Grid from '@mui/material/Grid';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <MDBox
    >
      <MDBox
      height={50}
      width="100%"
      >
        {/* <Card> */}


        <Grid container px={2} spacing={2} columns={16} >


        <Grid item xs={10}  height={50} color="text" >

        <MDTypography color="text">
          All rights reserved - {new Date().getFullYear()} Scientia FinTech Insights Ltd. &copy;
        </MDTypography>

        </Grid>



        <Grid item xs={6}  height={50} display="flex"justifyContent="flex-end" >

        <Link href="https://www.scientiaconsulting.eu/privacy-policy?hsLang=en" target="_blank" underline="none">
          <MDTypography color="text"  mr={2}>
            Privacy Policy
          </MDTypography>
           </Link>

           <Link href="https://www.scientiaconsulting.eu/terms-and-conditions?hsLang=en" target="_blank" underline="none">
          <MDTypography  color="text" >
            Terms & Conditions
          </MDTypography>
           </Link>
        </Grid>



        </Grid>


        {/* </Card> */}
      </MDBox>

      {/* <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}, Scientia Consulting

      </MDBox>
      <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </MDBox> */}
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://www.scientiaconsulting.eu/", name: "Scientia Consulting" },
  links: [

    { href: "https://www.scientiaconsulting.eu", name: "Scientia Consulting" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
