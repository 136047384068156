import Card from "@mui/material/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext, useEffect, useRef } from "react";
import UserContext from "context/UserContext";
import ChartsHeader from "../ChartsHeader";
import gif from "assets/images/marketwebp.webp";

HighchartsMore(Highcharts);

export default function Spidergraph({
  loadPopup,
  mediaid,
  workspaceMedia,
  spiderLabel1,
  spiderLabel2,
}) {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboards, setDashboards] = useState([]);
  const [selectedBankID, setSelectedBankID] = useState(0);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedMediaID, setSelectedMediaID] = useState(mediaid);
  const [functionalitiesOverview, setFunctionalitiesOverview] = useState(null);
  // const defaultBank="108"; // REVOLUT V.7 (EU)
  // const defaultBankname="Revolut V.7 (EU)";
  const [toolTipInfo, setToolTipInfo] = useState(
    "The Functionalities Coverage Radar shows an overview of each functionality category of the Primary market compared with the ones of the Target market. Each column represents the total number of functionalities per category for the Primary market, while the lines represent the total number of functionalities per category for the Target market. By clicking a selected bar (or line), the user can see a complete list of the functionalities that are implemented in the Primary market (or Target market), how many banks/fintechs are implementing each functionality in the Primary market and outside of it. Additionally, by clicking a chosen functionality the user can see which are the banks/fintechs implementing it. Lastly, by selecting any bank/fintech they can be redirected to the full Report and view the video of how the functionality has been implemented by that specific bank/fintech. "
  );

  const [label1, setLabel1] = useState(spiderLabel1);
  const [label2, setLabel2] = useState(spiderLabel2);

  const [options, setOptions] = useState({});

  async function chart_spidergraph() {
    setSelectedBankID(0);
    setSelectedName(null);
    setIsLoading(true);
    var data = {};
    // data["bankid"] = "107";
    data["mediaid"] = selectedMediaID;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_spidergraph2";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();

    // passing fixed data
    let arr = [];

    let x = 0;
    const colours_arr = [
      "rgb(255, 210, 224)",
      "rgb(250, 204, 219)",
      "rgb(244, 197, 214)",
      "rgb(239, 191, 209)",
      "rgb(233, 185, 204)",
      "rgb(228, 179, 200)",
      "rgb(222, 172, 195)",
      "rgb(217, 166, 190)",
      "rgb(211, 160, 186)",
      "rgb(206, 154, 181)",
      "rgb(200, 148, 177)",
      "rgb(194, 142, 173)",
      "rgb(188, 137, 169)",
      "rgb(183, 131, 164)",
      "rgb(177, 125, 160)",
      "rgb(171, 120, 156)",
      "rgb(165, 114, 152)",
      "rgb(159, 108, 148)",
      "rgb(153, 103, 144)",
      "rgb(147, 98, 140)",
      "rgb(141, 92, 137)",
      "rgb(135, 87, 133)",
      "rgb(129, 82, 129)",
      "rgb(122, 77, 125)",
      "rgb(116, 72, 122)",
      "rgb(110, 67, 118)",
      "rgb(103, 62, 115)",
      "rgb(97, 57, 111)",
      "rgb(91, 52, 108)",
      "rgb(84, 48, 104)",
      "rgb(77, 43, 101)",
      "rgb(71, 39, 97)",
      "rgb(64, 35, 94)",
      "rgb(57, 31, 90)",
      "rgb(50, 27, 87)",
      "rgb(42, 23, 83)",
      "rgb(34, 19, 80)",
      "rgb(25, 15, 77)",
      "rgb(15, 12, 73)",
      "rgb(2, 7, 70)",
    ];
    let names = resp.names;
    let points = resp.functionalities;
    // points = [
    //   2, 83, 150, 49, -175, 30, 8, 41, -133, 15, 40, 109, 75, 22, 43, 49, 75,
    // ];

    if (points.length !== 0) {
      while (x < points.length) {
        let obj = {
          name: names[x],
          color: colours_arr[x],
          treeid: resp.parents[x],
          market: "a",
          y: points[x],
        };

        arr.push(obj);

        x++;
      }
    }

    let line_points = resp.functionalitiesB;
    // line_points = [
    //   2, 67, -141, 41, 113, 27, 0, 30, -110, 5, 39, 103, 69, 22, 38, 50, 63,
    // ];
    let arr_lines = [];

    let xx = 0;
    if (line_points.length !== 0) {
      while (xx < line_points.length) {
        let obj = {
          name: names[xx],
          // color: colours_arr[x],
          // id: resp.parents[x],
          treeid: resp.parents[xx],
          market: "b",
          y: line_points[xx],
        };

        arr_lines.push(obj);
        xx++;
      }
    }
    let max_of_both = Math.max(...points.concat(line_points)) + 1;

    const dummy = {
      series: [
        {
          type: "column",
          name: "Primary Market",
          data: arr,
          color: "#FCDCE4",
        },
        {
          type: "line",
          color: "grey",
          name: "Target Market",
          data: arr_lines,
          marker: {
            // lineWidth: 2,
            // lineColor: Highcharts.getOptions().colors[1],
            lineColor: "orange",
            fillColor: "darkgrey",
          },
        },
      ],
    };

    // let options = {
    //   1: {
    //     chart: {
    //       polar: true,
    //     },

    //     width: 200,

    //     title: {
    //       text: "Revolut",
    //     },

    //     pane: {
    //       startAngle: 0,
    //       endAngle: 360,
    //     },

    //     xAxis: {
    //       categories: [
    //         "Login",
    //         "Accounts",
    //         "Cards",
    //         "Money Transfers",
    //         "Alerts",
    //         "Payments",
    //         "Crypto",
    //         "Security",
    //       ],
    //     },

    //     yAxis: {
    //       min: 0,
    //       max: 24,
    //     },

    //     plotOptions: {
    //       series: {
    //         // pointStart: 0,
    //         // pointInterval: 45,
    //       },
    //       column: {
    //         pointPadding: 0,
    //         groupPadding: 0,
    //       },
    //     },

    //     // series: [
    //     //   {
    //     //     type: "column",
    //     //     name: "Functionality",
    //     //     data: [12,3,4,12],
    //     //     pointPlacement: "between",
    //     //   },
    //     // ],
    //     series: dummy.series,
    //   },
    //   2: {
    //     chart: {
    //       polar: true,
    //     },

    //     width: 200,

    //     title: {
    //       text: "Euro Bank",
    //     },

    //     pane: {
    //       startAngle: 0,
    //       endAngle: 360,
    //     },

    //     xAxis: {
    //       categories: [
    //         "Login",
    //         "Accounts",
    //         "Cards",
    //         "Money Transfers",
    //         "Alerts",
    //         "Payments",
    //         "Crypto",
    //         "Security",
    //       ],
    //     },

    //     yAxis: {
    //       min: 0,
    //       max: 24,
    //     },

    //     plotOptions: {
    //       series: {
    //         // pointStart: 0,
    //         // pointInterval: 45,
    //       },
    //       column: {
    //         pointPadding: 0,
    //         groupPadding: 0,
    //       },
    //     },

    //     // series: [
    //     //   {
    //     //     type: "column",
    //     //     name: "Functionality",
    //     //     data: [12,3,4,12],
    //     //     pointPlacement: "between",
    //     //   },
    //     // ],
    //     series: dummy.series,
    //   },
    //   3: {
    //     chart: {
    //       polar: true,
    //     },

    //     width: 200,

    //     title: {
    //       text: "Alpha Bank",
    //     },

    //     pane: {
    //       startAngle: 0,
    //       endAngle: 360,
    //     },

    //     xAxis: {
    //       categories: [
    //         "Login",
    //         "Accounts",
    //         "Cards",
    //         "Money Transfers",
    //         "Alerts",
    //         "Payments",
    //         "Crypto",
    //         "Security",
    //       ],
    //     },

    //     yAxis: {
    //       min: 0,
    //       max: 24,
    //     },

    //     plotOptions: {
    //       series: {
    //         // pointStart: 0,
    //         // pointInterval: 45,
    //       },
    //       column: {
    //         pointPadding: 0,
    //         groupPadding: 0,
    //       },
    //     },

    //     // series: [
    //     //   {
    //     //     type: "column",
    //     //     name: "Functionality",
    //     //     data: [12,3,4,12],
    //     //     pointPlacement: "between",
    //     //   },
    //     // ],
    //     series: dummy.series,
    //   },
    // };

    setOptions({
      chart: {
        polar: true,
        height: 650,
        width: 700,
        events: {
          load: function () {
            if (label1 === false) this.series[0].hide();
            if (label2 === false) this.series[1].hide();
          },
        },
      },

      title: {
        // text: resp.allB[0].BankName,
        text: "",
      },
      exporting: {
        buttons: false,
      },

      pane: {
        startAngle: 0,
        endAngle: 360,
      },

      xAxis: {
        categories: names,
      },

      yAxis: {
        // min: 0,
        max: max_of_both,
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: "{point.y}",
          },
          point: {
            events: {
              click: function () {
                if (this.market === "a") {
                  loadPopup(
                    selectedMediaID,
                    44,
                    this.treeid,
                    undefined,
                    "6",
                    undefined,
                    "Functionalities Coverage Radar",
                    "Look further into the average number of functionalities offered in your market and the market of your focus to ensure that you have the features that your clients expect.",
                    gif
                  );
                }
                // else {
                if (this.market === "b") {
                  loadPopup(
                    selectedMediaID,
                    55,
                    this.treeid,
                    undefined,
                    "6",
                    undefined,
                    "Functionalities Coverage Radar",
                    "Look further into the average number of functionalities offered in your market and the market of your focus to ensure that you have the features that your clients expect.",
                    gif
                  );
                }
              },
            },
          },
          events: {
            legendItemClick: function () {
              if (this.visible) {
                if (this.name == "Primary Market")
                  setUserWorkspaceStateLabel(false, null);
                else if (this.name == "Target Market")
                  setUserWorkspaceStateLabel(null, false);
              } else {
                if (this.name == "Primary Market")
                  setUserWorkspaceStateLabel(true, null);
                else if (this.name == "Target Market")
                  setUserWorkspaceStateLabel(null, true);
              }
            },
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span> ',
        pointFormat:
          '<span style="color:{point.color}">{point.name_2}</span><b>{point.y}</b> Functionalities <br/>',
      },

      series: dummy.series,
    });
    // setData(resp);
    setIsLoading(false);
  }
  const setUserWorkspaceStateLabel = async (l1, l2) => {
    let data = {};
    let newState = workspaceMedia;
    if (l1 !== null) {
      newState.spiderLabel1 = l1;
      setLabel1(l1);
    }
    if (l2 !== null) {
      newState.spiderLabel2 = l2;
      setLabel2(l2);
    }
    data["charsState"] = [newState];
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    let resp = await metadataResponse.json();
  };

  async function setUserWorkspaceState(newmedia) {
    let data = {};
    var newState = workspaceMedia;
    newState.spidergraph = newmedia;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  useEffect(() => {
    chart_spidergraph();
  }, [selectedMediaID]);

  const handleMedia = (mediaid) => {
    setUserWorkspaceState(mediaid);
    setSelectedMediaID(mediaid);
  };

  const chartRef = useRef();

  return (
    <MDBox>
      <Card>
        <MDBox p={1}>
          <ChartsHeader
            title="Functionalities Coverage Radar"
            handleMedia={handleMedia}
            toolTipInfo={toolTipInfo}
            selectedMediaID={selectedMediaID}
            selectedName={selectedName}
            chartRef={chartRef}
          />
          {!isLoading ? (
            <>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                  ref={chartRef}
                />
              </MDBox>
            </>
          ) : (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ p: 25 }}
            >
              <CircularProgress />
            </MDBox>
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}
