/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState,useContext,useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CancelIcon from '@mui/icons-material/Cancel';


function Selections(props) {
  const [design, setDesign] = useState(false);
  const [code, setCode] = useState(false);
  const [develop, setDevelop] = useState(false);
  const [lastmid, setLastMid] = useState(null)

  const handleSetDesign = () => setDesign(!design);
  const handleSetCode = () => setCode(!code);
  const handleSetDevelop = () => setDevelop(!develop);
  const userContext = useContext(UserContext);
const [isLoading,setIsLoading]=useState(true);
const [functionalities,setFunctionalities]=useState([]);
const [selection,setSelection]=useState(props.value);
const [query, setQuery] = useState("")
const [checkAll, setCheckAll] = useState(false)
const [isEmpty, setIsEmpty] = useState(true)


// parent
   async function handleCheckParent(e,bid){
      // for more research
      // select all function
      // console.log(e)
      var boxes = document.getElementsByClassName(bid)
      // console.log("boxes",boxes)

      let activateall = false
      if (boxes[0].classList.contains("Mui-checked")){
          // console.log("deactivate all")
          activateall=false
      } else {
        // console.log("activate all")
          activateall=true
      }
      const { checked } = e.target;
      console.log(e.target);
      await  setSelection((values) => ({
          ...values,
          [bid]: checked
        }));
     if (activateall){
        for (var i=1;i<boxes.length;i++){
          var childBid = parseInt(boxes[i].children[0].value)
          boxes[i].classList.add("Mui-checked");
        await setSelection((values) => ({
          ...values,
          [childBid]: true
        }));
        }
      } else {
        for (var i=1;i<boxes.length;i++){
          var childBid = parseInt(boxes[i].children[0].value)
        boxes[i].classList.remove("Mui-checked")
         await setSelection((values) => ({
          ...values,
          [childBid]: false
        }));

      }
    }


    };

    const handleCheck = async (e,bid,this_mid)=> {

      // console.log(e.target._wrapperState.initialValue)

      setLastMid(this_mid)


        // handle empty children before check
        var father
        functionalities.map((i)=>{
          if(i.ID===this_mid){
            father = i
          }
        })
        var children_ids = []
        father.children.map((i)=>{
          children_ids.push(i.ID)
        })

        var emptyBefore = true
        children_ids.map((id)=>{
          if(selection[id]===true){
            emptyBefore = false
          }
        })
        setIsEmpty(emptyBefore)
        console.log("before check",emptyBefore)




      const { checked } = e.target;

      await  setSelection((values) => ({
          ...values,
          [bid]: checked
        }));



    };
    // child
  const handleCheck2 = (e,bid, mid)=> {

    setLastMid(mid)

     // handle empty children
     var father
     functionalities.map((i)=>{
       if(i.ID===mid){
         father = i
       }
     })
     var children_ids = []
     father.children.map((i)=>{
       children_ids.push(i.ID)
     })

     var empty = true
     children_ids.map((id)=>{
       if(selection[id]===true){
         empty = false
       }
     })
     console.log("before check",empty)
     setIsEmpty(empty)

    const { checked } = e.target;
    console.log("PAPARI");
    console.log(bid);
        console.log(checked);
      setSelection((values) => ({
        ...values,
        [bid]: checked
      }));
  };

async function doAction_getFunctionalitiesTemplate() {

  props.setDisableSave(true)
  var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL+="/api/node_getFunctionalitiesTemplate";
    var data={};
    data['parentid']="0";
    data['mediaid']="16";
    data['bankid']="107";
   const metadataResponse = await fetch(apiURL, {
     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  body:JSON.stringify(data),
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     headers: {
       'bearer': userContext.userData.apiToken,
       'Content-Type': 'application/json'
     },
   });

   var resp = await metadataResponse.json();


   if(!checkAll){

     if (selection.length<=0||selection==undefined) {
      resp.forEach(function(v,index) {
       setSelection((values) => ({
         ...values,
         [v.ID]: true
        }));
        v.children.forEach(function(vv,vindex) {
          setSelection((values) => ({
            ...values,
            [vv.ID]: true
          }));
        })
      })
        }
      setCheckAll(true)

    }

   setFunctionalities(resp);
   setIsLoading(false);
   props.setDisableSave(false)


}

const selectAll = () =>{

  var a=[];
  functionalities.forEach((item, i) => {
      a[i.ID]=true;
    item.children.forEach((ii,i)=>{
      a[ii.ID]=true;
    })

  });

  setSelection(a)

}

const unselectAll = () =>{
  var a=[];
  setSelection(JSON.stringify(a))
}

const handleQuery = (e) =>{
  setQuery(e.target.value)
}

useEffect(()=>{
  doAction_getFunctionalitiesTemplate();

  }, [query])

useEffect(()=>{

doAction_getFunctionalitiesTemplate();

}, []) // <-- empty dependency array

const handle_empty_dad = () =>{
  if(lastmid!==null){
    // handle empty children
    var father
    functionalities.map((i)=>{
      if(i.ID===lastmid){
        father = i
      }
    })
    var children_ids = []
    father.children.map((i)=>{
      children_ids.push(i.ID)
    })

    var emptyAfter = true
    children_ids.map((id)=>{
      if(selection[id]===true){
        emptyAfter = false
      }
    })

       // remove check from parent if it is empty after
       if(emptyAfter){
        var x = document.getElementsByClassName(lastmid)[0]
        if(x.classList.contains("Mui-checked")){
          x.classList.remove("Mui-checked")
        }
      } else {
        var x = document.getElementsByClassName(lastmid)[0]
        if(!x.classList.contains("Mui-checked")){
          x.classList.add("Mui-checked")
        }

      }
  }
 }



useEffect(()=>{
 console.log(selection)
     props.onChange(selection);

  //   handle_empty_dad();
}, [selection]) // <-- empty dependency array

  const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, info },
    typography: { size },
  }) => ({
    width: pxToRem(164),
    height: pxToRem(130),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      backgroundColor: `${transparent.main} !important`,
      border: `${borderWidth[2]} solid ${info.main} !important`,
      color: rgba(info.main, 0.75),
    },

    "& .material-icons-round": {
      fontSize: `${size["3xl"]} !important`,
    },
  });

  return (
    <MDBox>
      <MDBox width="80%" textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
          What are the functionalities you wish to include?
          </MDTypography>
        </MDBox>

        <TextField
      id="standard-basic"
      label="Sections"
      variant="standard"
      onChange={event => handleQuery(event)}
      sx={{width:500}}
      />

<MDBox  sx={{m:3, position:'absolute', right:10}}>

<MDButton
color="light"
title="Select all"
onClick={()=>{selectAll()}}
sx={{mr:2}}
><DoneAllIcon color="dark" /></MDButton>

<MDButton
color="light"
title="Unselect all"
onClick={()=>{unselectAll()}}
><CancelIcon color="dark" /></MDButton>

</MDBox>


        <MDTypography variant="body2" color="text">

        </MDTypography>
      </MDBox>

      {isLoading!=false?<>
        <MDBox display="flex" alignItems="center" justifyContent="center">
      <CircularProgress/>
      </MDBox>
      </>:
      <MDBox mt={2}>
        <Grid container spacing={3}>
           <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={8}>
          <MDBox mb={5}>

          <FormGroup>
            {functionalities.map((v, i) => (
            <>

              <FormControlLabel
            label={v.DisplayName}
              control={
              <Checkbox
              // onChange={(e)=>checkAll(e)}
              // onClick={(e)=>checkAll(e)}
              className= {v.ID}
              onChange={(e)=>handleCheckParent(e,v.ID)}
              checked={selection[v.ID]!==undefined?selection[v.ID]:false}

              />
              } />
               {
                // v.children.forEach(function(vv,vindex)
              v.children.filter(post => {
                if (query === '') {
                  return post;
                } else if (post.Name.toLowerCase().includes(query.toLowerCase())) {
                  return post;
                }
              }).map((vv,vindex) =>(
                <>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}
                  >
                    <FormControlLabel
                      label={vv.DisplayName}
                      control={
                        <Checkbox
                        className={vv.ParentID}
                        value={vv.ID}
                        onChange={(e)=>handleCheck(e,vv.ID, vv.ParentID)}
                        checked={selection[vv.ID]!==undefined?selection[vv.ID]:false}


                        />  } />
                </Box>
                  </>)

              )
            }


            </>
        ))}

           </FormGroup>


           </MDBox>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>

        </Grid>
      </MDBox>
     }
    </MDBox>
  );
}

export default Selections;
