import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import MDBox from "components/MDBox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import { useEffect, useState } from "react";

const MarketsList = ({
  listLabel = "Title",
  masterClear,
  marketA,
  marketB,
  setMarketA,
  setMarketB,
  tempMarketA,
  tempMarketB,
  setTempMarketA,
  setTempMarketB,
  data,
  setMasterClearA,
  setMasterClearB,
}) => {
  const [open, setOpen] = useState(false);
  const [checkChildren, setCheckChildren] = useState(false);
  const [checked, setChecked] = useState(false);
  const [flag, setFlag] = useState(false);
  const [checkedChildren, setCheckedChildren] = useState([]);

  // children check
  const handleToggle = (value) => () => {
    // handle if info comes from Market A tab
    if (marketA !== undefined) {
      const currentIndex = marketA.indexOf(value);
      const newMarketA = [...marketA];
      if (currentIndex === -1) {
        newMarketA.push(value);
      } else {
        newMarketA.splice(currentIndex, 1);
      }
      setMarketA(newMarketA);
      setTempMarketA(newMarketA);
    }

    // handle if info comes from Market A tab
    if (marketB !== undefined) {
      const currentIndex = marketB.indexOf(value);
      const newMarketB = [...marketB];
      if (currentIndex === -1) {
        newMarketB.push(value);
      } else {
        newMarketB.splice(currentIndex, 1);
      }
      setMarketB(newMarketB);
      setTempMarketB(newMarketB);
    }

    // local component state for more responsive UX
    const currentIndex = checkedChildren.indexOf(value);
    const newChecked = [...checkedChildren];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length > 0) setChecked(true);
    else setChecked(false);
    setCheckedChildren(newChecked);
  };

  // select all children
  const doSelectAll = () => {
    const newChecked = [...checkedChildren];
    let newMarketA;
    let newMarketB;
    if (marketA !== undefined) newMarketA = [...marketA];
    if (marketB !== undefined) newMarketB = [...marketB];

    data.forEach((item) => {
      if (!checkedChildren.includes(item)) {
        if (
          marketA !== undefined &&
          !marketA.includes(item) &&
          !tempMarketB.includes(item) &&
          newChecked
        ) {
          newMarketA.push(item);
          newChecked.push(item);
        }
        if (
          marketB !== undefined &&
          !marketB.includes(item) &&
          !tempMarketA.includes(item) &&
          newChecked
        ) {
          newMarketB.push(item);
          newChecked.push(item);
        }
      }
    });

    if (marketA !== undefined) {
      setMarketA(newMarketA);
      setTempMarketA(newMarketA);
      setCheckedChildren(newChecked);
    }
    if (marketB !== undefined) {
      setMarketB(newMarketB);
      setTempMarketB(newMarketB);
      setCheckedChildren(newChecked);
    }
  };

  // unselect all children
  const doRemoveAll = () => {
    if (marketA !== undefined) {
      let newMarketA = [...marketA];
      checkedChildren.forEach((item) => {
        if (marketA.includes(item)) {
          newMarketA.splice(newMarketA.indexOf(item), 1);
        }
        setMarketA(newMarketA);
        setTempMarketA(newMarketA);
      });
    }
    if (marketB !== undefined) {
      let newMarketB = [...marketB];
      checkedChildren.forEach((item) => {
        if (marketB.includes(item)) {
          newMarketB.splice(newMarketB.indexOf(item), 1);
        }
        setMarketB(newMarketB);
        setTempMarketB(newMarketB);
      });
    }
    setCheckedChildren([]);
  };

  // track if parent got clicked and remove or select all
  useEffect(() => {
    if (checkChildren) doSelectAll();
    else doRemoveAll();
  }, [flag]);

  // parent's checkbox
  const handleCheck = () => {
    if (checked && checkedChildren.length > 0) {
      setCheckChildren(false);
      setChecked(false);
      setFlag(!flag);
    } else {
      setCheckChildren(true);
      setChecked(true);
      setFlag(!flag);
    }
  };

  // track if 'clear all' button clicked
  useEffect(() => {
    if (masterClear > 0) {
      if (marketA !== undefined) {
        setMarketA([]);
        setTempMarketA([]);
      }
      if (marketB !== undefined) {
        setMarketB([]);
        setTempMarketB([]);
      }
      setChecked(false);
      setCheckChildren(false);
      setFlag(!flag);
      if (setMasterClearA !== undefined) setMasterClearA(0);
      if (setMasterClearB !== undefined) setMasterClearB(0);
    }
  }, [masterClear]);

  // if there are set data
  useEffect(() => {
    let newChecked = [...checkedChildren];
    if (marketA && marketA.length > 0) {
      data.forEach((item) => {
        if (marketA.includes(item) && !newChecked.includes(item))
          newChecked.push(item);
      });
      if (newChecked.length > 0) {
        setCheckedChildren(newChecked);
        setChecked(true);
      }
    }
    if (marketB && marketB.length > 0) {
      data.forEach((item) => {
        if (marketB.includes(item) && !newChecked.includes(item))
          newChecked.push(item);
      });
      if (newChecked.length > 0) {
        setCheckedChildren(newChecked);
        setChecked(true);
      }
    }
  }, [marketA, marketB]);

  return (
    <List sx={{ marginBottom: 2 }} disableRipple disablePadding>
      {/* major category JSX */}

      <MDBox sx={{ display: "flex" }}>
        <ListItemButton
          sx={{ flexGrow: 0 }}
          onClick={handleCheck}
          dense
          disableRipple
          disablePadding
        >
          <Checkbox
            edge="start"
            checked={checked && checkedChildren.length > 0}
            disableRipple
            disablePadding
          />
        </ListItemButton>

        <ListItemButton sx={{ flexGrow: 1 }} onClick={() => setOpen(!open)}>
          <ListItemText
            disableTypography
            sx={{ fontSize: 18, fontWeight: "bold" }}
          >
            {listLabel}
          </ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </MDBox>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {/* building children for each category */}
        <List
          className="scrollhost"
          disablePadding
          disableRipple
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            overflow: "auto",
            // maxHeight: 350, an thelo i upolistes na exoun scroll kai maxHeight
          }}
        >
          {marketA &&
            data &&
            data.length > 0 &&
            data.map((value) => (
              <ListItem key={value.BankID} disablePadding>
                <ListItemButton
                  onClick={handleToggle(value)}
                  dense
                  disabled={tempMarketB.includes(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                        marketA.includes(value) && !tempMarketB.includes(value)
                      }
                      tabIndex={-1}
                      disableRipple
                      disabled={tempMarketB.includes(value)}
                    />
                  </ListItemIcon>
                  <ListItemText>{value.BankName}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          {marketB &&
            data &&
            data.length > 0 &&
            data.map((value) => (
              <ListItem key={value.BankID} disablePadding>
                <ListItemButton
                  onClick={handleToggle(value)}
                  dense
                  disabled={tempMarketA.includes(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                        marketB.includes(value) && !tempMarketA.includes(value)
                      }
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText>{value.BankName}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        {/* end of building children for each category */}
      </Collapse>
    </List>
  );
};

export default MarketsList;
