import "./style.css";

const PrevButton = ({ children, onClick, height, width, fontWeight }) => {
  return (
    <button
      className={"PrevButton"}
      onClick={onClick}
      style={{
        height: height ?? "70px",
        width: width ?? "100%",
        fontWeight: fontWeight ?? null,
      }}
    >
      {children}
    </button>
  );
};

export default PrevButton;
