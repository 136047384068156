/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useContext } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Invoice page components
import UserContext from "context/UserContext";
import CircularProgress from "@mui/material/CircularProgress";
// import "../first_page/index.css";
import "./index.css";

// Images
import logoCT from "assets/images/logo-ct.png";
import logoCTDark from "assets/images/logo-ct-dark.png";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import WelcomeLayout from "layouts/authentication/components/WelcomeLayout";
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { DefaultPlayer as Video } from "react-html5video";
import AppleIcon from "@mui/icons-material/Apple";
import ComputerIcon from "@mui/icons-material/Computer";
import AndroidIcon from "@mui/icons-material/Android";
import WatchIcon from "@mui/icons-material/Watch";
import MDInput from "components/MDInput";
import brandDark from "assets/images/fi-small.png";
// import brandDark from "assets/images/xmas.png";
// import VerifyPhoneModal from "scientia/verifyPhoneModal/VerifyPhoneModal";

function Workspace() {
  const userContext = useContext(UserContext);
  const [controller] = useMaterialUIController();
  const [activeDashboard, setActiveDashboard] = useState({
    Title: "",
    _id: "",
  });
  const [dashboards, setDashboards] = useState([]);
  const [unique_users, setUniqueUsers] = useState([]);
  // dropdown
  const [action, setAction] = useState(false);
  const handleChange = () => {
    setAction(!action);
  };

  // verify phone modal
  const [phone, setPhone] = useState(null);
  const [open, setOpen] = useState(false);

  const getSecuritySettings = async () => {
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/setting/get";
    const metadataResponse = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    const resp = await metadataResponse.json();

    return metadataResponse.ok ? resp.org.PhoneVerification : false;
  };

  async function doAction_getMyDetails() {
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/getMyDetails";
    const metadataResponse = await fetch(apiURL, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    const resp = await metadataResponse.json();
    setPhone(resp.phone);

    if (resp.PhoneIsVerified === undefined) return false;
    return metadataResponse.ok ? resp.PhoneIsVerified : true;
  }

  const SecurityCheck = async () => {
    const SecurityStatus = await getSecuritySettings();
    const UserIsVerified = await doAction_getMyDetails();
    if (SecurityStatus && !UserIsVerified) setOpen(true);
    else window.location.href = "/home";
  };

  async function checkLoading() {
    // filter subs

    var data = {};

    data["tk"] = userContext.userData.tk;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/checkLoading";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      body: JSON.stringify(data),
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    if (metadataResponse.status !== 200) {
      setMessage(
        <>Your authentication token is expired, please login again.</>
      );
      setTimeout(() => {
        window.location.href = "/logout";
      }, 1500);

      return;
    }

    var resp = await metadataResponse.json();
    if (resp.new_wizard !== undefined) {
      setMessage(
        <>This is the first time you sign in to FinTech Insights, please wait</>
      );
      setTimeout(() => {
        window.location.href = "/welcome";
      }, 1500);
      return;
    }
    if (resp.wizard !== undefined) {
      setMessage(
        <>
          Your selected Market doesn't hane any enabled banks.
          <br /> Please choose another Market.
        </>
      );
      setTimeout(() => {
        window.location.href = "/welcome";
      }, 3000);
      return;
    }
    if (resp.disabled !== undefined) {
      setMessage(
        <>
          Your organization subscription has been expired. Contact our sales
          representatives.
        </>
      );
      setTimeout(() => {
        window.location.href = "/logout";
      }, 3000);
      return;
    }

    setTimeout(() => {
      window.location.href = "/analytics";
    }, 1000);
    return;
  }

  const [message, setMessage] = useState(null);

  useEffect(() => {
    checkLoading();
  }, []); // <-- empty dependency array

  return (
    <WelcomeLayout>
      {/* <VerifyPhoneModal
        open={open}
        setOpen={setOpen}
        phone={phone}
        setPhone={setPhone}
      /> */}
      <header class="header-main myloginheader">
        <img src="/images/header-bg.svg" alt="header-bg" class="header-bg" />
        <div class="container">
          <img src="/images/logo-main-header.svg" alt="logo" />
        </div>
      </header>
      <main>
        <div class="container">
          <div class="flex-row">
            {/* <img src="/images/arrow-nav-title.svg" alt="arrow" />
            <h1 class="title-header">Back to FinTech Insights</h1> */}
          </div>
          <div class="form-container">
            <form class="sign-up-form">
              <h2 class="title-form text-centered">
                Please wait while your workspace is loading
              </h2>
              {message !== null ? (
                <MDBox height={15}>
                  <MDTypography variant="h5" color="warning">
                    {message}
                  </MDTypography>
                </MDBox>
              ) : (
                <>
                  <MDBox height={15}>
                    <MDTypography variant="h5" color="warning">
                      {" "}
                    </MDTypography>
                  </MDBox>
                </>
              )}
              <MDBox>
                <div class="flex-row text-centered">
                  <div class="loading-div">
                    <CircularProgress color="info" size={80} />
                  </div>
                </div>
              </MDBox>
            </form>
          </div>
        </div>
      </main>
    </WelcomeLayout>
  );
}

export default Workspace;
