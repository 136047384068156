import Card from "@mui/material/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext, useEffect, useRef } from "react";
import UserContext from "context/UserContext";
import AppleIcon from "@mui/icons-material/Apple";
import ComputerIcon from "@mui/icons-material/Computer";
import WatchIcon from "@mui/icons-material/Watch";
import MDButton from "components/MDButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { line } from "stylis";
import ChartsHeader from "../ChartsHeader";

export default function UXBreakdown({ loadPopup, mediaid, workspaceMedia }) {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [selectedMediaID, setSelectedMediaID] = useState(mediaid);
  const [isLoadingMarketPosition, setIsLoadingMarketPosition] = useState(true);

  const [inner_graph_visible, setInnerGraphVisible] = useState(false);
  const [innerGraphData, setInnerGraphData] = useState(null);

  const [marketA, setMarketA] = useState(null);
  const [marketB, setMarketB] = useState(null);

  const [
    options_functionalities_breakdown,
    setOptions_functionalities_breakdown,
  ] = useState({
    title: {
      text: "Functionalities Breakdown",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });
  const defaultBank = "107"; // REVOLUT V.7 (EU)
  const defaultBankname = "";

  const [optionsMarketPosition, setOptionsMarketPosition] = useState({
    title: {
      text: "Market Position",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });

  const [options_ux_breakdown, setOptions_ux_breakdown] = useState(null);

  const [dimensions, setDimensions] = useState({
    // height: window.innerHeight,
    width: window.innerWidth,
  });

  const goPreviousGraph = () => {
    console.log("go back");

    setInnerGraphVisible(false);

    var dummy = {
      series: [
        {
          name: "Markets",
          data: [
            {
              name: "Market A",
              name_2: "Market A",
              color: "rgb(2, 7, 70)",
              id: "a",
              myDrilldown: "media16",
              y: marketA,
            },
            {
              name: "Market B",
              name_2: "Market B",
              color: "rgb(78,98,243)",
              id: "b",
              myDrilldown: "media22",
              y: marketB,
            },
          ],
        },
      ],
    };

    setOptions_ux_breakdown({
      chart: {
        drillID: "A",
        type: "column",
        events: {
          //	drillupall: drillup
        },
        width: dimensions.width - 230,
      },
      title: {
        //text: 'Barclays Average Use Case score Per Section'
        text: "",
      },
      credits: {
        enabled: false,
      },

      xAxis: {
        // type: 'category',
        categories: ["Market A", "Market B"],
        crosshair: true,
        // labels: {
        //     formatter: function() {
        //         return '<span></span>';
        //     }
        // },
        // categories: $categoryBank
      },
      yAxis: {
        title: {
          text: "User Experience Score",
        },
      },

      exporting: {
        buttons: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: "{point.y}",
          },
          point: {
            events: {
              click: function () {
                console.log(this.id);
                inside_markets(this.id);
              },
            },
          },
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name_2}</span> with a score of <b>{point.y}</b> <br/>',
      },

      series: dummy.series,
      drilldown: {
        drillUpButton: {
          relativeTo: "spacingBox",
          position: {
            //align: 'left',
            x: -25,
            y: -5,
            //verticalAlign: 'top',
            verticalAlign: "top",
          },
          theme: {
            fill: "white",
            "stroke-width": 1,
            stroke: "silver",
            r: 0,
            states: {
              hover: {
                stroke: "#4d5eFC",
                fill: "#4d5eFC",
              },
              select: {
                stroke: "#2f3b4a",
                fill: "#4d5eFC",
              },
            },
          },
        },
        series: dummy.series,
      },
    });
  };

  async function chart_marketposition(mediaid) {
    setSelectedMediaID(mediaid);
    setIsLoading(true);
    setIsLoadingMarketPosition(true);

    var data = {};

    data["mediaid"] = mediaid;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_superchart";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var json = await metadataResponse.json();
    var resp = [];
    for (var i in json) {
      resp.push(json[i]);
    }

    setOptionsMarketPosition({
      chart: {
        defaultSeriesType: "scatter",
        height: 550,
        zoomType: "xy",
      },
      title: "quadrant",
      plotOptions: {
        series: {
          softThreshold: false,
          stickyTracking: false,
          cursor: "pointer",
        },
      },
      xAxis: {
        startOnTick: true,
        endOnTick: true,
        tickInterval: 1,
        tickPositioner(min, max) {
          let maxTick = Math.ceil(max);
          const minTick = Math.floor(min);
          if ((maxTick + minTick) % 2 === 1) {
            maxTick += 1;
          }
          const midTick = (maxTick + minTick) / 2;
          return [minTick, midTick, maxTick];
        },
        gridLineWidth: 1,
        title: {
          text: "Functionalities",
        },
      },
      yAxis: {
        startOnTick: false,
        tickPositioner(min, max) {
          // Only render three ticks, two on the edges and one in the middle
          const mid = (min + max) / 2;
          return [min, mid, max];
        },
        title: {
          text: "UX Score",
        },
      },
      tooltip: {
        useHTML: true,
        borderWidth: 0,
        hideDelay: 0,
        backgroundColor: "#efefef",
        shadow: false,
        outside: true,
      },
      series: [
        {
          name: "A",
          color: "rgba(223, 83, 83, .5)",
          data: [[10000, 50000]],
        },
        {
          name: "B",
          color: "rgba(119, 152, 191, .5)",
          data: [[2000, 100000]],
        },
        {
          name: "C",
          color: "rgba(119, 152, 191, .5)",
          data: [[100000, 10000]],
        },
        {
          name: "D",
          color: "rgba(119, 152, 191, .5)",
          data: [[5000, 80000]],
        },
      ],
    });
    // setData(resp);
    setIsLoading(false);
    setIsLoadingMarketPosition(false);
  }

  async function chart_functionalities_breakdown(bankid, bankname) {
    setOptions_functionalities_breakdown({
      title: {
        text: "Please wait",
      },
      series: [],
    });

    setIsLoading(true);

    var data = {};
    data["bankid"] = bankid;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_functionalities_breakdown";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();
    //console.log(resp);

    setOptions_functionalities_breakdown({
      chart: {
        type: "column",
      },
      title: {
        text: "Functionalities Breakdown",
      },
      subtitle: {
        text: bankname,
      },
      xAxis: {
        categories: ["Web", "Mobile"],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Functionalities",
        },
      },
      tooltip: {
        formatter: function (d) {
          //  console.log(this.point.index);
          var rv = '<span style="color:' + this.point.color + '">\u25CF';
          if (this.series.name == "Functionalities") {
            rv = bankname;
          } else if (this.series.name == "Average") {
            rv = "Average of collection is ";
          } else if (this.series.name == "Worst") {
            rv = "Worst performer is ";
            if (this.point.index == 0) rv += resp.worst_16[0].BankName;
            else rv += resp.worst_23[0].BankName;
          } else {
            rv = "Best performer is ";
            if (this.point.index == 0) rv += resp.best_16[0].BankName;
            else rv += resp.best_23[0].BankName;
          }

          rv += "</span> <br>with <b> " + this.y + "</b> functionalities<br/>";
          return rv;
        },
      },
      plotOptions: {
        column: {
          colorByPoint: true,
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                //  chart_functionalities_overview_forBankMedia(bankid,fc[this.category]);
              },
            },
          },
        },
      },
      series: [
        {
          name: "Functionalities",
          data: [resp.my_16[0].Functionalities, resp.my_23[0].Functionalities],
        },
        {
          type: "spline",
          name: "Average",
          data: [resp.avg_16[0].avg, resp.avg_23[0].avg],
          marker: {
            lineWidth: 2,
            lineColor: Highcharts.getOptions().colors[3],
            fillColor: "white",
          },
        },
        {
          type: "spline",
          name: "Worst",
          data: [
            resp.worst_16[0].Functionalities,
            resp.worst_23[0].Functionalities,
          ],
          marker: {
            lineWidth: 2,
            lineColor: Highcharts.getOptions().colors[1],
            fillColor: "white",
          },
        },
        {
          type: "spline",
          name: "Best",
          data: [
            resp.best_16[0].Functionalities,
            resp.best_23[0].Functionalities,
          ],
          marker: {
            lineWidth: 2,
            lineColor: Highcharts.getOptions().colors[2],
            fillColor: "white",
          },
        },
      ],
    });
    // setData(resp);
    setIsLoading(false);
  }

  async function getDashboards() {
    setIsLoading(true);

    var data = {};
    data["mediaid"] = selectedMediaID;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_ux_breakdown";
    const metadataResponse2 = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse2.json();
    console.log(resp);

    //  setMarkets(resp)

    // console.log(resp.marketA[0].avgQuantity)
    if (resp.marketA.length === 0) {
      var quantityA = 0;
    } else {
      var quantityA = resp.marketA[0].avgQuantity.toFixed(0);
      quantityA = parseFloat(quantityA);
    }

    if (resp.marketB.length === 0) {
      var quantityB = 0;
    } else {
      var quantityB = resp.marketB[0].avgQuantity.toFixed(0);
      quantityB = parseFloat(quantityB);
    }

    setMarketA(quantityA);
    setMarketB(quantityB);

    var dummy = {
      series: [
        {
          name: "Markets",
          data: [
            {
              name: "Market A",
              name_2: "Market A",
              color: "rgb(2, 7, 70)",
              id: "a",
              myDrilldown: "media16",
              y: quantityA,
            },
            {
              name: "Market B",
              name_2: "Market B",
              color: "rgb(78,98,243)",
              id: "b",
              myDrilldown: "media22",
              y: quantityB,
            },
          ],
        },
      ],
    };

    setOptions_ux_breakdown({
      chart: {
        drillID: "A",
        type: "column",
        events: {
          //	drillupall: drillup
        },
        width: dimensions.width - 230,
      },
      title: {
        //text: 'Barclays Average Use Case score Per Section'
        text: "",
      },
      credits: {
        enabled: false,
      },
      exporting: {
        buttons: false,
      },
      xAxis: {
        // type: 'category',
        categories: ["Market A", "Market B"],
        crosshair: true,
        // labels: {
        //     formatter: function() {
        //         return '<span></span>';
        //     }
        // },
        // categories: $categoryBank
      },
      yAxis: {
        title: {
          text: "User Experience Score",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: "{point.y}",
          },
          point: {
            events: {
              click: function () {
                console.log(this.id);
                inside_markets(this.id);
              },
            },
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name_2}</span> with a score of <b>{point.y}</b> <br/>',
      },

      series: dummy.series,
      drilldown: {
        drillUpButton: {
          relativeTo: "spacingBox",
          position: {
            //align: 'left',
            x: -25,
            y: -5,
            //verticalAlign: 'top',
            verticalAlign: "top",
          },
          theme: {
            fill: "white",
            "stroke-width": 1,
            stroke: "silver",
            r: 0,
            states: {
              hover: {
                stroke: "#4d5eFC",
                fill: "#4d5eFC",
              },
              select: {
                stroke: "#2f3b4a",
                fill: "#4d5eFC",
              },
            },
          },
        },
        series: dummy.series,
      },
    });

    setIsLoading(false);
  }

  useEffect(() => {
    getDashboards();
    window.addEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    setDimensions({
      // height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  useEffect(() => {
    setIsLoading(true);

    if (inner_graph_visible) {
      var dummy = innerGraphData.dummy;
      var mycategories = innerGraphData.mycategories;
      setOptions_ux_breakdown({
        chart: {
          drillID: "A",
          type: "column",
          events: {
            //	drillupall: drillup
          },
          width: dimensions.width - 230,
        },
        title: {
          //text: 'Barclays Average Use Case score Per Section'
          text: "",
        },
        credits: {
          enabled: false,
        },

        xAxis: {
          // type: 'category',
          categories: mycategories,
          crosshair: true,

          // labels: {
          //     formatter: function() {
          //         return '<span></span>';
          //     }
          // },
          // categories: $categoryBank
        },
        yAxis: {
          title: {
            text: "User Experience Score",
          },
        },
        exporting: {
          buttons: false,
        },
        legend: {
          enabled: true,
        },
        plotOptions: {
          series: {
            cursor: "pointer",
            borderWidth: 0,
            dataLabels: {
              enabled: false,
              format: "{point.y}",
            },
            point: {
              events: {
                click: function () {
                  console.log(this);
                  //  loadPopup(selectedMediaID, 2, this.treeid, undefined, "5",undefined)
                  //      loadPopup(selectedMediaID, 9, this.treeid, undefined, "5",undefined)
                  // inside_markets(this.id)
                },
              },
            },
          },
        },

        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name_2}</span> with a score of <b>{point.y}</b> <br/>',
        },

        series: dummy.series,
        drilldown: {
          drillUpButton: {
            relativeTo: "spacingBox",
            position: {
              //align: 'left',
              x: -25,
              y: -5,
              //verticalAlign: 'top',
              verticalAlign: "top",
            },
            theme: {
              fill: "white",
              "stroke-width": 1,
              stroke: "silver",
              r: 0,
              states: {
                hover: {
                  stroke: "#4d5eFC",
                  fill: "#4d5eFC",
                },
                select: {
                  stroke: "#2f3b4a",
                  fill: "#4d5eFC",
                },
              },
            },
          },
          series: dummy.series,
        },
      });
    } else {
      var dummy = {
        series: [
          {
            name: "Markets",
            data: [
              {
                name: "Market A",
                name_2: "Market A",
                color: "rgb(2, 7, 70)",
                id: "a",
                myDrilldown: "media16",
                y: marketA,
              },
              {
                name: "Market B",
                name_2: "Market B",
                color: "#c608db",
                id: "b",
                myDrilldown: "media22",
                y: marketB,
              },
            ],
          },
        ],
      };

      setOptions_ux_breakdown({
        chart: {
          drillID: "A",
          type: "column",
          events: {
            //	drillupall: drillup
          },
          width: dimensions.width - 230,
        },
        title: {
          //text: 'Barclays Average Use Case score Per Section'
          text: "",
        },
        credits: {
          enabled: false,
        },
        exporting: {
          buttons: false,
        },
        xAxis: {
          // type: 'category',
          categories: ["Market A", "Market B"],
          crosshair: true,
          // labels: {
          //     formatter: function() {
          //         return '<span></span>';
          //     }
          // },
          // categories: $categoryBank
        },
        yAxis: {
          title: {
            text: "User Experience Score",
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            cursor: "pointer",
            borderWidth: 0,
            dataLabels: {
              enabled: false,
              format: "{point.y}",
            },
            point: {
              events: {
                click: function () {
                  console.log(this.id);
                  inside_markets(this.id);
                },
              },
            },
          },
        },

        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name_2}</span> with a score of <b>{point.y}</b> <br/>',
        },

        series: dummy.series,
        drilldown: {
          drillUpButton: {
            relativeTo: "spacingBox",
            position: {
              //align: 'left',
              x: -25,
              y: -5,
              //verticalAlign: 'top',
              verticalAlign: "top",
            },
            theme: {
              fill: "white",
              "stroke-width": 1,
              stroke: "silver",
              r: 0,
              states: {
                hover: {
                  stroke: "#4d5eFC",
                  fill: "#4d5eFC",
                },
                select: {
                  stroke: "#2f3b4a",
                  fill: "#4d5eFC",
                },
              },
            },
          },
          series: dummy.series,
        },
      });
    }

    setIsLoading(false);
  }, [dimensions]);

  async function setUserWorkspaceState(newmedia) {
    let data = {};
    var newState = workspaceMedia;
    console.log(newState);
    newState.ux_breakdown = newmedia;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  const handleMedia = (mediaid) => {
    // setUserWorkspaceState(mediaid)
    setUserWorkspaceState(mediaid);
    setSelectedMediaID(mediaid);
    // getDashboards()
  };

  useEffect(() => {
    getDashboards();
  }, [selectedMediaID]);

  const inside_markets = async (marketid) => {
    setIsLoading(true);

    // 1st api call
    var data = {};
    data["mediaid"] = selectedMediaID;
    data["market"] = marketid;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_ux_breakdown_inside";
    const metadataResponse2 = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var totalresp = await metadataResponse2.json();
    //  resp= resp.filter(word => !word._id.ParentName.includes(";"));

    let resp, resp2;
    if (marketid === "a") {
      resp = totalresp.ma;
      resp2 = totalresp.mb;
    }
    if (marketid === "b") {
      resp = totalresp.mb;
      resp2 = totalresp.ma;
    }

    // take care of &
    resp.map((word) => {
      if (word._id.ParentName.includes("Login")) {
        var name = word._id.ParentName;
        name = name.replace("&", "and");
        name = name.replace("andamp;", "and");
        word._id.ParentName = name;
      }
    });
    var login_found = 0;
    resp.map((word) => {
      if (word._id.ParentName.includes("Login")) {
        login_found++;
      }
    });
    if (login_found === 2) {
      resp.shift();
    }
    console.log(resp);

    //  2nd api call take care of &
    resp2.map((word) => {
      if (word._id.ParentName.includes("Login")) {
        var name = word._id.ParentName;
        name = name.replace("&", "and");
        name = name.replace("andamp;", "and");
        word._id.ParentName = name;
      }
    });
    var login_found = 0;
    resp2.map((word) => {
      if (word._id.ParentName.includes("Login")) {
        login_found++;
      }
    });
    if (login_found === 2) {
      resp2.shift();
    }
    console.log(resp2);

    var arr = [];

    var mycategories = [];
    //  find all market a columns
    resp.map((i) => {
      var name = i._id.ParentName;
      mycategories.push(name);
    });
    console.log(mycategories);

    // find all market b line names
    var line_names = [];
    resp2.map((i) => {
      var name = i._id.ParentName;
      name = name.replace("&", "and");
      name = name.replace("andamp;", "and");
      line_names.push(name);
    });
    console.log(line_names);

    // get both markets names
    var c = mycategories.concat(line_names);
    var both_markets = c.filter((item, pos) => c.indexOf(item) === pos);
    console.log("both", both_markets);

    // get market difference
    let difference = mycategories
      .filter((x) => !line_names.includes(x))
      .concat(line_names.filter((x) => !mycategories.includes(x)));
    console.log("dif", difference);

    //  generate our columns
    resp.map((i) => {
      var name = i._id.ParentName;
      if (i.avgQuantity !== undefined) {
        var quantity = i.avgQuantity.toFixed(0);
      } else {
        var quantity = 0;
      }
      // Math.round(quantity)
      quantity = parseFloat(quantity);
      // console.log(quantity)
      let obj = {
        name: name,
        name_2: i._id.ParentName,
        color: "#" + Math.floor(Math.random() * 16777215).toString(16),
        id: i._id.ParentName,
        myDrilldown: "media16",
        y: quantity,
        treeid: i._id.TreeID,
      };
      arr.push(obj);
    });

    // traverse array difference with resp2 when market a < market b
    //  if(difference.length>0 && mycategories.length<line_names.length){
    //     difference.map((i)=>{

    //         // for charts
    //         let obj = {
    //             "name": i,
    //             "name_2": i,
    //             "color":  '#'+ Math.floor(Math.random()*16777215).toString(16),
    //             "id": i,
    //             "myDrilldown": "media16",
    //             "y": 0,

    //         }
    //         arr.push(obj)

    //     })

    // }

    var lines = [];
    // generate our line
    both_markets.map((i) => {
      var not_found = true;
      resp2.map((ii) => {
        if (ii._id.ParentName === i) {
          if (ii.avgQuantity !== undefined) {
            var quantity = ii.avgQuantity.toFixed(0);
          } else {
            var quantity = 0;
          }

          lines.push(parseFloat(quantity));
          not_found = false;
        }
      });
      if (not_found) {
        lines.push(0);
      }
    });
    console.log("lines", lines);

    var dummy = {
      series: [
        {
          name: "Market " + marketid.toUpperCase(),
          data: arr,
        },
        {
          type: "line",
          name: marketid === "a" ? "Market B" : "Market A",
          data: lines,
          // data: [resp.avg_16[0].avg, resp.avg_23[0].avg],
          marker: {
            lineWidth: 2,
            lineColor: Highcharts.getOptions().colors[4],
            fillColor: "white",
          },
        },
      ],
    };

    var innergraph = {};
    innergraph["dummy"] = dummy;
    innergraph["mycategories"] =
      line_names.length > mycategories.length ? line_names : mycategories;
    setInnerGraphData(innergraph);
    setInnerGraphVisible(true);

    setOptions_ux_breakdown({
      chart: {
        drillID: "A",
        type: "column",
        events: {
          //	drillupall: drillup
        },
        width: dimensions.width - 230,
      },
      title: {
        //text: 'Barclays Average Use Case score Per Section'
        text: "",
      },
      credits: {
        enabled: false,
      },
      exporting: {
        buttons: false,
      },
      xAxis: {
        // type: 'category',
        categories: both_markets,
        crosshair: true,

        // labels: {
        //     formatter: function() {
        //         return '<span></span>';
        //     }
        // },
        // categories: $categoryBank
      },
      yAxis: {
        title: {
          text: "User Experience Score",
        },
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: "{point.y}",
          },
          point: {
            events: {
              click: function () {
                console.log(this);
                if (this.myDrilldown !== undefined) {
                  if (marketid === "a") {
                    console.log("show a");

                    loadPopup(
                      selectedMediaID,
                      9,
                      this.treeid,
                      undefined,
                      "5",
                      undefined
                    );
                  }
                  if (marketid === "b") {
                    console.log("show b");
                    loadPopup(
                      selectedMediaID,
                      10,
                      this.treeid,
                      undefined,
                      "5",
                      undefined
                    );
                  }
                }
                // inside_markets(this.id)
              },
            },
          },
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span> ',
        pointFormat:
          '<span style="color:{point.color}">{point.name_2}</span> with a score of <b>{point.y}</b> <br/>',
      },

      series: dummy.series,
      drilldown: {
        drillUpButton: {
          relativeTo: "spacingBox",
          position: {
            //align: 'left',
            x: -25,
            y: -5,
            //verticalAlign: 'top',
            verticalAlign: "top",
          },
          theme: {
            fill: "white",
            "stroke-width": 1,
            stroke: "silver",
            r: 0,
            states: {
              hover: {
                stroke: "#4d5eFC",
                fill: "#4d5eFC",
              },
              select: {
                stroke: "#2f3b4a",
                fill: "#4d5eFC",
              },
            },
          },
        },
        series: dummy.series,
      },
    });
    setIsLoading(false);
  };

  const chartRef = useRef();

  return (
    <Card>
      <MDBox p={1}>
        <ChartsHeader
          title="UX Scoring Breakdown per Market"
          handleMedia={handleMedia}
          selectedMediaID={selectedMediaID}
          chartRef={chartRef}
        />
        {inner_graph_visible && (
          <ArrowBackIcon
            color="dark"
            onClick={() => goPreviousGraph()}
            fontSize="medium"
            sx={{
              position: "absolute",
              left: 6,
              top: 32,
              cursor: "pointer",
              zIndex: 1,
            }}
          />
        )}

        {!isLoading ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={options_ux_breakdown}
            ref={chartRef}
          />
        ) : (
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ p: 25 }}
          >
            <CircularProgress />
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}
