import { useEffect } from "react";

const Timer = ({ timer, setTimer }) => {
  useEffect(() => {
    let innerTimer = timer;
    const interval = setInterval(() => {
      if (innerTimer === 0) return clearInterval(interval);
      innerTimer--;
      setTimer((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <b>
      {timer < 10 ? "0" : ""}
      {timer}
    </b>
  );
};

export default Timer;
