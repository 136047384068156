const initialState = {

    showActiveFunctionalities:false,
    notifications:[
        {id:1,status:'add_bank',text:'New Bank Revolut was added', time:'10 minutes ago'},
        {id:2,status:'add_bank',text:'New Bank Stripe was added', time:'15 minutes ago'},
        {id:3,status:'add_org',text:'New Organization was added', time:'20 minutes ago'},
        {id:4,status:'add_func',text:'Revolut has a new functionality', time:'25 minutes ago'},
        {id:5,status:'error',text:'There was an error with your login', time:'2 hours ago'},
      ],
    lastSidenavNode:[],
    allbanks:[],
    UserHasDarkMode:false

}

let newState


export const AppStateReducer=(state=initialState, action)=>{
    switch(action.type){
        case 'SHOW_FUNCTIONALITIES_ACTIVE':
            newState = Object.assign({},state, {showActiveFunctionalities:true})
            return newState;
        case 'SHOW_FUNCTIONALITIES_INACTIVE':
            newState = Object.assign({},state, {showActiveFunctionalities:false})
            return newState;    
        case 'CHANGE_LAST_SIDENAV_NODE':
            newState = Object.assign({},state, {lastSidenavNode: action.payload })
            // console.log('look',action.payload,newState)
            return newState; 
        case 'CHANGE_NOTIFICATIONS':
            newState = Object.assign({},state, {notifications: action.payload })
            return newState

        case 'CHANGE_USER_DARK_MODE':
            newState = Object.assign({},state, {UserHasDarkMode:action.payload})
            console.log('new state',newState)
            return newState;

        case 'CHANGE_ALLBANKS':
            newState = Object.assign({},state, {allbanks: action.payload })
            return newState
         default:
            return state
    }
}