import Card from "@mui/material/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext, useEffect, useRef } from "react";
import UserContext from "context/UserContext";
import gif from "assets/images/marketwebp.webp";
import ChartsHeader from "../ChartsHeader";
export default function FunctionalitiesGap({
  loadPopup,
  mediaid,
  workspaceMedia,
  funcGapLabel1,
  funcGapLabel2,
  funcGapLabel3,
}) {
  const userContext = useContext(UserContext);
  const [dashboards, setDashboards] = useState([]);
  const [selectedMediaID, setSelectedMediaID] = useState(null);
  const [toolTipInfo, setToolTipInfo] = useState(
    "The Functionality Gap Analysis graph shows how many functionalities are unique in our Primary market compared to the Target market (which means that at least one of the members of the Primary market is implementing a functionality that no-one from the Target market is implementing). Additionally, this graph shows how many functionalities at least one of the banks/fintechs from each market are implementing, thus marked as common, and how many functionalities our Target market has unique compared to the Primary market (at least one of the members of the Target market is implementing a functionality that no-one from the Primary market is implementing). Each functionality sum is colour-coded and marked with the number of the functionalities in each category. Functionalities are organised into functionality sections. By clicking on a sum of a functionality section, the user can see a complete list of which functionalities these are, along with how many from the market of focus of the user or outside of it are implementing them. By selecting any functionality, the user can see which are the banks/fintechs implementing that functionality. Lastly, by clicking any bank/fintech that appears on the lists, they can be redirected to the Report and view the video of how the functionality has been implemented by that specific bank/fintech."
  );

  const [label1, setLabel1] = useState(funcGapLabel1);
  const [label2, setLabel2] = useState(funcGapLabel2);
  const [label3, setLabel3] = useState(funcGapLabel3);

  const [options_functionalities_gap, setOptions_functionalities_gap] =
    useState({
      title: {
        text: "Functionality Gap Analysis",
      },
      type: "column",
      series: [
        {
          data: [1, 2, 3],
        },
      ],
    });

  const [isLoadingFuncGap, setIsLoadingFuncGap] = useState(true);

  async function chart_functionalities_gap() {
    var categories = [];
    var series = [];

    setIsLoadingFuncGap(true);

    var data = {};

    data["mediaid"] = selectedMediaID;

    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_functionality_gap";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var avail = [];
    var not_avail = [];
    var unique = [];
    var fc = [];
    var json = await metadataResponse.json();
    json.forEach((item, i) => {
      categories.push(item.DisplayName);
      avail.push(item.available);
      not_avail.push(item.not_available);
      unique.push(item.unique);
      fc[item.DisplayName] = item.ID;
    });

    const chart = {
      chart: {
        type: "column",
        events: {
          load: function () {
            if (label1 === false) this.series[2].hide();
            if (label2 === false) this.series[1].hide();
            if (label3 === false) this.series[0].hide();
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },

      xAxis: {
        categories: categories,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Total functionalities",
        },
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: "bold",
            color:
              // theme
              (Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              "gray",
          },
        },
      },

      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
          },
        },
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                if (this.color == "#fcdce4")
                  loadPopup(
                    selectedMediaID,
                    6,
                    fc[this.category],
                    1,
                    1,
                    1,
                    "Functionality Gap Analysis",
                    "Identify each feature that is unique, in common or missing from your market or a competitor, how it is implemented and UX Evaluated. Be the first to cover a real market need or bridge the gap with your competitors.",
                    gif
                  );
                if (this.color == "#020746")
                  loadPopup(
                    selectedMediaID,
                    6,
                    fc[this.category],
                    1,
                    1,
                    1,
                    "Functionality Gap Analysis",
                    "Identify each feature that is unique, in common or missing from your market or a competitor, how it is implemented and UX Evaluated. Be the first to cover a real market need or bridge the gap with your competitors.",
                    gif
                  );
                if (this.color == "#4d5efc")
                  loadPopup(
                    selectedMediaID,
                    6,
                    fc[this.category],
                    1,
                    1,
                    1,
                    "Functionality Gap Analysis",
                    "Identify each feature that is unique, in common or missing from your market or a competitor, how it is implemented and UX Evaluated. Be the first to cover a real market need or bridge the gap with your competitors.",
                    gif
                  );
              },
            },
          },
          events: {
            legendItemClick: function () {
              if (this.visible) {
                if (this.color == "#fcdce4")
                  setUserWorkspaceStateLabel(false, null, null);
                else if (this.color == "#020746")
                  setUserWorkspaceStateLabel(null, false, null);
                else if (this.color == "#4d5efc")
                  setUserWorkspaceStateLabel(null, null, false);
              } else {
                if (this.color == "#fcdce4")
                  setUserWorkspaceStateLabel(true, null, null);
                else if (this.color == "#020746")
                  setUserWorkspaceStateLabel(null, true, null);
                else if (this.color == "#4d5efc")
                  setUserWorkspaceStateLabel(null, null, true);
              }
            },
          },
        },
      },
      legend: {
        reversed: true,
      },

      exporting: {
        menuItemDefinitions: {
          toggleTable: {
            onclick: function () {
              if (
                this.dataTableDiv &&
                this.dataTableDiv.style.display !== "none"
              ) {
                this.dataTableDiv.style.display = "none";
              } else {
                this.viewData();
                this.dataTableDiv.style.display = "";
              }
            },
            text: "Toggle Table",
          },
        },
        buttons: false,
      },

      tooltip: {
        formatter: function () {
          let bankName =
            '<span style="color: ' +
            this.point.color +
            '">\u25CF</span> ' +
            `<span>${this.series.name}</span>`;
          let desc = "<b>" + this.point.y + "</b>";
          if (this.point.y == 1) {
            desc += " Functionality";
          } else {
            desc += " Functionalities";
          }
          return bankName + ": " + desc;
        },
      },

      series: [
        {
          name: "Unique to the Target Market",
          data: avail,
          color: "#4d5efc",
        },
        {
          name: "Common between the Primary & Target Market",
          data: not_avail,
          color: "#020746",
        },
        {
          name: "Unique to the Primary Market",
          data: unique,
          color: "#fcdce4",
        },
      ],
    };
    setOptions_functionalities_gap(chart);
    // setData(resp);
    setIsLoadingFuncGap(false);
    // setData(resp);
  }

  async function getDashboards() {
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/getall";
    const metadataResponse = await fetch(apiURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
    setDashboards(resp);
    setSelectedMediaID(mediaid);
  }

  async function setUserWorkspaceState(newmedia) {
    let data = {};
    var newState = workspaceMedia;
    newState.functionality_gap_analysis = newmedia;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  const setUserWorkspaceStateLabel = async (l1, l2, l3) => {
    let data = {};
    let newState = workspaceMedia;
    if (l1 !== null) {
      newState.funcGapLabel1 = l1;
      setLabel1(l1);
    }
    if (l2 !== null) {
      newState.funcGapLabel2 = l2;
      setLabel2(l2);
    }
    if (l3 !== null) {
      newState.funcGapLabel3 = l3;
      setLabel3(l3);
    }
    data["charsState"] = [newState];
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    let resp = await metadataResponse.json();
  };

  useEffect(() => {
    getDashboards();
  }, []);

  useEffect(() => {
    if (selectedMediaID !== null) chart_functionalities_gap();
  }, [selectedMediaID]);

  const handleMedia = (mediaid) => {
    setUserWorkspaceState(mediaid);
    setSelectedMediaID(mediaid);
  };

  const chartRef = useRef();

  return (
    <Card>
      <MDBox p={1}>
        <ChartsHeader
          title="Functionality Gap Analysis"
          handleMedia={handleMedia}
          toolTipInfo={toolTipInfo}
          selectedMediaID={selectedMediaID}
          chartRef={chartRef}
        />
        {isLoadingFuncGap !== true ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={options_functionalities_gap}
            ref={chartRef}
          />
        ) : (
          <MDBox display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}
