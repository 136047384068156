import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import MDBox from "components/MDBox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import { useEffect, useState } from "react";

const FuncsList = ({
  listLabel = "Title",
  masterClear,
  funcs,
  setFuncs,
  data,
  setMasterClearF
}) => {
  const [open, setOpen] = useState(false);
  const [checkChildren, setCheckChildren] = useState(false);
  const [checked, setChecked] = useState(false);
  const [flag, setFlag] = useState(false);
  const [checkedChildren, setCheckedChildren] = useState([]);

  // children check
  const handleToggle = (value) => () => {
    // handle if info comes from Market A tab
    if (funcs !== undefined) {
      const currentIndex = funcs.indexOf(value);
      const newFuncs = [...funcs];
      if (currentIndex === -1) {
        newFuncs.push(value);
      } else {
        newFuncs.splice(currentIndex, 1);
      }
      setFuncs(newFuncs);
    }

    // local component state for more responsive UX
    const currentIndex = checkedChildren.indexOf(value);
    const newChecked = [...checkedChildren];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length > 0) setChecked(true);
    else setChecked(false);
    setCheckedChildren(newChecked);
  };

  // select all children
  const doSelectAll = () => {
    const newChecked = [...checkedChildren];
    let newFuncs;
    if (funcs !== undefined) newFuncs = [...funcs];

    data.forEach((item) => {
      if (!checkedChildren.includes(item)) {
        if (funcs !== undefined && !funcs.includes(item) && newChecked) {
          newFuncs.push(item);
          newChecked.push(item);
        }
      }
    });

    if (funcs !== undefined) {
      setFuncs(newFuncs);
      setCheckedChildren(newChecked);
    }
  };

  // unselect all children
  const doRemoveAll = () => {
    if (funcs !== undefined) {
      let newFuncs = [...funcs];
      checkedChildren.forEach((item) => {
        if (funcs.includes(item)) {
          newFuncs.splice(newFuncs.indexOf(item), 1);
        }
        setFuncs(newFuncs);
      });
    }
    setCheckedChildren([]);
  };

  // track if parent got clicked and remove or select all
  useEffect(() => {
    if (checkChildren) doSelectAll();
    else doRemoveAll();
  }, [flag]);

  // parent's checkbox
  const handleCheck = () => {
    if (checked && checkedChildren.length > 0) {
      setCheckChildren(false);
      setChecked(false);
      setFlag(!flag);
    } else {
      setCheckChildren(true);
      setChecked(true);
      setFlag(!flag);
    }
  };

  useEffect(() => {
    if (masterClear > 0) {
      if (funcs !== undefined) {
        setFuncs([]);
      }
      setChecked(false);
      setCheckChildren(false);
      setFlag(!flag);
      if (setMasterClearF !== undefined) setMasterClearF(0)
    }
  }, [masterClear]);

  // if there are set data
  useEffect(() => {
    let newChecked = [...checkedChildren];
    if (funcs && funcs.length > 0) {
      data.forEach((item) => {
        if (funcs.includes(item) && !newChecked.includes(item))
          newChecked.push(item);
      });
      if (newChecked.length > 0) {
        setCheckedChildren(newChecked);
        setChecked(true);
      }
    }

  }, [funcs]);

  return (
    <List sx={{ marginBottom: 2 }} disableRipple disablePadding>
      {/* major category JSX */}

      <MDBox sx={{ display: "flex" }}>
        <ListItemButton
          sx={{ flexGrow: 0 }}
          onClick={handleCheck}
          dense
          disableRipple
          disablePadding
        >
          <Checkbox
            edge="start"
            checked={checked && checkedChildren.length > 0}
            disableRipple
            disablePadding
          />
        </ListItemButton>

        <ListItemButton sx={{ flexGrow: 1 }} onClick={() => setOpen(!open)}>
          <ListItemText
            disableTypography
            sx={{ fontSize: 18, fontWeight: "bold" }}
          >
            {listLabel}
          </ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </MDBox>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {/* building children for each category */}
        <List
          className="scrollhost"
          disablePadding
          disableRipple
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            overflow: "auto",
            // maxHeight: 350, an thelo i upolistes na exoun scroll kai maxHeight
          }}
        >
          {funcs &&
            data &&
            data.length > 0 &&
            data.map((value) => (
              <ListItem key={value.ID} disablePadding>
                <ListItemButton onClick={handleToggle(value)} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={funcs.includes(value)}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText>{value.DisplayName}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        {/* end of building children for each category */}
      </Collapse>
    </List>
  );
};

export default FuncsList;
