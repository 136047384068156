import React from "react";
//material-ui
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MDButton from "components/MDButton";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import UserContext from "context/UserContext";
import { Avatar } from "@mui/material";

function PopupItem({ header, subheader, gif, where, type, bankid, mediaid }) {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [message, setMessage] = useState(false);
  const handleClick = () => {
    talkToSales();

    //  window.location.href = "https://fi.localhost:3000/popup/thankyou.html";
  };
  async function talkToSales() {
    let extra = "";
    if (bankid !== undefined && bankid >= 1) {
      let details = {};
      details["mediaid"] = mediaid;
      details["bankid"] = bankid;
      var apiURL2 = process.env.REACT_APP_SERVER_URL;
      apiURL2 += "/api/node_getBankAndMediaDetails";
      const metadataResponse2 = await fetch(apiURL2, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        body: JSON.stringify(details),
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          bearer: userContext.userData.apiToken,
          "Content-Type": "application/json",
        },
      });
      var resp2 = await metadataResponse2.json();
      extra = resp2["BankName"];
    }
    let data = {};

    data["message"] =
      "BankId :" +
      bankid +
      " " +
      extra +
      " \n\r<br> media id : " +
      mediaid +
      " \n\r where:" +
      where +
      "<br>\n\r header:" +
      header +
      " \n\r subheader:" +
      subheader;

    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/talkToSales";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();
    setMessage(true);
  }

  return (
    <>
      {/* image with upgrade.svg from assets folder */}

      <MDBox
        display="flex"
        sx={{ alignItems: "center", width: "100%", minHeight: "100%", mt: 0 }}
      >
        <MDBox
          display="flex"
          sx={{
            flexDirection: "column",
            mx: 5,
            w: "100%",
            pr: 0,
            minHeight: "100%",
            mb: 0,
            mt: 0,
          }}
        >
          <MDBox display="flex" sx={{ flexDirection: "row", mt: 0 }}>
            <img
              src={require("assets/images/upgrade.svg").default}
              alt="upgrade"
            />
            <MDTypography sx={{ fontSize: "15px", fontWeight: 700, ml: 0.5 }}>
              Upgrade To FinTech Insights Full Version
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            sx={{
              maxWidth: "400px",

              flexDirection: "column",
              my: 3,
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <MDTypography
              sx={{
                fontSize: "25px",
                fontWeight: 800,
                // width is enough to fit the text in one line
                textAlign: "left",
              }}
            >
              {header}
            </MDTypography>
            <MDTypography
              sx={{
                fontSize: "13px",
                fontWeight: 600,
                maxWidth: "400px",
                width: "100%",
                textAlign: "left",
                mt: 2,
              }}
            >
              {subheader}
            </MDTypography>
            <MDBox sx={{ width: "100%", mt: 2, maxWidth: "400px" }}>
              {message === false ? (
                <>
                  <Button
                    onClick={handleClick}
                    sx={{
                      backgroundColor: "blue",
                      color: "white !important",
                      fontSize: "20px",
                      fontWeight: 800,
                      width: "100%",
                      height: "60px",
                      textTransform: "capitalize",
                      ":hover": { color: "white", backgroundColor: "blue" },
                    }}
                  >
                    talk to sales
                  </Button>
                </>
              ) : (
                <>
                  <p class="text">Our sales team has been contacted.</p>
                  <p class="text">We will reach out to you very soon!</p>
                </>
              )}
            </MDBox>
            <MDBox
              display="flex"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "98%",
                mt: 1,
              }}
            >
              <MDTypography sx={{ fontSize: "13px", fontWeight: 600 }}>
                Or
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              sx={{
                mt: 1,
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "400px",
                minWidth: "100%",
              }}
            >
              <MDTypography
                sx={{ fontSize: "15px", fontWeight: 600, textAlign: "center" }}
              >
                Directly Book A Meeting{" "}
                <Link
                  href="https://meetings.hubspot.com/n-belesis/upgrade-your-fi-license?embed=false"
                  sx={{ textDecorationLine: "underline", color: "blue" }}
                  target="_blank"
                >
                  Through This Link
                </Link>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          sx={{
            alignItems: "center",
            w: "10%",
            justifyContent: "center",
            mt: 0,

            pr: 0,
          }}
        >
          {/* image of assets/images/image.svg */}

          {gif && (
            <img
              src={gif}
              alt="image"
              style={{
                maxWidth: "80%",
                minWidth: "40%",
                minHeight: "40%",
                maxHeight: "80%",
                borderRadius: "10px",
              }}
            />
          )}
        </MDBox>
      </MDBox>
    </>
  );
}

export default PopupItem;
