/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import './index.css'
import { useState,useContext,useEffect } from "react";
import { useParams } from 'react-router';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import RestoreIcon from '@mui/icons-material/Restore';
import ClearIcon from '@mui/icons-material/Clear';
// Material Dashboard 2 PRO React example components
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import Footer from "scientia/Footer";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/Cancel';
// Wizard page components

import Account from "layouts/applications/wizard/components/Account";
import Address from "layouts/applications/wizard/components/Address";
import BanksMarketsA from "layouts/applications/wizard/components/BanksMarketsA";
import BanksMarketsB from "layouts/applications/wizard/components/BanksMarketsB";
import Selections from "layouts/applications/wizard/components/Selections";
import MDAlert from 'components/MDAlert';
import { useNavigate } from "react-router-dom";

import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';
import SettingsBar from 'layouts/pages/analyzed/bank/SettingsBar';
import Modal from '@mui/material/Modal';



function getSteps() {
  return ["Market A", "Market B", "Sections"];
}


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius:5,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};


function Wizard() {

 

 const { dashboardId,isCopy } = useParams();
 const [key,setKey]=useState(Math.random());
  const userContext = useContext(UserContext);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const [alertTrigger, setAlertTrigger] = useState(false)
  const [disabledParentsA, setDisabledParentsA] = useState([])
  const [disabledParentsB, setDisabledParentsB] = useState([])
  const [disableSave, setDisableSave ] = useState(false)

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {

    if(activeStep==0){
      if(valueA.length===0){
        setAlertTrigger(true)
        return
      }
    }


    // if(activeStep==1){
    //   if(valueB.length===0){
    //     setAlertTrigger(true)
    //     return
    //   }
    // }
    setActiveStep(activeStep + 1);
    setAlertTrigger(false)



  }
  const handleBack = () => setActiveStep(activeStep - 1);
  const privateLabel = { inputProps: { 'aria-label': 'Private dashboard' } };

  // reload components easily
  const [seed, setSeed] = useState(1);
  const reset = () => {
    setSeed(Math.random());
  }

  const [valueA, setValueA] =useState([]);
  const [valueB, setValueB] =useState([]);
  const [valueC, setValueC] =useState([]);
  const [valueName, setValueName] =useState("");
  const [priv, setPriv] =useState(false);
  const [org, setOrg] =useState(false);
  const [saved,setSaved]=useState(false);
    function handleChangeA(val) {
      setValueA(val);
    }
    function handleChangeB(val) {
      setValueB(val);
    }
    function handleChangeC(val) {
      setValueC(val);
    }

    function beforeSave(){
      handleClose()
      doSave()
    }

    async function doSave() {
      var data={};
      data['Title']=valueName;
      data['isPrivate']=priv;
      data['isGlobal']=org;
      let marketA=[];
      let marketB=[];
      let marketF=[];


      Object.keys(valueA).forEach(key => {
        if (valueA[key]===true) {
          if(!marketA.includes(key)){
            marketA.push(key);
          }
        }
      });
      console.log(marketA)
      Object.keys(valueB).forEach(key => {
        if (valueB[key]===true) {
          if(!marketB.includes(key)&&!marketA.includes(key)){
            marketB.push(key);
          }
        }
      });
      console.log(marketB)
      Object.keys(valueC).forEach(key => {
        if (valueC[key]===true) {
          if(!marketF.includes(key)){
            marketF.push(key);
          }
        }
      });

      data['MarketA']=marketA;
      data['MarketB']=marketB;
      data['Functionalities']=marketF;
      if (dashboardId!==null&&dashboardId!==undefined) {
        if (isCopy!==undefined&&isCopy=="true") {
              //create new is a copy
        } else {
         data['dashboardId']=dashboardId;
       }
      }
      var apiURL = process.env.REACT_APP_SERVER_URL;
        apiURL+="/api/dashboard/save";
       const metadataResponse = await fetch(apiURL, {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      body:JSON.stringify(data),
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         headers: {
           'bearer': userContext.userData.apiToken,
           'Content-Type': 'application/json'
         },
       });

       var resp = await metadataResponse.text();

       setSaved(true);


    }
    function handleChangeText(e) {
      setValueName(e.target.value);
    }
    function handlePrivate(e) {
      setPriv(e.target.checked);

    }
    function handleGlobal(e) {
      setOrg(e.target.checked);

    }
  function getStepContent(stepIndex) {
      switch (stepIndex) {
        case 0:
          return <BanksMarketsA 
          disabledParentsA={disabledParentsA} 
          setDisabledParentsA={setDisabledParentsA} 
          disabledParentsB={disabledParentsB} 
          setDisabledParentsB={setDisabledParentsB} 
          key={key}  
          value={valueA} 
          valueB={valueB} 
          onChange={handleChangeA}/>;
        case 1:
          return <BanksMarketsB 
          disabledParentsA={disabledParentsA} 
          setDisabledParentsA={setDisabledParentsA} 
          disabledParentsB={disabledParentsB} 
          setDisabledParentsB={setDisabledParentsB} 
          value={valueB} 
          valueA={valueA} 
          onChange={handleChangeB}/>;
        case 2:
          return <Selections 
          value={valueC} 
          onChange={handleChangeC}
          disableSave={disableSave}
          setDisableSave={setDisableSave}
          />;
        default:
          return null;
      }
    }
    async function getDashboard(dashboardId) {
      var data={};
      data['dashboardId']=dashboardId;
      var apiURL = process.env.REACT_APP_SERVER_URL;
        apiURL+="/api/dashboard/get";
       const metadataResponse = await fetch(apiURL, {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      body:JSON.stringify(data),
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         headers: {
           'bearer': userContext.userData.apiToken,
           'Content-Type': 'application/json'
         },
       });
        var resp = await metadataResponse.json();
        // console.log(resp[0].MarketA);
        let vl=[];
        resp[0].MarketA.forEach((item, i) => {
          vl[item]=true;
        });
        let vl2=[];
        resp[0].MarketB.forEach((item, i) => {
          vl2[item]=true;
        });
        let vl3=[];
        resp[0].Functionalities.forEach((item, i) => {
          vl3[item]=true;
        });
        // console.log('test');
        // console.log(vl);
        setValueA(vl);
        setValueB(vl2);
        setValueC(vl3);
        setValueName(resp[0].Title);

        setKey(Math.random());
        reset()

    }

    const navigate = useNavigate();

    const handleClearAll=()=>{
      // reload
      // empty all
      setValueA([])
      setValueB([])
      setValueC([])
      reset()
      // console.log(valueA)
      // console.log(valueB)
      // console.log(valueC)

    }

  

  useEffect(()=>{
    if (dashboardId!==undefined && dashboardId!=="0") {
      console.log("dashboard changed")
      console.log(dashboardId)
      getDashboard(dashboardId);
    } 

    if(dashboardId==="0"){
      navigate("/wizard")
      location.reload()
    }

    
    
 }, [dashboardId]) // <-- empty dependency array

    useEffect(()=>{
      if (dashboardId!==undefined) {
        getDashboard(dashboardId);
      }
   }, []) // <-- empty dependency array
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SettingsBar/>
      
      {/* {dashboardId!==null?dashboardId:""} */}
      <MDBox pt={0} pb={8} key={seed} >
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {valueName!==""&&dashboardId!==undefined?"Edit Market "+valueName:"Create your Market"}
                </MDTypography>

                {/* {dashboardId} */}

            

                {isCopy=="true"?<>  <MDTypography variant="h6" color="warning" fontWeight="bold">
                  You have selected another user's market. <br/>A new market will be created on save.
                  </MDTypography></>:<></>}

                <br/>
                <MDTypography variant="p">
                By using this Wizard, you can setup your Markets of focus, where:<br/>
      In Market A you'll need to add all the Banks/FinTechs that are primary to your focus<br/>
      In Market B you'll need to add all the Banks/FinTechs that are your immediate competition<br/>
      In Sections you have the option to remove the Functionality areas that are of less interest to you<br/>
          </MDTypography>
              </MDBox>

              <MDTypography variant="h5" fontWeight="regular" color="secondary">
              <Grid container justifyContent="center" sx={{ my: 4 }}>

{/* modal */}
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <MDBox sx={{ ...style,  }}>

        <MDBox sx={{position:"absolute", top:5, right:10,}}>
          <CancelIcon 
          sx={{cursor:"pointer"}} 
          onClick={()=>{setOpen(false);}}
          color='dark' 
          fontSize='medium'/></MDBox>

        <MDBox sx={{display:"flex", flexDirection:"column"}} className="save-modal-name">

          <MDTypography sx={{m:1}} color="text">Give your Market a Name</MDTypography>

        <Grid sx={{m:1}} >
                <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                      }}
                      noValidate
                      autoComplete="off"
                      >
                      <TextField onChange={e=>handleChangeText(e)} value={valueName} id="outlined-basic" label="" variant="outlined" />
                      </Box>
                </Grid>

          <MDButton 
          sx={{m:1}} 
          disabled={valueName!==""?false:true}
          color="info" 
          onClick={()=>beforeSave()}>Save</MDButton>

                      </MDBox>
        </MDBox>
      </Modal>

              </Grid>

              {alertTrigger?<>
              <MDAlert color="dark" dismissible className="alert">
                Select some banks to continue
              </MDAlert>
              </>:<></>}



    </MDTypography>
            </MDBox>
            <Card>
              <MDBox mt={-3} mx={2} >
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2} >
                <MDBox >
                {saved===false?<>
                  {getStepContent(activeStep)}

                </>:<>
                <MDBox display="flex" allignItems="center" justifyContent="center" p={3}>
                {/* <p>Saved successfully</p> */}
                <span class="save-icon">
                  <span class="loader"></span>
                  <span class="loader"></span>
                  <span class="loader"></span>
                </span>
                </MDBox>
                <MDBox display="flex" allignItems="center" justifyContent="center" p={3}>
                <MDTypography>
                  Dashboard was saved successfully!
                </MDTypography>
                </MDBox>
                <MDBox display="flex" allignItems="center" justifyContent="center" p={1}>
                <MDTypography>
                   <MDButton variant="outlined" color="info" onClick={()=>{navigate('/analytics');}}>Go Back</MDButton>
                </MDTypography>
                </MDBox>
                </>}

                </MDBox>
              </MDBox>
            </Card>
              </Grid>
              </Grid>
              </MDBox>

                  <MDBox
                  bgColor=""
             position="fixed"
             bottom={140}
             left="13%"
             mt={3}
             width="200px"
              display="flex"
              allignItems="center">


                    {activeStep === 0 ? (
                      <>
                      <Grid
                      >
                          <MDButton
                          className="Wizard-Buttons-Fr"
                          title="Clear all"
                          sx={{ml:-6}}
                          variant="gradient"
                          color="info" alt="Clear All" onClick={()=>{handleClearAll()}}>
                          <RestoreIcon />
                          </MDButton>
                          <MDButton
                          title="Exit"
                          sx={{ml:2}}
                          variant="gradient"
                          color="dark" onClick={()=>{navigate('/');}}>
                          <ClearIcon />
                          </MDButton>
                          </Grid>
                        <MDBox/>
                      </>
                      ) : !saved?(
                        (
                          <Grid
                          >
                              <MDButton
                                sx={{ml:-6}}
                                variant="gradient"
                                color="info"  alt="Clear All" onClick={()=>{handleClearAll()}}>
                                <RestoreIcon />
                                </MDButton>

                              <MDButton


                              sx={{ml:2}}
                              variant="gradient"
                              color="info" onClick={handleBack}>
                              <ArrowBackIosNewIcon />
                              </MDButton>
                          </Grid>
                    )
                    ):(<></>)

                    }
                </MDBox>

                <MDBox
                  bgColor=""
             position="fixed"
             bottom={140}
             right="1%"
             mt={3}
             width="200px"
              display="flex"
              allignItems="center">

                     
                      
                      {!saved?
                      <MDButton
                      variant="gradient"
                      color="info"
                      disabled={disableSave}
                      onClick={!isLastStep ? handleNext : ()=>handleOpen()}
                      >

                      {isLastStep ? "Save" : <ArrowForwardIosIcon />}
                    </MDButton>
                      :(<></>)
                    }
                    
                </MDBox>


      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Wizard;
