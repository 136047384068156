import "./style.css";

const PrimaryButton = ({
  children,
  onClick,
  height,
  width,
  color,
  fontWeight,
}) => {
  return (
    <button
      className={
        color == "white"
          ? "PrimaryButton btn-prv-white"
          : "PrimaryButton btn-prim-blue"
      }
      onClick={onClick}
      style={{
        height: height ?? "70px",
        width: width ?? "100%",
        fontWeight: fontWeight ?? null,
      }}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
