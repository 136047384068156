/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState,useContext,useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Link from "@mui/material/Link";
import {OpenInNew, ReadMore} from "@mui/icons-material";
// Material Dashboard 2 PRO React example components
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import Footer from "scientia/Footer";
import DataTable from "scientia/Tables/DataTable";
import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';

// Data
import dataTableData from "./data/dataTableData";

function Manage_AllUsers() {

  const userContext = useContext(UserContext);
  const [isLoading,setIsLoading]=useState(true);

  async function doAction_getAllusers() {




    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/org_getAllusers";
     const metadataResponse = await fetch(apiURL, {
       method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });

     var resp = await metadataResponse.json();
     let x=[];
     resp.forEach((item, i) => {
       if (item.last_login!==undefined&&item.last_login!=="") x.push(item)
     });

  /*   actions:
        resp.forEach(function(part, index) {
        this[index].actions = <MDButton
         component={Link}
         href="/toggleUserStatus/"
         rel="noreferrer"
         size="small"
         color="success"
         fullWidth
     >
      Toggle
     </MDButton>
      }, resp); // use arr as this;*/
      // console.log(resp);
     dataTableData.rows=x;
    //  console.log(dataTableData);
     setIsLoading(false)

  }

  useEffect(()=>{

   doAction_getAllusers();
 }, []) // <-- empty dependency array

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                View Users
              </MDTypography>
              <MDTypography variant="button" color="text">

              </MDTypography>
            </MDBox>
            {isLoading===false?(
              <>
                <DataTable canSearch={true} entriesPerPage={{defaultValue:1000}} table={dataTableData} />
              </>
            ):(
              <>
              </>

          )}
          </Card>
        </MDBox>

      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Manage_AllUsers;
