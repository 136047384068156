import Card from "@mui/material/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext, useEffect, useRef } from "react";
import UserContext from "context/UserContext";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChartsHeader from "../ChartsHeader";
import gif from "assets/images/marketwebp.webp";
export default function UXOverviewScores({
  loadPopup,
  mediaid,
  workspaceMedia,
  UXJLabel1,
  UXJLabel2,
  UXJLabel3,
  UXJLabel4,
  UXJLabel5,
}) {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboards, setDashboards] = useState([]);
  const [selectedBankID, setSelectedBankID] = useState(0);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedMediaID, setSelectedMediaID] = useState(mediaid);
  const [functionalitiesOverview, setFunctionalitiesOverview] = useState(null);
  // const defaultBank="108"; // REVOLUT V.7 (EU)
  // const defaultBankname="Revolut V.7 (EU)";
  const [toolTipInfo, setToolTipInfo] = useState(
    "The UX Scoring graph shows the average UX score for each bank/fintech inside the user's chosen market. Clicking on a bank/fintech, the average UX score is presented for each functionality section. By further clicking a selected bar, the user can see a complete list of which user journeys are analysed, their UX scores and the average UX score in the primary market, target market and the rest of the world. Moreover, the user can also see the Best UX Score in both the primary and target market. Additionally, by selecting any journey, user can see which are the banks/fintechs implementing it, along with their respective UX scores. Lastly, by clicking any bank/fintech, the user can be redirected to the full Report and view the UX analysis of that journey."
  );

  const [label1, setLabel1] = useState(UXJLabel1);
  const [label2, setLabel2] = useState(UXJLabel2);
  const [label3, setLabel3] = useState(UXJLabel3);
  const [label4, setLabel4] = useState(UXJLabel4);
  const [label5, setLabel5] = useState(UXJLabel5);

  const [options, setOptions] = useState({
    title: {
      text: "Functionalities Overview",
    },
    type: "column",
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  });

  const setUserWorkspaceStateLabel = async (l1, l2, l3, l4, l5) => {
    let data = {};
    let newState = workspaceMedia;
    if (l1 !== null) {
      newState.UXJLabel1 = l1;
      setLabel1(l1);
    }
    if (l2 !== null) {
      newState.UXJLabel2 = l2;
      setLabel2(l2);
    }
    if (l3 !== null) {
      newState.UXJLabel3 = l3;
      setLabel3(l3);
    }
    if (l4 !== null) {
      newState.UXJLabel4 = l4;
      setLabel4(l4);
    }
    if (l5 !== null) {
      newState.UXJLabel5 = l5;
      setLabel5(l5);
    }
    data["charsState"] = [newState];
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    let resp = await metadataResponse.json();
  };

  async function chart_ux_overview_forBank(bankid) {
    setSelectedBankID(bankid);

    setIsLoading(true);
    var Medianame = selectedMediaID == "16" ? "Web" : "iOS";
    var Medianame = selectedMediaID == "27" ? "Apple Watch" : Medianame;

    var data = {};
    data["bankid"] = bankid;
    data["mediaid"] = selectedMediaID;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_ux_overview_scores";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    var resp = await metadataResponse.json();
    var fc = [];
    var categories = [];
    var data = [];
    resp.forEach(function (v, index) {
      var bank = [];
      fc[v._id.category.DisplayName] = v._id.category.NodeID;

      categories.push(v._id.category.DisplayName);
      let score = parseFloat(v.AverageScore.toFixed(0));
      data.push(score);
    });
    setFunctionalitiesOverview(fc);
    setOptions({
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },

      xAxis: {
        categories: categories,
        crosshair: true,
      },
      yAxis: {
        // min: 0,
        title: {
          text: "UX Journeys",
        },
      },
      exporting: {
        buttons: false,
      },

      plotOptions: {
        column: {
          colorByPoint: true,
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          label: {
            enabled: false,
          },
          showInLegend: false,
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                //   chart_functionalities_overview_forBankMedia(bankid,fc[this.category]);
                loadPopup(
                  selectedMediaID,
                  "8",
                  fc[this.category],
                  bankid,
                  "5",
                  "1",
                  "UX Scoring",
                  "Find how each User Journey scores. Uncover fast the best-in class implementation and learn from others’ mistakes.",
                  gif
                );
              },
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          let bankName =
            '<span style="color: ' +
            this.point.color +
            '">\u25CF</span> ' +
            `<span>${this.x}</span>`;
          let desc = "<b>" + this.point.y + "</b> UX Score";

          return bankName + ": " + desc;
        },
      },
      series: [
        {
          name: "",
          data: data,
        },
      ],
    });
    // setData(resp);
    setIsLoading(false);
  }

  async function chart_ux_overview() {
    // Handle History
    if (workspaceMedia.ux_overview_with_scores_bank_selected !== "off") {
      setSelectedName(workspaceMedia.ux_overview_with_scores_bank_selected);
      chart_ux_overview_forBank(
        workspaceMedia.ux_overview_with_scores_bank_id_selected,
        workspaceMedia.ux_overview_with_scores_bank_selected
      );
    } else {
      setSelectedBankID(0);
      setSelectedName(null);
      setIsLoading(true);

      var data = {};
      data["mediaid"] = selectedMediaID;
      var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL += "/api/chart_ux_overview_scores_split_markets";
      const metadataResponse = await fetch(apiURL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        body: JSON.stringify(data),
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          bearer: userContext.userData.apiToken,
          "Content-Type": "application/json",
        },
      });

      var resp = await metadataResponse.json();
      var fc = [];
      var categories = [];
      var data = [];
      var colors = [];
      var avgA = [];
      var avgB = [];
      var bestA = [];
      var bestB = [];
      var bestAname = "";
      var bestBname = "";
      // populate data and names
      resp.market.forEach(function (v, index) {
        var bank = [];
        fc[v._id.BankName] = v._id.BankID;
        categories.push(v._id.BankName);
        let score = parseFloat(v.AverageScore.toFixed(0));
        data.push(score);
        colors.push(v.colors.Color);
        avgA.push(resp.avgA);
        avgB.push(resp.avgB);
        bestA.push(resp.bestA.AverageScore);
        bestB.push(resp.bestB.AverageScore);
      });
      bestAname =
        resp.bestA.BankName + " has the best UX Score in Primary market";
      bestBname =
        resp.bestB.BankName + " has the best UX Score in Target market";
      var Medianame = selectedMediaID == "16" ? "Web" : "iOS";
      var Medianame = selectedMediaID == "27" ? "Apple Watch" : Medianame;
      setFunctionalitiesOverview(fc);
      setOptions({
        chart: {
          type: "column",
          events: {
            load: function () {
              if (label1 === false) this.series[0].hide();
              if (label2 === false) this.series[1].hide();
              if (label3 === false) this.series[2].hide();
              if (label4 === false) this.series[3].hide();
              if (label5 === false) this.series[4].hide();
            },
          },
        },
        credits: {
          enabled: false,
        },
        // text: 'UX Overview'
        title: {
          text: "",
        },

        xAxis: {
          categories: categories,
          crosshair: true,
        },
        yAxis: {
          // min: 0,
          max: 1000,
          title: {
            text: "UX Score",
          },
        },
        exporting: {
          menuItemDefinitions: {
            toggleTable: {
              onclick: function () {
                if (
                  this.dataTableDiv &&
                  this.dataTableDiv.style.display !== "none"
                ) {
                  this.dataTableDiv.style.display = "none";
                } else {
                  this.viewData();
                  this.dataTableDiv.style.display = "";
                }
              },
              text: "Toggle Table",
            },
          },
          buttons: false,
        },

        plotOptions: {
          column: {
            colorByPoint: true,
            pointPadding: 0.2,
            borderWidth: 0,
          },
          series: {
            label: {
              enabled: true,
            },
            showInLegend: true,
            cursor: "pointer",
            point: {
              events: {
                click: function () {
                  setSelectedName(this.category);
                  setUserWorkspaceStateSelectedBank(
                    this.category,
                    fc[this.category]
                  );
                  chart_ux_overview_forBank(fc[this.category], this.category);
                },
              },
            },
            events: {
              legendItemClick: function () {
                if (this.visible) {
                  if (this.name == "Market")
                    setUserWorkspaceStateLabel(false, null, null, null, null);
                  else if (this.name == "Average Primary Market")
                    setUserWorkspaceStateLabel(null, false, null, null, null);
                  else if (this.name == "Average Target Market")
                    setUserWorkspaceStateLabel(null, null, false, null, null);
                  else if (
                    this.name.includes("the best UX Score in Primary market")
                  )
                    setUserWorkspaceStateLabel(null, null, null, false, null);
                  else if (
                    this.name.includes("has the best UX Score in Target market")
                  )
                    setUserWorkspaceStateLabel(null, null, null, null, false);
                } else {
                  if (this.name == "Market")
                    setUserWorkspaceStateLabel(true, null, null, null, null);
                  else if (this.name == "Average Primary Market")
                    setUserWorkspaceStateLabel(null, true, null, null, null);
                  else if (this.name == "Average Target Market")
                    setUserWorkspaceStateLabel(null, null, true, null, null);
                  else if (
                    this.name.includes("the best UX Score in Primary market")
                  )
                    setUserWorkspaceStateLabel(null, null, null, true, null);
                  else if (
                    this.name.includes("has the best UX Score in Target market")
                  )
                    setUserWorkspaceStateLabel(null, null, null, null, true);
                }
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            let bankName;
            if (this.point.series.name == "Market") {
              bankName =
                '<span style="color: ' +
                this.point.color +
                '">\u25CF</span> ' +
                `<span>${this.x}</span>`;
            } else {
              bankName =
                '<span style="color: ' +
                this.point.color +
                '">\u25CF</span> ' +
                `<span>${this.point.series.name}</span>`;
            }
            let desc = "<b>" + this.y.toString().slice(0, 3) + "</b> UX Score";

            return bankName + ": " + desc;
          },
        },
        series: [
          {
            name: "Market",
            colors: colors,
            data: data,
          },
          {
            type: "spline",
            name: "Average Primary Market",
            data: avgA,
            color: "#020746",
            marker: {
              enabled: false,
              lineWidth: 2,
              lineColor: "#020746",
              fillColor: "white",
            },
          },
          {
            type: "spline",
            name: "Average Target Market",
            data: avgB,
            color: "#ffc8e6",
            marker: {
              enabled: false,
              lineWidth: 2,
              lineColor: "#ffc8e6",
              fillColor: "white",
            },
          },
          {
            type: "spline",
            name: bestAname,
            data: bestA,

            marker: {
              enabled: false,
              lineWidth: 2,

              fillColor: "white",
            },
          },
          {
            type: "spline",
            name: bestBname,
            data: bestB,

            marker: {
              enabled: false,
              lineWidth: 2,

              fillColor: "white",
            },
          },
        ],
      });
      // setData(resp);
      setIsLoading(false);
    }
  }

  async function setUserWorkspaceState(newmedia) {
    let data = {};
    var newState = workspaceMedia;
    newState.ux_overview_with_scores = newmedia;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  async function setUserWorkspaceStateSelectedBank(bank, bid) {
    let data = {};
    var newState = workspaceMedia;
    newState.ux_overview_with_scores_bank_selected = bank;
    newState.ux_overview_with_scores_bank_id_selected = bid;
    data["charsState"] = [newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/changeUserWorkspaceState";
    const metadataResponse = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse.json();
  }

  useEffect(() => {
    if (selectedMediaID != null) {
      chart_ux_overview();
    }
  }, [selectedMediaID]);
  useEffect(() => {
    if (mediaid == null) {
      setSelectedMediaID("22");
    }
  }, []);

  const handleMedia = (mediaid) => {
    setUserWorkspaceState(mediaid);
    setSelectedMediaID(mediaid);
  };

  const chartRef = useRef();

  const goPreviousGraph = () => {
    setUserWorkspaceStateSelectedBank("off", "off");
    chart_ux_overview();
  };

  return (
    <MDBox>
      <Card>
        <MDBox p={1}>
          {selectedBankID != 0 && (
            <ArrowBackIcon
              color="dark"
              onClick={() => goPreviousGraph()}
              sx={{
                position: "absolute",
                left: 6,
                top: 32,
                cursor: "pointer",
                zIndex: 1,
              }}
              fontSize="medium"
            />
          )}

          <ChartsHeader
            title="UX Scoring"
            handleMedia={handleMedia}
            toolTipInfo={toolTipInfo}
            selectedMediaID={selectedMediaID}
            selectedName={selectedName}
            chartRef={chartRef}
          />
          {!isLoading ? (
            <>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
              />
            </>
          ) : (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ p: 25 }}
            >
              <CircularProgress />
            </MDBox>
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}
