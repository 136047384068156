/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState,useContext,useEffect } from "react";
import { Route, Navigate, useLocation,useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Link from "@mui/material/Link";
import {OpenInNew, ReadMore} from "@mui/icons-material";
// Material Dashboard 2 PRO React example components
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import Footer from "scientia/Footer";
import DataTable from "scientia/Tables/DataTable";
import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';

// Data
import dataTableData from "./data/dataTableData";
import org_dataTableData from "./data/org_dataTableData";


function Collections() {

  const userContext = useContext(UserContext);
  const [isLoading,setIsLoading]=useState(true);
  const [isLoadingOrg,setIsLoadingOrg]=useState(true);
   const navigate = useNavigate();
  const options = {
    sort: true,
    sortOrder: { name: 'BankName', direction: 'asc' },
  };
   function doAction_goWeb(bankid) {
  //   console.log(bankid);
     let path = '/analyzed/bank/'+bankid+'/16';
     navigate(path);

  }
   function doAction_goIos(bankid) {

     let path = '/analyzed/bank/'+bankid+'/22';
     navigate(path);
  }
  async function doAction_getAllCollections() {




    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/getAllCollections";
     const metadataResponse = await fetch(apiURL, {
       method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });

     var resp = await metadataResponse.json();
     actions:
        resp.forEach(function(part, index) {
        this[index].actions = <MDButton
         component={Link}
         href={"/collections/collection/"+this[index]._id}
         rel="noreferrer"
         size="small"
         color="success"
         fullWidth
     >
      Edit
     </MDButton>;
      }, resp); // use arr as this
     org_dataTableData.rows=resp;
    //  console.log(org_dataTableData);
     setIsLoadingOrg(false)

  }


  useEffect(()=>{
  doAction_getAllCollections();

 }, []) // <-- empty dependency array

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
              All Collections
              </MDTypography>
              <MDTypography variant="button" color="text">

              </MDTypography>
            </MDBox>
            {isLoadingOrg===false?(
              <>
                <DataTable table={org_dataTableData} canSearch={true} isSorted={true}  options={options}/>
              </>
            ):(
              <>
              </>

          )}
          </Card>
        </MDBox>

      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Collections;
