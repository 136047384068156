import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useContext, useEffect } from "react";
import UserContext from "context/UserContext";
import "./style.css";
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import { useNavigate } from "react-router-dom";
import View11 from "./views/1.1";
import View21 from "./views/2.1";
import View4 from "./views/4.0";
import ErrorPopup from "scientia/New Design/ErrorPopup/ErrorPopup";
import View31 from "./views/3.1";
import View12 from "./views/1.2";
import View22 from "./views/2.2";
import View32 from "./views/3.2";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import WelcomeLayout from "layouts/authentication/components/WelcomeLayout";

const WizardCreate = ({ welcome }) => {
  const userContext = useContext(UserContext);
  const [selectionA, setSelectionA] = useState([]);
  const [selectionB, setSelectionB] = useState([]);
  const [bucketSelectionA, setBucketSelectionA] = useState([]);
  const [bucketSelectionB, setBucketSelectionB] = useState([]);
  const [bucketSelectionF, setBucketSelectionF] = useState([]);

  // data stored from api response
  const [banks, setBanks] = useState([]);
  const [functionalities, setFunctionalities] = useState([]);

  const [step, setStep] = useState(1);
  const [innerStep, setInnerStep] = useState(1);
  const [funcSelection, setFuncSelection] = useState([]);
  const [title, setTitle] = useState("");

  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCB, setErrorCB] = useState(false);

  const [world, setWorld] = useState([]);
  const [marketA, setMarketA] = useState([]);
  const [marketB, setMarketB] = useState([]);

  const navigate = useNavigate();

  const handleBucketSelectionA = () => {
    let temp = [];
    banks.forEach((banks) => {
      temp.push({ title: banks._id.GroupTitle, banks: [] });
    });

    selectionA.forEach(({ title, inner }) => {
      temp = temp.map((element) => {
        if (element.title === title && !element.banks.includes(inner)) {
          let newBanks = element.banks;
          newBanks.push(inner);
          return {
            ...element,
            banks: newBanks,
          };
        } else return element;
      });
    });
    setBucketSelectionA(temp);
  };

  const handleBucketSelectionB = () => {
    let temp = [];
    banks.forEach((banks) => {
      temp.push({ title: banks._id.GroupTitle, banks: [] });
    });

    selectionB.forEach(({ title, inner }) => {
      temp = temp.map((element) => {
        if (element.title === title && !element.banks.includes(inner)) {
          let newBanks = element.banks;
          newBanks.push(inner);
          return {
            ...element,
            banks: newBanks,
          };
        } else return element;
      });
    });
    setBucketSelectionB(temp);
  };

  const handleBucketSelectionF = () => {
    let temp = [];
    functionalities.forEach((i) => {
      temp.push({ title: i.DisplayName, children: [] });
    });

    funcSelection.forEach(({ title, inner }) => {
      temp = temp.map((element) => {
        if (element.title === title && !element.children.includes(inner)) {
          let newChildren = element.children;
          newChildren.push(inner);
          return {
            ...element,
            children: newChildren,
          };
        } else return element;
      });
    });
    setBucketSelectionF(temp);
  };

  const emptyBucketSelectionA = () => setSelectionA([]);
  const emptyBucketSelectionB = () => setSelectionB([]);
  const emptyBucketSelectionF = () => setFuncSelection([]);

  useEffect(handleBucketSelectionA, [selectionA]);
  useEffect(handleBucketSelectionB, [selectionB]);
  useEffect(handleBucketSelectionF, [funcSelection]);

  const moveToA = () => {
    let tempA = [...marketA];
    let tempW = [...world];

    selectionA.forEach(({ title, inner }) => {
      let found = false;
      tempA = tempA.map((element) => {
        if (element.title === title && !element.banks.includes(inner)) {
          found = true;
          let newBanks = element.banks;
          newBanks.push(inner);
          return {
            ...element,
            banks: newBanks,
          };
        } else return element;
      });

      if (found) {
        tempW = tempW.map((element) => {
          if (element.title === title) {
            let newBanks = element.banks;
            newBanks = newBanks.filter((id) => id != inner);
            return {
              ...element,
              banks: newBanks,
            };
          } else return element;
        });
      }
    });
    setMarketA(tempA);
    setWorld(tempW);
  };

  const moveToB = () => {
    let tempB = [...marketB];
    let tempW = [...world];

    selectionB.forEach(({ title, inner }) => {
      let found = false;
      tempB = tempB.map((element) => {
        if (element.title === title && !element.banks.includes(inner)) {
          found = true;
          let newBanks = element.banks;
          newBanks.push(inner);
          return {
            ...element,
            banks: newBanks,
          };
        } else return element;
      });

      if (found) {
        tempW = tempW.map((element) => {
          if (element.title === title) {
            let newBanks = element.banks;
            newBanks = newBanks.filter((id) => id != inner);
            return {
              ...element,
              banks: newBanks,
            };
          } else return element;
        });
      }
    });
    setMarketB(tempB);
    setWorld(tempW);
  };

  const moveToWFA = () => {
    let tempA = [...marketA];
    let tempW = [...world];
    let tempS = [];

    tempA.forEach((i) => {
      i.banks.forEach((e) => {
        tempS.push({ title: i.title, inner: e });
      });
    });

    tempS.forEach(({ title, inner }) => {
      let found = false;
      tempW = tempW.map((element) => {
        if (element.title === title && !element.banks.includes(inner)) {
          found = true;
          let newBanks = element.banks;
          newBanks.unshift(inner);
          return {
            ...element,
            banks: newBanks,
          };
        } else return element;
      });

      if (found) {
        tempA = tempA.map((element) => {
          if (element.title === title) {
            let newBanks = element.banks;
            newBanks = newBanks.filter((id) => id != inner);
            return {
              ...element,
              banks: newBanks,
            };
          } else return element;
        });
      }
    });
    setMarketA(tempA);
    setWorld(tempW);
    setSelectionA(tempS);
  };

  const moveToWFB = () => {
    let tempB = [...marketB];
    let tempW = [...world];
    let tempS = [];

    tempB.forEach((i) => {
      i.banks.forEach((e) => {
        tempS.push({ title: i.title, inner: e });
      });
    });

    tempS.forEach(({ title, inner }) => {
      let found = false;
      tempW = tempW.map((element) => {
        if (element.title === title && !element.banks.includes(inner)) {
          found = true;
          let newBanks = element.banks;
          newBanks.unshift(inner);
          return {
            ...element,
            banks: newBanks,
          };
        } else return element;
      });

      if (found) {
        tempB = tempB.map((element) => {
          if (element.title === title) {
            let newBanks = element.banks;
            newBanks = newBanks.filter((id) => id != inner);
            return {
              ...element,
              banks: newBanks,
            };
          } else return element;
        });
      }
    });

    setMarketB(tempB);
    setWorld(tempW);
    setSelectionB(tempS);
  };

  // this check maybe needed later, got another solution instead.

  // useEffect(() => {
  //   let flag = false;
  //   let temp = [...selectionB];
  //   marketA.forEach((i) => {
  //     i.banks.forEach((e) => {
  //       let index = temp.findIndex(
  //         (s) => s.title == i.title && s.inner.BankID == e.BankID
  //       );
  //       if (index !== -1) {
  //         temp.splice(index, 1);
  //         flag = true;
  //       }
  //     });
  //   });
  //   if (flag) {
  //     setSelectionB(temp);
  //     setErrorMessage(
  //       "Some Banks that were selected in your Target Market, are already in your Primary Banks, hence they got removed from Target Market selection."
  //     );
  //     return setErrorModal(true);
  //   }
  // }, [marketA]);

  const handleFuncSelection = (title, inner) => {
    let temp = [...funcSelection];
    if (temp.some((e) => e.title === title && e.inner === inner))
      temp = temp.filter((element) => element.inner !== inner);
    else temp.push({ title, inner });
    setFuncSelection(temp);
  };

  // in case we need autocomplete later on

  // const handleSearchSelectionA = (e) => {
  //   const bank = e.target.value;
  //   const result = world.find((item) =>
  //     item.banks.some((e) => e.BankName === bank)
  //   );
  //   if (result) {
  //     const bankInfo = result?.banks?.find((i) => i.BankName === bank);
  //     console.log("result", result);
  //     console.log("bankInfo", bankInfo);
  //     handleSelectionA(result.title, bankInfo);
  //   }
  // };

  const handleSelectionA = (title, inner) => {
    let temp = [...selectionA];
    if (temp.some((e) => e.title === title && e.inner === inner))
      temp = temp.filter((element) => element.inner !== inner);
    else temp.push({ title, inner });
    setSelectionA(temp);
  };

  const handleSelectionB = (title, inner) => {
    let temp = [...selectionB];
    if (temp.some((e) => e.title === title && e.inner === inner))
      temp = temp.filter((element) => element.inner !== inner);
    else temp.push({ title, inner });
    setSelectionB(temp);
  };

  const toggleFuncChildren = (title, children) => {
    let temp = [...funcSelection];
    let flag = true;
    if (
      children.every((inner) =>
        temp.some((e) => e.title === title && e.inner === inner)
      )
    )
      flag = false;

    children.forEach((inner) => {
      if (temp.some((e) => e.title === title && e.inner === inner)) {
        let currentIndex = temp.findIndex(
          (e) => e.title === title && e.inner === inner
        );
        temp.splice(currentIndex, 1);
      }
    });

    if (flag)
      children.forEach((inner) => {
        temp.push({ title, inner });
      });

    setFuncSelection(temp);
  };

  const toggleChildrenA = (title, children) => {
    let temp = [...selectionA];
    let flag = true;
    if (
      children.every((inner) =>
        temp.some((e) => e.title === title && e.inner === inner)
      )
    )
      flag = false;

    children.forEach((inner) => {
      if (temp.some((e) => e.title === title && e.inner === inner)) {
        let currentIndex = temp.findIndex(
          (e) => e.title === title && e.inner === inner
        );
        temp.splice(currentIndex, 1);
      }
    });

    if (flag)
      children.forEach((inner) => {
        temp.push({ title, inner });
      });

    setSelectionA(temp);
  };

  const toggleChildrenB = (title, children) => {
    let temp = [...selectionB];
    let flag = true;
    if (
      children.every((inner) =>
        temp.some((e) => e.title === title && e.inner === inner)
      )
    )
      flag = false;

    children.forEach((inner) => {
      if (temp.some((e) => e.title === title && e.inner === inner)) {
        let currentIndex = temp.findIndex(
          (e) => e.title === title && e.inner === inner
        );
        temp.splice(currentIndex, 1);
      }
    });

    if (flag)
      children.forEach((inner) => {
        temp.push({ title, inner });
      });

    setSelectionB(temp);
  };

  const handleParentClassNameF = (title, children) => {
    let temp = [...funcSelection];
    return children.every((inner) =>
      temp.some((e) => e.title === title && e.inner === inner)
    );
  };

  const handleParentClassNameA = (title, children) => {
    let temp = [...selectionA];
    return children.every((inner) =>
      temp.some((e) => e.title === title && e.inner === inner)
    );
  };

  const handleParentClassNameB = (title, children) => {
    let temp = [...selectionB];
    return children.every((inner) =>
      temp.some((e) => e.title === title && e.inner === inner)
    );
  };

  async function doAction_getAnalyzedBanks() {
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/node_getBanksWizard";
    const metadataResponse = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    let resp = await metadataResponse.json();

    // text manipulation for special chars
    resp.map((v) => {
      v.banks.map((vv) => {
        if (vv.BankName.includes("&lt;"))
          vv.BankName = vv.BankName.replace("&lt;", " < ");

        if (vv.BankName.includes("amp;"))
          vv.BankName = vv.BankName.replace("amp;", "");
      });
    });
    setBanks(resp);
  }

  async function doAction_getFunctionalitiesTemplate() {
    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/node_getFunctionalitiesTemplate";
    let data = {};
    data["parentid"] = "0";
    data["mediaid"] = "16";
    data["bankid"] = "107";
    const metadataResponse = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      cache: "no-cache",
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });

    let resp = await metadataResponse.json();
    resp.map((f) => {
      if (f.DisplayName.includes("amp;"))
        f.DisplayName = f.DisplayName.replace("amp;", "");

      f?.children?.forEach((ff) => {
        if (ff.DisplayName.includes("amp;"))
          ff.DisplayName = ff.DisplayName.replace("amp;", "");
      });
    });
    setFunctionalities(resp);
  }

  async function selectDashboard(dashboard) {
    let data = {};
    data["dashboardId"] = dashboard;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/setActive";
    const metadataResponse = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      cache: "no-cache",
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    const resp = await metadataResponse.json();
    if (metadataResponse.ok) navigate("/home");
  }

  async function doSave() {
    if (!title) {
      setErrorMessage("Please give your market a name before proceeding. ");
      setErrorCB(false);
      return setErrorModal(true);
    }

    let data = {};
    data["Title"] = title;

    let tempA = [];
    let tempB = [];
    let tempF = [];

    marketA.forEach((item) => {
      item.banks.forEach((inner) => {
        if (!tempA.includes(inner.BankID)) tempA.push(inner.BankID);
      });
    });

    marketB.forEach((item) => {
      item.banks.forEach((inner) => {
        if (!tempB.includes(inner.BankID) && !tempA.includes(inner.BankID))
          tempB.push(inner.BankID);
      });
    });

    funcSelection.forEach((item) => {
      tempF.push(item.inner.ID);
      tempF.push(item.inner.ParentID);
    });

    let uniqueF = [...new Set(tempF)];

    data["MarketA"] = tempA;
    data["MarketB"] = tempB;
    data["Functionalities"] = uniqueF;

    let apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/dashboard/save";
    const metadataResponse = await fetch(apiURL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      cache: "no-cache",
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    const resp = await metadataResponse.json();
    selectDashboard(resp.dashId);
  }

  useEffect(() => {
    doAction_getAnalyzedBanks();
    doAction_getFunctionalitiesTemplate();
  }, []);

  // markets feed
  useEffect(() => {
    let temp = [];
    let tempA = [];
    let tempB = [];
    let tempC = [];
    banks.forEach((banks) => {
      temp.push({ title: banks._id.GroupTitle, banks: banks.banks });
      tempA.push({ title: banks._id.GroupTitle, banks: [] });
      tempB.push({ title: banks._id.GroupTitle, banks: [] });
      tempC.push({ title: banks._id.GroupTitle, banks: [] });
    });
    setWorld(temp);
    setMarketA(tempA);
    setMarketB(tempB);
    setBucketSelectionA(tempC);
    setBucketSelectionB(tempC);
  }, [banks]);

  // pre feed funcSelection with all the sections
  useEffect(() => {
    let temp = [];
    functionalities.forEach((item) => {
      item.children.forEach((inner) => {
        temp.push({ title: item.DisplayName, inner });
      });
    });
    setFuncSelection(temp);
  }, [functionalities]);

  // handle markets per step
  useEffect(() => {
    if (banks) {
      if (step === 1) {
        if (innerStep === 1) moveToA();
        else moveToWFA();
      }

      if (step === 2) {
        if (innerStep === 1) moveToB();
        else moveToWFB();
      }
      if (step === 3) moveToB();
    }
  }, [step, innerStep]);

  const checkBanksLength = () => {
    let tempA = [];
    let tempB = [];

    bucketSelectionA.forEach((item) => {
      item.banks.forEach((inner) => {
        if (!tempA.includes(inner.BankID)) tempA.push(inner.BankID);
      });
    });
    bucketSelectionB.forEach((item) => {
      item.banks.forEach((inner) => {
        if (!tempB.includes(inner.BankID)) tempB.push(inner.BankID);
      });
    });
    return tempA.length + tempB.length > 230;
  };

  const handleNextStep = () => {
    if (step == 1) {
      if (!selectionA.length) {
        setErrorMessage(
          "Usually in the Primary market, you should add the bank and fintechs of focus, for example your own organisation."
        );
        setErrorCB(false);
        return setErrorModal(true);
      }
      if (selectionA.length > 230) {
        setErrorMessage(
          "You can add to your Market (Primary & Target) up to 230banks/fintechs."
        );
        setErrorCB(false);
        return setErrorModal(true);
      }
      moveToA();
      setStep(2);
      setInnerStep(1);
    } else if (step == 2) {
      moveToB();
      if (checkBanksLength()) {
        setErrorMessage(
          "You can add to your Market (Primary & Target) up to 230banks/fintechs."
        );
        setErrorCB(false);
        return setErrorModal(true);
      }
      setStep(3);
      setInnerStep(2);
    } else if (step == 3) {
      if (!funcSelection.length) {
        setErrorMessage(
          "You have not selected any Functionality Sections. Please select at least one Functionality Section to be able to proceed."
        );
        setErrorCB(false);
        return setErrorModal(true);
      }
      setStep(4);
      setInnerStep(1);
    } else {
      doSave();
    }
  };

  const handlePreviousStep = () => {
    if (step == 4) {
      setStep(3);
      setInnerStep(2);
    } else if (step == 3) {
      setStep(2);
      setInnerStep(1);
    } else if (step == 2) {
      moveToB();
      setStep(1);
      setInnerStep(1);
    } else {
      setErrorMessage(
        "Are you sure you want to exit without completing the market creation process?"
      );
      setErrorCB(true);
      return setErrorModal(true);
    }
  };

  // dynamic tag change based on the welcome prop (welcome wizard / create new wizard)
  const Tag = welcome ? WelcomeLayout : DashboardLayout;
  return (
    <Tag>
      {!welcome && <DashboardNavbar />}
      <ErrorPopup
        errorModal={errorModal}
        setErrorModal={setErrorModal}
        errorCB={errorCB}
        setErrorCB={setErrorCB}
        cb={() => navigate(-1)}
        text={errorMessage}
      />

      <MDBox
        className="wizardCreate"
        sx={{ maxWidth: "640px", margin: "0 auto", marginTop: "40px" }}
      >
        <MDTypography
          sx={{ fontSize: "16px", lineHeight: "24px", color: "#9093B5" }}
        >
          Step {step}/4
        </MDTypography>
        <MDTypography
          sx={{
            color: "#090324",
            marginTop: "12px",
            marginBottom: "8px",
            lineHeight: "34px",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          {step == 1
            ? "Create your Primary Market"
            : step == 2
            ? "Create your Target Market"
            : step == 3
            ? "Select your Sections"
            : "Create your Market"}
        </MDTypography>

        {/* Description for each step */}
        {step == 1 ? (
          <MDTypography
            sx={{ color: "#394055", lineHeight: "24px", fontSize: "16px" }}
          >
            Add the bank, fintech, or other organization that is part of your
            Primary market. The Primary market is usually the organization you
            belong to.
            <br />
            <br />
            <b>Example 1 (bank to market):</b> If you belong to Bank X and want
            to compare yourself with your competitors, you may select Bank X as
            the only bank in your Primary market. Then you may select your
            competitors as your Target market.
            <br />
            <br />
            Alternatively, you could select a set of institutions to be part of
            your Primary market, if you would like to compare it with another
            one (your Target market).
            <br />
            <br />
            <b>Example 2 (market to market):</b> If you would like to compare
            the EU market with the US market, you could select European
            institutions for your Primary market and US institutions for your
            Target market.
            <br />
            <br />
            <b>Important note:</b> After creating your first market of focus,
            you have the option to create as many markets as you want.
          </MDTypography>
        ) : step == 2 ? (
          <MDTypography
            sx={{ color: "#394055", lineHeight: "24px", fontSize: "16px" }}
          >
            Add the banks, fintechs, or other organisations that is part of your
            Target market. The Target market usually consists of the set of
            banks/fintechs you would like to compare against your Primary
            market.
            <br />
            <br />
            <b>Important note:</b> After creating your first market of focus,
            you have the option to create as many markets as you want.
          </MDTypography>
        ) : step == 3 ? (
          <MDTypography
            sx={{ color: "#394055", lineHeight: "24px", fontSize: "16px" }}
          >
            Add the Functionality Categories you want to focus on.
            <br />
            <br />
            <b>Important note:</b> You can always add or remove functionalities
            later on through your markets management.
          </MDTypography>
        ) : (
          <MDTypography
            sx={{ color: "#394055", lineHeight: "24px", fontSize: "16px" }}
          >
            Give your market a name.
          </MDTypography>
        )}

        {step == 1 && innerStep == 1 ? (
          <View11
            setInnerStep={setInnerStep}
            bucketSelection={bucketSelectionA}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        ) : null}

        {step == 1 && innerStep == 2 ? (
          <View12
            emptyBucketSelection={emptyBucketSelectionA}
            world={world}
            handleSelection={handleSelectionA}
            selection={selectionA}
            toggleChildren={toggleChildrenA}
            handleParentClassName={handleParentClassNameA}
            bucketSelection={bucketSelectionA}
            setInnerStep={setInnerStep}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            errorModal={errorModal}
          />
        ) : null}

        {step == 2 && innerStep == 1 ? (
          <View21
            setInnerStep={setInnerStep}
            bucketSelection={bucketSelectionB}
            bucketSelectionA={bucketSelectionA}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        ) : null}

        {step == 2 && innerStep == 2 ? (
          <View22
            emptyBucketSelection={emptyBucketSelectionB}
            world={world}
            handleSelection={handleSelectionB}
            selection={selectionB}
            toggleChildren={toggleChildrenB}
            handleParentClassName={handleParentClassNameB}
            bucketSelection={bucketSelectionB}
            setInnerStep={setInnerStep}
            bucketSelectionA={bucketSelectionA}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            errorModal={errorModal}
          />
        ) : null}

        {step == 3 && innerStep == 1 ? (
          <View31
            setInnerStep={setInnerStep}
            bucketSelectionA={bucketSelectionA}
            bucketSelectionB={bucketSelectionB}
            bucketSelection={bucketSelectionF}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        ) : null}

        {step == 3 && innerStep == 2 ? (
          <View32
            emptyBucketSelection={emptyBucketSelectionF}
            handleSelection={handleFuncSelection}
            selection={funcSelection}
            toggleChildren={toggleFuncChildren}
            handleParentClassName={handleParentClassNameF}
            bucketSelection={bucketSelectionF}
            setInnerStep={setInnerStep}
            functionalities={functionalities}
            bucketSelectionA={bucketSelectionA}
            bucketSelectionB={bucketSelectionB}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            errorModal={errorModal}
          />
        ) : null}

        {step == 4 && (
          <View4
            title={title}
            setTitle={setTitle}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        )}
      </MDBox>
    </Tag>
  );
};

export default WizardCreate;
