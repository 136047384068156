import Card from "@mui/material/Card";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import MDBox from "components/MDBox";
import CircularProgress from '@mui/material/CircularProgress';
import { useState,useContext,useEffect,useRef } from "react";
import UserContext from 'context/UserContext';
import AppleIcon from '@mui/icons-material/Apple';
import ComputerIcon from '@mui/icons-material/Computer';
import WatchIcon from '@mui/icons-material/Watch';
import MDButton from "components/MDButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { line } from "stylis";
import ChartsHeader from "../ChartsHeader";


 
export default function FuncsBreakdown({loadPopup, workspaceMedia,mediaid}){

    const userContext = useContext(UserContext);
    const [isLoading,setIsLoading]=useState(false);
    const [dashboards,setDashboards]=useState([]);
    const [selectedMediaID,setSelectedMediaID]=useState(mediaid);
    const [isLoadingMarketPosition, setIsLoadingMarketPosition] = useState(true);

    const [inner_graph_visible, setInnerGraphVisible] = useState(false);
    const [innerGraphData, setInnerGraphData] = useState(null)

    const [marketA, setMarketA] = useState(null);
    const [marketB, setMarketB] = useState(null);

    const [options_functionalities_breakdown,setOptions_functionalities_breakdown] = useState({
        title: {
          text: 'Functionalities Breakdown'
        },   type: 'column',
        series: [{
          data: [1, 2, 3]
        }]
      });
    const defaultBank="107"; // REVOLUT V.7 (EU)
    const defaultBankname="";

    const [optionsMarketPosition,setOptionsMarketPosition]=useState({
        title: {
          text: 'Market Position'
        },   type: 'column',
        series: [{
          data: [1, 2, 3]
        }]
      });


      const [options_ux_breakdown,setOptions_ux_breakdown] = useState(null);


      const [dimensions, setDimensions] = useState({
        // height: window.innerHeight,
        width: window.innerWidth
      })


    
    const colours_arr = [
        "rgb(255, 210, 224)",
        "rgb(250, 204, 219)",
        "rgb(244, 197, 214)",
        "rgb(239, 191, 209)",
        "rgb(233, 185, 204)",
        "rgb(228, 179, 200)",
        "rgb(222, 172, 195)",
        "rgb(217, 166, 190)",
        "rgb(211, 160, 186)",
        "rgb(206, 154, 181)",
        "rgb(200, 148, 177)",
        "rgb(194, 142, 173)",
        "rgb(188, 137, 169)",
        "rgb(183, 131, 164)",
        "rgb(177, 125, 160)",
        "rgb(171, 120, 156)",
        "rgb(165, 114, 152)",
        "rgb(159, 108, 148)",
        "rgb(153, 103, 144)",
        "rgb(147, 98, 140)",
        "rgb(141, 92, 137)",
        "rgb(135, 87, 133)",
        "rgb(129, 82, 129)",
        "rgb(122, 77, 125)",
        "rgb(116, 72, 122)",
        "rgb(110, 67, 118)",
        "rgb(103, 62, 115)",
        "rgb(97, 57, 111)",
        "rgb(91, 52, 108)",
        "rgb(84, 48, 104)",
        "rgb(77, 43, 101)",
        "rgb(71, 39, 97)",
        "rgb(64, 35, 94)",
        "rgb(57, 31, 90)",
        "rgb(50, 27, 87)",
        "rgb(42, 23, 83)",
        "rgb(34, 19, 80)",
        "rgb(25, 15, 77)",
        "rgb(15, 12, 73)",
        "rgb(2, 7, 70)" ] 

    const goPreviousGraph  = () =>{

        console.log("go back")

        setInnerGraphVisible(false)

        var dummy={
            "series": [
                {
                    "name": "Markets",
                    "data": [
                        {
                            "name": "Market A",
                            "name_2": "Market A",
                            "color": "rgb(2, 7, 70)",
                            "id": "a",
                            "myDrilldown": "media16",
                            "y": marketA
                        },
                        {
                            "name": "Market B",
                            "name_2": "Market B",
                            "color": "rgb(255, 200, 230)",
                            "id": "b",
                            "myDrilldown": "media22",
                            "y": marketB
                        },
                    ]
                },
            ],
        }

        setOptions_ux_breakdown(        {chart: {
            drillID: 'A',
            type: 'column',
            events: {
            //	drillupall: drillup
    },
    width:dimensions.width-230,

        },
        title: {
            //text: 'Barclays Average Use Case score Per Section'
            text:""
        },
        credits: {
            enabled: false
        },

        xAxis: {
            // type: 'category',
            categories: ['Market A', 'Market B'],
            crosshair: true,
            // labels: {
            //     formatter: function() {
            //         return '<span></span>';
            //     }
            // },
            // categories: $categoryBank
        },
        yAxis: {
            title: {
                text: 'Number of Functionalities'
            }
        },

        exporting:{
            buttons: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y}'
                },
                point: {
                    events: {
                        click: function () {
                            console.log(this.id)
                            inside_markets(this.id)

                        }
                    }
                }

            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name_2}</span> with average of <b>{point.y}</b> <br/>'
        },

        series: dummy.series,
        drilldown: {
            drillUpButton: {
                relativeTo: 'spacingBox',
                position: {
                    //align: 'left',
                    x: -25,
                    y: -5,
                    //verticalAlign: 'top',
                    verticalAlign: 'top'
                },
                theme: {
                    fill: 'white',
                    'stroke-width': 1,
                    stroke: 'silver',
                    r: 0,
                    states: {
                        hover: {
                            stroke: '#4d5eFC',
                            fill: '#4d5eFC'
                        },
                        select: {
                            stroke: '#2f3b4a',
                            fill: '#4d5eFC'
                        }
                    }
                }
            },
            series: dummy.series

        }
    })

    }




    async function getDashboards() {


        setIsLoading(true)


         var data={};
        data['mediaid']=selectedMediaID;
        var apiURL = process.env.REACT_APP_SERVER_URL;
          apiURL+="/api/chart_funcs_breakdown";
         const metadataResponse2 = await fetch(apiURL, {
           method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        body:JSON.stringify(data),
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           headers: {
             'bearer': userContext.userData.apiToken,
             'Content-Type': 'application/json'
           },
         });
         var resp = await metadataResponse2.json();
         console.log(resp)

        //  setMarkets(resp)

        // console.log(resp.marketA[0].avgQuantity)
        if (resp.marketA.length===0){
            var quantityA = 0
        } else {
            var quantityA = resp.marketA[0].avgQuantity.toFixed(0)
            quantityA=parseFloat(quantityA)
        }


            if (resp.marketB.length===0){
                var quantityB = 0
            } else {
                var quantityB = resp.marketB[0].avgQuantity.toFixed(0)
                quantityB=parseFloat(quantityB)
            }

            setMarketA(quantityA)
            setMarketB(quantityB)


         var dummy={
            "series": [
                {
                    "name": "Markets",
                    "data": [
                        {
                            "name": "Market A",
                            "name_2": "Market A",
                            "color": "rgb(2, 7, 70)",
                            "id": "a",
                            "myDrilldown": "media16",
                            "y": quantityA
                        },
                        {
                            "name": "Market B",
                            "name_2": "Market B",
                            "color": "rgb(255, 200, 230)",
                            "id": "b",
                            "myDrilldown": "media22",
                            "y": quantityB
                        },
                    ]
                },
            ],
        }

        setOptions_ux_breakdown(        {chart:
            {
            drillID: 'A',
            type: 'column',
            events: {
            //	drillupall: drillup
    },
    width:dimensions.width-230,

        },
        title: {
            //text: 'Barclays Average Use Case score Per Section'
            text:""
        },
        credits: {
            enabled: false
        },
        exporting: {
            buttons: false
        },
        xAxis: {
            // type: 'category',
            categories: ['Market A', 'Market B'],
            crosshair: true,
            // labels: {
            //     formatter: function() {
            //         return '<span></span>';
            //     }
            // },
            // categories: $categoryBank
        },
        yAxis: {
            title: {
                text: 'Number of Functionalities'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y}'
                },
                point: {
                    events: {
                        click: function () {
                            console.log(this.id)
                            inside_markets(this.id)

                        }

                    }

                 }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name_2}</span> with average of <b>{point.y}</b> <br/>'
        },

        series: dummy.series,
        drilldown: {
            drillUpButton: {
                relativeTo: 'spacingBox',
                position: {
                    //align: 'left',
                    x: -25,
                    y: -5,
                    //verticalAlign: 'top',
                    verticalAlign: 'top'
                },
                theme: {
                    fill: 'white',
                    'stroke-width': 1,
                    stroke: 'silver',
                    r: 0,
                    states: {
                        hover: {
                            stroke: '#4d5eFC',
                            fill: '#4d5eFC'
                        },
                        select: {
                            stroke: '#2f3b4a',
                            fill: '#4d5eFC'
                        }
                    }
                }
            },
            series: dummy.series

        }
    })


    setIsLoading(false)
      }

    useEffect(()=>{
        getDashboards();
        window.addEventListener('resize', handleResize)
      }, [])






      function handleResize() {
        setDimensions({
          // height: window.innerHeight,
          width: window.innerWidth
        })

    }

    useEffect(()=>{
        setIsLoading(true)

        if (inner_graph_visible){
            var dummy = innerGraphData.dummy
            var mycategories = innerGraphData.mycategories
            setOptions_ux_breakdown(        {chart:
                 {
                drillID: 'A',
                type: 'column',
                events: {
                //	drillupall: drillup
        },
        width:dimensions.width-230,

            },
            title: {
                //text: 'Barclays Average Use Case score Per Section'
                text:""
            },
            credits: {
                enabled: false
            },

            xAxis: {
                // type: 'category',
                categories: mycategories,
                crosshair: true,

                // labels: {
                //     formatter: function() {
                //         return '<span></span>';
                //     }
                // },
                // categories: $categoryBank
            },
            yAxis: {
                title: {
                    text: 'Number of Functionalities'
                }
            },
            exporting: {
                buttons: false
            },
            legend: {
                enabled: true
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    borderWidth: 0,
                    dataLabels: {
                        enabled: false,
                        format: '{point.y}'
                    },
                    point: {
                        events: {
                            click: function () {
                                console.log(this)
                              //  loadPopup(selectedMediaID, 2, this.treeid, undefined, "5",undefined)
                          //      loadPopup(selectedMediaID, 9, this.treeid, undefined, "5",undefined)
                                // inside_markets(this.id)

                            }
                        }
                    }

                }
            },

            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name_2}</span> with average of <b>{point.y}</b> <br/>'
            },

            series: dummy.series,
            drilldown: {
                drillUpButton: {
                    relativeTo: 'spacingBox',
                    position: {
                        //align: 'left',
                        x: -25,
                        y: -5,
                        //verticalAlign: 'top',
                        verticalAlign: 'top'
                    },
                    theme: {
                        fill: 'white',
                        'stroke-width': 1,
                        stroke: 'silver',
                        r: 0,
                        states: {
                            hover: {
                                stroke: '#4d5eFC',
                                fill: '#4d5eFC'
                            },
                            select: {
                                stroke: '#2f3b4a',
                                fill: '#4d5eFC'
                            }
                        }
                    }
                },
                series: dummy.series

            }
        })
        } else {
            var dummy={
                "series": [
                    {
                        "name": "Markets",
                        "data": [
                            {
                                "name": "Market A",
                                "name_2": "Market A",
                                "color": "rgb(2, 7, 70)",
                                "id": "a",
                                "myDrilldown": "media16",
                                "y": marketA
                            },
                            {
                                "name": "Market B",
                                "name_2": "Market B",
                                "color": "rgb(255, 200, 230)",
                                "id": "b",
                                "myDrilldown": "media22",
                                "y": marketB
                            },
                        ]
                    },
                ],
            }

            setOptions_ux_breakdown(        {chart: {
                drillID: 'A',
                type: 'column',
                events: {
                //	drillupall: drillup
        },
        width:dimensions.width-230,

            },
            title: {
                //text: 'Barclays Average Use Case score Per Section'
                text:""
            },
            credits: {
                enabled: false
            },
            exporting: {
                buttons: false
            },
            xAxis: {
                // type: 'category',
                categories: ['Market A', 'Market B'],
            crosshair: true,
                // labels: {
                //     formatter: function() {
                //         return '<span></span>';
                //     }
                // },
                // categories: $categoryBank
            },
            yAxis: {
                title: {
                    text: 'Number of Functionalities'
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    borderWidth: 0,
                    dataLabels: {
                        enabled: false,
                        format: '{point.y}'
                    },
                    point: {
                        events: {
                            click: function () {
                                console.log(this.id)
                                inside_markets(this.id)

                            }
                        }
                    }

                }
            },

            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name_2}</span> with average of <b>{point.y}</b> <br/>'
            },

            series: dummy.series,
            drilldown: {
                drillUpButton: {
                    relativeTo: 'spacingBox',
                    position: {
                        //align: 'left',
                        x: -25,
                        y: -5,
                        //verticalAlign: 'top',
                        verticalAlign: 'top'
                    },
                    theme: {
                        fill: 'white',
                        'stroke-width': 1,
                        stroke: 'silver',
                        r: 0,
                        states: {
                            hover: {
                                stroke: '#4d5eFC',
                                fill: '#4d5eFC'
                            },
                            select: {
                                stroke: '#2f3b4a',
                                fill: '#4d5eFC'
                            }
                        }
                    }
                },
                series: dummy.series

            }
        })

        }


         setIsLoading(false)
    }, [dimensions])



    async function setUserWorkspaceState(newmedia){
        let data={};
        var newState = workspaceMedia
        console.log(newState)
        newState.functionalities_breakdown = newmedia
        data['charsState']=[newState];
        var apiURL = process.env.REACT_APP_SERVER_URL;
          apiURL+="/api/changeUserWorkspaceState";
         const metadataResponse = await fetch(apiURL, {
           method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        body:JSON.stringify(data),
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           headers: {
           'bearer': userContext.userData.apiToken,
           'Content-Type': 'application/json'
           },
         });
          var resp = await metadataResponse.json();
        }



    const handleMedia=(mediaid)=>{
		// setUserWorkspaceState(mediaid)
        setUserWorkspaceState(mediaid)
       	setSelectedMediaID(mediaid)
        // getDashboards()
      }





    useEffect(()=>{
        getDashboards()
    },[selectedMediaID])



    const inside_markets = async (marketid) =>{

        setIsLoading(true)

        // 1st api call
        var data={};
        data['mediaid']=selectedMediaID;
        data['market']=marketid
        var apiURL = process.env.REACT_APP_SERVER_URL;
          apiURL+="/api/chart_funcs_breakdown_inside";
         const metadataResponse2 = await fetch(apiURL, {
           method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        body:JSON.stringify(data),
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           headers: {
             'bearer': userContext.userData.apiToken,
             'Content-Type': 'application/json'
           },
         });
         var totalresp = await metadataResponse2.json();
        //  resp= resp.filter(word => !word._id.ParentName.includes(";"));

         let resp,resp2
        if(marketid==='a'){

            resp=totalresp.ma;
            resp2=totalresp.mb;
        } 
        if(marketid==='b'){

             resp=totalresp.mb;
            resp2=totalresp.ma;
        } 





        // take care of &
        resp.map((word) => {
            if(word._id.ParentName.includes("Login")){
                var name = word._id.ParentName
                name = name.replace('&', 'and');
                name = name.replace('andamp;', 'and');
                word._id.ParentName = name
            }

        });
        resp.map((word) => {
            if(word._id.ParentName.includes("Profile")){
                var name = word._id.ParentName
                name = name.replace('&', 'and');
                name = name.replace('andamp;', 'and');
                word._id.ParentName = name
            }

        });
        var login_found = 0
        var profile_found = 0

        resp.map((word) => {
            if(word._id.ParentName.includes("Login")){
                login_found++;
            }
            if(word._id.ParentName.includes("Profile")){
                profile_found++
            }
        });

        if(login_found===2){
            // resp.shift()
            const indexOfObject = resp.findIndex(object => {
                return object._id.ParentName.includes("Login");
              });
              resp.splice(indexOfObject, 1);
        }

        if(profile_found===2){
            const indexOfObject = resp.findIndex(object => {
                return object._id.ParentName.includes("Profile");
              });
              resp.splice(indexOfObject, 1);
        }

         console.log(resp)


            //  2nd api call take care of &
            resp2.map((word) => {
                if(word._id.ParentName.includes("Login")||word._id.ParentName.includes("Profile")){
                    var name = word._id.ParentName
                    name = name.replace('&', 'and');
                    name = name.replace('andamp;', 'and');
                    word._id.ParentName = name
                }

            });
            var profile_found = 0
            var login_found=0

            resp2.map((word) => {
                if(word._id.ParentName.includes("Login")){
                    login_found++;
                }
                if(word._id.ParentName.includes("Profile")){
                    profile_found++
                }
            });
    
            if(login_found===2){
                // resp.shift()
                const indexOfObject = resp2.findIndex(object => {
                    return object._id.ParentName.includes("Login");
                  });
                  resp2.splice(indexOfObject, 1);
            }
    
            if(profile_found===2){
                const indexOfObject = resp2.findIndex(object => {
                    return object._id.ParentName.includes("Profile");
                  });
                  resp2.splice(indexOfObject, 1);
            }
            console.log(resp2)


            var arr = []



            var mycategories = []
           //  find all market a columns
            resp.map((i)=>{
                var name = i._id.ParentName
                mycategories.push(name)
            })
            console.log(mycategories)



            // find all market b line names
            var line_names = []
            resp2.map((i)=>{
                var name = i._id.ParentName
                name = name.replace('&', 'and');
                name = name.replace('andamp;', 'and');
                line_names.push(name)
            })
            console.log(line_names)



            // get both markets names
            var c = mycategories.concat(line_names)
            var both_markets = c.filter((item, pos) => c.indexOf(item) === pos)
            console.log('both',both_markets)



            // get market difference
            let difference = mycategories
                 .filter(x => !line_names.includes(x))
                 .concat(line_names.filter(x => !mycategories.includes(x)));
            console.log('dif',difference)




            var colorB = 1;
            var colour_arr_length = colours_arr.length
            // if(marketid==="b"){
            //     colorB = 243
            // }

            //  generate our columns
            resp.map((i)=>{
            var name = i._id.ParentName
            if(i.avgQuantity!==undefined){
                var quantity = i.avgQuantity.toFixed(0)
            } else {
                var quantity = 0
            }
            // Math.round(quantity)
            quantity=parseFloat(quantity)
            // console.log(quantity)
            let obj = {
                     "name": name,
                    "name_2": i._id.ParentName,
                    "color":  marketid==="a"
                    ?
                    // "rgb(2, 7, 70)"
                    // "rgb(2, 7, 70,"+colorB+")"
                    colours_arr[colour_arr_length-colorB]
                    :
                    colours_arr[colorB],
                    // "rgb(275,210,224)",
                    // "rgb(78,98,243,"+colorB+")",
                    
                    "id": i._id.ParentName,
                    "myDrilldown": "media16",
                    "y": quantity,
                    "treeid":i._id.TreeID

            }
            arr.push(obj)
            colorB+=2;
         })

         // traverse array difference with resp2 when market a < market b
        //  if(difference.length>0 && mycategories.length<line_names.length){
        //     difference.map((i)=>{

        //         // for charts
        //         let obj = {
        //             "name": i,
        //             "name_2": i,
        //             "color":  '#'+ Math.floor(Math.random()*20077215).toString(16),
        //             "id": i,
        //             "myDrilldown": "media16",
        //             "y": 0,


        //         }
        //         arr.push(obj)


        //     })


        // }


        var lines = []
        // generate our line
        both_markets.map((i)=>{
            var not_found = true
            resp2.map((ii)=>{
                if(ii._id.ParentName===i){
                    if(ii.avgQuantity!==undefined){
                        var quantity = ii.avgQuantity.toFixed(0)
                    } else {
                        var quantity = 0
                    }

                    lines.push(parseFloat(quantity))
                    not_found = false
                }
            })
            if(not_found){
                lines.push(0)
            }
        })
        console.log('lines',lines)




        var dummy={
            "series": [
                {
                    "name": "Market "+marketid.toUpperCase(),
                    "data": arr
                },
                {
                    type: 'line',
                    name: marketid==="a"?'Market B':'Market A',
                    data: lines,
                    // data: [resp.avg_16[0].avg, resp.avg_23[0].avg],
                    marker: {
                        lineWidth: 2,
                        lineColor: Highcharts.getOptions().colors[4],
                        fillColor: 'white'
                    }
                  }
            ],
        }


        var innergraph = {}
        innergraph["dummy"]=dummy;
        innergraph["mycategories"]=line_names.length>mycategories.length?line_names:mycategories;
        setInnerGraphData(innergraph);
        setInnerGraphVisible(true)



        setOptions_ux_breakdown(        {chart: {
            drillID: 'A',
            type: 'column',
            events: {
            //	drillupall: drillup
    },
    width:dimensions.width-230,

        },
        title: {
            //text: 'Barclays Average Use Case score Per Section'
            text:""
        },
        credits: {
            enabled: false
        },
        exporting: {
            buttons: false
        },
        xAxis: {
            // type: 'category',
            categories: both_markets,
            crosshair: true,

            // labels: {
            //     formatter: function() {
            //         return '<span></span>';
            //     }
            // },
            // categories: $categoryBank
        },
        yAxis: {
            title: {
                text: 'Number of Functionalities'
            }
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y}'
                },
                point: {
                    events: {
                        click: function () {
                          console.log(this);
                            if (this.myDrilldown!==undefined) {
                              if(marketid==="a"){
                                  console.log("show a")

                                  loadPopup(selectedMediaID,4, this.treeid, undefined, "6",undefined)
                              }
                              if(marketid==="b"){
                                  console.log("show b")
                                  loadPopup(selectedMediaID, 5, this.treeid, undefined, "6",undefined)
                              }
                            }
                            // inside_markets(this.id)

                        }
                    }
                }

            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span> ',
            pointFormat: '<span style="color:{point.color}">{point.name_2}</span> with average of <b>{point.y}</b> <br/>'
        },

        series: dummy.series,
        drilldown: {
            drillUpButton: {
                relativeTo: 'spacingBox',
                position: {
                    //align: 'left',
                    x: -25,
                    y: -5,
                    //verticalAlign: 'top',
                    verticalAlign: 'top'
                },
                theme: {
                    fill: 'white',
                    'stroke-width': 1,
                    stroke: 'silver',
                    r: 0,
                    states: {
                        hover: {
                            stroke: '#4d5eFC',
                            fill: '#4d5eFC'
                        },
                        select: {
                            stroke: '#2f3b4a',
                            fill: '#4d5eFC'
                        }
                    }
                }
            },
            series: dummy.series

        }
    })
         setIsLoading(false)

    }

    const chartRef = useRef();

    return (
        <Card>
          <MDBox p={1}>
            <ChartsHeader
              title="Functionalities Breakdown"
              handleMedia={handleMedia}
              selectedMediaID={selectedMediaID}
              chartRef={chartRef}
            />
            {inner_graph_visible && (
              <ArrowBackIcon
                color="dark"
                onClick={() => goPreviousGraph()}
                fontSize="medium"
                sx={{
                  position: "absolute",
                  left: 6,
                  top: 32,
                  cursor: "pointer",
                  zIndex: 1,
                }}
              />
            )}

            {!isLoading ? (
              <HighchartsReact
                highcharts={Highcharts}
                options={options_ux_breakdown}
                ref={chartRef}
              />
            ) : (
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ p: 25 }}
              >
                <CircularProgress />
              </MDBox>
            )}
          </MDBox>
        </Card>
      );


}
