/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import UserContext from 'context/UserContext';
// redux
import { connect } from "react-redux"
import { change_notifications } from "context/actions/allActions";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import Footer from "scientia/Footer";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



function Notifications(props) {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const userContext = useContext(UserContext);
  const [notifications, setNotifications] = useState(props.ReduxState.notifications)

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
    
    // useEffect(() => {
    //   props.change_notifications(notifications.length)
    // });

    

  // const alertContent = (name) => (
  //   <MDMDTypography variant="body2" color="white">
  //     A simple {name} alert with{" "}
  //     <MDMDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
  //       an example link
  //     </MDMDTypography>
  //     . Give it a click if you like.
  //   </MDMDTypography>
  // );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="Clear"
      content="Are you sure you want to clear all"
      dateTime="11 mins ago"
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const clearAll = () =>{
    setNotifications([])
    props.change_notifications(0)
  }


  async function myaction(){
    // console.log('enter')
   
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={10}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant="h5">Notifications</MDTypography>
              </MDBox>
              <MDBox p={2}>
               
                {notifications?notifications.map((i)=>{
                  return(
                    <>
                  <div>
                    <Accordion className="accordion" expanded={expanded === 'panel'+i.id} onChange={handleChange('panel'+i.id)}>
                      
                      <AccordionSummary
                      
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                        <MDTypography sx={{ width: '33%', flexShrink: 0 }} color='white'>
                          {i.time}
                        </MDTypography>
                        <MDTypography color='white'>{i.text}</MDTypography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <MDTypography color="text">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                        </MDTypography>
                      </AccordionDetails>
                    </Accordion>
                   
                    
                  </div>
                </>
                )
              })
                :
                <>
                </>}
                
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h5">Actions</MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={3}>

                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton variant="gradient" color='info' className='share-clear' onClick={clearAll} fullWidth>
                      Clear All
                    </MDButton>
                    {renderSuccessSB}


                    {/* <MDButton variant="gradient" color='info' className='share-clear' onClick={myaction} >
                      My action
                    </MDButton> */}
                    
                  </Grid>

                </Grid>
              </MDBox>
            </Card>
          </Grid>

        
        </Grid>
      </MDBox>
      <Footer  />
    </DashboardLayout>
  );
}

// injecting state
const mapStateToProps = (state) =>{
  // console.log(state)
  return state
}

export default connect(mapStateToProps, {change_notifications}) (Notifications)


// export default Notifications;
