import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDBox from "components/MDBox";
import { Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const WizardAppBarr = ({
  value,
  handleChange,
  setSearchMarketA,
  setSearchMarketB,
  searchMarketA,
  searchMarketB,
}) => {
  return (
    <MDBox
      sx={{
        position: "relative",
        right: 0,
        top: 0,
      }}
    >
      <AppBar
        sx={{ position: "absolute", left: 0, top: 0 }}
        className="wizardTabs"
        // elevation={0} <---- appbar box shadow elevation
      >
        <Tabs
          value={value}
          onChange={handleChange}
          className="wizardTabs"
          TabIndicatorProps={{
            sx: { backgroundColor: "#020841" },
            className: "wizardTabIndicator",
            style: {
              borderBottom: "1px solid white",
            },
          }}
        >
          <Tab
            className="wizardTabs"
            label="Primary Bank(s)"
            icon={
              <Tooltip
                title="Add either your Organisation or the banks/fintechs you want to compare against with the Target market."
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 9,
                  cursor: "pointer",
                }}
              >
                <HelpOutlineIcon />
              </Tooltip>
            }
          />
          <Tab
            className="wizardTabs"
            label="Target Market"
            icon={
              <Tooltip
                title="Add the banks/fintechs that you want to compare against with the Primary."
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 9,
                  cursor: "pointer",
                }}
              >
                <HelpOutlineIcon />
              </Tooltip>
            }
          />
          <Tab
            className="wizardTabs"
            style={{ color: "white" }}
            label="Sections"
            icon={
              <Tooltip
                title="Add the Functionality Sections you want to focus on."
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 9,
                  cursor: "pointer",
                }}
              >
                <HelpOutlineIcon />
              </Tooltip>
            }
          />
        </Tabs>
      </AppBar>

      {/* search */}
      {value === 0 && (
        <MDBox
          className="wizardSearch"
          sx={{
            width: "100%",
            position: "absolute",
            top: 40,
            left: 0,
            zIndex: 5,
            paddingTop: "1.7em",
            paddingLeft: "2em",
            paddingRight: "2em",
            paddingBottom: "1em",
          }}
        >
          <input
            type="text"
            name="bankNameSearch"
            value={searchMarketA}
            onChange={(e) => setSearchMarketA(e.target.value)}
            className={searchMarketA.length > 0 ? "activeSearch" : null}
          />
          <label>Bank Name</label>
        </MDBox>
      )}

      {value === 1 && (
        <MDBox
          className="wizardSearch"
          sx={{
            width: "100%",
            position: "absolute",
            top: 40,
            left: 0,
            zIndex: 5,
            paddingTop: "1.7em",
            paddingLeft: "2em",
            paddingRight: "2em",
          }}
        >
          <input
            type="text"
            name="bankNameSearch"
            value={searchMarketB}
            onChange={(e) => setSearchMarketB(e.target.value)}
            className={searchMarketB.length > 0 ? "activeSearch" : null}
          />
          <label>Bank Name</label>
        </MDBox>
      )}
    </MDBox>
  );
};

export default WizardAppBarr;
