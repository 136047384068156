import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import svg from "./cross-popup.svg";
import "./style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "560px",
  bgcolor: "#FEFEFE",
  borderRadius: "10px",
  p: "32px",
  outline: "none",
  position: "relative",
};

export default function ErrorPopup({
  text,
  errorModal,
  setErrorModal,
  errorCB,
  cb,
}) {
  const handleErrorCB = () => {
    if (!errorCB) return setErrorModal(false);
    return cb();
  };

  return (
    <Modal
      open={errorModal}
      onClose={() => {
        setErrorModal(false);
      }}
      // BackdropProps marked as deprecated but it works
      BackdropProps={{ style: { backgroundColor: "#000000A3" } }}
      style={{ outline: "none" }}
      disableEnforceFocus
    >
      <MDBox sx={style}>
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: "8px",
          }}
        >
          <MDTypography
            sx={{
              fontSize: "18px",
              lineHeight: "27px",
              color: "#090324",
              fontWeight: "bold",
            }}
          >
            Looks like something went wrong!
          </MDTypography>
          <img
            style={{ marginBottom: 1, cursor: "pointer" }}
            src={svg}
            onClick={() => setErrorModal(false)}
          />
        </MDBox>
        <MDTypography sx={{ fontSize: "16px", lineHeight: "24px" }}>
          {text}
        </MDTypography>
        <MDBox
          sx={{
            display: "flex",
            width: "45%",
            gap: "8px",
            marginLeft: "auto",
            marginTop: "48px",
          }}
        >
          <button onClick={() => setErrorModal(false)} className="modal-btn">
            Cancel
          </button>
          <button onClick={handleErrorCB} className="modal-btn btn-blue">
            OK
          </button>
        </MDBox>
      </MDBox>
    </Modal>
  );
}
