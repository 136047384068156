import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const FuncBucketInactive = ({ bucketSelection, onClick }) => {
  return (
    <MDBox
      onClick={onClick}
      sx={{
        border: "1px solid #D4D6EA",
        marginBottom: "14px",
        backgroundColor: "transparent",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      {/* scroll container */}
      <div className="tag-parent">
        {bucketSelection.map((i) => {
          if (i?.children?.length > 0)
            return (
              <div key={i.title}>
                <MDTypography
                  sx={{
                    color: "#9093B5",
                    fontSize: "12px",
                    marginLeft: "14px",
                  }}
                >
                  {i.title}
                </MDTypography>
                <div className="tags-container">
                  {i.children.map((e) => (
                    <div className="disabled-tag">{e.DisplayName}</div>
                  ))}
                </div>
              </div>
            );
        })}
      </div>
    </MDBox>
  );
};

export default FuncBucketInactive;
