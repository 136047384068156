
  // vanilla js function to hide tabs
//   function hidethis(e) {
//     e.preventDefault();
//     var x = document.getElementsByClassName("mainTab")[0]
//     var y = document.getElementsByClassName("showTabBtn")[0]
//     x.classList.add("mainTab-hidden")
//     y.classList.remove("Btn-hidden")
//  }

export default {

  columns: [
    { Header: "ID", accessor: "NodeID", width:"9%" },
    
     { Header: "TItle", accessor: "title", },
   
    { Header: "Description", accessor: "Description", Cell: (props) => {
      return (<p style={{ width:'100%',overflowWrap: 'break-word' }}>{props.value}</p>
      )}},
    // { Header: "Status", accessor: d => d.Status=="1"?"Active":"Not Supported", Cell: (props) => {
    { Header: "Status", accessor: "status",
    //  Cell: (props) => {

    // return (
    //     <p style={{ color: props.value === "Active" ? "rgb(26, 115, 232)" : "#f4516c" }}><span >&#8226;</span>
    //     {props.value}</p>
    //   )}
    },

    { Header: "Actions", accessor: "actions",Cell: (props) => {
      return (<p style={{ width:'100%',overflowWrap: 'break-word' }} >{props.value}</p>
      )}

     },

  ],

  rows: [
  ],
};
