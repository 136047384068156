import { Skeleton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import InputWithIcon from "./inputWithIcon";

const List = ({
  data,
  selection,
  handleSelection,
  toggleChildren,
  handleParentClassName,
  bucketSelection,
}) => {
  const [active, setActive] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => setQuery(""), [selection]);

  return (
    <MDBox
      sx={{
        display: "flex",
        height: "338px",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        width: "800px",
        p: 1,
      }}
    >
      {data?.length === 0 ? (
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "50%",
            height: "100%",
          }}
        >
          <Skeleton width="100%" animation="wave" height="3em" />
          <Skeleton width="100%" animation="wave" height="3em" />
          <Skeleton width="100%" animation="wave" height="3em" />
          <Skeleton width="100%" animation="wave" height="3em" />
          <Skeleton width="100%" animation="wave" height="3em" />
          <Skeleton width="100%" animation="wave" height="3em" />
        </MDBox>
      ) : (
        <>
          {data.every((i) => i.banks.length === 0) ? (
            <MDTypography variant="h6">
              You have selected every available bank in your Primary Banks
              selection
            </MDTypography>
          ) : (
            <>
              <MDBox className="type-list">
                <ul className="ul">
                  {data.map((i) => {
                    if (i?.banks?.length > 0)
                      return (
                        <li
                          className={
                            active.title == i.title
                              ? "active"
                              : bucketSelection.some(
                                  (item) =>
                                    item.title == i.title &&
                                    item.banks.length > 0
                                )
                              ? "items-chosen"
                              : null
                          }
                          onClick={() => setActive(i)}
                          key={i.title}
                        >
                          {i.title}
                        </li>
                      );
                  })}
                </ul>
              </MDBox>
              <MDBox className="checkbox-content">
                {active?.banks?.length && (
                  <>
                    {/* <TextField
                      fullWidth
                      value={query}
                      label="Search"
                      onChange={(e) => setQuery(e.target.value)}
                    /> */}
                    <InputWithIcon
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                    <ul className="ul checkbox-list">
                      <li
                        onClick={() =>
                          toggleChildren(active.title, active.banks)
                        }
                      >
                        <label htmlFor="Select All">
                          <input
                            name="Select All"
                            type="checkbox"
                            checked={handleParentClassName(
                              active.title,
                              active.banks
                            )}
                          />
                          <div className="checkbox-block"></div>
                          Select All
                        </label>
                      </li>
                    </ul>

                    <ul className="ul checkbox-list">
                      {active?.banks?.length &&
                        active?.banks
                          ?.filter((i) =>
                            i.BankName.toLowerCase().includes(
                              query.toLowerCase()
                            )
                          )
                          ?.map((inner) => (
                            <li
                              key={inner.BankID}
                              onClick={() =>
                                handleSelection(active.title, inner)
                              }
                            >
                              <label htmlFor={inner.BankName}>
                                <input
                                  name={inner.BankName}
                                  type="checkbox"
                                  checked={selection.some(
                                    (e) =>
                                      e.title === active.title &&
                                      e.inner === inner
                                  )}
                                  readOnly
                                />
                                <div className="checkbox-block"></div>
                                {inner.BankName}
                              </label>
                            </li>
                          ))}
                    </ul>
                  </>
                )}
              </MDBox>
            </>
          )}
        </>
      )}
    </MDBox>
  );
};

export default List;
