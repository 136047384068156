import Card from "@mui/material/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext, useEffect, useRef } from "react";
import UserContext from "context/UserContext";
import { func } from "prop-types";
import ChartsHeader from "../ChartsHeader";
import { Pagination } from "@mui/material";

export default function SuperChart2({ loadPopup,mediaid,workspaceMedia }) {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMediaID, setSelectedMediaID] = useState(mediaid);
  const [selectedExclude, setSelectedExclude] = useState(false);
  const [superChartLoading, setsuperChartLoading] = useState(true);
  const [options, setOptions] = useState(null);
  const [seed, setSeed] = useState(1);
  const [banksPagination, setBanksPagination] = useState([])
  const chartRef = useRef();
  

  const reset = () => {
    var x = Math.random() * 100;
    setSeed(x);
  };
  // const [screenwidth, setScreenWidth] = useState(1560);

  const hashCode = (s) =>
    s.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);

  const loadchart = async () => {
    setsuperChartLoading(true);
    let mydata = {};
    if (selectedMediaID !== undefined) {
      mydata["mediaid"] = selectedMediaID;
    } else {
      mydata["mediaid"] = "22";
    }

    var data2 = {};
    //  console.log("super")
    data2["mediaid"] = selectedMediaID;
    data2["exclude"] = selectedExclude;
    var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL += "/api/chart_superchartv2";
    const metadataResponse2 = await fetch(apiURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data2),
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        bearer: userContext.userData.apiToken,
        "Content-Type": "application/json",
      },
    });
    var resp = await metadataResponse2.json();

    var mybanks = resp.mybanks;
    setBanksPagination(mybanks)

    // ----------------------------------------------
    //           var arr=[];
    //         for(var i in resp.data) {
    //           arr.push(resp.data[i]);
    //          }

    //          var fc=[];
    //          var categories=[];
    //          var series=[];
    //          var banks=[];
    //          var bank_name=[];
    //          arr.forEach(function(row,index) {
    //              if (!categories.includes(row.DisplayName)) {
    //                categories.push(row.DisplayName);
    //              }
    //              if (banks[row.BankID]===undefined)  banks[row.BankID]=[];
    //              var data={name:row.BankName,data:row.count}
    //              banks[row.BankID]['BankName']=row.BankName;

    //              banks[row.BankID][hashCode(row.DisplayName+"treeid")]=row.ParentID;
    //              banks[row.BankID]['BankID']=row.BankID;
    //              banks[row.BankID][hashCode(row.DisplayName)]=row.count;
    //              banks[row.BankID]['Color']=row.colors.Color;
    //          });
    //          banks.forEach(function(row,index) {
    //            let data=[]
    //            let nodes=[];
    //            categories.forEach(function(r,i) {
    //              let val=0;
    //             let valNode=0;
    //              if (row[hashCode(r)]!==undefined) val=row[hashCode(r)];
    //              data.push(val)
    //                 if (row[hashCode(r+"treeid")]!==undefined) valNode=row[hashCode(r+"treeid")];
    //               nodes.push(valNode)
    //            });
    //               series.push({name:row.BankName,bankid:row['BankID'],color:row['Color'],nodes:nodes,data:data});
    //          });
    // // ----------------------------------------------

    var objects = [];

    var j = 0;
    resp.correct_data_format.map((i) => {
      let object = {
        name: i.DisplayName,
        color: "#" + Math.floor(Math.random() * 16777215).toString(16),
        data: i.data,
        data_more: i.data_more,
      };

      objects.push(object);
      j++;
    });

    console.log(objects);

    //  resp = resp.all

    //    var banks = []

    //    if(resp[0].banksA.length!==0){
    //        resp[0].banksA.map((i)=>{
    //         // filter by media id
    //         if(i.MediaID === selectedMediaID){
    //             banks.push(i.BankName)
    //         }
    //        })
    //    }

    //    if(resp[0].banksB.length!==0){
    //     resp[0].banksB.map((i)=>{
    //      // filter by media id
    //      if(i.MediaID === selectedMediaID && !banks.includes(i.BankName)){
    //          banks.push(i.BankName)
    //      }
    //     })
    // }

    // console.log(mybanks)

    setOptions({
      chart: {
        type: "bar",
        height: 850,
      },

      // pointWidth: 500,
      // groupPadding: 5.2,
      // stacking: 'normal',
      title: {
        text: "",
      },
      xAxis: {
        // softMin:20,
        // softMax:20,
        categories: mybanks,
        // lineWidth:10,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Functionalities",
        },
      },
      exporting: {
        buttons: false,
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        bar: {
          stacking: "percent",
        },
        series: {
          stacking: "percent",
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                //    chart_functionalities_overview_forBankMedia(fc[this.category]);

                let d = this.series.userOptions;
                var bid;
                var treeid;
                d.data_more.map((i) => {
                  if (i.BankName) {
                    if (i.BankName === this.category) {
                      bid = i.BankID;
                      treeid = i.treeid;
                    }
                  }
                });

                //   if (selectedExclude==true) {
                //     loadPopup(selectedMediaID,"7",d.nodes[this.index],''+d.bankid,'6');
                //   } else {
                //     loadPopup(selectedMediaID,"1",d.nodes[this.index],''+d.bankid,'6');

                //   }
                // loadPopup(selectedMediaID,"1",fc[this.category],bankid);
                if (selectedExclude == true) {
                  loadPopup(selectedMediaID, "7", treeid, "" + bid, "6");
                } else {
                  loadPopup(selectedMediaID, "1", treeid, "" + bid, "6");
                }
                // loadPopup(selectedMediaID,"1",fc[this.category],bankid);
              },
            },
          },
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true,
        backgroundColor: "white",
      },
      series: objects,
      // [
      //         {
      //         name: 'login',
      //         color: 'gold',
      //         data: [4,2,12,23,12,1,4,3,4],
      //     },
      //     {
      //         name: 'account',
      //         color: 'blue',
      //         data: [4,2,12,23,12,5,4,6,8],
      //     }
      //     ]
    });

    setsuperChartLoading(false);

    // start pagination if there are more banks than PER_PAGE
    if (Math.ceil(mybanks.length / PER_PAGE) > 1) {
      chartRef?.current?.chart?.xAxis[0]?.setExtremes(0, PER_PAGE);
    }      
  };

  useEffect(() => {
    loadchart();
  }, []);

  useEffect(() => {
    loadchart();
    setPage(1);
  }, [selectedMediaID]);


  // const handleMedia = (newmedia) => {
  //   setSelectedMediaID(newmedia);
  // };


  async function setUserWorkspaceState(newmedia){
    let data={};
    var newState = workspaceMedia
    console.log(newState)
    newState.superchart2 = newmedia
    data['charsState']=[newState];
    var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/changeUserWorkspaceState";
     const metadataResponse = await fetch(apiURL, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    body:JSON.stringify(data),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
       'bearer': userContext.userData.apiToken,
       'Content-Type': 'application/json'
       },
     });
      var resp = await metadataResponse.json();
    }



const handleMedia=(mediaid)=>{
// setUserWorkspaceState(mediaid)
    setUserWorkspaceState(mediaid)
     setSelectedMediaID(mediaid)
    // getDashboards()
  }

  
  // pagination
  const [page, setPage] = useState(1);
  const PER_PAGE = 9;
  const count = Math.ceil(banksPagination.length / PER_PAGE);
  
  const handlePagination = (e, p) => {
    setPage(p);
    let begin = (p - 1) * PER_PAGE;
    let end = begin + PER_PAGE;    
    chartRef?.current?.chart?.xAxis[0]?.setExtremes(begin, end);
  };

  return (
    <Card key={seed}>
      <MDBox p={1}>
        <ChartsHeader
          title="Functionalities by Bank"
          handleMedia={handleMedia}
          selectedMediaID={selectedMediaID}
          chartRef={chartRef}
        />

        {setOptions !== null && !superChartLoading ? (
          <>
            <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
            />
            {count > 1 && 
            <MDBox sx={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 2, marginBottom: 2}}>
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                onChange={handlePagination}
              />
            </MDBox> }
          </>
        ) : (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 15,
            }}
          >
            <CircularProgress />
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}
