import SignInBasic from "layouts/authentication/sign-in/basic";
import ResetCover from "layouts/authentication/reset-password/cover";
import Logout from "layouts/authentication/sign-in/logout";

import NewLogin from "layouts/authentication/sign-in/newLogin";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team.jpg";

const auth_routes = [
  {
    type: "collapse",
    name: "Fi User",
    key: "fi-user",
    icon: <MDAvatar src={profilePicture} alt="Fi User" size="sm" />,
    collapse: [
      // {
      //   name: "Logout",
      //   key: "logout",
      //   route: "/authentication/sign-in/basic",
      //   component: <SignInBasic />,
      // },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <NewLogin />,
      },
    ],
  },
];

export default auth_routes;
