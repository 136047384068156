/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as React from 'react';
import { useState,useContext,useEffect } from "react";
import { CSSProperties } from 'react';
import { Route, Navigate, useLocation,useNavigate } from "react-router-dom";
import CardMedia from '@mui/material/CardMedia';
import AvailableBanks from './components/AvailableBanks';
// @mui material components
import Card from "@mui/material/Card";
import './home.css'
import { styled } from '@mui/material/styles';
// @mui material components
import Grid from "@mui/material/Grid";
import TooltipTop from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ImageListItem from '@mui/material/ImageListItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
// Material Dashboard 2 PRO React example components
import DashboardLayout from "scientia/LayoutContainers/DashboardLayout";
import DashboardNavbar from "scientia/Navbars/DashboardNavbar";
import Footer from "scientia/Footer";
import ReportsBarChart from "scientia/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "scientia/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "scientia/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "scientia/Cards/BookingCard";
import CircularProgress from '@mui/material/CircularProgress';
// Anaytics dashboard components
import MDInput from "components/MDInput";
import Box from '@mui/material/Box';
import ComputerIcon from '@mui/icons-material/Computer';
// Data

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import * as Cookies from "js-cookie";
import UserContext from 'context/UserContext';

import Parser from 'rss-parser';

import Select from 'react-select';
import {
  ColourOption,
  colourOptions,
  FlavourOption,
  GroupedOption,
  groupedOptions,
} from './data/demo.js';
import { Divider, ImageList } from "@mui/material";
import { Image } from '@mui/icons-material';


function NoAccess() {

  const userContext = useContext(UserContext);
  const [isLoading,setIsLoading]=useState(true);
  const [isRunning,setIsRunning]=useState(null);
  const [selectedOption, setSelectedOption]=useState("");
  const [data,setData]=useState(null);
  const [value, setValue] = useState('1');

  // latest Updates Array
  const [latestUpdates, setLatestUpdates] = useState([
    {id:1,bankid:238,mediaid:22,treeid:147, img:"https://fi.scientiaconsulting.eu/wp-content/uploads/2021/03/wise1.jpg",category:"Delete a Recepient", platform:"Ios",totalFunc:202,totalAverageUX:763},
    {id:2,bankid:275,mediaid:16,treeid:64, img:"https://fi.scientiaconsulting.eu/wp-content/uploads/2022/09/migros3.png",category:"Search for a Transaction", platform:"Web",totalFunc:173,totalAverageUX:721},
    {id:3,bankid:275,mediaid:22,treeid:83, img:"https://fi.scientiaconsulting.eu/wp-content/uploads/2022/09/migros3.png",category:"Foreign Currency Transfer(FCY)", platform:"Ios",totalFunc:110,totalAverageUX:714},
    {id:4, bankid:284,mediaid:22,treeid:1179,img:"https://fi.scientiaconsulting.eu/wp-content/uploads/2022/08/macu-logo.jpeg",category:"Account Opening", platform:"Ios",totalFunc:159,totalAverageUX:804},
    {id:5,bankid:284,mediaid:16, treeid:1179,img:"https://fi.scientiaconsulting.eu/wp-content/uploads/2022/08/macu-logo.jpeg",category:"Account Opening", platform:"Web",totalFunc:176,totalAverageUX:782},
  ])

  // newly added reports
  const [newlyAddedReports, setNewlyAddedReports] = useState([
    {id:1, bankid:284,mediaid:16,treeid:442,img:"https://fi.scientiaconsulting.eu/wp-content/uploads/2022/08/macu-logo.jpeg",category:"Analysis Overview", platform:"Web",totalFunc:176,totalAverageUX:782},
    {id:2,bankid:284,mediaid:22,treeid:442, img:"https://fi.scientiaconsulting.eu/wp-content/uploads/2022/08/macu-logo.jpeg",category:"Analysis Overview", platform:"Ios",totalFunc:159,totalAverageUX:804},
    {id:3,bankid:173,mediaid:16,treeid:442, img:"https://fi.scientiaconsulting.eu/wp-content/uploads/2020/07/nubank2.2.png",category:"Analysis Overview", platform:"Web",totalFunc:18,totalAverageUX:887},
    {id:4,bankid:107,mediaid:16,treeid:442, img:"https://fi.scientiaconsulting.eu/wp-content/uploads/2019/10/nationwide1.png",category:"Analysis Overview", platform:"Apple Watch",totalFunc:2,totalAverageUX:0},
    {id:5,bankid:282,mediaid:27,treeid:442, img:"https://fi.scientiaconsulting.eu/wp-content/uploads/2022/03/robinhood.png",category:"Analysis Overview", platform:"Apple Watch",totalFunc:3,totalAverageUX:995},
  ])

  const [seed, setSeed] = useState(1);
  const reset = () => {
    setSeed(Math.random());
  }


  const [expanded, setExpanded] = React.useState([
    {id:1, status:true},
    {id:2, status:false},
    {id:3, status:false},
    {id:4, status:false},
    {id:5, status:false},


  ]);

  const handleExpandClick = (index) => {
    var newexpanded = expanded
    newexpanded[index].status = !newexpanded[index].status
    setExpanded(newexpanded)
    reset()
  };


  async function doAction_homepage() {

    get_subs()

     setIsLoading(true)


    /*var apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL+="/api/homepage";
     const metadataResponse = await fetch(apiURL, {
       method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       headers: {
         'bearer': userContext.userData.apiToken,
         'Content-Type': 'application/json'
       },
     });
     if(metadataResponse.status!==200) {
       location.href="/logout";
       return;
     }
     var resp = await metadataResponse.json();
    //  console.log(resp);
     setData(resp);*/
     let parser = new Parser();
     let feed = await parser.parseURL(process.env.REACT_APP_SERVER_URL+'/api/rss');
     console.log(feed.items);
     setData(feed.items);
     setIsLoading(false)

  }

async function get_subs(){


  // ADD NEW SUB
  // var d={};
  // d['name']="First";
  // d["Banks"]=[{BankID:"107"},{BankID:"112"},{BankID:"112"}]
  // var apiURL = process.env.REACT_APP_SERVER_URL;
  //   apiURL+="/api/add_subscription";
  //  const metadataResponse = await fetch(apiURL, {
  //    method: 'POST', // *GET, POST, PUT, DELETE, etc.
  // mode: 'cors', // no-cors, *cors, same-origin
  // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  // body:JSON.stringify(d),
  //    headers: {
  //      'bearer': userContext.userData.apiToken,
  //      'Content-Type': 'application/json'
  //    },
  //  });

  //  var resp = await metadataResponse.json();

  // GET SINGLE SUB
  // var d={};
  // d['subscription_id'] = '6322f4345aa4a43ecc06c188'
  // var apiURL = process.env.REACT_APP_SERVER_URL;
  //   apiURL+="/api/single_subscription";
  //  const metadataResponse = await fetch(apiURL, {
  //    method: 'POST', // *GET, POST, PUT, DELETE, etc.
  // mode: 'cors', // no-cors, *cors, same-origin
  // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  // body:JSON.stringify(d),
  //    headers: {
  //      'bearer': userContext.userData.apiToken,
  //      'Content-Type': 'application/json'
  //    },
  //  });

  //  var resp = await metadataResponse.json();


}

  useEffect(()=>{

  doAction_homepage();


  }, []) // <-- empty dependency array
  // Action buttons for the BookingCard

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles: CSSProperties = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const actionButtons = (
    <>
      <TooltipTop title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </TooltipTop>
      <TooltipTop title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </TooltipTop>
    </>
  );


  async function  loadOptions (inputValue) {


  // perform a request
  var d={};
  d['search']=inputValue;
 var res=[];
  //if (isRunning) return;
  setIsRunning(true);
  setSearchBanks(null);
  setSearchFunctions(null);
  setSearchFunctionsIos(null);
  var apiURL = process.env.REACT_APP_SERVER_URL;
    apiURL+="/api/search_bank";
   const metadataResponse = await fetch(apiURL, {
     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  body:JSON.stringify(d),

     headers: {
       'bearer': userContext.userData.apiToken,
       'Content-Type': 'application/json'
     },
   });

   var resp = await metadataResponse.json();
   var banks=[];
    resp.forEach(function(v,index) {
      var r=(<p>label : {v['_id']['BankName']}, value: {v['_id']['BankID'] }</p>);
      banks.push(r);
    });
    setSearchBanks(banks);

     res.push({label: "Banks", options: banks});

     var apiURL = process.env.REACT_APP_SERVER_URL;
       apiURL+="/api/search_functionalities_web";
      const metadataResponse2 = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     body:JSON.stringify(d),

        headers: {
          'bearer': userContext.userData.apiToken,
          'Content-Type': 'application/json'
        },
      });

      var resp = await metadataResponse2.json();
      var funcs=[];
       resp.forEach(function(v,index) {
         var r=(<p>label : {v['_id']['DisplayName']}, value: {v['_id']['NodeID'] }</p>);
         funcs.push(r);
       });

       setSearchFunctions(funcs);

       res.push({label: "Functionalities Web", options: funcs});

       var apiURL = process.env.REACT_APP_SERVER_URL;
         apiURL+="/api/search_functionalities_mobile";
        const metadataResponse3 = await fetch(apiURL, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
       mode: 'cors', // no-cors, *cors, same-origin
       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       body:JSON.stringify(d),

          headers: {
            'bearer': userContext.userData.apiToken,
            'Content-Type': 'application/json'
          },
        });

        var resp = await metadataResponse3.json();
        var funcsMobile=[];
         resp.forEach(function(v,index) {
           var r=(<p>label : {v['_id']['DisplayName']}, value: {v['_id']['NodeID'] }</p>);
             funcsMobile.push(r);
         });
         res.push({label: "Functionalities Mobile", options: funcsMobile});

         setSearchFunctionsIos(funcsMobile);

         setIsRunning(false);

};
const onChangeSelectedOption = (e) => {
    // console.log(e); // <---- this will be selected object not event
    const selectedOption = e.value; // <--- you can get value from object directly
    setSelectedOption(selectedOption);
};




  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));


  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>

        Your subscription doesn't include this bank.<br/>
        Contact your organization adminstrator.


      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default NoAccess;
