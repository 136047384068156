/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DefaultNavbar from "scientia/Navbars/DefaultNavbar";
import PageLayout from "scientia/LayoutContainers/PageLayout";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "page.routes";

// Authentication pages components
import Footer from "layouts/authentication/components/Footer";

function NewBasicLayout({ children }) {
  return (
    <PageLayout>
      <MDBox position="absolute" width="100%" minHeight="100vh" />
      <MDBox px={1} width="100%" height="100vh">
        {children}
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
NewBasicLayout.propTypes = {
  // image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default NewBasicLayout;
